import { API, axiosInstance } from "lib";

export const getStaffListData = (data = {}) => {
  return axiosInstance
    .post(API.GET_STAFF_LIST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const createStaff = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_STAFF, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const deleteStaffData = (data = {}) => {
  return axiosInstance
    .post(API.DELETE_STAFF , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const fetchStaffDetailsData = (data = {}) => {
  return axiosInstance
    .post(API.GET_SINGLE_STAFF , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getStaffChangeStatusData = (data = {}) => {
  return axiosInstance
    .post(API.CHANGE_STAFF_STATUS , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

