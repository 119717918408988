import { fetchBranchDetailsData } from '_services/accountsServices/branchServices';
import { getFuelStatementData } from '_services/accountsServices/fuelStationServices';
import { CommonInput } from 'components';
import { constant } from 'constants/constant';
import { UserContext } from 'context';
import { utils } from 'helper';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import StorageService from 'utils/StorageServices/StorageServices';

const VehicleDetailsTab = ({ onDataFetch, loadingBarRef, addFuel }) => {

    let { id } = useParams();
    const { masterData } = useContext(UserContext)
    const [detailsData, setDetailsData] = useState({})
    const [loading, setLoading] = useState(false);

    const getDataFuelStatement = async (comp_id, id) => {
        setLoading(true);
        try {
            let object = {
                company_id: comp_id,
                vehicle_id: id,
            }
            const res = await getFuelStatementData(object);
            if (res?.responseCode == 200) {
                setLoading(false);
                onDataFetch(res?.data?.account_detail);
                setDetailsData(res?.data?.account_detail);
                // setTotalItems(res?.data?.total_record);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            // throw error;
        }
    }

    useEffect(() => {
        if (StorageService.getCompanyId()) {
            getDataFuelStatement(StorageService.getCompanyId(), id);
        }
    }, [StorageService.getCompanyId(), id, addFuel])



    return (
        <div>
            {
                loading ? <div className='p-5'> Loading... </div> : (
                    <div className='_addContainer2 '>
                        <div className='_ptyDetail_bx'>
                            <div className="inrPrt_bxs">
                                <div className='_inrPrt_head'>Basic Details</div>
                                <div className='_inrPrt_flx _min_h_auto'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            placeholder="Vehicle No"
                                            label="Vehicle No *"
                                            name='name'
                                            value={detailsData?.vehicle_no}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth other_exl'>
                                        {
                                            constant.vehicleOwnership?.map((el, ind) => {
                                                return (
                                                    <React.Fragment key={ind}>
                                                        <input
                                                            className="form-check-input _avPointer"
                                                            id={+ind + "vehicle_ownership" + el?.value}
                                                            type="radio"
                                                            name="vehicle_ownership"
                                                            checked={+el?.value === +detailsData?.vehicle_ownership}
                                                            disabled
                                                        />
                                                        <label htmlFor={+ind + "vehicle_ownership" + el.value} className="form-check-label ml-2" >
                                                            {el.label}
                                                        </label> &nbsp;
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div className="inrPrt_bxs ">
                                <div className='_inrPrt_head'> Address </div>
                                <div className='_inrPrt_flx _min_h_auto'>
                                    <div className='addPr_flBx'>
                                        <ul className="nav nav-tabs cstNew_tabs _truckSelection" id="addParty" role="tablist">
                                            <li className='singleDiv'><h4 className='_trkHead'>Select Fleet Type</h4></li>
                                            {lorryType?.length > 0 ? lorryType?.map((item, indx) => (
                                                <li
                                                    className="nav-item"
                                                    role="presentation"
                                                    key={indx}
                                                >
                                                    <button id={item?.lorry_type}
                                                        className={
                                                            "nav-link " + (activeTab?.lorry_type === item?.lorry_type ? "active" : "")
                                                        }
                                                        type="button"
                                                        aria-selected={activeTab?.lorry_type === item?.lorry_type}
                                                        onClick={(e, val) => changeActiveTab(e, item)}
                                                    >
                                                        {item?.lorry_type}
                                                    </button>
                                                </li>
                                            )) : <p className='p-2 mt-3'> Loading... </p>}
                                        </ul>

                                        <div className='pr_procssBx' id="addPartyContent">
                                            <div className='pr_procssBx_grid'>
                                                <div className='singleDiv'>
                                                    <CustomSelect
                                                        options={(activeTab && activeTab?.props?.length > 0 && activeTab?.props[0]?.values?.length > 0) ? activeTab?.props[0]?.values?.map((el) => {
                                                            return {
                                                                label: el?.name,
                                                                value: el?._id
                                                            }
                                                        }) : []}
                                                        name="vehicle_type_id_1"
                                                        flotingLabel="Type * "
                                                        placeholder={activeTab?.props?.length > 0 && activeTab?.props[0]?.key_label ? activeTab?.props[0]?.key_label : 'Select Type'}
                                                        value={tyrelabel}
                                                    />
                                                </div>

                                                <div className='singleDiv _relatePos'>
                                                    <CommonInput
                                                        type="text"
                                                        placeholder="Weight Passing"
                                                        label="Weight Passing *"
                                                        name='weight_passing'
                                                        value={detailsData.weight_passing}

                                                    />
                                                    <span className='_weightSpan' > Tons </span>
                                                </div>

                                                <div>
                                                    <div className='singleDiv p-1'>
                                                        <div className="form-check ps-0 ">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={(detailsData?.manage_trip_kms > 0) ? true : false}
                                                                id='is_trip_km'
                                                            />
                                                            <label className="form-check-label" htmlFor='is_trip_km'> Manage Trip Kms </label>
                                                        </div>
                                                        {(detailsData?.manage_trip_kms > 0) ?
                                                            <div className='truck_checkBv mb-2 pt-0'>
                                                                {
                                                                    constant.manageTripKm?.map((el, ind) => {
                                                                        return (
                                                                            <React.Fragment key={ind}>
                                                                                <input
                                                                                    className="form-check-input _avPointer"
                                                                                    id={+ind + "manage_trip_kms" + el?.value}
                                                                                    type="radio"
                                                                                    name="manage_trip_kms"
                                                                                    checked={detailsData?.manage_trip_kms === el?.value}
                                                                                />
                                                                                <label htmlFor={+ind + "manage_trip_kms" + el.value} className="form-check-label" >
                                                                                    {el.label}
                                                                                </label> &nbsp;
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </div> : <></>}
                                                    </div>
                                                    {
                                                        (detailsData?.vehicle_ownership !== '2') && (
                                                            <React.Fragment>

                                                                {(detailsData?.manage_trip_kms !== 0) && (<><div className='singleDiv p-1'>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id='is_manage_fuel'
                                                                        value={detailsData?.manage_fuel}
                                                                        checked={detailsData.manage_fuel ? true : false}
                                                                    />
                                                                    <label className="form-check-label" htmlFor='is_manage_fuel'> Manage Fuel </label>
                                                                </div>
                                                                    {
                                                                        detailsData?.manage_fuel ? (
                                                                            <React.Fragment>
                                                                                <div className='pr_procssBx_grid mb-3'>
                                                                                    <div className='halfDiv'>
                                                                                        <CommonInput
                                                                                            type="number"
                                                                                            placeholder="Fleet Average"
                                                                                            label="Fleet Average"
                                                                                            name='vehicle_average'
                                                                                            value={detailsData.vehicle_average}
                                                                                        />
                                                                                    </div>

                                                                                    <div className='halfDiv _relatePos'>
                                                                                        <CommonInput
                                                                                            type="number"
                                                                                            placeholder="Opening Fuel"
                                                                                            label="Opening Fuel"
                                                                                            name='opening_fuel'
                                                                                            value={detailsData.opening_fuel}
                                                                                        />
                                                                                        <span className='_weightSpan' > LTR </span>
                                                                                    </div>

                                                                                    <div className='halfDiv'>
                                                                                        <CommonInput
                                                                                            type="number"
                                                                                            placeholder="Fuel Amount"
                                                                                            label="Fuel Amount"
                                                                                            name='fuel_amount'
                                                                                            value={detailsData.fuel_amount}
                                                                                        />
                                                                                    </div>

                                                                                    <div className='halfDiv'>
                                                                                        <CommonInput
                                                                                            type="number"
                                                                                            placeholder="Fuel Rate"
                                                                                            label="Fuel Rate"
                                                                                            name='fuel_rate'
                                                                                            value={(detailsData?.fuel_amount > 0 && detailsData?.opening_fuel > 0) ? (detailsData?.fuel_amount / detailsData?.opening_fuel).toFixed(2) : ''}
                                                                                            disabled
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        ) : <></>
                                                                    }

                                                                    <div className='singleDiv p-1'>
                                                                        <div className="form-check ps-0 ">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                checked={(detailsData?.fuel_out > 0) ? true : false}
                                                                                id='is_fuel_average'
                                                                            />
                                                                            <label className="form-check-label" htmlFor='is_fuel_average'> Fuel Out & Average Amount at Trip End </label>
                                                                        </div>
                                                                        {(detailsData?.fuel_out > 0) ? <div className='truck_checkBv mb-2 pt-0'>
                                                                            {
                                                                                constant.fuelOut_Average?.map((el, ind) => {
                                                                                    return (
                                                                                        <React.Fragment key={ind}>
                                                                                            <input
                                                                                                className="form-check-input _avPointer"
                                                                                                id={+ind + "fuel_out" + el?.value}
                                                                                                type="radio"
                                                                                                name="fuel_out"
                                                                                                checked={detailsData?.fuel_out === el?.value}
                                                                                            />
                                                                                            <label htmlFor={+ind + "fuel_out" + el.value} className="form-check-label" >
                                                                                                {el.label}
                                                                                            </label> &nbsp;
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div> : <></>}
                                                                    </div> </>)}

                                                            </React.Fragment>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {(detailsData?.vehicle_ownership === '1' || detailsData?.vehicle_ownership === '3') && (
                                        <div className='pr_procssBx' id="addPartyContent">
                                            <div className='pr_procssBx_grid'>
                                                <div className='singleDiv'>
                                                    <CommonInput
                                                        type="text"
                                                        name="driver_id"
                                                        label="Assign Driver"
                                                        placeholder="Assign Driver"
                                                        value={detailsData?.driver_id?.name}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {detailsData?.vehicle_ownership === '2' && (
                                        <div className='pr_procssBx' id="addPartyContent">
                                            <div className='pr_procssBx_grid'>
                                                <div className='singleDiv'>
                                                    <CommonInput
                                                        type="text"
                                                        name="supplier_id"
                                                        label="Assign Supplier"
                                                        placeholder="Assign Supplier"
                                                        value={detailsData?.supplier_id?.name}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div> */}

                            <div className="inrPrt_bxs">
                                <div className='_inrPrt_head'> Fleet Type Info </div>
                                <div className='_inrPrt_flx _min_h_auto'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="GST Type"
                                            placeholder="GST Type"
                                            value={detailsData?.gst_no}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="GST Number"
                                            placeholder="GST Number"
                                            value={detailsData?.gst_no}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="State State"
                                            placeholder="State State"
                                            value={detailsData?.gst_state?.label}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="inrPrt_bxs">
                                <div className='_inrPrt_head'>Tax Info</div>
                                <div className='_inrPrt_flx _min_h_auto'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="GST Type"
                                            placeholder="GST Type"
                                            value={masterData?.gst_type_list?.filter((el) => (el?.id == detailsData?.gst_type))[0]?.name}
                                            disabled
                                        />
                                    </div>

                                    {
                                        detailsData?.gst_no ? (
                                            <div className='_divForth'>
                                                <CommonInput
                                                    type="text"
                                                    label="GST Number"
                                                    placeholder="GST Number"
                                                    value={detailsData?.gst_no}
                                                    disabled
                                                />
                                            </div>
                                        ) : <></>
                                    }

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="State State"
                                            placeholder="State State"
                                            value={detailsData?.gst_state?.label}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default VehicleDetailsTab