import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  ButtonHeading,
  CommonDatePicker,
  CommonInput,
  CommonModal,
} from "components";
import { deleteConsignorData } from "_services/accountsServices/consignorServices";
import StorageService from "utils/StorageServices/StorageServices";
import toast, { Toaster } from "react-hot-toast";
import {
  deleteRole,
  deleteUser,
  getRole,
  getTrip,
  getTripCount,
  handleKeyDown,
} from "_services";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "context";
import MastersFilterModal from "modals/MastersFilterModal";
import Tab from "components/common/Tab";
import { constant } from "constants/constant";
import {
  AllDispatchDetailTab,
  CancelledTab,
  DeliveredTab,
  DraftTab,
  InTransitTab,
} from "components/DispatchDetailTabs";
import { ConfirmModalFor } from "modals";
import { errorSchema, utils } from "helper";
import images from "constants/images";
import { addEwayWill } from "features";
import { useDispatch, useSelector } from "react-redux";

const Trip = () => {
  const ewayinit = {
    eway_bill_number: "",
    start_date: new Date(),
    end_date: new Date(),
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { settingChild, masterData, tripFilters ,allMenu} = useContext(UserContext);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [userlist, setUserList] = useState([]);
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const childsUser = allMenu?.find((i) => i?.label === "Trip");
  const [activeTab, setActiveTab] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [tabList, setTabList] = useState([]);
  const [tripCountList, setTripCountList] = useState([]);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: "",
    trip_status: 0,
  });
  const tripObj = {
    company_id: StorageService.getCompanyId(),
    trip_status: 0,
    branch_id: "",
    consignor_id: "",
    limit: 20,
    offset: 1,
    start_date: "",
    end_date: "",
  };
  const { loading, error, userData, data } = useSelector((state) => state.trip);
  const [ewayForm, setEwayForm] = useState(ewayinit);
  const [formError, setFormError] = useState("");
  const [ewayBillId, setEwayBillId] = useState("");
  const [ewaybillShow, setEwaybillShow] = useState(false);
  const [lrId, setLrId] = useState();


  useEffect(() => {
    if (masterData) {
      const list = masterData?.trip_status_list
        ?.filter((i) => i?.name != "Draft")
        ?.map((trip) => {
          return {
            ...trip,
            id: trip?.id,
            title: trip?.name,
            value: trip?.name,
          };
        });

        const allOption = {
          id: 0, // You can set this to something unique
          title: "All",
          value: "All",
          name:"All"
        };

        setTabList([allOption, ...list]);
    
    }
  }, [masterData]);

  useEffect(() => {
    setActiveTab(tripFilters);
  }, [])

  useEffect(() => {
    getTripList(StorageService.getCompanyId());
    getTripCountList(StorageService.getCompanyId());
  }, [StorageService.getCompanyId(), filter]);

  const tabchange = useMemo(() => {
    setFilter({ ...filter, trip_status: activeTab });
  }, [activeTab]);

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const deleteHandler = (item) => {
    setIsDelete(item);
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };

  const submitDelete = async () => {
    setLoadingDelete(true);
    try {
      let object = {
        role_id: isDelete?.user_id,
        company_id: StorageService.getCompanyId(),
      };

      const res = await deleteUser(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getTripList(StorageService.getCompanyId());
        setLoadingDelete(false);
        setIsDelete(false);
      } else {
        setLoadingDelete(false);
        throw new Error(`Error: ${res.message}`);

      }
    } catch (error) {
      setLoadingDelete(false);
      if (error.name === "TypeError") {
        // Handle network errors, such as a failed fetch request
        console.error("Network error:", error.message);
      } else {
        // Handle other errors, such as a bad response
        console.error("Error:", error.message);
      }
    }
  };

  const mapCountsToStatus = (status, count) => {
    return status?.map((statusItem) => {
      let countValue = 0;
      switch (statusItem.name) {
        case "Completed":
          countValue = count.completed;
          break;
        case "At Party":
          countValue = count.at_party;
          break;
        case "Draft":
          countValue = count.draft;
          break;
        case "In Transit":
          countValue = count.in_transit;
          break;
        case "Delivered":
          countValue = count.delivered;
          break;
        case "Cancelled":
          countValue = count.cancelled;
          break;
        case "All":
          countValue = count?.all;
          break;
        default:
          break;
      }
      return { ...statusItem, count: countValue };
    });
  };

  const changes = useMemo(() => {
    
    if (tabList?.length && tripCountList) {
      return mapCountsToStatus(tabList, tripCountList);
    }
  }, [tabList, tripCountList]);

  const getTripList = async (id) => {
    setLoadingDelete(true);
    try {
      const res = await getTrip({
        company_id: id,
        branch_id: "",
        consignor_id: "",
        ...filter,
      });

      if (res?.responseCode === 200) {
        setUserList(res.data);

        setTotalItems(res?.data?.total_record);
        setLoadingDelete(false);
      } else {
        toast.error("Api not response");
        setLoadingDelete(false);
      }
    } catch (error) {
      // throw error;
    }
  };

  const getTripCountList = async (id) => {
    setLoadingDelete(true);
    try {
      const res = await getTripCount({
        company_id: id,
        branch_id: "",
        consignor_id: "",
        start_date: "",
        end_date: "",
      });

      if (res?.responseCode === 200) {
        setTripCountList(res?.data?.trip_status);
        setLoadingDelete(false);
      } else {
        toast.error("Api not response");
        setLoadingDelete(false);
      }
    } catch (error) {
      // throw error;
    }
  };

  const handleChange = async (name, value) => {
    const stateObj = {
      ...ewayForm,
      [name]: value,
    };
    setEwayForm(stateObj);
    if (!!formError) {
      const validationResult = await utils.checkFormError(
        stateObj,
        errorSchema.ewayBillSchima
      );

      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleEwayBillUpdate = async () => {
    const validationResult = await utils.checkFormError(
      { ...ewayForm },
      errorSchema.ewayBillSchima
    );
    const payload = {
      lr_id: lrId,
      eway_bill_number: ewayForm?.eway_bill_number,
      eway_bill_date: ewayForm?.start_date,
      eway_expiry_date: ewayForm?.end_date,
    };


    if (validationResult === true) {
      const res = await dispatch(addEwayWill(payload));

      if (res?.payload?.responseCode === 200) {
        getTripList(StorageService.getCompanyId());
        setEwaybillShow(false);
        setEwayForm({ ...ewayForm, start_date: new Date(), end_date: new Date(), eway_bill_number: "" })
      } else {
        throw new Error(res?.payload?.responseMessage);
      }
    } else {

      setFormError(validationResult);
    }
  };


  const callBackLrId = (lrDetails) => {

    if (lrDetails) {
      setLrId(lrDetails?._id);
      setEwaybillShow(true);
    }
  };

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDelete,
    isLoadding: loadingDelete,
  };

  const tripProps = {
    list: userlist,
    loading: loadingDelete,
    handlePageChange: handlePageChange,
    filter: filter,
    totalItems: totalItems,
    itemsPerPage: itemsPerPage,
    callBackLrId: callBackLrId,
  };

  const tabProps = {
    tabList: changes ? changes : [],
    callBackTab: (e) => setActiveTab(e),
    activeTab: activeTab,
  };

  let ewaybillprops = {
    show: ewaybillShow,
    closeModal: (e) => setEwaybillShow(false),
    title: "Update",
    submitTitle: "Update",
    onSubmit: handleEwayBillUpdate,
    icon: images.EDIT,
    isLoadding: loading,
    disabled: loading
  };

  return (
    <div>
      <ButtonHeading
        showSearchFilter={{
          search: true,
          filterOption: true,
          filterCount: true,
        }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        setFilter={setFilter}
        title={"Trip"}
        onClick={() => navigate("/trip/addtrip/createtrip")}
        buttonTitle={childsUser?.create === 1 ? "Add Trip" : ""}
      />

      <div className="addPr_flBx">
        {changes?.length > 0 && <Tab {...tabProps} />}

        <div>
          <AllDispatchDetailTab {...tripProps} />
          {/* {activeTab === 0 && <AllDispatchDetailTab {...tripProps} />}
          {activeTab === 1 && <AllDispatchDetailTab {...tripProps} />}
          {activeTab === 2 && <AllDispatchDetailTab {...tripProps} />}
          {activeTab === 3 && <AllDispatchDetailTab {...tripProps} />}
          {activeTab === 4 && <AllDispatchDetailTab {...tripProps} />}
          {activeTab === 5 && <AllDispatchDetailTab {...tripProps} />}
          {activeTab === 6 && <AllDispatchDetailTab {...tripProps} />} */}

          {/* {activeTab === 1 && <DraftTab/>}
          {activeTab === 2 && <InTransitTab/>}
          {activeTab === 3 && <DeliveredTab/>}
          {activeTab === 4 && <CancelledTab/>} */}
        </div>
      </div>

      {/* <div className='p-5'>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">S No.</th>
              <th scope="col">User Name</th>
              <th scope="col">Role</th>
              <th scope="col">Mobile</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {
              !loading ? (userlist?.trip_list?.length > 0 ? (
                userlist?.trip_list?.map((el, ind) => {
                  return (
                    <tr key={ind + 'abc'}>
                      <th scope="row"><Link to={`dispatched/${el?._id}`}> {el?.trip_number} </Link></th>
                      <td>
                        {el?.driver_id?.name}
                      </td>
                      <td>
                        {el?.role_name}
                      </td>
                      <td>{el?.mobile_no}</td>
                      <td>
                        {childsUser?.permissions?.edit === 1 && <span disabled={loading}>Edit</span>} &nbsp; &nbsp;
                        {childsUser?.permissions?.delete === 1 && <span onClick={(e) => deleteHandler(el)}>Delete</span>}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr><td> No data available </td></tr>
              )) : (
                <tr><td>Loading...</td></tr>
              )
            }
          </tbody>
        </table>
      </div> */}

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? "show" : ""
          }`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? "visible" : "hidden" }}
      >
        {showFilterModal && (
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={(e) => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
            isShow={{
              datePicker: true,
            }}
          />
        )}
      </div>
      <div className={showFilterModal ? "offBack_drop" : ""}></div>

      {isDelete?.user_id && (
        <CommonModal {...propsObject}>
          <p className="p-3">
            {" "}
            Are you sure you want to delete {isDelete?.company_user_name}{" "} ?
          </p>
        </CommonModal>
      )}

      {ewaybillShow && (
        <ConfirmModalFor {...ewaybillprops}>
          <>
            <div className="colFlx">
              <div className="_addRouts_flex">
                <CommonDatePicker
                  name="dispatch_date"
                  label="Date of Dispatch"
                  startDate={ewayForm?.start_date}
                  sedatetStartDate={(data) => {
                    handleChange("start_date", data);
                  }}
                  error={formError?.start_date}
                  focus={formError?.start_date}
                />
                <CommonDatePicker
                  name="dispatch_date"
                  label="Date of Dispatch"
                  startDate={ewayForm?.end_date}
                  setStartDate={(data) => {
                    handleChange("end_date", data);
                  }}
                  error={formError?.end_date}
                  focus={formError?.end_date}
                />
              </div>
              <CommonInput
                label="Eway bill number"
                placeholder="Enter Number"
                name="eway_bill_number"
                onChange={({ target: { name, value } }) =>
                  handleChange(name, value)
                }
                maxLength={12}
                value={ewayForm?.eway_bill_number}
                onKeyDown={handleKeyDown}
                error={formError?.eway_bill_number}
                focus={formError?.eway_bill_number}
              />
            </div>
          </>
        </ConfirmModalFor>
      )}
    </div>
  );
};

export default Trip;
