import React, { useState, useEffect, useContext, useRef } from 'react'
import { CommonInput, CommonModal, CustomSelect } from 'components'
import { AddButton } from 'components/common/CommonButton'
import { AddRateModal } from 'modals'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import StorageService from 'utils/StorageServices/StorageServices'
import BootstrapTable from 'components/BootstrapTable'
import { UserContext } from 'context'
import { activeInactiveRateStatus, deleteRateData, getRateListData } from '_services/accountsServices/rateServices'
import LoadingBar from 'react-top-loading-bar';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import toast from 'react-hot-toast';
import { constant } from 'constants'
import { getContractDropdownListData } from '_services/dropdownServices/dropdownService'
import RateDetailsModal from 'modals/RateDetailsModal'

const Rate = () => {

    let { id } = useParams();
    const loadingBarRef = useRef(null);
    const { masterData } = useContext(UserContext)
    const company_id = StorageService.getCompanyId();
    const [show, setShow] = useState(false)
    const [rateList, setRateList] = useState([]);
    const [contractList, setContractList] = useState([]);
    const [isRateDetails, setIsRateDetails] = useState(false);

    const [searchInput, setSearchInput] = useState('')
    const [loading, setLoading] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const itemsPerPage = 100;
    const [totalItems, setTotalItems] = useState(0);
    const [filter, setFilter] = useState({
        limit: 100,
        offset: 1,
        status: { label: 'All', value: '' },
        contract_id: {},
        search: '',
    });

    const getListRateData = async (id_1, id_2) => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                ...filter,
                search: filter?.search,
                status: filter?.status?.value ? filter?.status?.value : '',
                contract_id: filter?.contract_id?.value ? filter?.contract_id?.value : '',
                consignor_id: id_1,
                company_id: id_2,
            }
            const res = await getRateListData(object);
            if (res?.responseCode == 200) {
                setRateList(res?.data?.rate_list);
                setTotalItems(res?.data?.total_record);
            }
        } catch (error) {
            console.log("error", error)
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    useEffect(() => {
        if (filter?.contract_id?.value) {
            getListRateData(id, company_id);
        }

    }, [show, filter])

    let propsObject = {
        isOpen: show,
        closeModal: () => setShow(!show),
        headerPart: true,
        footerPart: true,
        modalMainClass: "_mdModal",
        modalTitle: "Add Rate",
    }

    let rateDetailsProps = {
        isOpen: isRateDetails,
        closeModal: () => setIsRateDetails(false),
        headerPart: true,
        footerPart: true,
        hideFooterSumbmitBtn: true,
        modalMainClass: "_mdModal",
        modalTitle: "Rate Details",
    }

    const handleStatusEvent = async (data, ind) => {
        try {
            const payload = {
                company_id: company_id,
                active: data?.active === 1 ? 0 : 1, // active = 1 , inactive = 0
                consignor_id: data?.consignor_id?._id,
                contract_id: data?.contract_id?._id,
                contract_rate_id: data?._id,
            };

            const res = await activeInactiveRateStatus(payload);
            if (res?.responseCode === 200) {
                let list = [...rateList];
                list.splice(ind, 1, { ...list[ind], active: data?.active === 1 ? 0 : 1 })
                setRateList(list);
            } else {
                toast.error(`Invalid Request !!`);
            }
        } catch (error) {
            toast.error(`Invalid Request !!`);
        }
    };

    const getDropdownDataContractList = async () => {
        try {
            let object = {
                consignor_id: id,
                company_id: company_id,
                search: '',
                limit: 100,
                offset: 1,
            }
            const res = await getContractDropdownListData(object);
            if (res?.responseCode == 200) {
                let data = res?.data?.contract_list?.map((item) => {
                    return {
                        label: item?.name,
                        value: item?._id,
                        active: item?.active,
                    }
                })
                setContractList(data)
            }
        } catch (error) {
            // throw error;
        }
    }

    useEffect(() => {
        getDropdownDataContractList();
    }, [company_id]);

    const deleteHandler = (item) => {
        setIsDelete(item)
    }

    const submitDeleteContract = async () => {
        setLoading(true);
        try {
            let object = {
                company_id: isDelete?.company_id,
                contract_id: isDelete?.contract_id?._id,
                consignor_id: isDelete?.consignor_id?._id,
                contract_rate_id: isDelete?._id,
            }
            const res = await deleteRateData(object);
            if (res?.responseCode == 200) {
                toast.success(`${res?.responseMessage}`);
                getListRateData(id, company_id);
                setLoading(false);
                setIsDelete(false);
            } else {
                toast.success('Error !! ');
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            // throw error;
        }
    }

    let contractDeleteProps = {
        isOpen: isDelete,
        closeModal: setIsDelete,
        modalTitle: "Confirmation",
        submitTitle: "Confirm",
        cancleTitle: "Close",
        onSubmit: submitDeleteContract,
        isLoadding: loading,
    }

    const column = [
        { header: "", accessor: "serial" },
        { header: "Contract No.", accessor: "contract_no" },
        { header: "Contract Name", accessor: "contract_name" },
        { header: "From", accessor: "from" },
        { header: "To", accessor: "to" },
        { header: "Route", accessor: "route" },
        { header: "Rate Type", accessor: "rate_type" },
        { header: "Rate", accessor: "rate" },
        { header: "Amount", accessor: "amount" },
        { header: "Vehicle No", accessor: "vehicle_no" },
        { header: "Vehicle Type", accessor: "vehicle_type" },
        { header: "Vehicle Ownership", accessor: "vehicle_ownership" },
        { header: "Status", accessor: "status" },
    ];

    const rowDataList = (data) => {
        const listArray = data?.map((item, i) => ({
            ...item,
            serial: <>
                <div className="signDropdown">
                    <button className="dropbtn11">
                        <BsThreeDotsVertical />
                    </button>
                    <div className="dropdownContent">
                        <Link
                            // to={`/account/rate_details/${item?.consignor_id?._id}/${item?.contract_id?._id}/${item?._id}`}
                            className='_avPointer'
                            onClick={() => setIsRateDetails(item)}
                            style={{ textDecoration: 'none' }}
                        >
                            <span className='_samAnchor'>
                                <span className='viewIcon_avt'>
                                    <AiOutlineEye />
                                </span>View
                            </span>
                        </Link>

                        {/* <div
                            // onClick={(e) => editHandler(item)}
                            className='_avPointer'
                        >
                            <span className='_samAnchor'>
                                <span className='editIcon_avt'>
                                    <FiEdit />
                                </span>
                                Edit
                            </span>
                        </div> */}

                        {(item?.contract_id?.name !== 'Default') ?
                            <div
                                onClick={(e) => deleteHandler(item)}
                                className='_avPointer'
                            >
                                <span className='_samAnchor'>
                                    <span className='deleteIcon_avt'>
                                        <FaRegTrashCan color='red' />
                                    </span>
                                    Delete
                                </span>
                            </div>
                            : <></>}

                    </div>
                </div>
            </>,
            contract_no: (
                <>
                    <Link onClick={() => setIsRateDetails(item)} style={{ textDecoration: 'none' }}>{item?.contract_id?.code}</Link>
                </>
            ),
            contract_name: <>{item?.contract_id?.name}</>,
            from: <> {item?.from} </>,
            to: <> {item?.to} </>,
            route: <> {item?.route ? item?.route : '-'} </>,
            rate_type: <> {constant.rateTypeObject[item?.rate_type]} </>,
            rate: <> {item?.rate ? <> &#x20B9; {(item?.rate).toFixed(2)} </> : '-'} </>,
            amount: <> {item?.amount ? <> &#x20B9; {(item?.amount).toFixed(2)} </> : '-'}  </>,
            vehicle_no: <> {item?.vehicle_no ? item?.vehicle_no : '-'}  </>,
            vehicle_type: <> {item?.vehicle_type_name ? item?.vehicle_type_name : '-'} </>,
            vehicle_ownership: <> {item?.vehicle_ownership === 0 ? 'All' : (masterData?.vehicle_ownership[item?.vehicle_ownership] ? masterData?.vehicle_ownership[item?.vehicle_ownership] : '-')} </>,
            status: <>
                <div
                    className="_anvSwitcherNew form-check form-switch"
                    style={{ width: "125px" }}
                >
                    <input
                        className="form-check-input _pi_ter"
                        type="checkbox"
                        name="actStatus"
                        id={`actStatus-${item._id}`}
                        checked={item?.active === 1 ? true : false}
                        onChange={(e) => handleStatusEvent(item, i)}
                    />
                    <span className={item?.active === 1 ? 'green_status' : 'red_status'}>
                        {item?.active === 1 ? "Active" : "In Active"}
                    </span>
                </div>
            </>,
        }));
        return listArray;
    };

    // const handlePageChange = (page) => {
    //     setFilter((s) => ({ ...s, offset: page }))
    // };

    // const renderPagination = () => {
    //     const totalPages = Math.ceil(totalItems / itemsPerPage);
    //     let pages = [];
    //     for (let i = 1; i <= totalPages; i++) {
    //         pages.push(
    //             <li
    //                 key={i}
    //                 className={`page-item ${i === filter.offset ? "active" : ""}`}
    //             >
    //                 <a className="page-link _avPointer" onClick={(e) => handlePageChange(i)}>
    //                     {i}
    //                 </a>
    //             </li>
    //         );
    //     }

    //     return (
    //         <ul className="pagination ">
    //             <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
    //                 <a
    //                     className="page-link _avPointer"
    //                     onClick={() => handlePageChange(filter?.offset - 1)}
    //                 >
    //                     <IoChevronBack />
    //                 </a>
    //             </li>
    //             {pages}
    //             <li
    //                 className={`page-item ${filter?.offset === totalPages ? "disabled" : ""
    //                     }`}
    //             >
    //                 <a
    //                     className="page-link _avPointer"
    //                     onClick={() => handlePageChange(filter?.offset + 1)}
    //                 >
    //                     <IoChevronForward />
    //                 </a>
    //             </li>
    //         </ul>
    //     );
    // };

    useEffect(() => {
        const clearUse = setTimeout(() => {
            setFilter((s) => ({ ...s, search: searchInput }))
        }, [1000])
        return () => clearTimeout(clearUse)
    }, [searchInput])

    const filterOnhandleSelect = async (item, name) => {
        const stateObj = { ...filter, [name]: item };
        setFilter(stateObj);
    };

    useEffect(() => {
        if (contractList?.length > 0) {
            setFilter((s) => ({
                ...s,
                contract_id: contractList?.find((el) => (el?.active === 1)),
            }))
        }
    }, [contractList])

    return (
        <div>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <div className="contentHead">
                <div className='d-flex pt-3 pb-3 gap-3'>
                    <div>
                        <CustomSelect
                            options={[
                                { label: 'All', value: '' },
                                { label: 'Active', value: 1 },
                                { label: 'In Active', value: 0 },
                            ]}
                            name="status"
                            label="Select Status"
                            placeholder="Select Status"
                            value={filter?.status?.label ? filter?.status : ''}
                            onSelect={(data) => filterOnhandleSelect(data, "status")}
                            isClearOption={true}
                            isClearHandleChange={() => setFilter((s) => ({ ...s, status: { label: 'All', value: '' } }))}
                        />
                    </div>
                    <div>
                        <CustomSelect
                            options={contractList}
                            name="contract"
                            label="All Contract"
                            placeholder="All Contract"
                            value={filter?.contract_id?.label ? filter?.contract_id : ''}
                            onSelect={(data) => filterOnhandleSelect(data, "contract_id")}
                        />
                    </div>
                    <div>
                        <CommonInput
                            type="search"
                            placeholder="Search"
                            label="Search"
                            name='search'
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </div>
                </div>
                <div className='_rSideBtn'>
                    <AddButton onClick={() => setShow(!show)} name='Add Rate' />
                </div>
            </div>

            <div className='_tabWith_outTab'>
                <BootstrapTable
                    columns={column}
                    data={rowDataList(
                        rateList?.length
                            ? rateList
                            : []
                    )}
                />
                {/* <div className='paginationFt_avt '>
                    {renderPagination()}
                </div> */}
            </div>

            {show
                && (
                    <AddRateModal {...propsObject} />
                )
            }

            {isRateDetails
                && (
                    <RateDetailsModal {...rateDetailsProps} />
                )
            }

            {
                isDelete?.company_id && (
                    <CommonModal {...contractDeleteProps}>
                        <p className='p-3'> Are you sure you want to delete {isDelete?.name} ?</p>
                    </CommonModal>
                )
            }
        </div >
    )
}

export default Rate