import React, { useEffect, useState } from "react";
import { CommonModal, CustomSelect } from "components";
import {
  getAddressBookDropdownListData,
  getConsigneeDropdownListData,
  getUnitDropdownListData,
} from "_services/dropdownServices/dropdownService";
import StorageService from "utils/StorageServices/StorageServices";
import { errorSchema, utils } from "helper";
import AddEditConsignor from "modals/AddEditConsignor/AddEditConsignor";
import AddEditConsignee from "modals/AddEditConsignee";

const SelectConsigneeLrModal = (props) => {
  let {
    isOpen,
    closeModal,
    nextModal,
    selectConsignee,
    location_point,
    consignor_id,
  } = props;
  const [consigneeDropdownData, setConsigneeDropdownData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    consignee_id: {},
  });
  const [formError, setFormError] = useState({});

  const getDropdownDataConsigneeList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 1,
        search: "",
        consignor_id: consignor_id,
        location_point: location_point,
      };
      // const res = await getConsigneeDropdownListData(object); // change api unit to account
      const res = await getAddressBookDropdownListData(object); // change api unit to account
      if (res?.responseCode == 200) {
        setConsigneeDropdownData(res?.data?.list);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  };

  useEffect(() => {
    setLoading(true);
    getDropdownDataConsigneeList();
  }, [props?.isOpen, StorageService.getCompanyId()]);

  const onhandleSelect = async (item, name) => {
    const stateObj = { ...formValue, [name]: item };
    setFormValue(stateObj);

    if (!utils.isObjectKeyEmpty(formError)) {
      const validationResult = await utils.checkFormError(
        stateObj,
        errorSchema.selectConsigneeLrModalSchema
      );
      if (validationResult === true) {
        setFormError({});
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleSubmit = async () => {
    const validationResult = await utils?.checkFormError(
      formValue,
      errorSchema.selectConsigneeLrModalSchema
    );
    if (utils?.isObjectKeyEmpty(validationResult)) {
      setLoading(true);
      selectConsignee(formValue?.consignee_id);
      closeModal();
      setFormError("");
    } else {
      setFormError(validationResult);
      setLoading(false);
    }
  };

  let consigneeProps = {
    ...props,
    isLoadding: loading,
    disabled: loading,
    onSubmit: (e) => handleSubmit(e),
  };

  const addNewItemHandler = () => {
    nextModal(true);
    closeModal(false);
  };

  useEffect(() => {
    if (consigneeDropdownData?.length > 0) {
      setFormValue((s) => ({
        ...s,
        consignee_id: isOpen?._id
          ? consigneeDropdownData
              ?.map((el) => {
                return {
                  label: el?.name,
                  value: el?._id,
                };
              })
              ?.filter((el) => (el?.value === isOpen?._id ? el : ""))[0]
          : consigneeDropdownData?.map((el) => {
              return {
                label: el?.name,
                value: el?._id,
              };
            })[0],
      }));
    }
  }, [consigneeDropdownData?.length > 0]);

  return (
    <div>
      <CommonModal {...consigneeProps}>
        <div className="addLr_body">
          <CustomSelect
            options={consigneeDropdownData?.map((el) => {
              return {
                label: el?.name,
                value: el?._id,
              };
            })}
            name="consignee_id"
            label="Consignee Address"
            placeholder="Consignee Address"
            value={
              formValue?.consignee_id?.value ? formValue?.consignee_id : {}
            }
            onSelect={(data) => onhandleSelect(data, "consignee_id")}
            error={formError["consignee_id.label"]}
            isAddButton={true}
            addButtonAction={(e) => addNewItemHandler(e)}
          />
        </div>
      </CommonModal>
    </div>
  );
};

export default SelectConsigneeLrModal;
