import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CommonInput, CustomSelect } from 'components'
import { errorSchema, utils } from 'helper';
import { City, State } from "country-state-city";
import StorageService from "utils/StorageServices/StorageServices";
import { UserContext } from "context";
import toast, { Toaster } from 'react-hot-toast';
import { AddButton } from 'components/common/CommonButton';
import { createBranch, updateBranch, getBranch } from '_services/accountsServices/branchServices';
import { constant } from 'constants';
import { getStatesData } from '_services';

const formObj = {
    company_id: StorageService.getCompanyId(),
    branch: '',
    contact_no: '',
    branch_code: '',
    address_line1: '',
    fssai_licence: '',
    terms_conditions: '',
    state: {},
    city: {},
    pincode: '',
    email_id: '',
    alt_contact_no: '',
    gst_treatment_id: {},
    gst_no: '',
    gst_state: {},
    remarks: '',
    lr_bilty_format: '',
}

const newFormObj = {
    lr_bilty_format: '',
}


const AddEditBranch = ({ isOpen, onClose, updateBranchData }) => {

    const { masterData, cities, states } = useContext(UserContext);

    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [stateValue, setStateValue] = useState({});
    const [activeTab, setActiveTab] = useState(1);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [updateBranchID, setUpdateBranchID] = useState(null)
    const [gstType, setGstType] = useState(null)

    const [builtyFormat, setBuiltyFormat] = useState(newFormObj)

    useEffect(() => {
        if (!builtyFormat.lr_bilty_format) {
            if (masterData?.lr_bilty_format_list?.length) {
                const firstItem = {
                    label: masterData.lr_bilty_format_list[0]?.name,
                    value: masterData.lr_bilty_format_list[0]?.id,
                };
                setBuiltyFormat((prevState) => ({
                    ...prevState,
                    lr_bilty_format: firstItem,
                }));
            }
        }
    }, [masterData]);


    useEffect(()=>{
        setFormValue((prev)=>({
            ...prev,
            lr_bilty_format : builtyFormat?.lr_bilty_format?.value
        }))
    },[builtyFormat.lr_bilty_format])

    const onhandleSelectBuilty = async (item, name) => {
        const stateObj = { ...builtyFormat, [name]: item };
        setBuiltyFormat(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createBranch);
            setFormError(validationResult === true ? "" : validationResult);
        }
    };

    useEffect(() => {
        if (updateBranchData) {
            setUpdateBranchID(updateBranchData?._id)
        }
    }, [updateBranchData]);

    const findSingleBranchData = async () => {
        const object = {
            company_id: StorageService.getCompanyId(),
            branch_id: updateBranchID
        }
        try {
            const res = await getBranch(object);
            if (res?.responseCode == 200) {
                setFormValue(res?.data?.branch)
            }
        } catch (error) {
            toast.error('Error fetching Branch Data');
        }
    }

    useEffect(() => {
        if (formValue.gst_type) {
            setGstType(masterData?.gst_type_list?.find(item => item.id === formValue.gst_type))
        }
    }, [formValue.gst_type])

    useEffect(() => {
        if (updateBranchData && formValue.lr_bilty_format) {
            const biltyObject = masterData?.lr_bilty_format_list?.find(item => item.id === formValue.lr_bilty_format)
            setBuiltyFormat((prev) => ({
                ...prev,
                lr_bilty_format: {
                    value: biltyObject.id,
                    label: biltyObject.name
                }
            }))
        }
    }, [formValue.lr_bilty_format, updateBranchData])

    useEffect(() => {
        if (gstType) {
            setFormValue((prevData) => ({
                ...prevData,
                gst_treatment_id: {
                    value: gstType.id,
                    label: gstType.name
                }
            }));
        }
    }, [gstType])

    useEffect(() => {
        if (gstType) {
            setFormValue((prevData) => ({
                ...prevData,
                gst_treatment_id: {
                    value: gstType.id,
                    label: gstType.name
                }
            }));
        }
    }, [gstType])


    useEffect(() => {
        if (updateBranchID) {
            findSingleBranchData()
        }
    }, [updateBranchID])


    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createBranch);
            setFormError(validationResult === true ? "" : validationResult);
        }
    };

    const apiCallingFunction = async (shouldCloseModal) => {
        try {
            let object = {
                ...formValue,

                gst_treatment_id: formValue?.gst_treatment_id?.value,
                gst_state: {
                    value: formValue?.gst_state?.value,
                    label: formValue?.gst_state?.label,
                },
                alt_contact_no: formValue?.alt_contact_no || '',
                contact_no: formValue?.contact_no || '',
            };
            const res = await createBranch(object);
            if (res?.responseCode === 200) {
                setFormValue(formObj);
                setLoading(false);
                if (shouldCloseModal) {
                    onClose();
                } else {
                    setActiveTab(1); // Reset tab to the first tab
                }
                toast.success(`${res?.responseMessage}`);
            } else {
                setLoading(false);
                toast.error(`${res?.responseMessage}`);
            }
        } catch (error) {
            setLoading(false);
            toast.error('Error !!');
        } finally {
            setIsButtonDisabled(false);
        }
    };


    const handleClick = async (e) => {
        e.preventDefault();
        const validationResult = await utils.checkFormError(formValue, errorSchema.createBranch);

        console.log("validationResult",validationResult)

        if (activeTab === 3) {
            if (utils.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(true); // Pass true to close the modal
                setFormError("");
            } else {
                setFormError(validationResult);
                setLoading(false);
            }
        } else {
            if (utils.isObjectKeyEmpty(validationResult)) {
                setActiveTab(activeTab + 1);
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const handleResetFormAndSubmit = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true); // Disable the button immediately
        const validationResult = await utils.checkFormError(formValue, errorSchema.createBranch);
        if (activeTab === 3) {
            if (utils.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(false); // Pass false to keep the modal open and reset the form
                setFormValue(formObj);
                setFormError("");
            } else {
                setFormError(validationResult);
                setLoading(false);
                setIsButtonDisabled(false); // Re-enable the button if validation fails
            }
        } else {
            if (utils.isObjectKeyEmpty(validationResult)) {
                setActiveTab(activeTab + 1);
                setFormError("");
                setIsButtonDisabled(false); // Re-enable the button if validation fails
            } else {
                setFormError(validationResult);
            }
        }
    };

    const handleClickUpdateBranch = async (e) => {
        e.preventDefault();
        const validationResult = await utils.checkFormError(formValue, errorSchema.createBranch);
        if (activeTab === 3) {
            if (utils.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await updateApiCallingFunction();
                setFormError("");
            } else {
                setFormError(validationResult);
                setLoading(false);
            }
        } else {
            if (utils.isObjectKeyEmpty(validationResult)) {
                setActiveTab(activeTab + 1);
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };


    const onhandleSelect = async (item, name) => {
        const stateObj = { ...formValue, [name]: item };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createBranch);
            setFormError(validationResult === true ? "" : validationResult);
        }
    };



    const changeActiveTab = async (event, value) => {
        event.preventDefault();
        if (value > activeTab) {
            const validationResult = await utils.checkFormError(formValue, errorSchema.createBranch);
            if (validationResult === true) {
                setActiveTab(value);
            } else {
                setFormError(validationResult);
            }
        } else {
            setActiveTab(value);
        }
    };

    useEffect(() => {
        if (masterData?.gst_type_list?.length > 0 && states?.length > 0) {
            setFormValue((s) => ({
                ...s,
                gst_state: states.map((el) => ({
                    label: el?.name,
                    value: el?.isoCode,
                }))[0],
                gst_treatment_id: masterData?.gst_type_list.map((el) => ({
                    label: el?.name,
                    value: el?.id,
                }))[0],
                company_id: StorageService.getCompanyId(),
            }));
        }
    }, [masterData?.gst_type_list, states, StorageService.getCompanyId]);

    const getStatesByApi = async (countryCode, stateCode) => {
        try {
          let data = {
            countryCode : countryCode,
            stateCode : stateCode
          }
          let response = await getStatesData(data);
          if (response?.responseCode === 200) {
            setStateValue(response?.data[0]);
          }
        } catch (error) {
          toast.error("An unexpected error occurred. Please try again.")
        }
      }
    
    const handleCityChange = (data) => {
        handleOnChange("city", {
            label: data?.label,
            value: data?.name,
            lat:String(data?.latitude),
            long:String(data?.longitude)
        });

        getStatesByApi(data?.countryCode, data?.stateCode);
        
        // const stateDetails = State.getStateByCodeAndCountry(
        //     data?.stateCode,
        //     data?.countryCode
        // );
        // if (stateDetails?.name && stateDetails?.isoCode) {
        //     setStateValue(stateDetails);
        // }
    };

    useEffect(() => {
        if (stateValue?.name && stateValue?.isoCode) {
            handleOnChange("state", { label: stateValue?.name, value: stateValue?.isoCode ,lat:String(stateValue?.latitude),
                long:String(stateValue?.longitude)})
        }
    }, [stateValue]);

    const updateApiCallingFunction = async () => {
        try {
            let object = {
                ...formValue,
                branch_id: updateBranchID,
                gst_treatment_id: formValue?.gst_treatment_id?.value,
                gst_state: {
                    value: formValue?.gst_state?.value,
                    label: formValue?.gst_state?.label,
                },
                alt_contact_no: formValue?.alt_contact_no || '',
                contact_no: formValue?.contact_no || '',
                pincode: formValue?.pincode || '',
            };
            // Remove redundant _id if branch_id exists
            if (object.branch_id) {
                delete object._id;
                delete object.gst_type;
                delete object.created_by;
                delete object.user_count;
                delete object.active;
                delete object.is_deleted;
                delete object.createdAt;
                delete object.updatedAt;

            }
            const res = await updateBranch(object);
            if (res?.responseCode === 200) {
                setFormValue(formObj);
                toast.success('Branch Updated Successfully');
                setLoading(false);
                onClose();
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };


    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    return (
        <React.Fragment>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title">
                    {updateBranchData ? 'Update Branch' : 'Add Branch'}
                </h5>
                <button type="button" className="btn-close" onClick={() => onClose(!isOpen)}></button>
            </div>

            <div className="offcanvas-body">

                <div className='pr_procssBx'>
                    <div className='pr_procssBx_grid'>

                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                placeholder="Branch Name"
                                label="Branch Name *"
                                name='branch'
                                value={formValue.branch}
                                focus={!!(typeof formError === "object" && formError?.branch)}
                                error={!!(typeof formError === "object") ? formError?.branch : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                placeholder="Branch Code"
                                label="Branch Code"
                                name='branch_code'
                                value={formValue.branch_code}
                                focus={!!(typeof formError === "object" && formError?.branch_code)}
                                error={!!(typeof formError === "object") ? formError?.branch_code : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='singleDiv'>
                            <CommonInput
                                type="number"
                                placeholder="Mobile Number"
                                label='Mobile Number'
                                name='contact_no'
                                maxLength="10"
                                onInput={maxLengthCheck}
                                value={formValue.contact_no}
                                focus={!!(typeof formError === "object" && formError?.contact_no)}
                                error={!!(typeof formError === "object") ? formError?.contact_no : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                    </div>

                    {/*
                     <div className='checkBx_lv mt-2'>
                        {
                            constant.Receive_Pay?.map((el, ind) => {
                                return (
                                    <React.Fragment key={ind}>
                                        <input
                                            className="form-check-input"
                                            id={+ind + "pay_type" + el?.value}
                                            type="radio"
                                            name="pay_type"
                                            checked={formValue?.pay_type === el?.value}
                                            value={formValue?.pay_type ? formValue?.pay_type : ""}
                                            onChange={({ target: { name, value } }) =>
                                                handleOnChange(name, el?.value)
                                            }
                                        />
                                        <label htmlFor={+ind + "pay_type" + el.value} className="form-check-label" >
                                            {el.label}
                                        </label> &nbsp;
                                    </React.Fragment>
                                )
                            })
                        }
                    </div> 
                    */}
                </div>

                <div className='addPr_flBx'>
                    <ul className="nav nav-tabs cstNew_tabs" id="addParty" role="tablist">
                        {constant.addBranchTabs?.map((item, indx) => (
                            <li
                                className="nav-item"
                                role="presentation"
                                key={indx + item?.value}
                            >
                                <button
                                    className={
                                        "nav-link " + (activeTab === item?.id ? "active" : "")
                                    }
                                    type="button"
                                    aria-selected={activeTab === item?.id}
                                    onClick={(e, val) => changeActiveTab(e, item.id)}
                                >
                                    {item?.title}
                                </button>
                            </li>
                        ))}
                    </ul>

                    {activeTab === 1 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>

                                <div className='singleDiv'>
                                    <div className="h4Head">Add Address</div>
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="Address Line 1"
                                        label="Address Line 1"
                                        name='address_line1'
                                        value={formValue.address_line1}
                                        focus={!!(typeof formError === "object" && formError?.address_line1)}
                                        error={!!(typeof formError === "object") ? formError?.address_line1 : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={cities}
                                        onSelect={(data) => handleCityChange(data, 'city')}
                                        value={formValue?.city}
                                        placeholder="Select a city"
                                        error={formError["city.label"]}
                                        label="City"
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="State"
                                        label="State"
                                        name='state'
                                        disabled={true}
                                        value={formValue?.state?.label}
                                        focus={!!(typeof formError === "object" && formError?.state)}
                                        error={!!(typeof formError === "object") ? formError?.state : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="PIN Code"
                                        label="PIN Code"
                                        name='pincode'
                                        value={formValue.pincode}
                                        focus={!!(typeof formError === "object" && formError?.pincode)}
                                        error={!!(typeof formError === "object") ? formError?.pincode : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <div className="h4Head">Other Contact</div>
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="Alternate Number"
                                        label="Alternate Number"
                                        name='alt_contact_no'
                                        maxLength="10"
                                        onInput={maxLengthCheck}
                                        value={formValue.alt_contact_no}
                                        focus={!!(typeof formError === "object" && formError?.alt_contact_no)}
                                        error={!!(typeof formError === "object") ? formError?.alt_contact_no : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="Email"
                                        placeholder="Email ID"
                                        label='Email ID'
                                        name='email_id'
                                        value={formValue.email_id}
                                        focus={!!(typeof formError === "object" && formError?.email_id)}
                                        error={!!(typeof formError === "object") ? formError?.email_id : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="Remark"
                                        label='Remark'
                                        name='remarks'
                                        value={formValue.remarks}
                                        focus={!!(typeof formError === "object" && formError?.remarks)}
                                        error={!!(typeof formError === "object") ? formError?.remarks : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 2 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>
                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={masterData?.gst_type_list?.map((el) => {
                                            return {
                                                label: el?.name,
                                                value: el?.id
                                            }
                                        })}
                                        name="gst_treatment_id"
                                        flotingLabel="GST Type"
                                        value={formValue?.gst_treatment_id}
                                        focus={!!(typeof formError === "object" && formError?.gst_treatment_id)}
                                        error={!!(typeof formError === "object") ? formError?.gst_treatment_id : ""}
                                        onSelect={(data) => onhandleSelect(data, 'gst_treatment_id')}
                                        placeholder="Select GST Type"
                                    />
                                </div>


                                {
                                    (formValue?.gst_treatment_id?.value == '2' || formValue?.gst_treatment_id?.value == '3') && (
                                        <div className='singleDiv'>
                                            <CommonInput
                                                type="text"
                                                placeholder="GST Number"
                                                label='GST Number'
                                                name='gst_no'
                                                value={formValue.gst_no}
                                                onChange={({ target: { name, value } }) =>
                                                    handleOnChange(name, value)
                                                }
                                                focus={!!(typeof formError === "object" && formError?.gst_no)}
                                                error={!!(typeof formError === "object") ? formError?.gst_no : ""}
                                            />
                                        </div>
                                    )
                                }

                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={states?.map((el) => {
                                            return {
                                                label: el?.name,
                                                value: el?.isoCode
                                            }
                                        })}
                                        name="gst_state"
                                        flotingLabel="GST State"
                                        value={formValue?.gst_state}
                                        focus={!!(typeof formError === "object" && formError?.gst_state)}
                                        error={!!(typeof formError === "object") ? formError?.gst_state : ""}
                                        onSelect={(data) => onhandleSelect(data, 'gst_state')}
                                        placeholder="Select GST State"
                                    />
                                </div>

                            </div>
                        </div>
                    )}

                    {activeTab === 3 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>


                                <div className='singleDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="Fssai Licence"
                                        label="Fssai Licence"
                                        name='fssai_licence'
                                        value={formValue.fssai_licence}
                                        focus={!!(typeof formError === "object" && formError?.fssai_licence)}
                                        error={!!(typeof formError === "object") ? formError?.fssai_licence : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder='Terms and Conditions'
                                        label='Terms and Conditions'
                                        name='terms_conditions'
                                        value={formValue.terms_conditions}
                                        focus={!!(typeof formError === "object" && formError?.terms_conditions)}
                                        error={!!(typeof formError === "object") ? formError?.terms_conditions : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={masterData?.lr_bilty_format_list?.map((el) => {
                                            return {
                                                label: el?.name,
                                                value: el?.id
                                            }
                                        })}
                                        name="lr_bilty_format_list"
                                        flotingLabel="LR Bilty Format"
                                        value={builtyFormat?.lr_bilty_format}
                                        focus={!!(typeof formError === "object" && formError?.lr_bilty_format)}
                                        error={!!(typeof formError === "object") ? formError?.lr_bilty_format : ""}
                                        onSelect={(data) => onhandleSelectBuilty(data, 'lr_bilty_format')}
                                        placeholder="Select LR Bilty Format"
                                    />
                                </div>

                            </div>
                        </div>
                    )}
                </div>

            </div>

            <div className='offcanvas-footer'>
                {
                    (activeTab === 1 || activeTab === 2) && (
                        <AddButton
                            onClick={handleClick}
                            disabled={!formValue?.branch}
                            name='Continue'
                        />
                    )
                }
                {
                    activeTab === 3 && !updateBranchData && (
                        <React.Fragment>
                            <AddButton
                                onClick={handleClick}
                                disabled={loading}
                                name='Add Branch'
                            />
                            <button
                                className={isButtonDisabled ? '_addNewComm_btn_disabled' : '_addNewComm_btn'}
                                onClick={handleResetFormAndSubmit}
                                disabled={isButtonDisabled}
                            >
                                Add & New
                            </button>
                        </React.Fragment>
                    )
                }
                {
                    activeTab === 3 && updateBranchData && (
                        <AddButton
                            onClick={handleClickUpdateBranch}
                            disabled={loading}
                            name='Update Branch'
                        />
                    )
                }
            </div>


        </React.Fragment>
    )
}

export default AddEditBranch
