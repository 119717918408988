import { ButtonHeading, CommonInput, CustomSelect, Heading } from "components";
import Tab from "components/common/Tab";
import { MakePaymentModal } from "modals";
import React, { useContext, useEffect, useState } from "react";
import { constant } from "constants/constant";
import {
  AllDetails,
  ApprovedTab,
  CompletedTab,
  HoldTab,
  PendingTab,
  RejectedTab,
} from "components/PaymentDetailsTabs";
import { UserContext } from "context";
import { getDashbordData, getExpense, getUser, paymentCountApi } from "_services";
import { getConsignorListData } from "_services/accountsServices/consignorServices";
import toast from "react-hot-toast";
import StorageService from "utils/StorageServices/StorageServices";
import MastersFilterModal from "modals/MastersFilterModal";

const PaymentApproval = () => {
  const { masterData ,allMenu} = useContext(UserContext);
  const [showMakePayment, setShowMakePayment] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [newTabList, setNewTabList] = useState([]);
  const [filter, setFilter] = useState({
    branch_id: "",
    start_date: new Date(),
    end_date: "",
    user_id: "",
    expense_category_id: "",
    keyword: "",
  });
  const [countFilter,setCountFilter] = useState({
    company_id:StorageService.getCompanyId(),
    search:"",
    start_date:"",
    end_date:""
  })

  const [branchList, setBranchList] = useState([]);
  const [expenseList, setExpenseList] = useState([]);
  const [userlist, setUserList] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [list, setList] = useState({
    branch: [],
    user: [],
    expense: [],
  });

  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...filter,
      [name]: value,
    };
    setFilter(stateObj);
  };

useEffect(()=>{
setCountFilter({
  ...countFilter,
  search:filter?.search,
  start_date:filter?.start_date,
  end_date:filter?.end_date
})
},[filter])

  useEffect(() => {
    getBranchList();
    getExpenseHandler();
    getUserList();
  }, []);

 

  useEffect(() => {
    setList({
      ...list,
      branch: branchList,
      user: userlist,
      expense: expenseList,
    });
  }, [branchList, userlist, expenseList]);

  console.log(newTabList)

  useEffect(() => {

    getPaymentCount()
    if (masterData) {
     
      // const tablist = masterData?.payment_approval_status_list?.map(
      //   (item, index) => {
      //     return {
      //       ...item,
      //       id: item?.id,
      //       title: item?.name,
      //       value: item?.name,
      //     };
      //   }
      // );

      // setNewTabList(tablist);
    }
  }, [filter]);

  const getPaymentCount = async()=>{
    try {
      const res = await paymentCountApi(countFilter)
      console.log(res)
      if(res?.responseCode===200){
        setNewTabList(res?.data?.approval_count?.map((i)=>{
          return{
            title:i?.label,
            count:i?.value,
            id:i?.approval_status
          }
        }))
      }else{
        toast.error(res?.responseMessage)
      }
    } catch (error) {
      toast.error(error)
    }
  }

  const getBranchList = async () => {
    const res = await getDashbordData({
      company_id: StorageService.getCompanyId(),
      select_company_id: 1,
    });
    if (res?.responseCode === 200) {
      const list = res.data?.branch_list.map((i) => {
        return {
          ...i,
          label: i.branch,
          value: i._id,
        };
      });
      setBranchList(list);
    } else {
      toast.error(res?.responseMessage);
    }
  };

  const getUserList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 1,
        search: "",
        status: 1,
      };
      const res = await getUser(object);
      if (res?.responseCode == 200) {
        const list = res.data?.user_list.map((i) => {
          return {
            ...i,
            label: i.company_user_name,
            value: i.user_id,
          };
        });
        setUserList(list);
      }
    } catch (error) {
      // throw error;
    }
  };

  const getExpenseHandler = async () => {
    // try {
    //   const res = await getExpense({
    //     type: 2,
    //   });

    //   if (res?.responseCode === 200) {
    //     const details = res?.data?.categories?.map((item) => {
    //       return {
    //         label: item?.name,
    //         value: item?._id,
    //       };
    //     });

    //     setExpenseList(details);
    //   } else {
    //     console.log(res?.responseMessage);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  let firstMakePayment = {
    isOpen: showMakePayment,
    closeModal: (e) => setShowMakePayment(false),
    headerPart: false,
    footerPart: true,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Make Payment",
    submitTitle: "Add Freight",
    cancelButtonTitle: "Send for Approval",
  };

  const callBackTab = (data) => {
    setActiveTab(data);
  };

  const tabProps = {
    tabList: newTabList,
    callBackTab: callBackTab,
    activeTab: activeTab,
    
  };


  const pendingProps = {
    activeTab: activeTab,
    userlist: userlist,
    filter:filter,
    setFilter:setFilter
  };
  

  return (
    <>
      {/* <Heading title="Payment Approval" /> */}
      <ButtonHeading
       
        // onClick={toggleOffcanvas}
        buttonTitle="Payment Approval"
        showSearchFilter={{
          search: true,
          filterOption: true,
          filterCount: true,
        }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        filter={filter}
        setFilter={setFilter}
      />
      <div className="addPr_flBx">
        <Tab {...tabProps} />

        <div className="">
         
          {activeTab === 0 && <AllDetails {...pendingProps} />}
          {activeTab === 1 && <PendingTab {...pendingProps} />}
          {activeTab === 3 && <ApprovedTab {...pendingProps} />}
          {activeTab === 2 && <HoldTab {...pendingProps} />}
          {activeTab === 4 && <RejectedTab {...pendingProps} />}
          {activeTab === 5 && <CompletedTab {...pendingProps} />}
        </div>
      </div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          showFilterModal ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? "visible" : "hidden" }}
      >
        {showFilterModal && (
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
            list={list}
            isShow={{
              datePicker: true,
              allUser: true,
              allBranch: true,
              expenseCatagory: true,
            
            }}
            name="payment"
          />
        )}
      </div>

      <div className={showFilterModal ? 'offBack_drop' : ''}></div>

      {showMakePayment && (
        // <AddBankAccount {...firstMakePayment} />
        <MakePaymentModal {...firstMakePayment} />
      )}
    </>
  );
};

export default PaymentApproval;
