import React, { useContext, useEffect, useState, useRef } from 'react';
import { ButtonHeading, CommonInput } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import { useLocation, useParams } from 'react-router-dom';
import StorageService from 'utils/StorageServices/StorageServices';
import { UserContext } from 'context';
import LoadingBar from 'react-top-loading-bar';
import { utils } from 'helper';
import { fetchStaffDetailsData } from '_services/accountsServices/staffServices';
import { getComapnyUser } from '_services/dropdownServices/dropdownService';

const StaffDetails = () => {
  const location = useLocation();
  const loadingBarRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const tabFromQuery = parseInt(queryParams.get('tab')) || 1;
  const [activeTab, setActiveTab] = useState(tabFromQuery);
  let { id } = useParams();
  const { masterData, cities } = useContext(UserContext);
  let company_id = StorageService.getCompanyId();
  const [detailsData, setDetailsData] = useState({});
  const [linkUsers, setLinkUsers] = useState([])

  const getDataStaffDetails = async (id_1) => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        staff_id: id_1,
      };
      const res = await fetchStaffDetailsData(object);
      if (res?.responseCode == 200) {
        setDetailsData(res?.data?.staff);
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  };

  const getDropdownUserList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
      }
      const res = await getComapnyUser(object);
      if (res?.responseCode === 200) {
        let arrayData = res?.data?.user_list?.map((el) => {
          return {
            label: el?.company_user_name,
            value: el?.user_id?._id
          }
        })
        setLinkUsers(arrayData)
      } else {
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    if (id) {
      getDataStaffDetails(id);
      getDropdownUserList();
    }
  }, [id]);

  useEffect(() => {
    setActiveTab(tabFromQuery);
  }, [tabFromQuery]);

  const tabProps = {
    tabList: constant.staffDetailsTabs,
    callBackTab: (e) => setActiveTab(e),
    activeTab: activeTab,
  };

  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading title='Staff Details' buttonTitle="Edit" />

      <div className="addPr_flBx">
        <Tab {...tabProps} />
        {activeTab === 1 &&
          <>
            <div>
              <div className='_addContainer2 '>
                <div className='_ptyDetail_bx'>
                  <div className="inrPrt_bxs">
                    <div className='_inrPrt_head'>Basic Details</div>
                    <div className='_inrPrt_flx'>
                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          placeholder="Staff Name"
                          label="Staff Name *"
                          name='name'
                          value={detailsData?.name}
                          disabled
                        />
                      </div>

                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="Mobile Number"
                          placeholder="Mobile Number"
                          value={detailsData?.contact_no}
                          disabled
                        />
                      </div>

                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="Alternate Mobile Number"
                          placeholder="Alternate Mobile Number"
                          value={detailsData?.alt_contact_no}
                          disabled
                        />
                      </div>

                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="Email ID"
                          placeholder="Email ID"
                          value={detailsData?.email}
                          disabled
                        />
                      </div>

                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="Opening Balance (Rs)"
                          placeholder="Opening Balance (Rs)"
                          value={`${detailsData?.opening_balance} Rs`}
                          disabled
                        />
                      </div>
                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="As of Date"
                          placeholder="As of Date"
                          value={utils.formatDate(detailsData?.as_of_date)}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="inrPrt_bxs ">
                    <div className='_inrPrt_head'> Address </div>
                    <div className='_inrPrt_flx _min_h_auto'>
                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="Address Line 1"
                          placeholder="Address Line 1"
                          value={detailsData?.address_line1}
                          disabled
                        />
                      </div>

                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="State"
                          placeholder="State"
                          value={detailsData?.state?.label}
                          disabled
                        />
                      </div>

                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="City"
                          placeholder="City"
                          value={detailsData?.city?.label}
                          disabled
                        />
                      </div>

                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="PIN Code"
                          placeholder="PIN Code"
                          value={detailsData?.pincode}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="inrPrt_bxs">
                    <div className='_inrPrt_head'> Documents </div>
                    <div className='_inrPrt_flx _min_h_auto'>
                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="Pan Number"
                          placeholder="Pan Number"
                          value={detailsData.pan_no}
                          disabled
                        />
                      </div>

                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="Pan Expiry Date"
                          placeholder="Pan Expiry Date"
                          value={utils.formatDate(detailsData?.pan_expiry_date)}
                          disabled
                        />
                      </div>

                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="Aadhar Number"
                          placeholder="Aadhar Number"
                          value={detailsData?.aadhar_no}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="inrPrt_bxs">
                    <div className='_inrPrt_head'> Other Details </div>
                    <div className='_inrPrt_flx'>
                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="Salary Amount"
                          placeholder="Salary Amount"
                          value={detailsData?.salary_amount}
                          disabled
                        />
                      </div>

                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="Joining Date"
                          placeholder="Joining Date"
                          value={utils.formatDate(detailsData?.joining_date)}
                          disabled
                        />
                      </div>

                      <div className='_divForth'>
                        <CommonInput
                          type="text"
                          label="Link User"
                          placeholder="Link User"
                          value={linkUsers?.filter((el) => (el?.value == detailsData?.link_user))[0]?.label}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default StaffDetails;