import React, { useEffect, useState } from 'react'
import { CommonInput } from 'components'
import { errorSchema, utils } from 'helper';
import StorageService from "utils/StorageServices/StorageServices";
import toast from 'react-hot-toast';
import { AddButton } from 'components/common/CommonButton';
import { constant } from 'constants/constant';
import { createMarketDriver } from '_services/accountsServices/marketDriverServices';


const formObj = {
    company_id: StorageService.getCompanyId(),
    name: '',
    contact_no: '',
    driver_type: '2',
    alt_contact_no: '',
    licence_number: '',
    licence_expiry: '',
    aadhar_no: '',
}

const AddEditMarketDriver = (props) => {
    let { isOpen, onClose,callBackDriver } = props;
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);

    const [activeTab, setActiveTab] = useState(1);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createMarketDriverSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async (shouldCloseModal) => {
        try {
            const res = await createMarketDriver(formValue);
            if (!!res) {
                setFormValue({});
                setLoading(false);
                toast.success('Market Driver Created Successfully');
                if (shouldCloseModal) {
                    onClose();
                    callBackDriver(res?.data?.driver)
                } else {
                    setActiveTab(1); // Reset tab to the first tab
                }
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            setIsButtonDisabled(false);
        }
    }

    const handleClick = async (e) => {
        e.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createMarketDriverSchema);
        if (activeTab === 2) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(true); // Pass true to close the modal
                setFormError("");

            } else {
                setFormError(validationResult);
                setLoading(false);
            }
        } else {
            setActiveTab(activeTab + 1);
            setFormError(validationResult);
        }
    };

    const handleResetFormAndSubmit = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true); // Disable the button immediately
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createMarketDriverSchema);
        if (activeTab === 2) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(false);
                setFormValue((prevData) => ({
                    ...prevData,
                    company_id: StorageService.getCompanyId(),
                    name: '',
                    contact_no: '',
                    driver_type: '2',
                    alt_contact_no: '',
                    licence_number: '',
                    licence_expiry: '',
                    aadhar_no: '',
                }))
                setFormError("");

            } else {
                setFormError(validationResult);
                setLoading(false);
                setIsButtonDisabled(false); // Re-enable the button if validation fails
            }
        } else {
            setActiveTab(activeTab + 1);
            setFormError(validationResult);
            setIsButtonDisabled(false); // Re-enable the button if validation fails
        }
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const changeActiveTab = async (event, value) => {
        event.preventDefault();
        if (value > activeTab) {
            const validationResult = await utils.checkFormError(formValue, errorSchema.createMarketDriverSchema);
            if (validationResult === true) {
                setActiveTab(value);
            } else {
                setFormError(validationResult);
            }
        } else {
            setActiveTab(value);
        }
    };

    useEffect(() => {
        setFormValue((s) => ({
            ...s,
            company_id: StorageService.getCompanyId(),
        }))
    }, [StorageService.getCompanyId()])

    return (
        <>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Add Market Driver</h5>
                <button type="button" className="btn-close" onClick={() => onClose(!isOpen)}></button>
            </div>

            <div className="offcanvas-body">

                <div className='pr_procssBx'>
                    <div className='pr_procssBx_grid'>

                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                placeholder="Driver Name"
                                label="Driver Name *"
                                name='name'
                                value={formValue.name}
                                focus={!!(typeof formError === "object" && formError?.name)}
                                error={!!(typeof formError === "object") ? formError?.name : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='singleDiv'>
                            <CommonInput
                                type="number"
                                placeholder="Mobile Number"
                                label='Mobile Number'
                                name='contact_no'
                                maxLength="10"
                                onInput={maxLengthCheck}
                                value={formValue.contact_no}
                                focus={!!(typeof formError === "object" && formError?.contact_no)}
                                error={!!(typeof formError === "object") ? formError?.contact_no : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                    </div>
                </div>

                <div className='addPr_flBx'>
                    <ul className="nav nav-tabs cstNew_tabs" id="addParty" role="tablist">
                        {constant.addMarketDriver?.map((item, indx) => (
                            <li
                                className="nav-item"
                                role="presentation"
                                key={indx + item?.value}
                            >
                                <button
                                    className={
                                        "nav-link " + (activeTab === item?.id ? "active" : "")
                                    }
                                    type="button"
                                    aria-selected={activeTab === item?.id}
                                    onClick={(e, val) => changeActiveTab(e, item.id)}
                                >
                                    {item?.title}
                                </button>
                            </li>
                        ))}
                    </ul>

                    {activeTab === 1 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="Alternate Number"
                                        label="Alternate Number"
                                        name='alt_contact_no'
                                        maxLength="10"
                                        onInput={maxLengthCheck}
                                        value={formValue.alt_contact_no}
                                        focus={!!(typeof formError === "object" && formError?.alt_contact_no)}
                                        error={!!(typeof formError === "object") ? formError?.alt_contact_no : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                            </div>
                        </div>
                    )}

                    {activeTab === 2 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>
                                <div className='halfDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="e.g. MP09N-2012-0280598"
                                        label="License Number"
                                        name='licence_number'
                                        value={formValue.licence_number}
                                        focus={!!(typeof formError === "object" && formError?.licence_number)}
                                        error={!!(typeof formError === "object") ? formError?.licence_number : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="date"
                                        placeholder="Licence Expiry Date"
                                        label="Licence Expiry Date"
                                        name='licence_expiry'
                                        onKeyDown={(e) => e.preventDefault()}
                                        value={formValue.licence_expiry}
                                        focus={!!(typeof formError === "object" && formError?.licence_expiry)}
                                        error={!!(typeof formError === "object") ? formError?.licence_expiry : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="Aadhar Number e.g.1111-2222-3333"
                                        label='Aadhar Number'
                                        name='aadhar_no'
                                        maxLength="12"
                                        onInput={maxLengthCheck}
                                        value={formValue.aadhar_no}
                                        focus={!!(typeof formError === "object" && formError?.aadhar_no)}
                                        error={!!(typeof formError === "object") ? formError?.aadhar_no : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <div className='offcanvas-footer'>
                {
                    (activeTab === 2 ? (
                        <React.Fragment>
                            <AddButton onClick={handleClick} disabled={loading} name='Add Market Driver' />
                            <button
                                className={isButtonDisabled ? '_addNewComm_btn_disabled' : '_addNewComm_btn'}
                                onClick={handleResetFormAndSubmit}
                                disabled={isButtonDisabled}

                            >
                                Add & New
                            </button>
                        </React.Fragment>
                    ) : (
                        <AddButton onClick={handleClick} disabled={!formValue?.name} name='Continue' />
                    ))
                }
                {/* <button className='_addNewComm_btn' disabled={loading ? loading : (!formValue?.consignor_group_id || !formValue?.template_no || !formValue?.gst_treatment_id || !formValue?.gst_state)}>Add & New</button> */}
            </div>

        </>
    )
}

export default AddEditMarketDriver