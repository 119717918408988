import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CommonInput, CustomSelect } from 'components'
import { errorSchema, utils } from 'helper';
import { City, State } from "country-state-city";
import Dropdown from 'components/common/Customdropdown/Dropdown';
import StorageService from "utils/StorageServices/StorageServices";
import { UserContext } from "context";
import debounce from 'lodash.debounce';
import toast, { Toaster } from 'react-hot-toast';
import { createConsignor, getAllConsignorgGroup, getConsignorListData } from '_services/accountsServices/consignorServices';
import { AddButton } from 'components/common/CommonButton';
import { constant } from 'constants/constant';
import { createOwnDriver } from '_services/accountsServices/ownDriverServices';
import { getStatesData } from '_services';

const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const formObj = {
    company_id: StorageService.getCompanyId(),
    name: '',
    contact_no: '',
    driver_type: 1,
    pay_type: '1',
    opening_balance: '',
    as_of_date: '',
    address_line1: '',
    address_line2: '',
    state: {},
    city: {},
    pincode: '',
    alt_contact_no: '',
    licence_number: '',
    licence_expiry: '',
    aadhar_no: '',
}

const initDrop = {
    city: "",
    state: "",
};

const AddEditOwnDriver = (props) => {
    let { isOpen, onClose, callBackDriver } = props;

    const { masterData, cities, states } = useContext(UserContext)

    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [city, setCity] = useState([]);
    const [consignorGroupList, setConsignorGroupList] = useState([])
    const [accountDrop, setAccountDrop] = useState(initDrop);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredCities, setFilteredCities] = useState([]);
    const [gstCharge, setGstCharge] = useState(false);
    const [stateValue, setStateValue] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [activeTab, setActiveTab] = useState(1);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createOwnDriverSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async (shouldCloseModal) => {
        try {
            let object = {
                ...formValue,
            }
            const res = await createOwnDriver(object);
            console.log(res)
            if (!!res) {
                toast.success('Own Driver Created Successfully');
                setFormValue({});
                setLoading(false);
                if (shouldCloseModal) {
                    onClose();
                } else {
                    setActiveTab(1); // Reset tab to the first tab
                }
                callBackDriver(res?.data?.driver)
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            setIsButtonDisabled(false);
        }
    }

    const handleClick = async (e) => {
        e.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createOwnDriverSchema);
        if (activeTab === 2) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(true);
                setFormError("");

            } else {
                setFormError(validationResult);
                setLoading(false);
            }
        } else {
            setActiveTab(activeTab + 1);
            setFormError(validationResult);
        }
    };

    const handleResetFormAndSubmit = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true); // Disable the button immediately
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createOwnDriverSchema);
        if (activeTab === 2) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(false);
                setFormValue((prevData) => ({
                    ...prevData,
                    company_id: StorageService.getCompanyId(),
                    name: '',
                    contact_no: '',
                    driver_type: 1,
                    pay_type: '1',
                    opening_balance: '',
                    as_of_date: getFormattedDate(),
                    address_line1: '',
                    address_line2: '',
                    state: {},
                    city: {},
                    pincode: '',
                    alt_contact_no: '',
                    licence_number: '',
                    licence_expiry: '',
                    aadhar_no: '',
                }))
                setFormError("");
            } else {
                setFormError(validationResult);
                setLoading(false);
                setIsButtonDisabled(false); // Re-enable the button if validation fails
            }
        } else {
            setActiveTab(activeTab + 1);
            setFormError(validationResult);
            setIsButtonDisabled(false); // Re-enable the button if validation fails
        }
    }


    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const changeActiveTab = async (event, value) => {
        event.preventDefault();
        if (value > activeTab) {
            const validationResult = await utils.checkFormError(formValue, errorSchema.createOwnDriverSchema);
            if (validationResult === true) {
                setActiveTab(value);
            } else {
                setFormError(validationResult);
            }
        } else {
            setActiveTab(value);
        }
    };

    useEffect(() => {
        setFormValue((s) => ({
            ...s,
            as_of_date: utils.getTodayDate(),
            company_id: StorageService.getCompanyId(),
        }))
    }, [StorageService.getCompanyId()])

    const getStatesByApi = async (countryCode, stateCode) => {
        try {
          let data = {
            countryCode : countryCode,
            stateCode : stateCode
          }
          let response = await getStatesData(data);
          if (response?.responseCode === 200) {
            setStateValue(response?.data[0]);
          }
        } catch (error) {
          toast.error("An unexpected error occurred. Please try again.")
        }
      }

    const handleCityChange = (data) => {
        handleOnChange("city", {
            label: data?.label,
            value: data?.name,
            lat:String(data?.latitude),
            long:String(data?.longitude)
        })

        getStatesByApi(data?.countryCode, data?.stateCode);

        // const stateDetails = State.getStateByCodeAndCountry(
        //     data?.stateCode,
        //     data?.countryCode
        // );
        // if (stateDetails?.name && stateDetails?.isoCode) {
        //     setStateValue(stateDetails)
        // }
    }

    useEffect(() => {
        if (stateValue?.name && stateValue?.isoCode) {
            handleOnChange("state", { label: stateValue?.name, value: stateValue?.isoCode ,lat:String(stateValue?.latitude),
                long:String(stateValue?.longitude)})
        }
    }, [stateValue])

    return (
        <>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title"> Add Own Driver </h5>
                <button type="button" className="btn-close" onClick={() => onClose(!isOpen)}></button>
            </div>

            <div className="offcanvas-body">

                <div className='pr_procssBx'>
                    <div className='pr_procssBx_grid'>

                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                placeholder="Driver Name"
                                label="Driver Name *"
                                name='name'
                                value={formValue.name}
                                focus={!!(typeof formError === "object" && formError?.name)}
                                error={!!(typeof formError === "object") ? formError?.name : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='singleDiv'>
                            <CommonInput
                                type="number"
                                placeholder="Mobile Number"
                                label='Mobile Number'
                                name='contact_no'
                                maxLength="10"
                                onInput={maxLengthCheck}
                                value={formValue.contact_no}
                                focus={!!(typeof formError === "object" && formError?.contact_no)}
                                error={!!(typeof formError === "object") ? formError?.contact_no : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='halfDiv'>
                            <CommonInput
                                type="text"
                                placeholder="Opening Balance (Rs)"
                                label="Opening Balance (Rs)"
                                name='opening_balance'
                                maxLength="15"
                                onInput={maxLengthCheck}
                                value={formValue.opening_balance}
                                focus={!!(typeof formError === "object" && formError?.opening_balance)}
                                error={!!(typeof formError === "object") ? formError?.opening_balance : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='halfDiv'>
                            <CommonInput
                                type="date"
                                placeholder="As of Date *"
                                label="As of Date *"
                                onKeyDown={(e) => e.preventDefault()}
                                // className="simpleDate_picker"
                                name='as_of_date'
                                value={formValue.as_of_date}
                                focus={!!(typeof formError === "object" && formError?.as_of_date)}
                                error={!!(typeof formError === "object") ? formError?.as_of_date : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>
                    </div>

                    <div className='checkBx_lv mt-2'>
                        {
                            constant.Receive_Pay?.map((el, ind) => {
                                return (
                                    <React.Fragment key={ind}>
                                        <input
                                            className="form-check-input"
                                            id={+ind + "pay_type" + el?.value}
                                            type="radio"
                                            name="pay_type"
                                            checked={formValue?.pay_type === el?.value}
                                            value={formValue?.pay_type ? formValue?.pay_type : ""}
                                            onChange={({ target: { name, value } }) =>
                                                handleOnChange(name, el?.value)
                                            }
                                        />
                                        <label htmlFor={+ind + "pay_type" + el.value} className="form-check-label" >
                                            {el.label}
                                        </label> &nbsp;
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='addPr_flBx'>
                    <ul className="nav nav-tabs cstNew_tabs" id="addParty" role="tablist">
                        {constant.addOwnDriver?.map((item, indx) => (
                            <li
                                className="nav-item"
                                role="presentation"
                                key={indx + item?.value}
                            >
                                <button
                                    className={
                                        "nav-link " + (activeTab === item?.id ? "active" : "")
                                    }
                                    type="button"
                                    aria-selected={activeTab === item?.id}
                                    onClick={(e, val) => changeActiveTab(e, item.id)}
                                >
                                    {item?.title}
                                </button>
                            </li>
                        ))}

                    </ul>

                    {activeTab === 1 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>

                                <div className='singleDiv'>
                                    <div className="h4Head">Add Address</div>
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="Address Line 1"
                                        label="Address Line 1"
                                        name='address_line1'
                                        value={formValue.address_line1}
                                        focus={!!(typeof formError === "object" && formError?.address_line1)}
                                        error={!!(typeof formError === "object") ? formError?.address_line1 : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={cities}
                                        onSelect={(data) => handleCityChange(data, 'city')}
                                        value={formValue?.city}
                                        placeholder="Select a city"
                                        error={formError["city.label"]}
                                        label="City"
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="State"
                                        label="State"
                                        name='state'
                                        disabled={true}
                                        value={formValue?.state?.label}
                                        focus={!!(typeof formError === "object" && formError?.state)}
                                        error={!!(typeof formError === "object") ? formError?.state : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="PIN Code"
                                        label="PIN Code"
                                        name='pincode'
                                        value={formValue.pincode}
                                        focus={!!(typeof formError === "object" && formError?.pincode)}
                                        error={!!(typeof formError === "object") ? formError?.pincode : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <div className="h4Head">Other Contact</div>
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="Alternate Number"
                                        label="Alternate Number"
                                        name='alt_contact_no'
                                        maxLength="10"
                                        onInput={maxLengthCheck}
                                        value={formValue.alt_contact_no}
                                        focus={!!(typeof formError === "object" && formError?.alt_contact_no)}
                                        error={!!(typeof formError === "object") ? formError?.alt_contact_no : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 2 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>
                                <div className='halfDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="e.g. MP09N-2012-0280598"
                                        label="License Number"
                                        name='licence_number'
                                        value={formValue.licence_number}
                                        focus={!!(typeof formError === "object" && formError?.licence_number)}
                                        error={!!(typeof formError === "object") ? formError?.licence_number : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="date"
                                        placeholder="Licence Expiry Date"
                                        label="Licence Expiry Date"
                                        name='licence_expiry'
                                        onKeyDown={(e) => e.preventDefault()}
                                        value={formValue.licence_expiry}
                                        focus={!!(typeof formError === "object" && formError?.licence_expiry)}
                                        error={!!(typeof formError === "object") ? formError?.licence_expiry : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="Aadhar Number e.g.1111-2222-3333"
                                        label='Aadhar Number'
                                        name='aadhar_no'
                                        maxLength="12"
                                        onInput={maxLengthCheck}
                                        value={formValue.aadhar_no}
                                        focus={!!(typeof formError === "object" && formError?.aadhar_no)}
                                        error={!!(typeof formError === "object") ? formError?.aadhar_no : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                </div>

            </div>

            <div className='offcanvas-footer'>
                {
                    (
                        activeTab === 2 ? (
                            <React.Fragment>
                                <AddButton onClick={handleClick} disabled={loading} name='Add Own Driver' />
                                <button
                                    className={isButtonDisabled ? '_addNewComm_btn_disabled' : '_addNewComm_btn'}
                                    onClick={handleResetFormAndSubmit}
                                    disabled={isButtonDisabled}

                                >
                                    Add & New
                                </button>
                            </React.Fragment>
                        ) : (
                            <AddButton onClick={handleClick} disabled={!formValue?.name} name='Continue' />
                        )
                    )
                }
            </div >

        </>
    )
}

export default AddEditOwnDriver