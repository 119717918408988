import React, { useContext, useEffect, useRef, useState } from "react";
import { getLedgerStatementData } from "_services/accountsServices/ledgerStatementServices";
import {
  ButtonHeading,
  CommonDateRange,
  CommonInput,
  NavTabs,
} from "components";
import { UserContext } from "context";
import { utils } from "helper";
import { Link, useNavigate, useParams } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";
import LoadingBar from "react-top-loading-bar";
import moment from "moment";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { ConfirmModalFor } from "modals";
import { deleteTransactionApi } from "_services";
import toast from "react-hot-toast";
import { Images } from "constants";

const Ledger = (props) => {
  let runing_balance = 0;
  let { id } = useParams();
  const navigate = useNavigate();
  const loadingBarRef = useRef(null);
  let { type } = props;

  const currentDate = new Date();
  const firstDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );
  const lastDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const { masterData, cities, states } = useContext(UserContext);
  const [ledgerStatementData, setLedgerStatementData] = useState([]);
  const [accountDetails, setAccountDetails] = useState({});
  const [openingBalance, setOpeningBalance] = useState(0);
  const [selectedRange, setSelectedRange] = useState({
    startDate: moment(firstDay).subtract(31, "days"),
    endDate: moment(lastDay),
  });

  const itemsPerPage = 100;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 100,
    offset: 1,
    // status: 1,
    // start_date: new Date(),
    // end_date: new Date()
  });
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getDataLedgerStatement = async (idd) => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        ...filter,
        start_date: moment(selectedRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(selectedRange.endDate).format("YYYY-MM-DD"),
        company_id: StorageService.getCompanyId(),
        vendor_type: type,
        vendor_id: idd,
      };
      const res = await getLedgerStatementData(object);
      if (res?.responseCode == 200) {
        setAccountDetails(res?.data?.account_detail);
        setLedgerStatementData(res?.data?.account_statement);
        setOpeningBalance(res?.data?.opening_balance);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {
      // throw error;
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  };

  useEffect(() => {
    getDataLedgerStatement(id);
  }, [id, filter, selectedRange]);

  const handleApply = (newRange) => {
    setSelectedRange(newRange);
    setFilter((s) => ({ ...s, offset: 1 }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };

  // const renderPagination = () => {
  //     const totalPages = Math.ceil(totalItems / itemsPerPage);
  //     let pages = [];
  //     for (let i = 1; i <= totalPages; i++) {
  //         pages.push(
  //             <li
  //                 key={i}
  //                 className={`page-item ${i === filter.offset ? "active" : ""}`}
  //             >
  //                 <a className="page-link _avPointer" onClick={(e) => handlePageChange(i)}>
  //                     {i}
  //                 </a>
  //             </li>
  //         );
  //     }

  //     return (
  //         <ul className="pagination ">
  //             <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
  //                 <a
  //                     className="page-link _avPointer"
  //                     onClick={() => handlePageChange(filter?.offset - 1)}
  //                 >
  //                     <IoChevronBack />
  //                 </a>
  //             </li>
  //             {pages}
  //             <li
  //                 className={`page-item ${filter?.offset === totalPages ? "disabled" : ""
  //                     }`}
  //             >
  //                 <a
  //                     className="page-link _avPointer"
  //                     onClick={() => handlePageChange(filter?.offset + 1)}
  //                 >
  //                     <IoChevronForward />
  //                 </a>
  //             </li>
  //         </ul>
  //     );
  // };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const currentPage = filter.offset;
    let pages = [];
    const createPageItem = (i) => (
      <li key={i} className={`page-item ${i === currentPage ? "active" : ""}`}>
        <a className="page-link _avPointer" onClick={() => handlePageChange(i)}>
          {i}
        </a>
      </li>
    );

    const rangeStart = Math.max(2, currentPage - 2);
    const rangeEnd = Math.min(totalPages - 1, currentPage + 2);

    pages.push(createPageItem(1));

    if (rangeStart > 2) {
      pages.push(
        <li key="dots-before" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    for (let i = rangeStart; i <= rangeEnd; i++) {
      pages.push(createPageItem(i));
    }

    if (rangeEnd < totalPages - 1) {
      pages.push(
        <li key="dots-after" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    return (
      <ul className="pagination ">
        <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset - 1)}
          >
            <IoChevronBack />
          </a>
        </li>
        {pages}
        <li
          className={`page-item ${
            filter?.offset === totalPages ? "disabled" : ""
          }`}
        >
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset + 1)}
          >
            <IoChevronForward />
          </a>
        </li>
      </ul>
    );
  };

  const getDate = (date) => {
    if (date) {
      const today = new Date(date);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  const handleDelteSubmit = (i) => {
    setConfirmModalShow(i);
  };

  const handleDelete = async () => {
    const payload = {
      company_id: StorageService?.getCompanyId(),
      transaction_id: confirmModalShow?.transaction_id,
    };
    try {
      setDeleteLoading(true);
      const res = await deleteTransactionApi(payload);
      if (res?.responseCode == 200) {
        toast.success(res?.responseMessage);
        setConfirmModalShow(false);
        setDeleteLoading(false);
        getDataLedgerStatement(id);
      } else {
        setDeleteLoading(false);
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      setDeleteLoading(false);
      toast.error(error?.message);
    }
  };

  let confirmModalProps = {
    show: confirmModalShow,
    closeModal: (e) => {
      setConfirmModalShow(false);
    },
    title: "Confirm",
    submitTitle: "Delete",
    onSubmit: handleDelete,
    confirmModal: "confirmModal",
    icon: Images.DELETEICON.default,
    isLoadding: deleteLoading,
    disabled: deleteLoading,
  };

  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <div>
        <div className="_rangeDate_bx">
          <div className="_fleX_inds">
            <div className="_divForth">
              <CommonDateRange
                initialStartDate={selectedRange.startDate}
                initialEndDate={selectedRange.endDate}
                onApply={handleApply}
              />
            </div>
          </div>
        </div>

        <div className="_ledgerOtr">
          <div className="ledger_details_main">
            <div className="ledger_details_div">
              <div className="ledger_address">
                <div>To</div>

                <div className="ledger_company">
                  {" "}
                  {StorageService?.getCompName()}{" "}
                </div>

                <div className="legder_iner_address">
                  {accountDetails?.address_line1 &&
                    accountDetails?.address_line1}{" "}
                  {accountDetails?.city?.value && accountDetails?.city?.value}{" "}
                  {accountDetails?.state?.label && accountDetails?.state?.label}
                </div>
              </div>

              <div className="ledger_expense">
                <div className="expense_date">
                  <div className="expence_title"> Statement of Accounts </div>
                  <span>
                    {" "}
                    {moment(selectedRange.startDate).format(
                      "YYYY-MM-DD"
                    )} To {moment(selectedRange.endDate).format("YYYY-MM-DD")}{" "}
                  </span>
                </div>
                <div>
                  <div className="tableForTripdetails">
                    <table className="table">
                      <thead>
                        <tr className="_headTr">
                          <th>Account Summary</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="modal_td">Opening Balance (Rs)</td>
                          <td align="right" className="modal_td">
                            <span
                              className={
                                Math.sign(openingBalance) === 1
                                  ? "green_Cls"
                                  : Math.sign(openingBalance) === -1
                                  ? "red_Cls"
                                  : ""
                              }
                            >
                              {openingBalance ? `${openingBalance} Rs` : 0}
                            </span>
                          </td>
                        </tr>
                        {/* <tr>
                                                    <td className="modal_td">Invoiced Amount</td>
                                                    <td align='right' className="modal_td"> 0 </td>
                                                </tr>
                                                <tr>
                                                    <td className="modal_td">Payment Received</td>
                                                    <td align='right' className="modal_td"> 0 </td>
                                                </tr> */}
                        <tr>
                          <td className="modal_td">Balance Due (Rs)</td>
                          <td align="right" className="modal_td">
                            {accountDetails?.balance
                              ? `${accountDetails?.balance} Rs`
                              : 0}
                          </td>
                        </tr>
                        <tr className="total_value"></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="ledger_table_div">
              <div className="tabFix ">
                <div className="container_table table-responsive tabMin_hgt">
                  <table role="table">
                    <thead className="table_head_style background">
                      <tr>
                        <th></th>
                        <th>
                          <div className="dFsx">Date</div>
                        </th>
                        <th>
                          <div className="dFsx">Voucher</div>
                        </th>
                        <th>
                          <div className="dFsx">Category</div>
                        </th>
                        <th>
                          <div className="dFsx">Type</div>
                        </th>
                        <th>
                          <div className="dFsx">Description</div>
                        </th>
                        <th>
                          <div className="dFsx">Credit (Rs)</div>
                        </th>
                        <th>
                          <div className="dFsx">Debit (Rs)</div>
                        </th>
                        <th>
                          <div className="dFsx">Balance (Rs)</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ledgerStatementData?.length > 0 ? (
                        ledgerStatementData?.map((el, ind) => {
                          // runing_balance += el?.credit_amount - el?.debit_amount

                          runing_balance +=
                            el?.debit_amount - el?.credit_amount;
                          return (
                            <tr key={ind}>
                              <td>
                               
                                  {el?.transaction_id && (
                                    <React.Fragment>
                                      <div className="signDropdown">
                                        <button className="dropbtn11">
                                          <BsThreeDotsVertical />
                                        </button>
                                        <div className="dropdownContent">
                                          <div
                                            onClick={(e) => {}}
                                            className="_avPointer"
                                          >
                                            <span className="_samAnchor">
                                              <span className="deleteIcon_avt">
                                                <AiOutlineEye color="green" />
                                              </span>
                                              View
                                            </span>
                                          </div>

                                          {/* <div
                                            onClick={(e) => {}}
                                            className="_avPointer"
                                          >
                                            <span className="_samAnchor">
                                              <span className="deleteIcon_avt">
                                                <FiEdit color="blue" />
                                              </span>
                                              Edit
                                            </span>
                                          </div>
                                          <div
                                            onClick={(e) => {
                                              handleDelteSubmit(el);
                                            }}
                                            className="_avPointer"
                                          >
                                            <span className="_samAnchor">
                                              <span className="deleteIcon_avt">
                                                <FaRegTrashCan color="red" />
                                              </span>
                                              Delete
                                            </span>
                                          </div> */}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )}
                              
                               
                              </td>
                              <td>
                                {el?.date ? (
                                  <div className="dFsx2">
                                    <div> {getDate(el?.date)} </div>
                                    <span>{utils?.formatTime(el?.date)}</span>
                                  </div>
                                ) : (
                                  <div className="dFsx2">
                                    <div> - </div>
                                    <span> - </span>
                                  </div>
                                )}
                              </td>

                              <td>
                                <div className="dFsx2">
                                  <span className="voucher">
                                    {" "}
                                    {el?.voucher_no ? el?.voucher_no : "-"}{" "}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="dFsx2">
                                  {el?.expense_category?.name
                                    ? el?.expense_category?.name
                                    : "-"}
                                </div>
                              </td>

                              <td>
                                <div className="dFsx2">
                                  {el?.type_name && el?.sub_type_name
                                    ? `${el.type_name} | ${el.sub_type_name}`
                                    : el?.type_name || el?.sub_type_name}
                                </div>
                              </td>

                              <td>
                                <div className="dFsx2">
                                  <div className="fBold">
                                    <Link
                                      className=""
                                      onClick={(e) =>
                                        navigate(
                                          `/trip/addtrip/dispatched/${el?.trip?._id}`
                                        )
                                      }
                                    >
                                      {el?.trip?.trip_number
                                        ? `${el?.trip?.trip_number}`
                                        : ""}{" "}
                                    </Link>
                                  </div>

                                  <div>
                                    {el?.trip?.route_name
                                      ? `${el?.trip?.route_name}`
                                      : ""}{" "}
                                    {el?.trip?.route_name && el?.description
                                      ? "|"
                                      : ""}
                                    {el?.description
                                      ? `${el?.description}`
                                      : // `${
                                        //     el?.description?.length > 10
                                        //       ? el?.description.slice(0, 10) +
                                        //         "..."
                                        //       : el?.description
                                        //   }`
                                        ""}
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="dFsx2">
                                  {el?.credit_amount ? (el?.credit_amount).toFixed(2) : "-"}
                                </div>
                              </td>

                              <td>
                                <div className="dFsx2">
                                  {el?.debit_amount ? (el?.debit_amount).toFixed(2) : "-"}
                                </div>
                              </td>

                              <td>
                                <div className="dFsx2">
                                  {runing_balance ? (runing_balance).toFixed(2) : 0}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td></td>
                          <td className="p-2"> No data </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="paginationFt_avt ">{renderPagination()}</div>
          </div>
        </div>
      </div>
      {confirmModalShow && (
        <ConfirmModalFor {...confirmModalProps}>
          <>
            <p className="_confirmPrx"> Are you sure you want to delete ?</p>
          </>
        </ConfirmModalFor>
      )}
    </div>
  );
};

export default Ledger;
