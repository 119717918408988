import React, { useContext, useEffect, useState } from 'react'
import { ButtonHeading, CommonModal, CustomPagination } from 'components'
import { deleteConsignorData } from '_services/accountsServices/consignorServices';
import StorageService from "utils/StorageServices/StorageServices";
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { AddEditConsignorGroupModal } from 'modals';

import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { deleteConsignorGroupData, getConsignorGroupListData } from '_services/accountsServices/consignorGroupServices';
import MastersFilterModal from 'modals/MastersFilterModal';
import { constant } from 'constants/constant';
import { UserContext } from 'context';

const ConsignorGroup = () => {

  const { settingChild } = useContext(UserContext);
  const settingChildConsignorGrp = settingChild?.find((i) => i?.label === "Party Group");
  const [isOpenZoneModal, setIsOpenZoneModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [consignorGroupList, setConsignorGroupList] = useState([])
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: '',
    status: 1
  });

  const toggleZoneModal = () => {
    setIsOpenZoneModal(!isOpenZoneModal);
  };

  const getDataConsignorGroupList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        ...filter
      }
      const res = await getConsignorGroupListData(object);
      if (res?.responseCode == 200) {
        setConsignorGroupList(res?.data?.consignor_group_list);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {
      // throw error;
    }
  }

  useEffect(() => {
    getDataConsignorGroupList();
  }, [filter, isOpenZoneModal])

  const deleteHandler = (item) => {
    setIsDelete(item)
  }

  const submitDeleteConsignor = async () => {
    setLoading(true);
    try {
      let object = {
        company_id: isDelete?.company_id,
        consignor_group_id: isDelete?._id,
      }
      const res = await deleteConsignorGroupData(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getDataConsignorGroupList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success('Error !! ');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  }

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteConsignor,
    isLoadding: loading,
  }

  let openZoneModal = {
    isOpen: isOpenZoneModal,
    closeModal: (e) => setIsOpenZoneModal(false),
    // nextModal: (e) => submitUpdateData(e),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: isOpenZoneModal?.view?"View Consignor Group":'Add Consignor Group',
    submitTitle: isOpenZoneModal?.view?"View Consignor Group":'Add Consignor Group',
    footerPart: true
  }

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Consignor Group Name", accessor: "consignor_group" },
    { header: "Number of Consignors", accessor: "number_of_consignors" },
    { header: "Status", accessor: "status" },
   
  ];

  const rowListData = (data) => {
    return data?.map((item, i) => ({
      ...item,
      // serial: <>{i + 1 + filter?.limit * (filter?.page - 1)}</>,
      serial: <>
        <div className="signDropdown">
          <button className="dropbtn11">
            <BsThreeDotsVertical />
          </button>
          <div className="dropdownContent">
            {/* <Link to={`/account/supplier/details/${item?._id}`} */}
            {settingChildConsignorGrp?.view === 1 && <div
            onClick={(e) => setIsOpenZoneModal({...item,view:true})}
              className='_avPointer'
              style={{ textDecoration: 'none' }}
            >
              <span className='_samAnchor'>
                <span className='viewIcon_avt'>
                  <AiOutlineEye />
                </span>View
              </span>
            </div>}

            {/* {settingChildConsignorGrp?.edit === 1 && <div
              // onClick={(e) => setIsOpenZoneModal(item)}
              className='_avPointer'
            >
              <span className='_samAnchor'>
                <span className='editIcon_avt'>
                  <FiEdit />
                </span>
                Edit
              </span>
            </div>} */}

            {settingChildConsignorGrp?.delete === 1 && <div
              onClick={(e) => deleteHandler(item)}
              className='_avPointer'
            >
              <span className='_samAnchor'>
                <span className='deleteIcon_avt'>
                  <FaRegTrashCan color='red' />
                </span>
                Delete
              </span>
            </div>}
          </div>
        </div>
      </>,
      consignor_group: <>{item?.name ? item?.name : '-'}</>,
      number_of_consignors: <>{item?.contact_nunber ? item?.contact_nunber : '-'}</>,
      status: <> {item?.active ? constant.activeArray[item?.active] : '-'} </>,
      action: (
        <>
          -
        </>
      ),
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }))
  };

 

  return (
    <div>
      <ButtonHeading
        title={"Consignor Group"}
        onClick={toggleZoneModal}
        buttonTitle={settingChildConsignorGrp?.create === 1 && "Add Consignor Group"}
        showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        filter={filter}
        setFilter={setFilter}
      />

      <div className='_tabWith_outTab'>
        <BootstrapTable
          columns={columns}
          data={rowListData(consignorGroupList?.length > 0 ? consignorGroupList : [])}
          isLoading={loading}
        />

        {consignorGroupList?.length && <div className='paginationFt_avt'>
        <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter.offset}
          onPageChange={handlePageChange}
        />
        </div>}
      </div>

      {
        isOpenZoneModal
        && (
          <AddEditConsignorGroupModal {...openZoneModal} />
        )
      }

      {
        isDelete?.company_id && (
          <CommonModal {...propsObject}>
            <p className='p-3'> Are you sure you want to delete {isDelete?.name} ?</p>
          </CommonModal>
        )
      }

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
      >
        {showFilterModal &&
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        }
      </div>
      <div className={showFilterModal ? 'offBack_drop' : ''}></div>

    </div>
  )
}

export default ConsignorGroup