import { getMasterRole, getRoleMasters, getSinglePlanSubscription } from "_services";
import { ButtonHeading, CommonInput, CommonTextarea } from "components";
import { addroleCreate } from "features/pageServices/pageAction";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";
import { NewRole } from ".";

const AddRole = () => {

  const formObj = {
    is_default: 1,
    // company_id: StorageService.getCompanyId(),
    plan_name: '',
    plan_type: {},
    description: '',
    no_of_branch: '',
    min_no_of_user: '',
    max_no_of_user: '',
    no_of_lr: '',
    no_of_trip: '',
    no_of_marketload: '',
    web_access: {},
    mobile_access: {},
    monthly_rate: '',
    user_rate: '',
    branch_rate: '',
    gst_perc: '',
    permission: [],
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, data } = useSelector((state) => state.auth);
  const isInitialRender = useRef(true);
  const [roleName, setRoleName] = useState("");
  const [description, setDescription] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [whichRole, setWhichRole] = useState();
  const [permission, setPermission] = useState(false);
  const [disble, setDisable] = useState(true);
  const [roleError, setRoleError] = useState({
    name: false,
    check: false,
  });
  const [formValue, setFormValue] = useState(formObj);
  const [roleModules, setRoleModules] = useState([])
  const [loadingApi, setLoadingApi] = useState(false);
  const [isLoading,setIsLoading] = useState(false)

  useEffect(() => {
    // getAllMain();
    getRoles()
  }, []);

  useEffect(() => {
    const flatChildren = roleModules
    .map((item) => item.children || []) // Extract children arrays
    .flat();
    const allViewsAreTwo = flatChildren.some(item => item.view == 1 ||item.edit == 1||item.delete == 1||item.create == 1||item.web == 1||item.mobile == 1);

   setDisable(allViewsAreTwo)
  }, [roleModules]);

  const getRoles = async (_id) => {
    try {
      if (_id) {
        let payload = {
          subscription_id: _id,
        }
        const res = await getSinglePlanSubscription(payload);
        if (res?.responseCode == 200) {
          let resData = {
            ...res?.data?.subscription,
            plan_type: { label: res?.data?.subscription?.plan_type, value: res?.data?.subscription?.plan_type },
            // web_access: constant.webAccess.find((el) => el?.value == res?.data?.subscription?.web_access),
            // mobile_access: constant.mobileAccess.find((el) => el?.value == res?.data?.subscription?.mobile_access),
          }
          setFormValue(resData);
          setRoleModules(res?.data?.subscription?.permission)
        }
      } else {
        const res = await getRoleMasters({company_id:StorageService.getCompanyId()});
        if (res?.responseCode == 200) {
          setRoleModules(res?.data?.permission)
        }
      }
    } catch (error) {
    }
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values
    if (value) {
      setRoleName(value);
      setRoleError("");
    } else {
      setRoleName("");
      setRoleError({ ...roleError, name: "Role Name is required" });
    }
  };

  const getAllMain = async () => {
    const postData = {
      company_id: StorageService.getCompanyId(),
    };
    try {
      let response = await getMasterRole(postData);

      if (response?.responseCode === 200) {
        setRoleData(response?.data?.menu_list);
        setWhichRole(response?.data?.which_role);

        await response?.payload?.menu_list.map((user) => {
          let array = [];
          user?.menus?.map((menus) => {
            array.push(
              ...array,
              menus?.permissions?.create === 1 ||
                menus?.permissions?.edit === 1 ||
                menus?.permissions?.delete === 1 ||
                menus?.permissions?.view === 1
            );
          });
          setPermission(array.every((i) => i) ? false : true);
        });
      }
    } catch (error) {}
  };

  const handleFullCheck = (e, index) => {
    const { id, checked, value } = e.target;

    roleData?.map((category, cateIndex) => {
      category?.menus.map((oneMenu, newIndex) => {
        if (id === `fullCheck_${index}` && index === cateIndex) {
          let newData = [...roleData];

          if (checked) {
            newData[cateIndex].menus[newIndex].full_access = 1;
          } else {
            newData[cateIndex].menus[newIndex].full_access = 0;
          }

          if (newData[cateIndex].menus[newIndex].full_access) {
            newData[cateIndex].menus[newIndex].view = 1;
            newData[cateIndex].menus[newIndex].edit = 1;
            newData[cateIndex].menus[newIndex].create = 1;
            newData[cateIndex].menus[newIndex].delete = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            newData[cateIndex].menus[newIndex].edit = 0;
            newData[cateIndex].menus[newIndex].create = 0;
            newData[cateIndex].menus[newIndex].delete = 0;
            setDisable(true);
          }

          setRoleData(newData);
        }
      });
    });
  };

  const handleSelectAll = (e, index) => {
    const { id, checked, value } = e.target;

    let fullcheckValue = false;

    roleData?.map((category, cateIndex) => {
      category?.menus.map((oneMenu, newIndex) => {
        if (
          id === `allSelect_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].full_access = newData[cateIndex]
            .menus[newIndex]?.full_access
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].full_access) {
            newData[cateIndex].menus[newIndex].view = 1;
            newData[cateIndex].menus[newIndex].edit = 1;
            newData[cateIndex].menus[newIndex].create = 1;
            newData[cateIndex].menus[newIndex].delete = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            newData[cateIndex].menus[newIndex].edit = 0;
            newData[cateIndex].menus[newIndex].create = 0;
            newData[cateIndex].menus[newIndex].delete = 0;
            setDisable(true);
          }

          setRoleData(newData);
        }
        if (oneMenu.full_access) {
          fullcheckValue = true;
        }
      });

      setDisable(!fullcheckValue);
    });
  };

  const handleChange = (e, index) => {
    const { id, checked, value } = e.target;

    roleData?.map((category, cateIndex) => {
      category?.menus.map((oneMenu, newIndex) => {
        if (
          id === `view_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].view = newData[cateIndex].menus[
            newIndex
          ]?.view
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].view) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;

            setDisable(true);
          }

          setRoleData(newData);
        } else if (
          id === `create_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].create = newData[cateIndex].menus[
            newIndex
          ]?.create
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].create) {
            newData[cateIndex].menus[newIndex].create = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].create = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;

            setDisable(true);
          }

          if (
            newData[cateIndex].menus[newIndex].create ||
            newData[cateIndex].menus[newIndex].edit ||
            newData[cateIndex].menus[newIndex].delete
          ) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            setDisable(true);
          }

          setRoleData(newData);
        } else if (
          id === `edit_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].edit = newData[cateIndex].menus[
            newIndex
          ]?.edit
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].edit) {
            newData[cateIndex].menus[newIndex].edit = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].edit = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;

            setDisable(true);
          }

          if (
            newData[cateIndex].menus[newIndex].create ||
            newData[cateIndex].menus[newIndex].edit ||
            newData[cateIndex].menus[newIndex].delete
          ) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            setDisable(true);
          }

          setRoleData(newData);
        } else if (
          id === `delete_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].delete = newData[cateIndex].menus[
            newIndex
          ]?.delete
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].delete) {
            newData[cateIndex].menus[newIndex].delete = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].delete = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;
            setDisable(true);
          }

          if (
            newData[cateIndex].menus[newIndex].create ||
            newData[cateIndex].menus[newIndex].edit ||
            newData[cateIndex].menus[newIndex].delete
          ) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            setDisable(true);
          }

          setRoleData(newData);
        }

        let newData = [...roleData];

        if (
          newData[cateIndex].menus[newIndex].create &&
          newData[cateIndex].menus[newIndex].edit &&
          newData[cateIndex].menus[newIndex].delete &&
          newData[cateIndex].menus[newIndex].view
        ) {
          newData[cateIndex].menus[newIndex].full_access = 1;
        } else {
          newData[cateIndex].menus[newIndex].full_access = 0;
        }
      });
    });
  };



  const handuleSubmit = async (e, action) => {
   
    e.preventDefault();
    setDisable(true);
    //prepare add role data here
    // let role_menus = [];
    // roleModules?.map((i) => {
    //   i?.children?.map((a) => {
    //     role_menus.push({
    //       menu_id: a?._id,
    //       permissions: {
    //         web:a?.web,
    //         mobile:a?.mobile,
    //         view: a?.view
    //         create: a?.create,
    //         edit: a?.edit,
    //         delete: a?.delete,
    //       },
    //     });
    //   });
    // });

    const updatedData = roleModules.map((parent) => {
      if (parent.children) {
        const hasChildWithWeb = parent.children.some((child) => child.web === 1);
        const hasChildWithMObile = parent.children.some((child) => child.mobile === 1);
        if (hasChildWithWeb) {
          return { ...parent, web: 1 };
        }
        if (hasChildWithMObile) {
          return { ...parent, mobile: 1 };
        }
      }
      return parent;
    });

    let payload = {
      company_id: StorageService.getCompanyId(),
      role_name: roleName,
      // role_menus: role_menus,
      description: description,
      permission:updatedData
    };

    try {
      setIsLoading(true);
      const res = await dispatch(addroleCreate(payload));
    
      if (res?.payload?.responseCode === 200) {
        toast.success(res?.payload?.responseMessage);
        if (action === "add") {
          navigate("/setting/role");
          setIsLoading(false);
          clearAll();
        } else {
          clearAll();
          setIsLoading(false);
        }
      } else {
        toast.error(res?.payload?.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      // throw error;
    }
  };

  // useEffect(() => {
  //   if (isInitialRender.current) {
  //     isInitialRender.current = false;
  //     return;
  //   }
  //   setRoleError({
  //     ...roleError,
  //     check: shouldReturnFalse(roleModules) ? "Permission is required" : "",
  //   });
  // }, [roleModules]);

  // const shouldReturnFalse = (menus) => {
  //   for (const category of menus) {
  //     for (const menu of category.menus) {
  //       const permissions = menu;
  //       if (
  //         permissions.view === 1 ||
  //         permissions.create === 1 ||
  //         permissions.edit === 1 ||
  //         permissions.delete === 1
  //       ) {
  //         return false;
  //       }
  //     }
  //   }
  //   return true;
  // };

  const clearAll = () => {
    setRoleName("");
    setDescription("");
    // getAllMain();
  };

  

 

  return (
    <div>
      <ButtonHeading title={"Add Role"} buttonTitle={"Add Role"} />

      <div>
        <div className="_addContainer">
          <div className="_addRl_bx">
            <div className="_addRlHead">Role Details</div>

            <div className="_addRl_tableBx">
              <div className="_rlsInputs_bx">
                <div className="addRl_inputOtr">
                  {/* <input type="text" className='simpleInput' placeholder='Role Name' /> */}
                  <CommonInput
                    type="text"
                    placeholder="Role Name"
                    label="Role Name"
                    value={roleName}
                    onChange={handleInputChange}
                    error={roleError.name}
                  />
                </div>

                <div className="addRl_inputOtr">
                  {/* <textarea name="" className='simpleTextarea' placeholder='Description' id=""></textarea> */}
                  <CommonTextarea
                    placeholder="Description"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <NewRole roleModules={roleModules}
              setRoleModules={(e) => setRoleModules(e)}
              loading={loading}/>
              {/* {roleError.check && (
                <span className={"input_drop_error"}>{roleError.check}</span>
              )} */}
              <div className="_otrRl_table">
                {/* {roleData.map((category, categoryIndex) => {
                  return (
                    <div className="_mainRl_table" key={category.name}>
                      <div className="_addRl_headCheck">
                        <input
                          type="checkbox"
                          label="Dashboard"
                          id={`fullCheck_${categoryIndex}`}
                          onChange={(e) => handleFullCheck(e, categoryIndex)}
                          checked={category?.menus.every(
                            (i) => i.full_access === 1
                          )}
                          value={
                            category?.menus.every((i) => i.full_access === 1)
                              ? 1
                              : 0
                          }
                          disabled={
                            whichRole === 0
                              ? permission
                                ? false
                                : true
                              : false
                          }
                        />
                        <label
                          htmlFor={`fullCheck_${categoryIndex}`}
                          className="anvLabel"
                        >
                          {category?.name}
                        </label>
                      </div>

                      {
                        category?.category_type === 1 && (
                          <table>
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th>Full Access</th>
                                <th>View</th>
                                <th>Create</th>
                                <th>Edit</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            {category?.menus.map((menus, menuIndex) => (
                              <tbody key={`menuIndex_${menuIndex}`}>
                                <tr>
                                  <td> {menus?.label}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`allSelect_${menuIndex}_${menus?.category_id._id}`}
                                      checked={
                                        menus?.full_access ? true : false
                                      }
                                      onChange={(e) =>
                                        handleSelectAll(e, menuIndex)
                                      }
                                      value={menus?.full_access}
                                      disabled={
                                        whichRole === 0
                                          ? menus?.permissions?.create === 1 &&
                                            menus?.permissions?.edit === 1 &&
                                            menus?.permissions?.delete === 1 &&
                                            menus?.permissions?.view === 1
                                            ? false
                                            : true
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`allSelect_${menuIndex}_${menus?.category_id._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl1
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`view_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.view}
                                      checked={menus?.view ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? menus?.permissions?.create === 1 &&
                                            menus?.permissions?.edit === 1 &&
                                            menus?.permissions?.delete === 1 &&
                                            menus?.permissions?.view === 1
                                            ? false
                                            : true
                                          : menus?.create ||
                                            menus?.edit ||
                                            menus?.delete
                                      }
                                    />
                                    <label
                                      htmlFor={`view_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl2
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`create_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.create}
                                      checked={menus?.create ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? !menus?.permissions.create
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`create_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl3
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`edit_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.edit}
                                      checked={menus?.edit ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? !menus?.permissions.edit
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`edit_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl4
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`delete_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.delete}
                                      checked={menus?.delete ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? !menus?.permissions.delete
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`delete_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl5
                                      </span>
                                    </label>
                                  </td>
                                </tr>

                                
                              </tbody>
                            ))}
                          </table>
                        )
                      }
                    </div>
                  );
                })} */}

                
              </div>
            </div>
          </div>
        </div>

        <div className="bottomFix">
          <button
            className="_addComm_btn"
            type="button"
            disabled={loading  ||!disble|| !roleName || isLoading}
            onClick={(e) => handuleSubmit(e, "add")}
          >
            Add Role
          </button>
          {/* _addNewComm_btn */}
          <button
            className="_addComm_btn"
            type="button"
            disabled={loading || !disble || !roleName || isLoading}
            onClick={(e) => handuleSubmit(e, "addNew")}
          >
            Add & New
          </button>
          <button className="_cancel_btn" onClick={()=>navigate(-1)}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default AddRole;
