import { API, axiosInstance } from "lib";


export const profitLossReportList = (data={}) => {
  return axiosInstance
    .post(API.PROFIT_AND_LOSS , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const ownVehicleProfitableList = (data={}) => {
    return axiosInstance
      .post(API.OWN_VEHICLE_PROFITABILITY , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
  //      throw err;
      });
  };

  export const downloadTripDetailsReports = (data={}) => {
    return axiosInstance
      .post(API.TRIP_DETAILS_REPORT , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
  //      throw err;
      });
  };
  
  export const downloadTripRevenueReports = (data={}) => {
    return axiosInstance
      .post(API.TRIP_REVENUE_REPORT , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
  //      throw err;
      });
  };