import React, { useEffect, useState, useRef, useContext } from 'react'
import { ButtonHeading, CommonModal, CustomPagination } from 'components'
import { deleteConsignorData } from '_services/accountsServices/consignorServices';
import StorageService from "utils/StorageServices/StorageServices";
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { deleteVendersData, getfuelStationListData } from '_services/accountsServices/vendorsServices';
import { AddEditVendors } from 'modals';
import AddEditFuelStation from 'modals/AddEditFuelStation';
import { changeFuelStationStatus, deleteFuelStationData, getFuelStationListData } from '_services/accountsServices/fuelStationServices';

import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import MastersFilterModal from 'modals/MastersFilterModal';
import RupeeIcon from '../Supplier/RupeeIcon';
import LoadingBar from 'react-top-loading-bar';
import { UserContext } from 'context';

const FuelStation = (props) => {

  const loadingBarRef = useRef(null);
  const { mastersChild } = useContext(UserContext);
  const masterChildFuelStation = mastersChild?.find((i) => i?.label === "Fuel Station");
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [fuelStationList, setFuelStationList] = useState([])
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: '',
    status: 1
  });
  const [editFuelStationData, setEditFuelStationData] = useState(null)
  const [checkboxStates, setCheckboxStates] = useState({});

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
    setEditFuelStationData(null)
  };

  const getDataFuelStationList = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        ...filter,
        company_id: StorageService.getCompanyId(),
      }
      const res = await getFuelStationListData(object);
      if (res?.responseCode == 200) {
        setFuelStationList(res?.data?.list);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {
      // throw error;
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }

  }

  useEffect(() => {
    getDataFuelStationList();
  }, [filter, showOffcanvas])

  const deleteHandler = (item) => {
    setIsDelete(item)
  }

  const submitDeleteFuelStation = async () => {
    setLoading(true);
    try {
      let object = {
        company_id: isDelete?.company_id,
        fuelstation_id: isDelete?._id,
      }
      const res = await deleteFuelStationData(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getDataFuelStationList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success('Error !! ');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  }

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteFuelStation,
    isLoadding: loading,
  }

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Fuel Station", accessor: "fuel_station" },

    { header: "Contact No.", accessor: "contact_nunber" },
    { header: "City", accessor: "city" },
    { header: "Status", accessor: "status" },
    { header: "Balance", accessor: "balance" },
   
  ];
  useEffect(() => {
    if (fuelStationList && fuelStationList.length > 0) {
      const initialStates = fuelStationList.reduce((acc, item) => {
        acc[item._id] = Boolean(item?.active);
        return acc;
      }, {});
      setCheckboxStates(initialStates);
    }
  }, [fuelStationList]);

  const handleCheckboxChange = (id) => {
    const newStatus = !checkboxStates[id];
    setCheckboxStates(prevStates => ({ ...prevStates, [id]: newStatus }));

    const object = {
      company_id: StorageService.getCompanyId(),
      fuelstation_id: id,
      active: Number(newStatus)
    };
    statusChangeofFuelStation(object);
  };

  const statusChangeofFuelStation = async (obj) => {
    try {
      if (obj) {
        const res = await changeFuelStationStatus(obj);
        if (res?.responseCode === 200) {
          toast.success(`${res?.responseMessage}`);
        } else {
          toast.error('Error !!');
          await getDataFuelStationList();
        }
      }
    } catch (error) {
      toast.error(error.message);
      await getDataFuelStationList();
    }
  };

  const editHandler = (supplier) => {
    setShowOffcanvas(true);
    setEditFuelStationData(supplier);
  };


  const RowListData = (data) => {

    return data?.map((item, i) => ({
      ...item,
      // serial: <>{i + 1 + filter?.limit * (filter?.page - 1)}</>,
      serial: <>
        <div className="signDropdown">
          <button className="dropbtn11">
            <BsThreeDotsVertical />
          </button>
          <div className="dropdownContent">
            {masterChildFuelStation?.view === 1 && <Link to={`/account/fuel_station/details/${item?._id}`} className='_avPointer'
              style={{ textDecoration: 'none' }}
            >
              <span className='_samAnchor'>
                <span className='viewIcon_avt'>
                  <AiOutlineEye />
                </span>View
              </span>
            </Link>}

            {/* {masterChildFuelStation?.edit === 1 && <div
              onClick={(e) => editHandler(item)}
              className='_avPointer'
            >
              <span className='_samAnchor'>
                <span className='editIcon_avt'>
                  <FiEdit />
                </span>
                Edit
              </span>
            </div>} */}

            {masterChildFuelStation?.delete === 1 && <div
              onClick={(e) => deleteHandler(item)}
              className='_avPointer'
            >
              <span className='_samAnchor'>
                <span className='deleteIcon_avt'>
                  <FaRegTrashCan color='red' />
                </span>
                Delete
              </span>
            </div>}
          </div>
        </div>

      </>,
      fuel_station:
        <Link to={`/account/fuel_station/details/${item?._id}`} style={{ textDecoration: 'none' }}>
          {item?.name ? item?.name : '-'}
        </Link>,
      name:
        <React.Fragment>
          {item?.name ? item?.name : '-'}
        </React.Fragment>,
      contact_nunber:
        <React.Fragment>
          {item?.contact_no ? item?.contact_no : '-'}
        </React.Fragment>,
      city:
        <React.Fragment>
          {item?.city?.label ? item?.city?.label : '-'}
        </React.Fragment>,
      status: (
        <div className="_anvSwitcherNew form-check form-switch" style={{ width: "125px" }}>
          <input
            className="form-check-input"
            type="checkbox"
            name="actStatus"
            id={`actStatus-${item._id}`}
            autoComplete="off"
            checked={checkboxStates[item._id]}
            onChange={() => handleCheckboxChange(item._id)}
          />
          <span className={checkboxStates[item._id] ? "active_label_masters_status" : "disabled_label_masters_status"}>
            {checkboxStates[item._id] ? "Active" : "Disabled"}
          </span>
        </div>
      ),
      balance:
        <React.Fragment>
          {item?.balance ? (
            <Link to={`/account/fuel_station/details/${item?._id}?tab=2`} style={{ textDecoration: 'none' }}>
              <div style={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                color: item.balance < 0 ? '#dc3545' : item.balance > 0 ? '#28a745' : '#1450ec'
              }}>
                <span>
                  <RupeeIcon
                    color={item.balance < 0 ? '#dc3545' : item.balance > 0 ? '#28a745' : '#1450ec'}
                    size={20}
                  />
                </span>
                <span>
                  {item.balance}
                </span>
              </div>
            </Link>
          ) : (
            <div style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              color: '#1450ec'
            }}>
              <span>
                <RupeeIcon
                  color='#1450ec'
                  size={20}
                />
              </span>
              <span>
                0
              </span>
            </div>
          )}
        </React.Fragment>,
      action: (
        <React.Fragment>
          -
        </React.Fragment>
      ),
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }))
  };

  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading
        title={"Fuel Station"}
        onClick={toggleOffcanvas}
        buttonTitle={masterChildFuelStation?.create === 1 && "Add Fuel Station"}
        showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        filter={filter}
        setFilter={setFilter}
      />

      <div className='_tabWith_outTab'>
        <BootstrapTable
          columns={columns}
          data={RowListData(fuelStationList?.length > 0 ? fuelStationList : [])}
          isLoading={loading}
        />

        {fuelStationList?.length && <div className='paginationFt_avt'>
        <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter.offset}
          onPageChange={handlePageChange}
        />
        </div>}
      </div>

      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Confirm Modal
      </button>

      <div className="modal fade confirmModal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-header">
              <h4 className="modal-title " id="exampleModalLabel"> <img src={Images.RISKICON} alt="" /> Leave this page ?</h4>
            </div>

            <div className="modal-body">If you leave, your unsaved changes will be discarded.</div>

            <div className="modal-footer">
              <button type="button" className="_addComm_btn">Save changes</button>
              <button type="button" className="_cancel_btn" data-bs-dismiss="modal">Close</button>
            </div>

          </div>
        </div>
      </div> */}

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvas ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showOffcanvas ? 'visible' : 'hidden' }}
      >
        {showOffcanvas &&
          <AddEditFuelStation isOpen={showOffcanvas} onClose={(e) => setShowOffcanvas(false)} />
        }
      </div>
      <div className={showOffcanvas ? 'offBack_drop' : ''}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
      >
        {showFilterModal &&
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        }
      </div>
      <div className={showFilterModal ? 'offBack_drop' : ''}></div>

      {
        isDelete?.company_id && (
          <CommonModal {...propsObject}>
            <p className='p-3'> Are you sure you want to delete {isDelete?.name} ? </p>
          </CommonModal>
        )
      }
    </div>
  )
}

export default FuelStation