import React, { useEffect, useState, useRef, useContext } from 'react'
import { ButtonHeading, CommonModal, CustomPagination } from 'components'
import StorageService from "utils/StorageServices/StorageServices";
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import AddEditSupplier from 'modals/AddEditSupplier';
import { deleteSupplierData, getSupplierListData, changeSupplierStatusData } from '_services/accountsServices/supplierServices';

import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import MastersFilterModal from 'modals/MastersFilterModal';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import RupeeIcon from './RupeeIcon';
import LoadingBar from 'react-top-loading-bar';
import { UserContext } from 'context';

const Supplier = () => {

  const loadingBarRef = useRef(null);

  const { mastersChild } = useContext(UserContext);
  const masterChildSupplier = mastersChild?.find((i) => i?.label === "Supplier");

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [supplierList, setSupplierList] = useState([])
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: '',
    status: 1
  });

  const [editSupplierData, setEditSupplierData] = useState(null)
  const [checkboxStates, setCheckboxStates] = useState({});



  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
    setEditSupplierData(null)
  };

  const getDataSupplierList = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        ...filter,
        company_id: StorageService.getCompanyId(),
      };
      const res = await getSupplierListData(object);
      if (res?.responseCode === 200) {
        setSupplierList(res?.data?.supplier_list);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {
      toast.error(error)
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete();
      }
    }
  };

  useEffect(() => {
    getDataSupplierList();
  }, [filter, showOffcanvas])

  const deleteHandler = (item) => {
    setIsDelete(item)
  }

  const submitDeleteSupplier = async () => {
    setLoading(true);
    try {
      loadingBarRef.current?.continuousStart();
      let object = {
        company_id: isDelete?.company_id,
        supplier_id: isDelete?._id,
      }
      const res = await deleteSupplierData(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getDataSupplierList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success('Error !! ');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error)
    }
  }

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteSupplier,
    isLoadding: loading,
  }

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Supplier Name", accessor: "supplier_name" },
    { header: "Contact No.", accessor: "contact_nunber" },
    { header: "City", accessor: "city" },
    { header: "Status", accessor: "status" },
    { header: "Balance", accessor: "balance" },

  ];

  useEffect(() => {
    if (supplierList && supplierList.length > 0) {
      const initialStates = supplierList.reduce((acc, item) => {
        acc[item._id] = Boolean(item?.active);
        return acc;
      }, {});
      setCheckboxStates(initialStates);
    }
  }, [supplierList]);

  const handleCheckboxChange = (id) => {
    const newStatus = !checkboxStates[id];
    setCheckboxStates(prevStates => ({ ...prevStates, [id]: newStatus }));

    const object = {
      company_id: StorageService.getCompanyId(),
      supplier_id: id,
      active: Number(newStatus)
    };
    statusChangeofSupplier(object);
  };

  const statusChangeofSupplier = async (obj) => {
    try {
      if (obj) {
        const res = await changeSupplierStatusData(obj);
        if (res?.responseCode === 200) {
          toast.success(`${res?.responseMessage}`);
        } else {
          toast.error('Error !!');
          await getDataSupplierList();
        }
      }
    } catch (error) {
      toast.error(error.message);
      await getDataSupplierList();
    }
  };

  const editHandler = (supplier) => {
    setShowOffcanvas(true);
    setEditSupplierData(supplier);
  };
  // RowListData of Supplier
  function RowListData(data) {

    return (
      data?.map((item, i) => ({
        ...item,
        serial: (
          <React.Fragment>
            <div className="signDropdown">
              <button className="dropbtn11">
                <BsThreeDotsVertical />
              </button>
              <div className="dropdownContent">
                {
                  masterChildSupplier?.view === 1 &&
                  <Link to={`/account/supplier/details/${item?._id}`}
                    className='_avPointer'
                    style={{ textDecoration: 'none' }}
                  >
                    <span className='_samAnchor'>
                      <span className='viewIcon_avt'>
                        <AiOutlineEye />
                      </span>View
                    </span>
                  </Link>
                }
                {
                  masterChildSupplier?.edit === 1 &&
                  <div
                    onClick={(e) => editHandler(item)}
                    className='_avPointer'
                  >
                    <span className='_samAnchor'>
                      <span className='editIcon_avt'>
                        <FiEdit />
                      </span>
                      Edit
                    </span>
                  </div>
                }
                {
                  masterChildSupplier?.delete === 1 &&
                  <div
                    onClick={(e) => deleteHandler(item)}
                    className='_avPointer'
                  >
                    <span className='_samAnchor'>
                      <span className='deleteIcon_avt'>
                        <FaRegTrashCan color='red' />
                      </span>
                      Delete
                    </span>
                  </div>
                }
              </div>
            </div>
          </React.Fragment>
        ),
        supplier_name: (
          <Link to={`/account/supplier/details/${item?._id}`} style={{ textDecoration: 'none' }}>
            {item?.name ? item?.name : '-'}
          </Link>
        ),
        contact_number: (
          <React.Fragment>
            {item?.contact_no ? item?.contact_no : '-'}
          </React.Fragment>
        ),
        party_group: (
          <React.Fragment>
            {item?.consignor_group_id?.name ? item?.consignor_group_id?.name : '-'}
          </React.Fragment>
        ),
        city: (
          <React.Fragment>
            {item?.city?.label ? item?.city?.label : '-'}
          </React.Fragment>
        ),
        status: (
          <div className="_anvSwitcherNew form-check form-switch" style={{ width: "125px" }}>
            <input
              className="form-check-input"
              type="checkbox"
              name="actStatus"
              id={`actStatus-${item._id}`}
              autoComplete="off"
              checked={checkboxStates[item._id]}
              onChange={() => handleCheckboxChange(item._id)}
            />
            <span className={checkboxStates[item._id] ? "active_label_masters_status" : "disabled_label_masters_status"}>
              {checkboxStates[item._id] ? "Active" : "Disabled"}
            </span>
          </div>
        ),
        balance: item?.balance ? (
          <Link to={`/account/supplier/details/${item?._id}?tab=4`} style={{ textDecoration: 'none' }}>
            <div style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              color: item.balance < 0 ? '#dc3545' : item.balance > 0 ? '#28a745' : '#1450ec'
            }}>
              <span>
                <RupeeIcon
                  color={item.balance < 0 ? '#dc3545' : item.balance > 0 ? '#28a745' : '#1450ec'}
                  size={20}
                />
              </span>
              <span>
                {item.balance}
              </span>
            </div>
          </Link>
        ) : (
          <Link to={`/account/supplier/details/${item?._id}?tab=4`} style={{ textDecoration: 'none' }}>
            <div style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              color: '#1450ec'
            }}>
              <span>
                <RupeeIcon
                  color='#1450ec'
                  size={20}
                />
              </span>
              <span>
                0
              </span>
            </div>
          </Link>
        ),
      }))
    );
  };


  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }))
  };


  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading
        title={"Supplier"}
        onClick={toggleOffcanvas}
        buttonTitle={masterChildSupplier?.create === 1 && "Add Supplier"}
        showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        filter={filter}
        setFilter={setFilter}
      />

      {/* <div className='p-5'>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">S No.</th>
              <th scope="col">Supplier Name</th>
              <th scope="col">City</th>
              <th scope="col">Contact Number</th>
              <th scope="col">Status</th>
              <th scope="col">Updated On</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              supplierList?.length > 0 ? (
                supplierList?.map((el, ind) => {
                  return (
                    <tr key={ind + 'abc'}>
                      <th scope="row">{ind + 1}</th>
                      <td>
                        <Link to={`/account/supplier/details/${el?._id}`}> {el?.name} </Link>
                      </td>
                      <td>{el?.city?.label ? el?.city?.label : '-'}</td>
                      <td>{el?.contact_no}</td>
                      <td>{el?.opening_balance?.$numberDecimal}</td>
                      <td>{new Date(el?.updatedAt).toDateString()}</td>
                      <td>
                        <span disabled={loading}>Edit</span> &nbsp; &nbsp;
                        <span onClick={(e) => deleteHandler(el)}>Delete</span>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr > <td> No data available </td> </tr>
              )
            }
          </tbody>
        </table>
      </div> */}

      <div className='_tabWith_outTab'>
        <BootstrapTable
          columns={columns}
          data={RowListData(supplierList?.length > 0 ? supplierList : [])}
          isLoading={loading}
        />

        {supplierList?.length > 0&& <div className='paginationFt_avt '>
          <CustomPagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            currentPage={filter.offset}
            onPageChange={handlePageChange}
          />
        </div>}
      </div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvas ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showOffcanvas ? 'visible' : 'hidden' }}
      >
        {showOffcanvas &&
          <AddEditSupplier
            isOpen={showOffcanvas}
            onClose={() => setShowOffcanvas(false)}
            supplierData={editSupplierData}
          />
        }
      </div>

      <div className={showOffcanvas ? 'offBack_drop' : ''}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
      >
        {showFilterModal &&
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        }
      </div>
      <div className={showFilterModal ? 'offBack_drop' : ''}></div>

      {
        isDelete?.company_id && (
          <CommonModal {...propsObject}>
            <p className='p-3'> Are you sure you want to delete {isDelete?.name} ?</p>
          </CommonModal>
        )
      }
    </div>
  )
}

export default Supplier