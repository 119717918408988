import React, { useEffect, useState, useRef, useContext } from "react";
import { ButtonHeading, CommonModal, CustomPagination } from "components";
import StorageService from "utils/StorageServices/StorageServices";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import {
  changeConsigneeStatusData,
  deleteConsigneeData,
  getConsigneeListData,
} from "_services/accountsServices/consigneeServices";
import { AddEditConsignee } from "modals";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import BootstrapTable from "components/BootstrapTable";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import MastersFilterModal from "modals/MastersFilterModal";
import LoadingBar from "react-top-loading-bar";
import AddConsigneeModal from "modals/AddConsigneeModal";
import { deleteAddressBook } from "_services/accountsServices/addressBookService";
import { UserContext } from "context";

const Consignee = (props) => {
  const loadingBarRef = useRef(null);

  const { mastersChild } = useContext(UserContext);
  const masterChildConsignee = mastersChild?.find(
    (i) => i?.label === "Consignee"
  );

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [consigneeList, setConsigneeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [addressId, setAddressId] = useState({
    id: "",
    title: "address",
    view:false
  });
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: "",
    status: 1,
  });

  const [editConsigneeData, setEditConsigneeData] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState({});

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const getDataConsigneeList = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart();
      }

      let object = {
        ...filter,
        company_id: StorageService.getCompanyId(),
      };
      const res = await getConsigneeListData(object);
      if (res?.responseCode == 200) {
        setConsigneeList(res?.data?.consignee_list);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {
      // throw error;
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  };

  useEffect(() => {
    getDataConsigneeList();
  }, [filter, showOffcanvas]);

  const deleteHandler = (item) => {
    setIsDelete(item);
  };

  const submitDeleteConsignee = async () => {
    setLoading(true);
    try {
      let object = {
        company_id: isDelete?.company_id,
        addressbook_id: isDelete?._id,
      };
      const res = await deleteAddressBook(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getDataConsigneeList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success("Error !! ");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  };

  let { isOpen, onClose } = props;

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteConsignee,
    isLoadding: loading,
  };

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Consignee Name", accessor: "consignee_name" },
    { header: "Associate Party", accessor: "associate_party" },
    { header: "Consignee Code", accessor: "code" },
    { header: "City", accessor: "city" },
    { header: "Branch", accessor: "branch" },
    { header: "Zone", accessor: "zone" },
    { header: "Status", accessor: "status" },
  ];

  useEffect(() => {
    if (consigneeList && consigneeList.length > 0) {
      const initialStates = consigneeList.reduce((acc, item) => {
        acc[item._id] = Boolean(item?.active);
        return acc;
      }, {});
      setCheckboxStates(initialStates);
    }
  }, [consigneeList]);

  const handleCheckboxChange = (id) => {
    const newStatus = !checkboxStates[id];

    setCheckboxStates((prevStates) => ({ ...prevStates, [id]: newStatus }));

    const object = {
      company_id: StorageService.getCompanyId(),
      consignee_id: id,
      active: Number(newStatus),
    };
    statusChangeofConsignee(object);
  };

  const statusChangeofConsignee = async (obj) => {
    try {
      if (obj) {
        // const res = await changeConsigneeStatusData(obj);
        // if (res?.responseCode === 200) {
        //     toast.success(`${res?.responseMessage}`);
        // } else {
        //     toast.error('Error !!');
        await getDataConsigneeList();
        // }
      }
    } catch (error) {
      toast.error(error.message);
      await getDataConsigneeList();
    }
  };

  const editHandler = (supplier) => {
    setShowOffcanvas(true);
    setEditConsigneeData(supplier);
    setAddressId({ ...supplier, id: supplier?._id });
  };



  const RowListData = (data) => {
    return data?.map((item, i) => ({
      ...item,
      // serial: <>{i + 1 + filter?.limit * (filter?.page - 1)}</>,
      serial: (
        <>
          <div className="signDropdown">
            <button className="dropbtn11">
              <BsThreeDotsVertical />
            </button>
            <div className="dropdownContent">
              {masterChildConsignee?.view === 1 && (
                <Link
                  to={`/account/consignee/details/${item?._id}`}
                  className="_avPointer"
                  style={{ textDecoration: "none" }}
                >
                  <span className="_samAnchor">
                    <span className="viewIcon_avt">
                      <AiOutlineEye />
                    </span>
                    View
                  </span>
                </Link>
              )}

              {masterChildConsignee?.edit === 1 && (
                <div onClick={(e) => editHandler(item)} className="_avPointer">
                  <span className="_samAnchor">
                    <span className="editIcon_avt">
                      <FiEdit />
                    </span>
                    Edit
                  </span>
                </div>
              )}

              {masterChildConsignee?.delete === 1 && (
                <div
                  onClick={(e) => deleteHandler(item)}
                  className="_avPointer"
                >
                  <span className="_samAnchor">
                    <span className="deleteIcon_avt">
                      <FaRegTrashCan color="red" />
                    </span>
                    Delete
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      ),
      consignee_code: (
        <React.Fragment>
          {item?.consignee_code ? item?.consignee_code : "-"}
        </React.Fragment>
      ),
      consignee_name: (
        <Link
          to={`/account/consignee/details/${item?._id}`}
          style={{ textDecoration: "none" }}
        >
          {item?.name ? item?.name : "-"}
        </Link>
      ),
      city: (
        <React.Fragment>
          {item?.city?.label ? item?.city?.label : "-"}
        </React.Fragment>
      ),
      branch: (
        <React.Fragment>
          {item?.branch_id?.branch ? item?.branch_id?.branch : "-"}
        </React.Fragment>
      ),
      zone: (
        <React.Fragment>
          {item?.zone_id?.name ? item?.zone_id?.name : "-"}
        </React.Fragment>
      ),
      status: (
        <div
          className="_anvSwitcherNew form-check form-switch"
          style={{ width: "125px" }}
        >
          {console.log("Consignee item", item)}
          <input
            className="form-check-input"
            type="checkbox"
            name="actStatus"
            id={`actStatus-${item._id}`}
            autoComplete="off"
            checked={checkboxStates[item._id]}
            onChange={() => handleCheckboxChange(item._id)}
          />
          <span
            className={
              checkboxStates[item._id]
                ? "active_label_masters_status"
                : "disabled_label_masters_status"
            }
          >
            {checkboxStates[item._id] ? "Active" : "Disabled"}
          </span>
        </div>
      ),
      associate_party:<React.Fragment>
      {item?.consignor_id?.name ? item?.consignor_id?.name : '-'}
    </React.Fragment>,
      action: <React.Fragment>-</React.Fragment>,
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };

  const callBackAddressData = (data) => {
    getDataConsigneeList();
  };

  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading
        title={"Consignee"}
        onClick={toggleOffcanvas}
        buttonTitle={
          masterChildConsignee?.create === 1 && "Add Consignee"
        }
        showSearchFilter={{
          search: true,
          filterOption: true,
          filterCount: true,
        }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        filter={filter}
        setFilter={setFilter}
      />
      <div className="_tabWith_outTab">
        <BootstrapTable
          columns={columns}
          data={RowListData(consigneeList?.length > 0 ? consigneeList : [])}
          isLoading={loading}
        />

        {consigneeList?.length >0 && (
          <div className="paginationFt_avt">
            <CustomPagination
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={filter.offset}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>

      <div>
        <div
          className={`offcanvas offcanvas-end common_offCanvas ${
            showOffcanvas ? "show" : ""
          }`}
          tabIndex={-1}
          style={{ visibility: showOffcanvas ? "visible" : "hidden" }}
        >
          {showOffcanvas && (
            <AddConsigneeModal
              isOpen={showOffcanvas}
              onClose={(e) => {
                setAddressId({ id: "", title: "Consignee" ,view:false});
                setShowOffcanvas(false);
              }}
              id={addressId}
              callBackAddressData={callBackAddressData}
            />
          )}
        </div>
        <div className={showOffcanvas ? "offBack_drop" : ""}></div>
      </div>
      {/* <div
                className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvas ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: showOffcanvas ? 'visible' : 'hidden' }}
            >
                {showOffcanvas &&
                    <AddEditConsignee isOpen={showOffcanvas} onClose={(e) => setShowOffcanvas(false)} />
                }
            </div>
            <div className={showOffcanvas ? 'offBack_drop' : ''}></div> */}

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          showFilterModal ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? "visible" : "hidden" }}
      >
        {showFilterModal && (
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        )}
      </div>
      <div className={showFilterModal ? "offBack_drop" : ""}></div>

      {isDelete?.company_id && (
        <CommonModal {...propsObject}>
          <p className="p-3"> Are you sure you want to delete {isDelete?.name} ? </p>
        </CommonModal>
      )}
    </div>
  );
};

export default Consignee;
