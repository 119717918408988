import { API, axiosInstance } from "lib";

export const getLRListData = (data = {}) => {
  return axiosInstance
    .post(API.LORRY_RECEIPT + "apk_lr_list", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getLorryTypeList = (data = {}) => {
  return axiosInstance
    .post(API.SETTINGS_DATA + "get_lorry_type", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const fetchLrNumberData = (data = {}) => {
  return axiosInstance
    .post(API.GET_BRANCH + "get_lr_number", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const createNewLr = (data = {}) => {
  return axiosInstance
    .post(API.LORRY_RECEIPT + "apk_add_lr", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getMainLrListData = (data = {}) => {
  return axiosInstance
    .post(API.LORRY_RECEIPT + "lr_list", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getLRDeaultColumn = (data = {}) => {
  return axiosInstance
    .post(API.LR_DEAULT_COLUMN , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const updateUserLrColumns = (data = {}) => {
  return axiosInstance
    .post(API.UPDATE_USER_LR_COLUMNS, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getDataLrDetails = (data = {}) => {
  return axiosInstance
    .post(API.LORRY_RECEIPT + "get_lr_detail", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const updateNewLr = (data = {}) => {
  return axiosInstance
    .post(API.LORRY_RECEIPT + "apk_update_lr", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getLrCountData = (data = {}) => {
  return axiosInstance
    .post(API.LORRY_RECEIPT_COUNT, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};
