import { ButtonHeading } from "components";
import { AddFuelCardModal } from "modals";
import React, { useContext, useEffect, useState } from "react";
import Tab from "components/common/Tab";
import { constant } from "constants/constant";
import OwnDriver from "../OwnDriver";
import MarketDriver from "../MarketDriver";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { fuelCardAccount } from "_services";
import { StorageServices } from "utils";
import { UserContext } from "context";
import { utils } from "helper";
import { getFuelTxnList } from "_services/accountsServices/fuelStationServices";
import toast from "react-hot-toast";

const FuelCard = () => {
  const { mastersChild } = useContext(UserContext);
  const masterChildFuelcard = mastersChild?.find(
    (i) => i?.label === "Fuel Card"
  );
 
  const [showExpModal, setShowExpModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [fuelCardAccountList, setFuelCardAccountList] = useState([]);
  const [fuelTransactionList, setFuelTransactionList] = useState([]);

  // const toggleOffcanvas = () => {
  //     setShowOffcanvas(!showOffcanvas);
  //     setEditFuelStationData(null)
  //   };

  useEffect(() => {
    getfuelCardAccount();
    getFuelTxn()
  }, []);

  const getFuelTxn = async()=>{
    try {
      const res = await getFuelTxnList({company_id:StorageServices?.getCompanyId()})
      
      if(res?.responseCode==200){
        setFuelTransactionList(res?.data?.transactions)
      }else{
        toast.error(res?.responseMessage)
      }
    } catch (error) {
      
    }
}

  const getfuelCardAccount = async () => {
    try {
      const res = await fuelCardAccount({
        company_id: StorageServices.getCompanyId(),
      });

      if (res?.responseCode === 200) {
        // setFuelCardAccountList(
        //   res?.data?.fuelcard.flatMap((master) =>
        //     master.fuelcard_accounts.map((account) => ({
        //       ...account,
        //       master_name: master.master_name,
        //     }))
        //   )
        // );
        const transformDataToAccounts = (data) => {
          return data.map((master) => ({
            ...master,
            master_name: master?.fuelcard_company_id.name,
          }));
        };
        
        setFuelCardAccountList(transformDataToAccounts(res?.data?.fuelcard));
        
      }
    } catch (error) {}
  };

  let AddFuelCardModals = {
    isOpen: showExpModal,
    closeModal: (e) => setShowExpModal(false),
    modalMainClass: "_dashCommon_Modal ",
    modalTitle: "Add Fuel Card",
    // headerPart: true,
    footerPart: true,
    submitTitle: "Add Fuel Card",
    getAccountListHandler: getfuelCardAccount,
  };

  const tabProps = {
    tabList: constant.fuel_cardTabs,
    callBackTab: (e) => setActiveTab(e),
    activeTab: activeTab,
  };

  return (
    <div>
      <ButtonHeading
        title={"Fuel Card"}
        onClick={() => setShowExpModal(!showExpModal)}
        buttonTitle={
          masterChildFuelcard?.permissions?.create === 1 && "Add Fuel Card"
        }
        showSearchFilter={{
          search: true,
          filterOption: true,
          filterCount: true,
        }}
        // expenseOnClick={() => setShowExpModal(!showExpModal)}
        // Expenses={true}
        // onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        // filter={filter}
        // setFilter={setFilter}
      />

      <div className="addPr_flBx">
        <Tab {...tabProps} />
        {activeTab === 1 && (
          <div className="_mainFc_hv">
            <div className="accordion _fuelCard_acc" id="quickUploadAccordion">
              {fuelCardAccountList.map((account, index) => {

                const firstReturn  = (vehicleNumber)=>{
                  const firstPart = vehicleNumber.slice(0, 5);
                  return firstPart
                }

                const secondReturn  = (vehicleNumber)=>{
                  const secondPart = vehicleNumber.slice(5);
                  return secondPart
                }
               

                return (
                  <div className="accordion-item" key={account.id}>
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          index === 0 ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded={index === 0 ? "true" : "false"}
                        aria-controls={`collapse${index}`}
                      >
                        <div className="_msxFlx">
                          <p>
                            {account.master_name} | {account.account_number}
                          </p>
                          <span>{account?.fuelcard_accounts?.length}</span>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${
                        index === 0 ? "show" : ""
                      }`}
                      data-bs-parent="#quickUploadAccordion"
                    >
                      <div className="accordion-body">
                        <table className="_trpTable_xd">
                          {account?.fuelcard_accounts.map((item) => {
                            return (
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="signDropdown">
                                      <button className="dropbtn11">
                                        <BsThreeDotsVertical />
                                      </button>
                                      <div className="dropdownContent">
                                      {masterChildFuelcard?.view ===
                                  1 && (  <Link
                                          to="#"
                                          className="_avPointer"
                                          style={{ textDecoration: "none" }}
                                        >
                                          <span className="_samAnchor">
                                            <span className="viewIcon_avt">
                                              <AiOutlineEye />
                                            </span>
                                            View
                                          </span>
                                        </Link>)}
                                        {masterChildFuelcard?.edit ===
                                  1 && (<div className="_avPointer">
                                          <span className="_samAnchor">
                                            <span className="editIcon_avt">
                                              <FiEdit />
                                            </span>
                                            Edit
                                          </span>
                                        </div>)}
                                        {masterChildFuelcard?.delete ===
                                  1 && (<div className="_avPointer">
                                          <span className="_samAnchor">
                                            <span className="deleteIcon_avt">
                                              <FaRegTrashCan color="red" />
                                            </span>
                                            Delete
                                          </span>
                                        </div>)}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <b>{`${
                                        item?.account_number
                                          ? item?.account_number
                                          : ""
                                      }`}</b>
                                      <span className="textBottom">
                                        {firstReturn(item?.associate_vehicle_id?.vehicle_no?item?.associate_vehicle_id?.vehicle_no:"")}{" "}
                                        <span className="blue">
                                          {secondReturn(item?.associate_vehicle_id?.vehicle_no?item?.associate_vehicle_id?.vehicle_no:"")}
                                        </span>
                                      </span>
                                    </div>
                                  </td>
                                  {/* <td>
                                  <div>
                                    <b>
                                      Example Petroleum, Bharat Petroleum
                                      Dealers, Mungeli
                                    </b>
                                    <span className="textBottom">
                                      {new Date().toLocaleDateString()}{" "}
                                      {new Date().toLocaleTimeString()}
                                    </span>
                                  </div>
                                </td> */}
                                  <td>
                                    <div>
                                      <span className="textBottom">
                                        Balance
                                      </span>
                                      <span>{`Rs. ${item?.balance.toFixed(
                                        2
                                      )}`}</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {activeTab === 2 && (
          <div>
            {/* <ul className="_tapUpper_tab">
              <li className="active">
                <span>All</span>
              </li>
              <li className="">
                <span>Assigned 0</span>
              </li>
              <li className="">
                <span>Unassigned 0</span>
              </li>
              <li className="">
                <span>Omitted 0</span>
              </li>
            </ul> */}

            <div className="_indentTable _disPatchView _fuelCrd__table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>DATE | TIME</th>
                    <th>VEHICLE NUMBER</th>
                    <th>FUEL CARD MASTER</th>
                    <th>DESCRIPTION</th>
                    <th>FUEL TYPE</th>
                    <th>RATE</th>
                    <th>LTR</th>
                    <th>AMOUNT</th>
                    {/* <th>TRIP RELATION</th> */}
                  </tr>
                </thead>

                {fuelTransactionList &&
                  fuelTransactionList?.map((item) => {
                    return (
                      <tbody>
                        <tr>
                          <td>
                            <div className="signDropdown">
                              <button className="dropbtn11">
                                <BsThreeDotsVertical />
                              </button>
                              <div className="dropdownContent">
                                {masterChildFuelcard?.view ===
                                  1 && (
                                  <Link
                                    to="/"
                                    className="_avPointer"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <span className="_samAnchor">
                                      <span className="viewIcon_avt">
                                        <AiOutlineEye />
                                      </span>
                                      View
                                    </span>
                                  </Link>
                                )}
                                {masterChildFuelcard?.edit ===
                                  1 && (
                                  <div
                                    // onClick={(e) => editHandler(item)}
                                    className="_avPointer"
                                  >
                                    <span className="_samAnchor">
                                      <span className="editIcon_avt">
                                        <FiEdit />
                                      </span>
                                      Edit
                                    </span>
                                  </div>
                                )}
                                {masterChildFuelcard?.delete ===
                                  1 && (
                                  <div
                                    // onClick={(e) => deleteHandler(item)}
                                    className="_avPointer"
                                  >
                                    <span className="_samAnchor">
                                      <span className="deleteIcon_avt">
                                        <FaRegTrashCan color="red" />
                                      </span>
                                      Delete
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="_dateNtime _pDate">
                              <p className="_tblText_inDent">{utils.formatDateMonth(item?.date)} </p>
                              <p className="_lgtText_inDent"> {utils.formatTime(item?.date)} </p>
                            </div>
                          </td>
                          <td>
                            <div className="_dateNtime _pDate">
                              <p className="_tblText_inDent">{item?.vehicle?.vehicle_no}</p>
                              <p className="_lgtText_inDent">{item?.transaction_no}</p>
                            </div>
                          </td>

                          <td>
                            <div className="_dateNtime _pDate">
                              <p className="_tblText_inDent">{`Fuel-Master : ${item?.fuelcard_master_id?.account_number}`}</p>
                              <p className="_tblText_inDent">{`Fuel-Account : ${item?.fuelcard_account_id?.account_number}`}</p>

                            </div>

                          </td>

                          <td>
                            <div className="">
                              <p className="_tblText_inDent">
                               {item?.description}
                              </p>
                              <p className="_lgtText_inDent">
                              {utils.formatDateMonth(item?.date)} | {utils.formatTime(item?.date)}
                              </p>
                            </div>
                          </td>

                          <td>
                            <div className="_dateNtime _pDate">
                              {/* <p className="_tblText_inDent">Diesel</p> */}
                            </div>
                          </td>

                          <td>
                            <div className="_dateNtime _pDate">
                              <p className="_tblText_inDent">{(item?.fuel_rate).toFixed(2)}</p>
                            </div>
                          </td>

                          <td>
                            <div className="_dateNtime _pDate">
                              <p className="_tblText_inDent">{(item?.fuel_ltr).toFixed(2)} LTR</p>
                            </div>
                          </td>

                          <td>
                            <div className="__locationWid_bx">
                              <p className="_lgtText_inDent">Charges</p>
                              <p className="_tblText_inDent">Rs. {item?.credit_or_debit ===
                                                            2
                                                              ? item?.debit_amount?.toFixed(
                                                                  2
                                                                )
                                                              : item?.credit_amount?.toFixed(
                                                                  2
                                                                )}</p>
                            </div>
                          </td>

                          {/* <td>
    <div>
      <button className="_addComm_btn">Add Relation</button>
      <button className="_addComm_btn ms-1">OMIT</button>
    </div>
  </td> */}
                        </tr>
                      </tbody>
                     );
                  })} 
              </table>
            </div>
          </div>
        )}
      </div>
      {showExpModal && <AddFuelCardModal {...AddFuelCardModals} />}
    </div>
  );
};

export default FuelCard;
