import { Heading } from 'components'
import React, { useState } from 'react'
import StorageService from 'utils/StorageServices';
import { CommonReportFilters } from '..';
import moment from 'moment';
import { API, AppConfig } from 'lib';

const TripDetailsReports = () => {

  const [loading, setLoading] = useState(false);
  let isToken = localStorage.getItem("token");

  const currentDate = new Date();
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  const [filter, setFilter] = useState({
    company_id: '',
    start_date:  moment(firstDay).subtract(31, 'days'),
    end_date: moment(lastDay),
    branch_id: '',
    consignor_id: '',
    ownership_no: '',
    vehicle_id: '',
    supplier_id: '',
  });

  const getDownloadTripDetailsReports = async () => {
    setLoading(true)
    try {
      const payload = {
        ...filter,
        company_id: StorageService.getCompanyId(),
        branch_id: filter?.branch_id?.value ? filter?.branch_id?.value : '',
        consignor_id: filter?.consignor_id?.value ? filter?.consignor_id?.value : '',
        ownership_no: filter?.ownership_no?.value ? filter?.ownership_no?.value : '',
        vehicle_id: filter?.vehicle_id?.value ? filter?.branch_id?.value : '',
        supplier_id: filter?.supplier_id?.value ? filter?.supplier_id?.value : '',
        start_date: moment(filter.start_date).format("YYYY-MM-DD"),
        end_date: moment(filter.end_date).format("YYYY-MM-DD"),
      }
      const response = await fetch(`${AppConfig.api_baseurl}${API.TRIP_DETAILS_REPORT}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': `${isToken}`,
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'transport360.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();

    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <>
      <Heading title="Trip Details Reports" />
      <div className='_mrktLoad_mainBx __main_p_n_l _trpRevenue _ctLoad_cts _ctBx_mainBx'>

        <CommonReportFilters
          filter={filter}
          setFilter={(e) => setFilter(e)}
        />

      </div>

      <div className='paginationFt_avt' style={{ justifyContent: "flex-start", alignItems: "center" }}>
        <button className='_addComm_btn' onClick={(e) => getDownloadTripDetailsReports(e)}>Download</button>
      </div>
    </>
  )
}

export default TripDetailsReports