import { API, axiosInstance } from "lib";

export const getFuelStationListData = (data={}) => {
  return axiosInstance
    .post(API.GET_LIST_FUEL_STATION , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const createFuelStation = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_FUEL_STATION , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getSingleFuelStationData = (data={}) => {
  return axiosInstance
    .post(API.SINGLE_FUEL_STATION , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const deleteFuelStationData = (data={}) => {
  return axiosInstance
    .post(API.DELETE_FUEL_STATION , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const changeFuelStationStatus = (data={}) => {
  return axiosInstance
    .post(API.CHANGE_FUEL_STATION_STATUS , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getFuelStatementData = (data={}) => {
  return axiosInstance
    .post(API.GET_FUEL_STATEMENT , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getFuelCompanyDropdownData = (data={}) => {
  return axiosInstance
    .get(API.GET_FUEL_COMPANY_DROPDOWN , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getFuelTxnList = (data={}) => {
  return axiosInstance
    .post(API.FUEL_TXN_LIST , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getFastagCompanyDropdownData = (data={}) => {
  return axiosInstance
    .get(API.GET_FASTAG_COMPANY_DROPDOWN , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getFastagMasterDropdownData = (data={}) => {
  return axiosInstance
    .post(API.GET_FASTAG_MASTER_DROPDOWN , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getFuelCardAccountDropdownData = (data={}) => {
  return axiosInstance
    .post(API.FUEL_CARD_ACCOUNT_DROPDOWN , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};


export const getFastagTxnList = (data={}) => {
  return axiosInstance
    .post(API.FASTAG_TXN_LIST , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};



