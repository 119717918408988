import React, { useContext, useEffect, useState } from "react";
import { errorSchema, utils } from "helper";
import {
  CommonInput,
  CommonModal,
  CommonTextarea,
  CustomSelect,
} from "components";
import StorageService from "utils/StorageServices/StorageServices";
import toast, { Toaster } from "react-hot-toast";
import { createMaterialCategory } from "_services/materialServices/materialCategoryServices";
import { podReceivedData, updateLrProcess, uploadFiles } from "_services";
import { RiAddLargeFill } from "react-icons/ri";
import { createZone } from "_services/accountsServices/zoneServices";
import { getFastagCompanyDropdownData } from "_services/accountsServices/fuelStationServices";

const formObj = {
  company_id: StorageService.getCompanyId(),
  name: "",
  fastag_company_id:""
};

const AddEditModal = (props) => {
  const {
    isOpen,
    closeModal,
    nextModal,
    placeholder,
    label,
    ApiPoint,
    callBackApi,
    name = "",
    payloadData = {},
  } = props;
  const [formValue, setFormValue] = useState(formObj);
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [fastagCompanyList, setFastagCompanyList] = useState([]);

  useEffect(() => {
    getFastagCompanyStation();
  }, []);

  const handleOnChange = async (name, value) => {
    const stateObj = { ...formValue, [name]: value };
    setFormValue(stateObj);

    if (!utils.isObjectKeyEmpty(formError)) {
      const validationResult = await utils.checkFormError(
        stateObj,
        errorSchema.addEditZoneSchema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const getFastagCompanyStation = async () => {
    try {
      const res = await getFastagCompanyDropdownData();

      if (res?.responseCode == 200) {
        console.log(res);
        setFastagCompanyList(
          res?.data?.fastag_company?.map((i) => ({
            ...i,
            label: i?.name,
            value: i?._id,
          }))
        );
      }
    } catch (error) {}
  };

  const apiCallingFunction = async () => {
    const customePayload = {
      1: {
        company_id:formValue?.company_id,
        name:formValue?.name
      },

      2: {
        category_id: payloadData?.category_id,
        sub_category_id: payloadData?.sub_category_id,
        name:formValue?.name
      },
      3: {
        company_id: formValue?.company_id,
        account_number: formValue?.name,
      },
      4: {
        company_id: formValue?.company_id,
        customer_id: formValue?.name,
        fastag_company_id:formValue?.fastag_company_id
      },
    };

    try {
      const res = await ApiPoint(
        customePayload[
          name == "Trip"
            ? 2
            : name == "fuel_card"
            ? 3
            : name == "fastag"
            ? 4
            : 1
        ]
      );
      if (res.responseCode === 200) {
        setFormValue({});
        setLoading(false);
        toast.success(res?.responseMessage);
        closeModal(false);
        callBackApi(res?.data);
      } else {
        setLoading(false);
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleClick = async (value) => {
    const validationResult = await utils?.checkFormError(
      formValue,
      name == "fastag"?errorSchema.addFastagMasterSchema:errorSchema.addEditZoneSchema
    );
    if (utils?.isObjectKeyEmpty(validationResult)) {
      setLoading(true);
      apiCallingFunction();
      setFormError("");
    } else {
      setFormError(validationResult);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setFormValue((s) => ({
        ...s,
        company_id: StorageService.getCompanyId(),
      }));
    }
  }, [isOpen]);

  let inhanceProps = {
    ...props,
    submitTitle: loading ? "Loading..." : props.submitTitle,
    disabled: loading,
    onSubmit: handleClick,
  };

  return (
    <div>
      <CommonModal {...inhanceProps}>
        <div className="addItem_body mt-2">
          <div className="colFlx">
            <div className="singleDiv">
              <CommonInput
                type="text"
                label={label}
                placeholder={placeholder}
                name="name"
                value={formValue.name}
                focus={!!(typeof formError === "object" && formError?.name)}
                error={!!(typeof formError === "object") ? formError?.name : ""}
                onChange={({ target: { name, value } }) =>
                  handleOnChange(name, value)
                }
              />
            </div>
            <div className="singleDiv">
              <CustomSelect
                options={fastagCompanyList}
                label="Fastag Company"
                placeholder="Fastag Company"
                name="fastag_company_id"
                focus={!!formError?.fastag_company_id}
                error={formError.fastag_company_id}
                onSelect={(data) =>
                  handleOnChange("fastag_company_id", data?.value)
                }
                value={fastagCompanyList.find((i)=>i?.value==formValue?.fastag_company_id)}
              />
            </div>
          </div>
        </div>
      </CommonModal>
      {/* <div className='offBack_drop'></div> */}
    </div>
  );
};

export default AddEditModal;
