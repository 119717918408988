import { API, axiosInstance } from "lib";

export const getMarketLoadListData = (data = {}) => {
  return axiosInstance
    .post(API.GET_MARKET_LOAD_LIST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const createBidMarketLoadData = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_BID_MARKET_LOAD, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const updateBidMarketLoadData = (data = {}) => {
  return axiosInstance
    .post(API.UPDATE_BID_MARKET_LOAD, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getBidListData = (data = {}) => {
  return axiosInstance
    .post(API.GET_BID_LIST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getBidCounts = (data = {}) => {
  return axiosInstance
    .post(API.GET_BID_COUNT, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const changeBidStatus = (data = {}) => {
  return axiosInstance
    .post(API.CHANGES_BID_STATUS, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

// FILTERS  

export const getFavoriteLocation = (data = {}) => {
  return axiosInstance
    .post(API.GET_FAVORITE_LOCATION, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const saveFavoriteLocation = (data = {}) => {
  return axiosInstance
    .post(API.SAVE_FAVORITE_LOCATION, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const recentSearchList = (data = {}) => {
  return axiosInstance
    .post(API.RECENT_SEARCH_DATA, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};