import { CommonInput, CommonModal, MapInput } from "components";
import React, { useContext, useEffect, useMemo, useState } from "react";
import dummyMap from "../../../src/assets/img/dummyMap.webp";
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
  MarkerClusterer,
  InfoBox,
  Circle,
} from "@react-google-maps/api";
import { UserContext } from "context";
import { useGoogleMaps } from "components/GoogleMapsProvider/GoogleMapsProvider";

const AddMapLocationModal = (props) => {
  const isLoaded = useGoogleMaps();

  const lat = 28.7041;
  const lng = 77.1025;

  const [map, setMap] = useState(null);
  const [mapMaker, setMapMAker] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [city, setCity] = useState("");
  const [center, setCenter] = useState({ lat: Number(lat), lng: Number(lng) });
  const [circleRadius, setCircleRadius] = useState(1000);
  const [circleRef, setCircleRef] = useState(null);
  const [location, setLocation] = useState(center);
  const [selectFormat, setSelectFormat] = useState();
  const [formError, setFormError] = useState("");
  const geocoder = useMemo(
    () => (isLoaded ? new window.google.maps.Geocoder() : null),
    []
  );

  const loadHandler = (map) => {
    setMap(map);
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    setCenter({ lat: Number(location?.lat), lng: Number(location?.lng) });
  }, [location]);

  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocation = { lat: latitude, lng: longitude };

          setLocation(currentLocation);
          setCenter(currentLocation);

          // Reverse geocode to get formatted address
          getFormatAddres(latitude, longitude);
        },
        (error) => {
          console.error("Error fetching current location: ", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    fetchCurrentLocation();
  }, []);

  const getFormatAddres = (latValue, langValue) => {
    geocoder?.geocode(
      { location: { lat: latValue, lng: langValue } },
      (results, status) => {
        if (status === "OK" && results[0]) {
          const country = results[0].address_components.find((component) =>
            component.types.includes("country")
          )?.long_name;

          if (country !== "India") {
            alert("Please select a location within India.");
            return;
          }

          setSelectFormat(results[0]);
          const addressComponents = results[0].address_components;
          let formattedAddress = {
            city: "",
            state: "",
            country: "",
            postalCode: "",
          };

          for (const component of addressComponents) {
            if (component.types.includes("locality")) {
              formattedAddress.city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
              formattedAddress.state = component.long_name;
            }
            if (component.types.includes("country")) {
              formattedAddress.country = component.long_name;
            }
            if (component.types.includes("postal_code")) {
              formattedAddress.postalCode = component.long_name;
            }
          }

          const finalFormattedAddress = ` ${formattedAddress.city}, ${formattedAddress.state} ${formattedAddress.postalCode}, ${formattedAddress.country}`;
          setCity(finalFormattedAddress);
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      }
    );
  };
  // useEffect(() => {
  //   getFormatAddres(lat, lng);
  // }, []);


  const handleCityChange = (value) => {
   
    setSelectFormat(value);
    setCity(value?.formatted_address);
    setLocation({
      lat: Number(value.geometry?.location?.lat()),
      lng: Number(value.geometry?.location?.lng()),
    });
  };

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const options = useMemo(
    () => ({
      // mapId: "b181cac70f27f5e6",
      disableDefaultUI: true,
      clickableIcons: false,
      zoomControl: true,
      restriction: {
        latLngBounds: {
          north: 37.0838, // Northernmost latitude of India
          south: 6.4627, // Southernmost latitude of India
          east: 97.3956, // Easternmost longitude of India
          west: 68.1097, // Westernmost longitude of India
        },
        strictBounds: true, // Enforces the restriction
      },
    }),
    []
  );

  const onMapClick = (event) => {
    setLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };
  const validateRadius = () => {
    if (circleRadius < 1000) {
      return "Radius cannot be less than 1000 meters.";
    } else if (circleRadius > 10000) {
      return "Radius cannot be greater than 10000 meters.";
    }
    return ""; // No error
  };
  const handleSubmitCity = () => {
    const error = validateRadius();
    if (error) {
      setFormError(error);
    } else {
      props?.callBackDataMap(selectFormat);
      props?.closeModal();
    }
  };

  const propsMapLocation = {
    ...props,
    onSubmit: handleSubmitCity,
  };

  const onCircleDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setCenter({ lat: newLat, lng: newLng });
    setLocation({ lat: newLat, lng: newLng });
    getFormatAddres(newLat, newLng);
  };

  const onCircleRadiusChanged = () => {
    if (circleRef) {
      const newRadius = circleRef.getRadius();
      setCircleRadius(Math.floor(newRadius));
    }
  };

  const handleRadiusChange = (e) => {
    setCircleRadius(Number(e.target.value));
  };

  return (
    <>
      <CommonModal {...propsMapLocation}>
        <div className="addMap_body">
          <div className="_inrPrt_flx2">
            <div className="singleDiv">
              <MapInput
                label="Search on Map"
                value={city}
                handleInputChange={handleCityChange}
              />
              {/* <CommonInput
                type="text"
                label="Search on Map"
                placeholder="Search on Map"
                // name='prefix'
                // value={formValue.prefix}
                // focus={!!(typeof formError === "object" && formError?.prefix)}
                // error={!!(typeof formError === "object") ? formError?.prefix : ""}
                // onChange={({ target: { name, value } }) =>
                //     handleOnChange(name, value)
                // }
              /> */}
            </div>

            <div className="singleDiv">
              <CommonInput
                type="number"
                label="Radius (in meters)"
                placeholder="Radius"
                value={circleRadius}
                min="1000" // Minimum value
                max="10000" // Maximum value
                step="1"
                onChange={handleRadiusChange}
                focus={formError}
                error={formError}
              />
            </div>
          </div>

          {/* <LoadScript googleMapsApiKey="AIzaSyAkWxbuO-maU16USeyELd3UP0hPLITP3Ec"> */}
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={location}
              zoom={14}
              // onClick={onMapClick}
              options={options}
              onLoad={loadHandler}
              onUnmount={onUnmount}
              onClick={() => setActiveMarker(null)}
            >
              <Marker position={location} />
              <Circle
                center={center}
                radius={circleRadius}
                options={{
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.35,
                  draggable: true,
                  editable: true,
                }}
                onDragEnd={onCircleDragEnd}
                onRadiusChanged={onCircleRadiusChanged}
                onLoad={(circle) => setCircleRef(circle)}
              />
            </GoogleMap>
          ) : (
            <></>
          )}
          {/* </LoadScript> */}
          {/* <img src={dummyMap} style={{width: "100%"}} alt="" /> */}
        </div>
      </CommonModal>
    </>
  );
};

export default AddMapLocationModal;
