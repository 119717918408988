import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CommonDateRange, Heading } from 'components';
import { ownVehicleProfitableList } from '_services/reportService/reportService';
import StorageService from 'utils/StorageServices';
import Loading from 'pages/Loading';

const OwnVehicleReport = () => {
  const [ownVehicleData, setOwnVehicleData] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentDate = new Date();
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  const [selectedRange, setSelectedRange] = useState({
    startDate: moment(firstDay).subtract(31, 'days'),
    endDate: moment(lastDay),
  });

  const [filter, setFilter] = useState({
    limit: 100,
    offset: 1,
  });

  useEffect(() => {
    getProfitLossReportList();
  }, [filter]);

  const getProfitLossReportList = async () => {
    setLoading(true)
    const payload = {
      ...filter,
      company_id: StorageService.getCompanyId(),
      start_date: moment(selectedRange.startDate).format('YYYY-MM-DD'),
      end_date: moment(selectedRange.endDate).format('YYYY-MM-DD'),
    };

    try {
      const res = await ownVehicleProfitableList(payload);
      if (res?.responseCode === 200) {
        setOwnVehicleData(res?.data);
        setLoading(false)
      } else {
        setLoading(false)
        console.error('Error fetching data');
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };

  const handleApply = (newRange) => {
    setSelectedRange(newRange);
    setFilter((s) => ({
      ...s,
      offset: 1,
    }));
  };

  const resetFunction = () => {
    const newStartDate = moment(firstDay).subtract(31, 'days');
    const newEndDate = moment(lastDay);

    setSelectedRange({
      startDate: newStartDate,
      endDate: newEndDate,
    });

    setFilter((s) => ({
      ...s,
      offset: 1,
    }));
  };

  return (
    <>
      <Heading title="Own Vehicle Profit" />
      <div className="_mrktLoad_mainBx __main_p_n_l">
        <div className="_mrktFilt_mainBx">
          <div className="_mrktInfilts_bx" style={{ minHeight: '100%' }}>
            <div className="head_load">
              <span className="search_loads">Filters</span>
            </div>

            <div className="_optFilts">
              <div style={{ marginBottom: '12px' }}>
                <CommonDateRange
                  key={`${selectedRange.startDate}-${selectedRange.endDate}`}
                  initialStartDate={selectedRange.startDate}
                  initialEndDate={selectedRange.endDate}
                  onApply={handleApply}
                />
              </div>

              <button className="reSet_btn_lg" onClick={resetFunction}>
                Reset
              </button>
            </div>
          </div>
        </div>
        {loading ? <div style={{position: 'relative', width: '100%'}}><Loading/></div> :
          <div className="_pNl_listBx">
            <div className="_pNl_list_otrBx">
              <h4>Own Vehicle P&L Report</h4>
              <p>{`From ${moment(selectedRange.startDate).format('DD MMM YYYY')} - ${moment(selectedRange.endDate).format('DD MMM YYYY')}`}</p>


              {/* Data rendering for ownVehicleData */}
              <div className='_pNl_list_inrBx'>
                <div className='__pNlFlx' style={{ marginBottom: '16px' }}>
                  <span>Own/ Leased Vehicle Trips</span> <span>{ownVehicleData?.total_trips}</span>
                </div>

                <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                  <span>Revenue</span> <span>₹ {ownVehicleData?.total_party_freight}</span>
                </div>

                <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                  <span>Trip Charges</span> <span>₹ {ownVehicleData?.total_truck_freight}</span>
                </div>

                <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                  <span>Claims</span> <span>₹ {ownVehicleData?.claims}</span>
                </div>

                <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                  <span>Other Charges</span> <span>₹ {ownVehicleData?.other_charges}</span>
                </div>

                <div className='__pNlFlx __plTotalBl' style={{ marginBottom: '12px' }}>
                  <span>Trip Profit</span> <span>₹ {ownVehicleData?.total_profit_loss}</span>
                </div>

                <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                  <span>Servicing</span> <span>₹ {ownVehicleData?.servicing}</span>
                </div>

                <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                  <span>Documents</span> <span>₹ {ownVehicleData?.documents}</span>
                </div>

                <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                  <span>Salary</span> <span>₹ {ownVehicleData?.salary}</span>
                </div>

                <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                  <span>Fastag (Not Associated)</span> <span>₹ {ownVehicleData?.fastag}</span>
                </div>

                <div className='__pNlFlx __plTotalYl' >
                  <span>Profit from Own/ Leased (A)</span> <span>₹ {ownVehicleData?.profit_A}</span>
                </div>

              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default OwnVehicleReport;
