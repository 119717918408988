import { API, axiosInstance } from "lib";

export const createVehicle = (data = {}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'add_vehicle', data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getVehicleListData = (data={}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'get_vehicle_list' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const deleteVehicleData = (data={}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'delete_vehicle' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const changeVehicleStatusData = (data={}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'change_vehicle_status' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

