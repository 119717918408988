import {
    addExpenseAccount,
    getAllDriver,
    getChartExpense,
    getUserAccountList,
    handleKeyDown,
    transferFundApi,
    uploadFiles,
    venderDropdownApi,
  } from "_services";
  import {
    CommonDatePicker,
    CommonInput,
    CommonModal,
    CommonSwitch,
    CustomSelect,
  } from "components";
  import { Images } from "constants";
  import { UserContext } from "context";
  import { errorSchema, utils } from "helper";
  import React, { useContext, useEffect, useState } from "react";
  import toast from "react-hot-toast";
  import { IoCloseCircle } from "react-icons/io5";
  import StorageService from "utils/StorageServices";
  
  const TransferModal = (props) => {
    const arryOptions = [
      { label: "Goods", value: "Goods" },
      { label: "Service", value: "Service" },
    ];
  
    const initial = {
        company_id: StorageService.getCompanyId(),
      date: new Date(),
      from_account_category: "",
      from_account_sub_category: "",
      from_account_id: "",
      from_driver_id:"",

      to_account_category: "",
      to_account_sub_category: "",
      to_account_id: "",
      to_driver_id:"",
     
      amount: "",
    };

  
    const { masterData, states } = useContext(UserContext);
    const [form, setForm] = useState(initial);
    const [expenseTypeList, setExpenseTypeList] = useState([]);
    const [formError, setFormError] = useState("");
    const [accountLoading, setAccountLoading] = useState(false);
    const [fromaccountList, setFromAccountList] = useState([]);
    const [toaccountList, setToAccountList] = useState([]);
    const [driverList, setDriverList] = useState([]);
    
    const [accountType, setAccountType] = useState();
    const [accountTypeto, setAccountTypeto] = useState();
    const [isLoadding, setIsLoadding] = useState(false);
    
   
  
    useEffect(() => {
        if (masterData){
            getDriverList()
            initializeForm();
        } 
      }, [masterData]);
  
    useEffect(() => {
      if (
        form?.from_account_sub_category != "15" &&
        form?.from_account_category &&
        expenseTypeList.length
      ) {
        if (form?.from_account_id == "") {
            fetchAccountList("from");
        }
      }
    }, [
      form?.from_account_sub_category,
      form?.from_account_category,
      expenseTypeList,
      form?.from_account_id,
    ]);

    useEffect(() => {
        if (
          form?.to_account_sub_category != "15" &&
          form?.to_account_category &&
          expenseTypeList.length
        ) {
          if (form?.to_account_id == "") {
            fetchAccountList("to");
          }
        }
      }, [
        form?.to_account_sub_category,
        form?.to_account_category,
        expenseTypeList,
        form?.to_account_id,
      ]);
  
  
  
    useEffect(() => {
      if (accountType) {
        setForm({ ...form, from_account_id: "",from_driver_id:"" });
       
     
      }
    }, [accountType]);

    useEffect(() => {
        if (accountTypeto) {
          setForm({ ...form, to_account_id: "",to_driver_id:"" });
          
        }
      }, [accountTypeto]);
  
    const handleOnChange = async (name, value) => {
      const stateObj = {
        ...form,
        [name]: value,
      };
      setForm(stateObj);
  
      if (!!formError) {
        const validationResult = await utils.checkFormError(
          { ...stateObj },
          errorSchema.addExpenseAccountSchema
        );
        if (validationResult === true) {
          setFormError("");
        } else {
          setFormError(validationResult);
        }
      }
    };
  
    

    const initializeForm = () => {
        const detailsAccount = masterData?.own_account_category?.sub_category
          ?.filter((item) => item.id !== 10)
          ?.map((item) => ({
            label: item.name,
            value: item.id,
            category_id: masterData?.own_account_category?.id,
          }));
    
        if (detailsAccount?.length) {
          setForm((prevState) => ({
            ...prevState,
            from_account_category: detailsAccount[0]?.category_id,
            from_account_sub_category: detailsAccount[0]?.value,
            to_account_category: detailsAccount[0]?.category_id,
            to_account_sub_category: detailsAccount[0]?.value,
          }));
          setExpenseTypeList(detailsAccount);
        }
      };

      const getDriverList = async () => {
        const res = await getAllDriver({
          company_id: StorageService.getCompanyId(),
        });
        if (res?.responseCode === 200) {
          setDriverList(
            res?.data?.driver_list
              ?.filter((i) => i?.driver_type == 2)
              ?.map((i) => {
                return {
                  ...i,
                  label: i.name,
                  value: i._id,
                };
              })
          );
        } else {
          toast.error(res?.responseMessage);
        }
      };

    const fetchAccountList = async (type) => {
        setAccountLoading(true);
        try {
          const payload = {
            company_id: StorageService.getCompanyId(),
            category_id: parseInt(form[`${type}_account_category`]),
            sub_category_id: parseInt(form[`${type}_account_sub_category`]),
          };
          const res = await getUserAccountList(payload);
    
          if (res?.responseCode === 200) {
            const accounts = res.data.account_list.map((item) => ({
              label: item.name,
              value: item.id,
            }));

            if(type=="from"){
                setFromAccountList(accounts);
            }else{
                setToAccountList(accounts);
            }
            
            

            setForm((prevState) => ({
              ...prevState,
              [`${type}_account_id`]: accounts[0]?.value || "",
            }));
          } else {
            toast.error(res.responseMessage);
          }
        } catch (error) {
          toast.error("Failed to fetch accounts.");
        } finally {
          setAccountLoading(false);
        }
      };
    
  
      const onHandleSubmit = async () => {
    
        const validationResult = await utils.checkFormError(
          { ...form },
          errorSchema.fundTransferSchema
        );
      
        let updatedFormError = { ...validationResult };
      
        if (
          form.from_account_sub_category == form.to_account_sub_category &&
          form.from_account_id == form.to_account_id
        ) {
          updatedFormError = {
            ...updatedFormError,
            from_account_id: "From Account and To Account cannot be the same.",
            to_account_id: "From Account and To Account cannot be the same.",
          };
        }
      
        setFormError(updatedFormError);
      
        if (Object.keys(updatedFormError).length > 0) {
          return;
        }
      
        const payload = {
          ...form,
          amount: parseFloat(parseFloat(form.amount || 0).toFixed(2)),
        };
      
        setIsLoadding(true);
        try {
          const res = await transferFundApi(payload);
          if (res?.responseCode === 200) {
            setIsLoadding(false);
            props?.callBackFreight();
            props?.closeModal();
            toast.success(res?.responseMessage);
          } else {
            setIsLoadding(false);
           
          }
        } catch (error) {
          setIsLoadding(false);
          toast.error(error?.message );
        }
      };
      
  
  
    const propsExpenses = {
      ...props,
      isLoadding: isLoadding,
      disabled: isLoadding,
      
      onSubmit: onHandleSubmit,
    
    };
  
    return (
      <>
        <CommonModal {...propsExpenses}>
          <div className="__addExp_body">
            <div className="flxInr">
              
              <div className="thalfDiv ps-3">
                <CommonDatePicker
                  name="date"
                  label="Expense Date"
                  placeholderText="Expense Date"
                  startDate={form?.date}
                  setStartDate={(data) => {
                    handleOnChange("date", data);
                  }}
                  error={formError?.date}
                  focus={!!formError?.date}
                  errorShow={false}
                />
              </div>

              <div className="thalfDiv">
                <CommonInput
                  type="text"
                  label="Amount (Rs)"
                  placeholder="Enter Amount"
                  name="amount"
                  errorShow={false}
                  error={formError?.amount}
                  focus={!!formError?.amount}
                  onKeyDown={handleKeyDown}
                  onChange={({ target: { name, value } }) => {
                  handleOnChange(name, value);
                }}
                />
              </div>
  
             
  
              <div className="px-3 w-100">
                <div className="_pMode_exbx">
                  <p>From Account</p>
                  <div className="tFlex_bd">
                    {expenseTypeList.map((i) => {
                      return (
                        <button
                          className={`_baseSpan ${
                            i?.value == form?.from_account_sub_category
                              ? "active"
                              : "_bspDisable"
                          }`}
                          name="from_account_sub_category"
                          disabled={accountLoading}
                          value={i.value}
                          onClick={({ target: { name, value } }) => {
                            setAccountType(value);
                            handleOnChange(name, Number(value));
                          }}
                        >
                          {i.label}
                        </button>
                      );
                    })}
                  </div>
                  {form?.from_account_sub_category != 11 ? (
                    <div className="mt-4">
                      <CustomSelect
                        label="Select Account"
                        options={fromaccountList}
                        placeholder="Select Account"
                        name="from_account_id"
                        onSelect={(data) => {
                          handleOnChange("from_account_id", data?.value);
                        }}
                        error={formError?.from_account_id}
                        value={
                          form?.from_account_id
                            ? fromaccountList?.find(
                                (i) => i.value == form?.from_account_id
                              )
                            : { label: "", value: "" }
                        }
                        // errorShow={false}
                      />
                    </div>
                  ):(
                    <div className="mt-4">
                      <CustomSelect
                        label="Select Driver"
                        options={driverList}
                        placeholder="Select Driver"
                        name="from_driver_id"
                        onSelect={(data) => {
                          handleOnChange("from_driver_id", data?.value);
                        }}
                        error={formError?.from_driver_id}
                        value={
                          form?.from_driver_id
                            ? driverList?.find(
                                (i) => i.value == form?.from_driver_id
                              )
                            : { label: "", value: "" }
                        }
                        errorShow={false}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="px-3 w-100">
                <div className="_pMode_exbx">
                  <p> To Account</p>
                  <div className="tFlex_bd">
                    {expenseTypeList.map((i) => {
                      return (
                        <button
                          className={`_baseSpan ${
                            i?.value == form?.to_account_sub_category
                              ? "active"
                              : "_bspDisable"
                          }`}
                          name="to_account_sub_category"
                          disabled={accountLoading}
                          value={i.value}
                          onClick={({ target: { name, value } }) => {
                            setAccountTypeto(value);
                            handleOnChange(name, Number(value));
                          }}
                        >
                          {i.label}
                        </button>
                      );
                    })}
                  </div>
                  {form?.to_account_sub_category != 11 ? (
                    <div className="mt-4">
                      <CustomSelect
                        label="Select Account"
                        options={toaccountList}
                        placeholder="Select Account"
                        name="to_account_id"
                        onSelect={(data) => {
                          handleOnChange("to_account_id", data?.value);
                        }}
                        error={formError["to_account_id"]}
                        value={
                          form?.to_account_id
                            ? toaccountList?.find(
                                (i) => i.value == form?.to_account_id
                              )
                            : { label: "", value: "" }
                        }
                        // errorShow={false}
                      />
                    </div>
                  ):(
                    <div className="mt-4">
                      <CustomSelect
                        label="Select Driver"
                        options={driverList}
                        placeholder="Select Driver"
                        name="to_driver_id"
                        onSelect={(data) => {
                          handleOnChange("to_driver_id", data?.value);
                        }}
                        error={formError?.to_driver_id}
                        value={
                          form?.to_driver_id
                            ? driverList?.find(
                                (i) => i.value == form?.to_driver_id
                              )
                            : { label: "", value: "" }
                        }
                        errorShow={false}
                      />
                    </div>
                  )}
                </div>
              </div>
              
            </div>
          </div>
        </CommonModal>
      </>
    );
  };
  
  export default TransferModal;
  