import { API, axiosInstance } from "lib";

export const createInvoiceBillRaise = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_INVOICE_BILL_RAISE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getInvoiceBillRaiseListData = (data = {}) => {
  return axiosInstance
    .post(API.GET_INVOICE_BILL_RAISE_LIST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getDetailsInvoiceBillRaise = (data = {}) => {
  return axiosInstance
    .post(API.GET_DETAILS_INVOICE_BILL_RAISE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getDetailsOfSummaryData = (data = {}) => {
  return axiosInstance
    .post(API.GET_SUMMARY_DETAILS, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getInvoiceBillRaiseSummaryData = (data = {}) => {
  return axiosInstance
    .post(API.GET_INVOICE_BILL_RAISE_SUMMARY, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getInvoiceBillRaiseDataOfLr = (data = {}) => {
  return axiosInstance
    .post(API.GET_LR_FOR_INVOICE_BILL_RAISE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const createInvoiceBillTemplate = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_INVOICE_BILL_RAISE_TEMPLATE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const updateInvoiceBillRaiseDataOfLr = (data = {}) => {
  return axiosInstance
    .post(API.UPDATE_LR_INVOICE_BILL_RAISE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const resetColumnInvoiceBillRaise = (data = {}) => {
  return axiosInstance
    .post(API.RESET_LR_INVOICE_BILL_RAISE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};