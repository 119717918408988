import { API, axiosInstance } from "lib";

export const createConsignee = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_CONSIGNEE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getConsigneeListData = (data = {}) => {
  return axiosInstance
    .post(API.GET_CONSIGNEE_LIST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const deleteConsigneeData = (data = {}) => {
  return axiosInstance
    .post(API.DELETE_CONSIGNEE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const fetchConsigneeDetailsData = (data = {}) => {
  return axiosInstance
    .post(API.GET_SINGLE_CONSIGNEE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const changeConsigneeStatusData = (data = {}) => {
  return axiosInstance
    .post(API.CHANGE_CONSIGNEE_STATUS, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};
