import React, { useEffect } from 'react';
import { routeConfigs } from "./RouteList";
import { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { NotFound } from "pages";
import Loading from "pages/Loading";
import { startTransition } from 'react';

export default function Router() {

  const createRoutes = (configs) => {
    return configs.map(config => ({
      element: <config.layout />,
      errorElement: <NotFound />,
      children: config?.children?.map(child => ({
        path: child.path,
        element: child.isPrivate
          ? <PrivateRoute element={<child.element />} />
          : <PublicRoute element={<child.element />} termsAndCondition={child.termsAndCondition} policy={child?.policy} />,
        errorElement: <NotFound />,
      })),
    }));
  };

  const routes = createRoutes(routeConfigs);
  const router = createBrowserRouter(routes, {
    future: {
      v7_skipActionErrorRevalidation: true,
      v7_relativeSplatPath: true,
      v7_partialHydration: true,
      v7_normalizeFormMethod: true,
      v7_fetcherPersist:true,
      startTransition: true, 
    },
  });

return (
  <div>
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  </div>
);
}
