// import React, { useEffect, useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './commonDatePicker.css'
// import moment from 'moment';

// const CommonDatePicker = (props) => {

//     const { name, error, startDate, setStartDate, id = Math.random() * 1000, label=`Select Date & Time` ,disabled} = props;

//     useEffect(() => {
//         const style = document.createElement('style');
//         style.innerHTML = `
//           .anv_dateTimePicker .react-datepicker__input-container::after {
//             content: "${label}";
//             position: absolute;
//             display: block;
//             top: -8px;
//             left: 8px;
//             padding: 1px 2px;
//             height: auto;
//             margin: 0;
//             font-size: 12px;
//             font-weight: 400;
//             color: #475f7b;
//             line-height: 14px;
//             background-color: #fff;
//           }
//         `;
//         document.head.appendChild(style);
//         return () => {
//           document.head.removeChild(style);
//         };
//       }, [props, label]);

//     useEffect(() => {
//         if (id) {
//             const element = document && document?.getElementById(id);
//             const handleMouseDown = (e) => {
//                 if (e?.detail > 1) {
//                     e?.preventDefault();
//                 }
//             };
//             if (element) {
//                 element.addEventListener('mousedown', handleMouseDown);
//             }
//             return () => {
//                 if (element) {
//                     element.removeEventListener('mousedown', handleMouseDown);
//                 }
//             };
//         }
//     }, [id]);

//     return (
//         <div className={error ? 'anv_dateTimePicker anvInputError' : 'anv_dateTimePicker'}>
//             <DatePicker
//                 id={id}
//                 name={name}
//                 selected={startDate}
//                 disabled={disabled}
//                 placeholderText='Select Date & Time'
//                 onChange={(date) => setStartDate(date)}
//                 timeInputLabel="test"
//                 showTimeSelect
//                 injectTimes={[
//                     moment().hours(0).minutes(1).seconds(10).toDate(),
//                     moment().hours(12).minutes(5).toDate(),
//                     moment().hours(23).minutes(59).toDate(),
//                 ]}
//                 dateFormat="dd/MM/yyyy h:mm aa"
//                 popperClassName="someCustom"
//             />
//             {error && (
//                 <span className="input_drop_error">
//                     {error}
//                 </span>
//             )}
//         </div>
//     );
// };

// export default CommonDatePicker;

import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './commonDatePicker.css';
import moment from 'moment';

const CommonDatePicker = (props) => {
    const { name, error, startDate, setStartDate, id = Math.random() * 100000, label = 'Select Date & Time', disabled,minDate } = props;
  

    useEffect(() => {
        if (id) {
            const element = document.getElementById(id);
            const handleMouseDown = (e) => {
                if (e.detail > 1) {
                    e.preventDefault();
                }
            };
            if (element) {
                element.addEventListener('mousedown', handleMouseDown);
            }
            return () => {
                if (element) {
                    element.removeEventListener('mousedown', handleMouseDown);
                }
            };
        }
    }, [id]);

    return (
        <div className={error ? 'anv_dateTimePicker anvInputError' : 'anv_dateTimePicker'}>
            {/* <div className="react-datepicker__input-container" data-label={label}> */}
            <span className='anvDate_lable' data-label={label}></span>
                <DatePicker
                    id={id}
                    name={name}
                    selected={startDate}
                    disabled={disabled}
                    placeholderText='Select Date & Time'
                    onChange={(date) => setStartDate(date)}
                    timeInputLabel="test"
                    showTimeSelect
                    injectTimes={[
                        moment().hours(0).minutes(1).seconds(10).toDate(),
                        moment().hours(12).minutes(5).toDate(),
                        moment().hours(23).minutes(59).toDate(),
                    ]}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    popperClassName="someCustom"
                    minDate={minDate}
                />
            {/* </div> */}
            {error && (
                <span className="input_drop_error">
                    {error}
                </span>
            )}
        </div>
    );
};

export default CommonDatePicker;
