import { addFuelCardMaster, createFastagAccountApi, getAllVehicleNumber, getFuelCardMaster, handleKeyDown } from '_services';
import { errorSchema, utils } from "helper";
import AddEditModal from "modals/AddEditModal";
import { CommonInput, CommonModal, CustomSelect } from 'components'
import React, {useEffect, useState } from "react";
import toast from "react-hot-toast";
import { StorageServices } from "utils";
import StorageService from "utils/StorageServices";

const AddFastTagModal = (props) => {

    const initialFrom = {
        company_id: StorageServices.getCompanyId(),
        fastag_id_number: "",
        fastag_associate_vehicle_id: "",
        fastag_opening_balance: "",
        fastag_credit_or_debit: 1,
        fastag_parent_account_id: "",
      };
    
      const [form, setForm] = useState(initialFrom);
      const [formError, setFormError] = useState("");
      const [loading, setLoading] = useState(false);
      const [vehicleList, setVehicleList] = useState([]);
      const [isOpenFuelModal, setIsOpenFuelModal] = useState(false);
      const [masterFcList, setMasterFcList] = useState([]);
    
      useEffect(() => {
        fetchInitialData();
        getMasterFc();
      }, []);
    
      const fetchInitialData = async () => {
        try {
          const [vehicleRes] = await Promise.all([getVehicleList()]);
    
          if (vehicleRes?.responseCode === 200) {
            const list = vehicleRes.data?.vehicle_list.map((i) => ({
              ...i,
              label: i.vehicle_no,
              value: i._id,
            }));
            setVehicleList(list);
    
            handleListResponse(list, "associate_vehicle_id");
            handleListResponse(list, "fastag_associate_vehicle_id");
          } else {
            toast.error(vehicleRes?.responseMessage);
          }
        } catch (error) {
          console.error("Error fetching initial data:", error);
          toast.error("Error fetching initial data.");
        }
      };
    
      const handleListResponse = (list, labelKey) => {
        if (list.length > 0) {
          setForm((prev) => ({ ...prev, [labelKey]: list[0].value }));
        }
      };
    
      const getVehicleList = () => {
        return getAllVehicleNumber({
          company_id: StorageService.getCompanyId(),
          
        });
      };
    
      const getMasterFc = async () => {
        try {
          const res = await getFuelCardMaster({
            company_id: StorageService.getCompanyId(),
          });
          if (res?.responseCode === 200) {
            setMasterFcList(
              res?.data?.fuelcard_master?.map((i) => ({
                ...i,
                label: i?.name,
                value: i?._id,
              }))
            );
          }
        } catch (error) {}
      };
    
      const handleOnChange = async (name, value) => {
        const stateObj = { ...form, [name]: value };
        setForm(stateObj);
    
        if (!!formError) {
          const validationResult = await utils.checkFormError(
            stateObj,
            errorSchema.fuelSchema
          );
    
          if (validationResult === true) {
            setFormError("");
          } else {
            setFormError(validationResult);
          }
        }
      };
    
      const handleOnSubmit = async () => {
        const validationResult = await utils.checkFormError(
          { ...form },
          errorSchema.fastagSchema
        );
    
        const payloads = {
            company_id: form?.company_id,
            fastag_id_number: form?.fastag_id_number,
            associate_vehicle_id: form?.fastag_associate_vehicle_id,
            opening_balance: form?.fastag_opening_balance,
            credit_or_debit: form?.fastag_credit_or_debit,
            fastag_parent_account_id: form?.fastag_parent_account_id,
        };
    
        if (validationResult == true) {
          setLoading(true);
          try {
            const apiCall = createFastagAccountApi;
    
            if (!payloads || !apiCall) {
              toast?.error("Invalid tab or missing payload");
              return;
            }
    
            const res = await apiCall(payloads);
    
            if (res?.responseCode === 200) {
              setLoading(false);
              props?.closeModal();
            //   props?.getAccountListHandler();
              toast?.success(res?.responseMessage);
            } else {
              setLoading(false);
              toast?.error(res?.responseMessage);
            }
          } catch (error) {
            setLoading(false);
            console.error("Error:", error);
            toast?.error(error?.message || "An unexpected error occurred");
          }
        } else {
          setLoading(false);
          setFormError(validationResult);
        }
      };
    
      const addFuelCardHandle = (data) => {
        setIsOpenFuelModal(true);
      };
    
      const callBackApi = (data) => {
        getMasterFc();
        handleOnChange("fuel_card_master_id", data?._id);
      };
    
      const propsTransAccount = {
        ...props,
        onSubmit: handleOnSubmit,
        isLoadding: loading,
        disabled: loading,
        show: isOpenFuelModal ? "" : "show",
      };
    
      let openZoneModal = {
        isOpen: isOpenFuelModal,
        closeModal: (e) => setIsOpenFuelModal(false),
        // nextModal: (e) => submitUpdateData(e),
        modalMainClass: "_dashCommon_Modal _smModal",
        modalTitle: "Add Fuel Card Master",
        submitTitle: "Add FC Master",
        footerPart: true,
        label: "Fuel Card Master *",
        placeholder: "Enter Fuel Card Master ",
        ApiPoint: addFuelCardMaster,
        callBackApi: callBackApi,
      };
    return (
        <>
            <CommonModal {...propsTransAccount}>
                <div className="addLr_body">
                    <div className="addPr_flBx _addTrns_flx">
                        <div className="_allDflx ">
                            <div>
                                <CommonInput
                                     type="text"
                                     placeholder="Fastag ID Number"
                                     label="Fastag ID Number"
                                     name="fastag_id_number"
                                     focus={!!formError?.fastag_id_number}
                                     error={formError.fastag_id_number}
                                     onChange={({ target: { name, value } }) =>
                                       handleOnChange(name, value)
                                     }
                                     value={form?.fastag_id_number}
                                />
                            </div>

                            <div>
                                <CustomSelect
                                    options={vehicleList}
                                    label="Associate Vehicle"
                                    placeholder="Associate Vehicle"
                                    name="fastag_associate_vehicle_id"
                                    focus={!!formError?.fastag_associate_vehicle_id}
                                    error={formError.fastag_associate_vehicle_id}
                                    onSelect={(data) =>
                                      handleOnChange("fastag_associate_vehicle_id", data?.value)
                                    }
                                    // value={form?.associate_vehicle_id}
                                    value={vehicleList?.find(
                                      (i) => i?.value == form?.fastag_associate_vehicle_id
                                    )}
                                />
                            </div>

                            <div>
                                <CommonInput
                                    type="text"
                                    placeholder="Opening Balance (Rs)"
                                    label="Opening Balance (Rs)"
                                    name="fastag_opening_balance"
                                    focus={!!formError?.fastag_opening_balance}
                                    error={formError.fastag_opening_balance}
                                    onChange={({ target: { name, value } }) => {
                                      const validValue = value.replace(/[^0-9.]/g, "");
                                      const formattedValue = validValue.includes(".")
                                        ? validValue.split(".")[0] +
                                          "." +
                                          validValue.split(".")[1].slice(0, 2)
                                        : validValue;
                
                                      handleOnChange(name, formattedValue);
                                    }}
                                    value={form?.fastag_opening_balance}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>

                            <div className="d-flex gap-4">
                  {[
                    { label: "(+) Balance", value: 1 },
                    { label: "(-) Balance", value: 2 },
                  ].map((i) => (
                    <div className="_dualCheckr_bx">
                      <input
                        className="form-check-input"
                        id={i?.label}
                        type="radio"
                        name="fastag_credit_or_debit"
                        checked={i.value == form.fastag_credit_or_debit}
                        value={i.value}
                        onChange={({ target: { name, value } }) =>
                          handleOnChange(name, Number(value))
                        }
                      />
                      <label htmlFor={i?.label} className="form-check-label">
                        {i?.label}
                      </label>
                    </div>
                  ))}
                </div>

                <div>
                  <CustomSelect
                    options={[]}
                    label="Fastag Parent Account"
                    placeholder="Fastag Parent Account"
                    name="consignor_id"
                    // value={isDropDownFilter?.consignor_id}
                    // onSelect={(data) => setIsDropDownFilter((s) => ({ ...s, consignor_id: data }))}
                  />
                </div>
                        </div>
                    </div>
                </div>
            </CommonModal>
            {isOpenFuelModal && <AddEditModal {...openZoneModal} />}
        </>
    )
}

export default AddFastTagModal