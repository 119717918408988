import { deleteUserData } from "_services";
import { deleteAddressBook, getAddressBookListData } from "_services/accountsServices/addressBookService";
import { ButtonHeading, CommonModal, CustomPagination, Heading, MapInput } from "components";
import BootstrapTable from "components/BootstrapTable";
import { AddAddressBook, AddMapLocationModal } from "modals";
import MastersFilterModal from "modals/MastersFilterModal";
import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineEye } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegTrashCan } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import StorageService from "utils/StorageServices/StorageServices";
import RupeeIcon from "../Supplier/RupeeIcon";
import { UserContext } from "context";
import { getZoneDropdownListData } from "_services/dropdownServices/dropdownService";

const AddressBook = () => {
  const { masterData , mastersChild} = useContext(UserContext);
  const masterChildAddressBook = mastersChild?.find((i) => i?.label === "Address Book");
  const navigate = useNavigate();
  const loadingBarRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [addressBooklist, setAddressBookList] = useState([]);
  const [zoneList,setZoneList] = useState([])
  const [addressId,setAddressId] = useState({
    id:"",
    title:"address",
    view:false
  })

  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: "",
    status: 1,
  });

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Type", accessor: "category" },
    { header: "Associate Party", accessor: "associate_party" },
    { header: "Location", accessor: "locationPoint" },
    { header: "Code", accessor: "code" },
    { header: "Name", accessor: "name" },
    
    { header: "City", accessor: "city" },
    { header: "Zone", accessor: "zoneName" },
    { header: "Contact", accessor: "contact_no" },
    
     
    { header: "Status", accessor: "status" },
  ];

  useEffect(() => {
    getAddressBookList();
    getZoneList()
  }, [filter, showOffcanvas]);

  const getZoneList = async () => {

    let object = { company_id: StorageService.getCompanyId() };
    const res = await getZoneDropdownListData(object)

    if (res?.responseCode === 200) {
      setZoneList(res.data?.zone_list);
    
    }
  };

  const getAddressBookList = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        ...filter,
        company_id: StorageService.getCompanyId(),
      };
      const res = await getAddressBookListData(object);
      if (res?.responseCode === 200) {
        setAddressBookList(res.data?.addressbook_list);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete();
      }
    }
  };

  const editHandler = (data) => {};

  const deleteHandler = (item) => {
    setIsDelete(item)
  };

  const statusChangeHnadle = async (data, index) => {
    // console.log("third",data)
    // const list = [...userlist]
    // try {
    //   const payload = {
    //     company_id: StorageService.getCompanyId(),
    //     param_user_id: data?.user_id?._id,
    //     status: data?.active === 1 ? 0 : 1,
    //   };
    //   const res = await changeUserStatusData(payload);
    //   if (res?.responseCode === 200) {
    //     getDataSupplierList()
    //   } else {
    //     console.log(res?.responseCode);
    //     setTimeout(()=>{
    //       list[index].active = data?.active === 1 ? 0 : 1
    //       console.log(list)
    //       setUserList(list)
    //     },500)
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleStatusEvent = async (data, index) => {
    statusChangeHnadle(data, index);
    const list = [...addressBooklist];
    list[index].active = data?.active === 1 ? 0 : 1;
    setAddressBookList(list);
  };

  const callBackAddressData = ()=>{
    setAddressId({id:"",title:"address"})
  }


  const rowListData = (data) => {

    return data?.map((item, i) => ({
      ...item,
      serial: (
        <React.Fragment>
          <div className="signDropdown">
            <button className="dropbtn11">
              <BsThreeDotsVertical />
            </button>
            <div className="dropdownContent">
              {masterChildAddressBook?.view === 1 && <Link
                // to={`/account/supplier/details/${item?._id}`}
                onClick={()=>{
                  setAddressId({...addressId,
                    id:item?._id,title:"address",view:true
                  })
                  setShowOffcanvas(true)
                }}
                className="_avPointer"
                style={{ textDecoration: "none" }}
              >
                <span className="_samAnchor">
                  <span className="viewIcon_avt">
                    <AiOutlineEye />
                  </span>
                  &nbsp; View
                </span>
              </Link>}
              {masterChildAddressBook?.edit === 1 && <Link
                onClick={()=>{
                  setAddressId({...addressId,
                    id:item?._id,title:"address",view:false
                  })
                  setShowOffcanvas(true)
                }}
                className="_avPointer"
                style={{ textDecoration: "none" }}
              >
                <span className="_samAnchor">
                  <span className="viewIcon_avt">
                    <FiEdit />
                  </span>
                  &nbsp; Edit
                </span>
              </Link>}
              {/* <div
                    
                    className='_avPointer'
                  >
                    <span className='_samAnchor'>
                      <span className='editIcon_avt'>
                        <FiEdit />
                      </span>
                      Edit
                    </span>
                  </div> */}

              {masterChildAddressBook?.delete === 1 && <div onClick={(e) => deleteHandler(item)} className="_avPointer">
                <span className="_samAnchor">
                  <span className="deleteIcon_avt">
                    <FaRegTrashCan color="red" />
                  </span>
                  Delete
                </span>
              </div>}
            </div>
          </div>
        </React.Fragment>
      ),

      locationPoint:item?.location_point==1?"Loading point / Unit":item?.location_point==2?"Drop point / Consignee":"-",
      zoneName: zoneList?.find((i) => i?._id === item?.zone_id)?.name || "",
      supplier_name: (
        <Link style={{ textDecoration: "none" }}>
          {item?.company_user_name ? item?.company_user_name : "-"}
        </Link>
      ),
      contact_number: (
        <React.Fragment>
          {item?.user_id?.mobile_no ? item?.user_id?.mobile_no : "-"}
        </React.Fragment>
      ),
      category: (
        <React.Fragment>
          {
            masterData?.addressbook_category_list.find(
              (fx) => fx?.id == item?.category
            )?.name
          }
        </React.Fragment>
      ),
      party_group: (
        <React.Fragment>
          {item?.consignor_group_id?.name
            ? item?.consignor_group_id?.name
            : "-"}
        </React.Fragment>
      ),
      city: (
        <React.Fragment>
          {item?.city?.label ? item?.city?.label : "-"}
        </React.Fragment>
      ),
      status: (
        <div
          className="_anvSwitcherNew form-check form-switch"
          style={{ width: "125px" }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            name="actStatus"
            id={`actStatus-${item._id}`}
            autoComplete="off"
            checked={item?.active}
            onChange={() => handleStatusEvent(item, i)}
          />
          <span
            className={
              item?.active
                ? "active_label_masters_status"
                : "disabled_label_masters_status"
            }
          >
            {item?.active ? "Active" : "Disabled"}
          </span>
        </div>
      ),
      associate_party:<React.Fragment>
      {item?.consignor_id?.name ? item?.consignor_id?.name : '-'}
    </React.Fragment>,
      balance: item?.balance ? (
        <Link
          to={`/account/supplier/details/${item?._id}?tab=4`}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color:
                item.balance < 0
                  ? "#dc3545"
                  : item.balance > 0
                  ? "#28a745"
                  : "#1450ec",
            }}
          >
            <span>
              <RupeeIcon
                color={
                  item.balance < 0
                    ? "#dc3545"
                    : item.balance > 0
                    ? "#28a745"
                    : "#1450ec"
                }
                size={20}
              />
            </span>
            <span>{item.balance}</span>
          </div>
        </Link>
      ) : (
        <Link
          to={`/account/supplier/details/${item?._id}?tab=4`}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color: "#1450ec",
            }}
          >
            <span>
              <RupeeIcon color="#1450ec" size={20} />
            </span>
            <span>0</span>
          </div>
        </Link>
      ),
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };

  const submitDeleteSupplier = async () => {
    setLoading(true);
    try {
      loadingBarRef.current?.continuousStart();
      let object = {
        company_id: isDelete?.company_id,
        addressbook_id: isDelete?._id,
      };

      const res = await deleteAddressBook(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getAddressBookList()
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success("Error !! ");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteSupplier,
    isLoadding: loading,
  };

  const [city, setCity] = useState("");

  const handleCityChange = (value) => {
    setCity(value);
    console.log("Selected city:", value);
  };

  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading
        title={"Address Book"}
        onClick={() => setShowOffcanvas(true)}
        buttonTitle={masterChildAddressBook?.create === 1 && "Add Address Book"}
        showSearchFilter={{
          search: true,
          filterOption: true,
          filterCount: true,
        }}
        onClickFilterModal={(e) => setShowFilterModal(false)}
        filter={filter}
        setFilter={setFilter}
      />
      
      <div className="_tabWith_outTab">
        <BootstrapTable
          columns={columns}
          data={rowListData(addressBooklist?.length ? addressBooklist : [])}
          isLoading={loading}
        />

       { addressBooklist?.length && <div className="paginationFt_avt "> <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter.offset}
          onPageChange={handlePageChange}
        /></div>}
      </div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          showOffcanvas ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showOffcanvas ? "visible" : "hidden" }}
      ></div>

      <div className={showOffcanvas ? "offBack_drop" : ""}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          showFilterModal ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? "visible" : "hidden" }}
      >
        {showFilterModal && (
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        )}
      </div>
      <div className={showFilterModal ? "offBack_drop" : ""}></div>

      {isDelete?.company_id && (
        <CommonModal {...propsObject}>
          <p className="p-3"> Are you sure you want to delete {isDelete?.name} ? </p>
        </CommonModal>
      )}

      <div>
        <div
          className={`offcanvas offcanvas-end common_offCanvas ${
            showOffcanvas ? "show" : ""
          }`}
          tabIndex={-1}
          style={{ visibility: showOffcanvas ? "visible" : "hidden" }}
        >
          {showOffcanvas && (
            <AddAddressBook
              isOpen={showOffcanvas}
              onClose={(e) => {
                setAddressId({id:"",title:"address",view:false})
                setShowOffcanvas(false)}}
              id={addressId}
              callBackAddressData={callBackAddressData}
            />
          )}
        </div>
        <div className={showOffcanvas ? "offBack_drop" : ""}></div>
      </div>
    </div>
  );
};

export default AddressBook;
