import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {
    branch_id: { label: "All", value: "" },
    consignor_id: { label: "All", value: "" },
    from_location: { label: "All", value: "" },
    to_location: { label: "All", value: "" },
    pod_status: { label: "All", value: 0 },
    eway_bill_status:{ label: 'All', value: 0 },
    start_date: "",
    end_date: "",
    lr_status: 0,
    // search: "",
  },
};

const lrFilterSlice = createSlice({
  name: "input",
  initialState,
  reducers: {
    updateInputValue: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { updateInputValue } = lrFilterSlice.actions;
export default lrFilterSlice.reducer;
