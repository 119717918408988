import { API, axiosInstance } from "lib";

export const addNewLrSeries = (data = {}) => {
  return axiosInstance
    .post(API.GET_BRANCH + 'add_lr_series', data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getLrSeriesListData = (data={}) => {
  return axiosInstance
    .post(API.GET_BRANCH + 'get_lr_series' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const deleteLrSeriesData = (data={}) => {
  return axiosInstance
    .post(API.GET_BRANCH + 'delete_lr_series' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const setLrSeriesData = (data={}) => {
  return axiosInstance
    .post(API.GET_BRANCH + 'set_lr_series' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const updateLrSeriesData = (data={}) => {
    return axiosInstance
      .post(API.GET_BRANCH + 'update_lr_series' , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
  //      throw err;
      });
  };