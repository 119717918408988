import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CommonInput, CustomSelect } from 'components'
import { errorSchema, utils } from 'helper';
import { City, State } from "country-state-city";
import Dropdown from 'components/common/Customdropdown/Dropdown';
import StorageService from "utils/StorageServices/StorageServices";
import { UserContext } from "context";
import debounce from 'lodash.debounce';
import toast, { Toaster } from 'react-hot-toast';
import { createConsignor, getAllConsignorgGroup, getConsignorListData } from '_services/accountsServices/consignorServices';
import { AddButton } from 'components/common/CommonButton';
import { constant } from 'constants/constant';
import { createStaff } from '_services/accountsServices/staffServices';
import { getComapnyUser } from '_services/dropdownServices/dropdownService';
import { getStatesData } from '_services';

const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const formObj = {
    company_id: StorageService.getCompanyId(),
    name: '',
    contact_no: '',
    pay_type: '2',
    opening_balance: '',
    as_of_date: '',
    address_line1: '',
    state: {},
    city: {},
    pincode: '',
    email_id: '',
    alt_contact_no: '',
    pan_no: '',
    pan_expiry_date: '',
    aadhar_no: '',
    salary_amount: '',
    joining_date: '',
    link_user: '',
}

const AddEditStaff = (props) => {
    let { isOpen, onClose, isPreviousModal, showingPrefield, withoutFirstModalDirectLrOpen, isTrue = false } = props;

    const { masterData, cities, states } = useContext(UserContext)

    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [stateValue, setStateValue] = useState({});
    const [linkUsers, setLinkUsers] = useState([])

    const [activeTab, setActiveTab] = useState(1);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createStaff);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async (shouldCloseModal) => {
        try {
            let object = {
                ...formValue,
                link_user: formValue?.link_user?.value ? formValue?.link_user?.value : '',
            }
            const res = await createStaff(object);
            if (!!res) {
                toast.success('Staff Created Successfully');
                setFormValue({});
                setLoading(false);
                if (shouldCloseModal) {
                    onClose();
                } else {
                    setActiveTab(1); // Reset tab to the first tab
                }
                if (isTrue) {
                    isPreviousModal(false);
                    withoutFirstModalDirectLrOpen(...res?.data?.consignor);
                    showingPrefield(false);
                } else {
                    showingPrefield(true);
                    isPreviousModal(true);
                }
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            setIsButtonDisabled(false);
        }
    }

    const handleClick = async (e) => {
        e.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createStaff);
        if (activeTab === 3) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(true);
                setFormError("");

            } else {
                setFormError(validationResult);
                setLoading(false);
            }
        } else {
            if (activeTab == 2) {
                if (utils?.isObjectKeyEmpty(validationResult)) {
                    setActiveTab(activeTab + 1);
                }
            } else {
                setActiveTab(activeTab + 1);
            }

            setFormError(validationResult);
        }
    };

    const handleResetFormAndSubmit = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true); // Disable the button immediately
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createStaff);
        if (activeTab === 3) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(false);
                setFormValue((prevValues) => ({
                    ...prevValues,
                    company_id: StorageService.getCompanyId(),
                    name: '',
                    contact_no: '',
                    opening_balance: '',
                    pay_type: '1',
                    as_of_date: getFormattedDate(),
                    address_line1: '',
                    // state: {},
                    // city: {},
                    pincode: '',
                    email_id: '',
                    alt_contact_no: '',
                }))
                setFormError("");

            } else {
                setFormError(validationResult);
                setLoading(false);
                setIsButtonDisabled(false); // Re-enable the button if validation fails
            }
        } else {
            setActiveTab(activeTab + 1);
            setFormError(validationResult);
            setIsButtonDisabled(false); // Re-enable the button if validation fails
        }
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const onhandleSelect = async (item, name) => {
        const stateObj = { ...formValue, [name]: item };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createStaff);
            if (validationResult === true) {
                setFormError({});
            } else {
                setFormError(validationResult);
            }
        }
    };

    const getDropdownUserList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
            }
            const res = await getComapnyUser(object);
            if (res?.responseCode === 200) {
                let arrayData = res?.data?.user_list?.map((el) => {
                    return {
                        label: el?.company_user_name,
                        value: el?.user_id?._id
                    }
                })
                setLinkUsers(arrayData)
            } else {
                toast.error('Error !!');
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    useEffect(() => {
        setFormValue((s) => ({
            ...s,
            as_of_date: utils.getTodayDate(),
            company_id: StorageService.getCompanyId(),
        }));
        getDropdownUserList();
    }, [StorageService.getCompanyId()]);

    const changeActiveTab = async (event, value) => {
        event.preventDefault();
        if (value > activeTab) {
            const validationResult = await utils.checkFormError(formValue, errorSchema.createStaff);
            if (validationResult === true) {
                setActiveTab(value);
            } else {
                setFormError(validationResult);
            }
        } else {
            setActiveTab(value);
        }
    };

    const getStatesByApi = async (countryCode, stateCode) => {
        try {
          let data = {
            countryCode : countryCode,
            stateCode : stateCode
          }
          let response = await getStatesData(data);
          if (response?.responseCode === 200) {
            setStateValue(response?.data[0]);
          }
        } catch (error) {
          toast.error("An unexpected error occurred. Please try again.")
        }
      }

    const handleCityChange = (data) => {
        handleOnChange("city", {
            label: data?.label,
            value: data?.name,
        })

        getStatesByApi(data?.countryCode, data?.stateCode);

        // const stateDetails = State.getStateByCodeAndCountry(
        //     data?.stateCode,
        //     data?.countryCode
        // );
        // setStateValue(stateDetails)
    }

    useEffect(() => {
        if (stateValue) {
            handleOnChange("state", { label: stateValue?.name, value: stateValue?.isoCode })
        }
    }, [stateValue])

    return (
        <>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title"> Add Staff </h5>
                <button type="button" className="btn-close" onClick={() => onClose(!isOpen)}></button>
            </div>

            <div className="offcanvas-body">

                <div className='pr_procssBx'>
                    <div className='pr_procssBx_grid'>

                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                placeholder="Staff Name"
                                label="Staff Name *"
                                name='name'
                                value={formValue.name}
                                focus={!!(typeof formError === "object" && formError?.name)}
                                error={!!(typeof formError === "object") ? formError?.name : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='singleDiv'>
                            <CommonInput
                                type="number"
                                placeholder="Mobile Number"
                                label='Mobile Number'
                                name='contact_no'
                                maxLength="10"
                                onInput={maxLengthCheck}
                                value={formValue.contact_no}
                                focus={!!(typeof formError === "object" && formError?.contact_no)}
                                error={!!(typeof formError === "object") ? formError?.contact_no : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='halfDiv'>
                            <CommonInput
                                type="number"
                                placeholder="Opening Balance (Rs)"
                                label="Opening Balance (Rs)"
                                name='opening_balance'
                                maxLength="15"
                                onInput={maxLengthCheck}
                                value={formValue.opening_balance}
                                focus={!!(typeof formError === "object" && formError?.opening_balance)}
                                error={!!(typeof formError === "object") ? formError?.opening_balance : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='halfDiv'>
                            <CommonInput
                                type="date"
                                placeholder="As of Date * "
                                label="As of Date * "
                                onKeyDown={(e) => e.preventDefault()}
                                // className="simpleDate_picker"
                                name='as_of_date'
                                value={formValue.as_of_date}
                                focus={!!(typeof formError === "object" && formError?.as_of_date)}
                                error={!!(typeof formError === "object") ? formError?.as_of_date : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>
                    </div>

                    <div className='checkBx_lv mt-2'>
                        {
                            constant.Receive_Pay?.map((el, ind) => {
                                return (
                                    <React.Fragment key={ind}>
                                        <div className='_dualCheckr_bx'>
                                            <input
                                                className="form-check-input"
                                                id={+ind + "pay_type" + el?.value}
                                                type="radio"
                                                name="pay_type"
                                                checked={formValue?.pay_type === el?.value}
                                                value={formValue?.pay_type ? formValue?.pay_type : ""}
                                                onChange={({ target: { name, value } }) =>
                                                    handleOnChange(name, el?.value)
                                                }
                                            />
                                            <label htmlFor={+ind + "pay_type" + el.value} className="form-check-label" >
                                                {el.label}
                                            </label>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='addPr_flBx'>
                    <ul className="nav nav-tabs cstNew_tabs" id="addParty" role="tablist">
                        {constant.addStaffTabs?.map((item, indx) => (
                            <li
                                className="nav-item"
                                role="presentation"
                                key={indx + item?.value}
                            >
                                <button
                                    className={
                                        "nav-link " + (activeTab === item?.id ? "active" : "")
                                    }
                                    type="button"
                                    aria-selected={activeTab === item?.id}
                                    onClick={(e, val) => changeActiveTab(e, item.id)}
                                >
                                    {item?.title}
                                </button>
                            </li>
                        ))}
                    </ul>

                    {activeTab === 1 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>

                                <div className='singleDiv'>
                                    <div className="h4Head">Add Address</div>
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="Address Line 1"
                                        label="Address Line 1"
                                        name='address_line1'
                                        value={formValue.address_line1}
                                        focus={!!(typeof formError === "object" && formError?.address_line1)}
                                        error={!!(typeof formError === "object") ? formError?.address_line1 : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={cities}
                                        onSelect={(data) => handleCityChange(data, 'city')}
                                        value={formValue?.city}
                                        placeholder="Select a city"
                                        error={formError["city.label"]}
                                        label="City"
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="State"
                                        label="State"
                                        name='state'
                                        disabled={true}
                                        value={formValue?.state?.label}
                                        focus={!!(typeof formError === "object" && formError?.state)}
                                        error={!!(typeof formError === "object") ? formError?.state : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="PIN Code"
                                        label="PIN Code"
                                        name='pincode'
                                        value={formValue.pincode}
                                        focus={!!(typeof formError === "object" && formError?.pincode)}
                                        error={!!(typeof formError === "object") ? formError?.pincode : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <div className="h4Head">Other Contact</div>
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="Alternate Number"
                                        label="Alternate Number"
                                        name='alt_contact_no'
                                        maxLength="10"
                                        onInput={maxLengthCheck}
                                        value={formValue.alt_contact_no}
                                        focus={!!(typeof formError === "object" && formError?.alt_contact_no)}
                                        error={!!(typeof formError === "object") ? formError?.alt_contact_no : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="Email"
                                        placeholder="Email ID"
                                        label='Email ID'
                                        name='email_id'
                                        value={formValue.email_id}
                                        focus={!!(typeof formError === "object" && formError?.email_id)}
                                        error={!!(typeof formError === "object") ? formError?.email_id : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 2 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>
                                <div className='halfDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="Pan Number"
                                        label="Pan Number"
                                        name='pan_no'
                                        value={formValue.pan_no}
                                        focus={!!(typeof formError === "object" && formError?.pan_no)}
                                        error={!!(typeof formError === "object") ? formError?.pan_no : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="date"
                                        placeholder="Expiry Date"
                                        label="Expiry Date"
                                        name='pan_expiry_date'
                                        onKeyDown={(e) => e.preventDefault()}
                                        value={formValue.pan_expiry_date}
                                        focus={!!(typeof formError === "object" && formError?.pan_expiry_date)}
                                        error={!!(typeof formError === "object") ? formError?.pan_expiry_date : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="Aadhar Number"
                                        label='Aadhar Number'
                                        name='aadhar_no'
                                        maxLength="12"
                                        onInput={maxLengthCheck}
                                        value={formValue.aadhar_no}
                                        focus={!!(typeof formError === "object" && formError?.aadhar_no)}
                                        error={!!(typeof formError === "object") ? formError?.aadhar_no : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 3 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="Salary Amount"
                                        label='Salary Amount'
                                        name='salary_amount'
                                        maxLength="12"
                                        onInput={maxLengthCheck}
                                        value={formValue.salary_amount}
                                        focus={!!(typeof formError === "object" && formError?.salary_amount)}
                                        error={!!(typeof formError === "object") ? formError?.salary_amount : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="date"
                                        placeholder="Joining Date"
                                        label="Joining Date"
                                        name='joining_date'
                                        onKeyDown={(e) => e.preventDefault()}
                                        value={formValue.joining_date}
                                        focus={!!(typeof formError === "object" && formError?.joining_date)}
                                        error={!!(typeof formError === "object") ? formError?.joining_date : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={linkUsers}
                                        name="link_user"
                                        flotingLabel="Link User"
                                        isDropdownTop={true}
                                        placeholder="Link User"
                                        value={formValue?.link_user}
                                        focus={!!(typeof formError === "object" && formError?.link_user)}
                                        error={!!(typeof formError === "object") ? formError?.link_user : ""}
                                        onSelect={(data) => onhandleSelect(data, 'link_user')}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className='offcanvas-footer'>
                {
                    (activeTab === 3) ? (
                        <React.Fragment>
                            <AddButton onClick={handleClick} disabled={loading} name={loading ? 'Loading...' : 'Add Staff'} />
                            <button
                                className={isButtonDisabled ? '_addNewComm_btn_disabled' : '_addNewComm_btn'}
                                onClick={handleResetFormAndSubmit}
                                disabled={isButtonDisabled}

                            >
                                Add & New
                            </button>
                        </React.Fragment>
                    ) : (activeTab === 2 ? (
                        <AddButton onClick={handleClick} name='Continue' />
                    ) : (
                        <AddButton onClick={handleClick} disabled={!formValue?.name || !formValue?.as_of_date} name='Continue' />
                    ))
                }
            </div>

        </>
    )
}

export default React.memo(AddEditStaff)