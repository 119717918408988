import { CommonDateRange, CustomSelect } from 'components';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from 'context';
import { State } from "country-state-city";
import { constant } from 'constants/constant';
import { getAllVehicleNumber, getStatesData } from '_services';
import moment from 'moment';
import StorageService from 'utils/StorageServices/StorageServices';
import { getBranchDropdownListData, getConsignorDropdownListData, getSupplierDropdownListData } from '_services/dropdownServices/dropdownService';
import toast from 'react-hot-toast';

const CommonReportFilters = ({ filter, setFilter }) => {
    const { masterData } = useContext(UserContext);
    const [dropdownBranchList, setDropdownBranchList] = useState([]);
    const [consignorList, setConsignorList] = useState([]);
    const [vehicleNumbList, setVehicleNumbList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [ownershipData, setOwnershipData]= useState([]);

    const fetchVehicleList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: "",
            };
            const vehicleRes = await getAllVehicleNumber(object);
            if (vehicleRes?.responseCode === 200) {
                const newVehicles = vehicleRes.data?.vehicle_list.map((i) => ({
                    ...i,
                    label: i.vehicle_no,
                    value: i._id,
                }));
                let newData = {
                    vehicle_no: 'All',
                    label:'All',
                    value:'',
                    lorry_type_id: {
                        _id: '',
                        lorry_type: 'All'
                    },
                    vehicle_ownership: 1,
                    vehicle_status: 1,
                    vehicle_type_id: {
                        _id: '',
                        name: 'All'
                    },
                    vehicle_type_id_1: {
                        _id: '',
                        name: 'All'
                    }
                }

                setVehicleNumbList([newData, ...newVehicles]);

            } else {
                toast.error(vehicleRes?.responseMessage);
            }
        } catch (error) {
            toast.error("Error fetching vehicle list.");
        }
    };

    const getDropdownDataBranchList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: "",
            };
            const res = await getBranchDropdownListData(object);
            if (res?.responseCode == 200) {
                let data = res?.data?.branch_list?.map((el) => {
                    return {
                        label: el?.branch,
                        value: el?._id,
                    }
                })
                setDropdownBranchList([{ label: "All", value: "" }, ...data]);
            }
        } catch (error) { }
    };

    const getDropdownDataConsignorList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getConsignorDropdownListData(object);
            if (res?.responseCode == 200) {
                let data = res?.data?.consignor_list?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?._id,
                    }
                })
                setConsignorList([{ label: "All", value: "" }, ...data])
            }
        } catch (error) {
            // throw error;
        }
    }

    const getDropdownDataSupplierList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
            }
            const res = await getSupplierDropdownListData(object);
            if (res?.responseCode == 200) {
                let list = res?.data?.supplier_list.map((el) => {
                    return {
                        label: el?.name,
                        value: el?._id,
                    };
                });
                setSupplierList(list)
            } else {
                // setLoading(false);
            }
        } catch (error) {
            // setLoading(false);
            // throw error;
        }
    }

    useEffect(() => {
        getDropdownDataBranchList();
        getDropdownDataConsignorList();
        fetchVehicleList();
        getDropdownDataSupplierList();
    }, [])

    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const [selectedRange, setSelectedRange] = useState({
        startDate: moment(firstDay).subtract(31, 'days'),
        endDate: moment(lastDay),
    });

    const handleApply = (newRange) => {
        setSelectedRange(newRange);
        setFilter((s) => ({
            ...s,
            start_date: moment(newRange?.startDate).format("YYYY-MM-DD"),
            end_date: moment(newRange?.endDate).format("YYYY-MM-DD"),
        }));
    };

    const onhandleSelect = async (item, name) => {
        const stateObj = { ...filter, [name]: item };
        setFilter(stateObj);
    };

    useEffect(() => {
        if (masterData?.vehicle_ownership_list?.length > 0) {
            let data = [{ label: 'All', value: '' }, ...masterData?.vehicle_ownership_list?.map((el) => ({
                label: el?.name,
                value: el?.id,
            }))];
            setOwnershipData(data);
        }

    }, [masterData?.vehicle_ownership_list]);

    const resetFunction = () => {
        const newStartDate = moment(firstDay).subtract(31, 'days');
        const newEndDate = moment(lastDay);

        setSelectedRange({
            startDate: newStartDate,
            endDate: newEndDate,
        });

        setFilter((s) => ({
            ...s,
            offset: 1,
        }));
    };

    return (
        <>
            <div className='_mrktFilt_mainBx'>
                <div className='ctbx_childBox'>
                    <div className='head_load'>
                        <span className="search_loads"> Filters </span>
                    </div>
                    <div className='_optFilts'>
                        <div style={{ marginBottom: '20px' }}>
                            <CommonDateRange
                                key={`${selectedRange.startDate}-${selectedRange.endDate}`}
                                initialStartDate={selectedRange.startDate}
                                initialEndDate={selectedRange.endDate}
                                onApply={handleApply}
                            />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <CustomSelect
                                label='Branch'
                                placeholder="All"
                                options={dropdownBranchList}
                                value={filter?.branch_id}
                                onSelect={(data) => onhandleSelect(data, 'branch_id')}
                            />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <CustomSelect
                                label='Party'
                                placeholder="All"
                                options={consignorList}
                                value={filter?.consignor_id}
                                onSelect={(data) => onhandleSelect(data, 'consignor_id')}
                            />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <CustomSelect
                                options={ownershipData}
                                name="vehicle_ownership"
                                label="Vehicle Ownership"
                                placeholder="All"
                                value={filter?.ownership_no}
                                onSelect={(data) => onhandleSelect(data, 'ownership_no')}
                            />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <CustomSelect
                                label="Vehicle Number"
                                placeholder="All"
                                options={vehicleNumbList}
                                value={filter?.vehicle_id}
                                component_class="vehicle_number_trip"
                                onSelect={(data) => onhandleSelect(data, 'vehicle_id')}
                            />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <CustomSelect
                                options={supplierList}
                                label="Supplier"
                                placeholder="All"
                                name="supplier_id"
                                value={filter?.supplier_id}
                                onSelect={(data) => onhandleSelect(data, 'supplier_id')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(CommonReportFilters)