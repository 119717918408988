import React, { useContext, useEffect, useState } from "react";
import { AddButton, CommonInput, CustomSelect } from "components";
import { constant } from "constants/constant";
import AddMapLocationModal from "modals/AddMapLocationModal";
import { UserContext } from "context";
import StorageService from "utils/StorageServices/StorageServices";
import { errorSchema, utils } from "helper";
import { State } from "country-state-city";
import { getStatesData, handleKeyDown } from "_services";
import {
  getBranchDropdownListData,
  getZoneDropdownListData,
} from "_services/dropdownServices/dropdownService";
import {
    createConsigneeAdd,
  createUnitAdd,
  getAddressDetailsApi,
  getPartyAssociateApi,
  updateAddressBookApi,
} from "_services/accountsServices/addressBookService";
import toast from "react-hot-toast";

const AddConsigneeModal = (props) => {
  const initi = {
    name: "",
    consignor_id: "",
    contact_no: "",
    email_id: "",
    address_line1: "",
    address_line2: "",
    state: {},
    city: {},
    pincode: "",
    code: "",
    zone_id: "",
    branch_id: "",
    company_id: StorageService.getCompanyId(),
  };

  let { isOpen, onClose, id } = props;
  const { masterData, cities, states } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(1);
  const [showGoogleMapModal, setShowGoogleMapModal] = useState(false);
  const [form, setForm] = useState(initi);
  const [formError, setFormError] = useState("");
  const [stateValue, setStateValue] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState({
    zoneList: [],
    branchList: [],
    associateList: [],
  });

 

  useEffect(() => {
    if (stateValue) {
      handleOnChange("state", {
        label: stateValue?.name,
        value: stateValue?.isoCode,
        lat:String(stateValue?.latitude),
        long:String(stateValue?.longitude)
      });
    }
  }, [stateValue]);

  useEffect(() => {
    if (id?.id) {
      fetchData();
    } else {
      fetchData();
    }
  }, [masterData, id]);

  useEffect(() => {
    if (id?.id) {
      getAddressBook(id?.id);
    }
  }, [listData]);

  const getAddressBook = async (id) => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      addressbook_id: id,
    };

    const res = await commonFetchFuction(getAddressDetailsApi, payload);

    const details = res?.data?.book_detail;

    const zoneCheck = (category) => {
      if (!category) {
        return listData?.zoneList[0]?.value;
      } else {
        return category;
      }
    };

    const branchCheck = (category) => {
      if (!category) {
        return listData?.branchList[0]?.value;
      } else {
        return category;
      }
    };

    setForm((prevForm) => ({
      ...prevForm,
      consignor_id: details?.associate_party_id || "",
      branch_id: branchCheck(details?.branch_id) || "",
      name: details?.name || "",
      address_line1: details?.address_line1 || "",
      address_line2: details?.address_line2 || "",
      city: details?.city || "",
      state: details?.state || "",
      pincode: details?.pincode || "",
      contact_no: details?.contact_no || "",
      email_id: details?.email || "",
      code: details?.code || "",
      zone_id: zoneCheck(details?.zone_id?._id) || "",
    }));
  };


  const fetchData = async () => {
    try {
      // setLoading(true);
      const [zoneRes, branchRes, associateRes] = await Promise.all([
        getZoneList(),
        getBranchList(),
        getPartyAssociate(),
      ]);

      const zoneList = formatList(zoneRes?.data?.zone_list, "name", "_id");
      const branchList = formatList(
        branchRes?.data?.branch_list,
        "branch",
        "_id"
      );
      const associateList = formatList(associateRes?.data?.list, "name", "_id");

      setListData({
        zoneList,
        branchList,
        associateList,
      });


      if (!id?.id) {
        console.log("id",id?.id)
        setInitialFormValues(zoneList, branchList, associateList);
      }
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data", error);
    }
  };

  const formatList = (list, labelKey, valueKey, defaultOption = null) => {
    const formattedList = list?.map((item) => ({
      ...item,
      label: item[labelKey],
      value: item[valueKey],
    }));
    return defaultOption
      ? [{ label: defaultOption, value: "" }, ...formattedList]
      : formattedList;
  };

  const setInitialFormValues = (zoneList, branchList, associateList) => {
    handleOnChange("zone_id", zoneList?.[0]?.value || "");
    handleOnChange("consignor_id", associateList?.[0]?.value || "");
    if (branchList.length == 1) {
      handleOnChange("branch_id", branchList[0]?.value || "");
    }
  };

  const commonFetchFuction = async (
    apiMethod,
    payload = {},
    transformer = (res) => res
  ) => {
    try {
      const res = await apiMethod(payload);
      if (res?.responseCode === 200) {
        return transformer(res);
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.addAddressBook
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const getStatesByApi = async (countryCode, stateCode) => {
    try {
      let data = {
        countryCode : countryCode,
        stateCode : stateCode
      }
      let response = await getStatesData(data);
      if (response?.responseCode === 200) {
        setStateValue(response?.data[0]);
        return response?.data[0]
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.")
    }
  }

  const handleCityChange = (data) => {
    handleOnChange("city", {
      label: data?.label,
      value: data?.name,
      lat:String(data?.latitude),
      long:String(data?.longitude)
    });

    getStatesByApi(data?.countryCode, data?.stateCode);

    // const stateDetails = State.getStateByCodeAndCountry(
    //   data?.stateCode,
    //   data?.countryCode
    // );
    // setStateValue(stateDetails);
  };

  const getZoneList = async () => {
    let object = { company_id: StorageService.getCompanyId() };
    return commonFetchFuction(getZoneDropdownListData, object);
  };

  const getBranchList = async () => {
    let object = { company_id: StorageService.getCompanyId() };
    return commonFetchFuction(getBranchDropdownListData, object);
  };

  const getPartyAssociate = async () => {
    let object = { company_id: StorageService.getCompanyId(), category: 1 };
    return commonFetchFuction(getPartyAssociateApi, object);
  };
  const handleClick = async (e, action) => {
    e.preventDefault();
    const validationResult = await utils?.checkFormError(
      form,
      errorSchema.addUnitSchemaAdd
    );

    if (validationResult == true) {
      setLoading(true);
      await apiCallingFunction(action);
      setFormError("");
    } else {
      setFormError(validationResult);
      setLoading(false);
    }
  };

  const handleClickContinue = async (e) => {
    e.preventDefault();

    const validationResult = await utils.checkFormError(
      { ...form },
      errorSchema.addUnitContinue
    );
    console.log(validationResult);

    if (validationResult == true) {
      setActiveTab(2);
    } else {
      setFormError(validationResult);
    }
  };

  const changeActiveTab = async (event, value) => {
    event.preventDefault();
    if (value > activeTab) {
      const validationResult = await utils.checkFormError(
        form,
        errorSchema.addUnitContinue
      );
      if (validationResult === true) {
        setActiveTab(value);
      } else {
        setFormError(validationResult);
      }
    } else {
      setActiveTab(value);
    }
  };

  const apiCallingFunction = async (action) => {
    try {
      let object = {
        ...form,
      };

      let updateObject = {
        ...form,
        associate_party_id:form?.consignor_id,
        addressbook_id: props?.id?.id,
      };

      const res = props?.id?.id
        ? await updateAddressBookApi(updateObject)
        : await createConsigneeAdd(object);
      if (res?.responseCode == 200) {
        setLoading(false);
        toast.success("Created Successfully");
        if (action != "add") {
          setActiveTab(1);
        }
        onClose();
        clearAll();
        props?.callBackAddressData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const callBackDataMap = async (data) => {
     const { address_components: addressComponents,geometry:geometry } = data;
     const { city, state, postalCode, country } =
       parseAddressComponents(addressComponents,geometry);
       const stateValue = await getStatesByApi(country?.country_code, state?.value);
     if (city || state || postalCode || country || stateValue) {
       const object = {
         city: city,
         state: {
           label: stateValue?.name,
           value: stateValue?.isoCode,
           lat:String(stateValue?.latitude),
           long:String(stateValue?.longitude)
         },
         address_line1: ` ${city?.label}, ${state?.label} ${postalCode}, ${country}`,
         pincode: postalCode,
       };
 
       const validationResult = await utils.checkFormError(
         form,
         object,
         errorSchema.addAddressBookContinue
       );
 
       if (validationResult === false) {
         setFormError(validationResult);
       }
 
       setForm({
         ...form,
         city: city,
         state: {
           label: stateValue?.name,
           value: stateValue?.isoCode,
           lat:String(stateValue?.latitude),
           long:String(stateValue?.longitude)
         },
         address_line1: ` ${city?.label}, ${state?.label} ${postalCode}, ${country?.country}`,
         pincode: postalCode,
       });
     }
   };
 
  const parseAddressComponents = (addressComponents,geometry) => {
    let city = "";
    let state = "";
    let postalCode = "";
    let country = "";
    addressComponents?.map((component) => {
      if (component.types.includes("locality")) {
        city = { label: component.long_name, value: component?.short_name ,lat: String(geometry?.location?.lat()) || null,
          long: String(geometry?.location?.lng()) || null,};
      } else if (component.types.includes("administrative_area_level_1")) {
        state = { label: component.long_name, value: component?.short_name ,lat: String(geometry?.location?.lat())|| null,
          long: String(geometry?.location?.lng()) || null,};
      } else if (component.types.includes("postal_code")) {
        postalCode = component.long_name;
      } else if (component.types.includes("country")) {
        country = {country:component.long_name,country_code :component.short_name};
      }
    });

    return { city, state, postalCode, country };
  };

  const clearAll = () => {
    setForm({
      ...form,
      name: "",
      consignor_id: "",
      contact_no: "",
      email_id: "",
      address_line1: "",
      address_line2: "",
      state: {},
      city: {},
      pincode: "",
      code: "",
      zone_id: "",
      company_id: StorageService.getCompanyId(),
    });
  };

  let gmapLocate = {
    isOpen: showGoogleMapModal,
    closeModal: (e) => setShowGoogleMapModal(false),
    modalMainClass: "_dashCommon_Modal _mdModal",
    modalTitle: "Select on Map",
    submitTitle: "Add Location",
    callBackDataMap: callBackDataMap,
  };

  return (
    <>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">{id?.id? "Edit Consignee":"Add Consignee"} </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => onClose(!isOpen)}
        ></button>
      </div>

      <div className="offcanvas-body">
        <div className="pr_procssBx">
          <div className="pr_procssBx_grid">
            <div className="singleDiv">
              <CommonInput
                type="text"
                placeholder="Consignee Name"
                label="Consignee Name"
                labelRedStar={true}
                name="name"
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name, value);
                }}
                value={form?.name}
                focus={!!(typeof formError === "object" && formError?.name)}
                error={!!(typeof formError === "object") ? formError?.name : ""}
              />
            </div>

            <div className="singleDiv">
              <CustomSelect
                placeholder={`Select Associate Party`}
                label={`Associate Party`}
                options={listData?.associateList}
                onSelect={(data) => {
                  handleOnChange("consignor_id", data.value);
                }}
                value={
                  form?.consignor_id
                    ? listData?.associateList?.find(
                        (i) => i.value == form?.consignor_id
                      )
                    : { label: "", value: "" }
                }
                focus={
                  !!(
                    typeof formError === "object" &&
                    formError?.consignor_id
                  )
                }
                error={
                  !!(typeof formError === "object")
                    ? formError?.consignor_id
                    : ""
                }
              />
            </div>
          </div>
        </div>

        <div className="addPr_flBx">
          <ul className="nav nav-tabs cstNew_tabs" id="addParty" role="tablist">
            {constant.addAddressBookTabs?.map((item, indx) => (
              <li
                className="nav-item"
                role="presentation"
                key={indx + item?.value}
              >
                <button
                  className={
                    "nav-link " + (activeTab === item?.id ? "active" : "")
                  }
                  type="button"
                  aria-selected={activeTab === item?.id}
                  onClick={(e, val) => changeActiveTab(e, item.id)}
                >
                  {item?.title}
                </button>
              </li>
            ))}
          </ul>

          {activeTab === 1 && (
            <div className="pr_procssBx" id="addPartyContent">
              <div className="pr_procssBx_grid">
                <div className="singleDiv">
                  <button
                    className="gogLocation_btn"
                    onClick={() => setShowGoogleMapModal(true)}
                  >
                    <div>
                      Select From Google <span className="valId">*</span>
                    </div>
                  </button>
                </div>

                <div className="singleDiv">
                  <div className="h4Head">Add Address</div>
                </div>

                <div className="singleDiv">
                  <CommonInput
                    type="text"
                    placeholder="Address"
                    label="Address Line 1"
                    name="address_line1"
                    value={form.address_line1}
                    focus={
                      !!(
                        typeof formError === "object" &&
                        formError?.address_line1
                      )
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.address_line1
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>

                <div className="singleDiv">
                  <CustomSelect
                    options={cities}
                    onSelect={(data) => handleCityChange(data, "city")}
                    value={form?.city}
                    placeholder="Select a city"
                    error={formError["city.label"]}
                    label="City"
                  />
                </div>

                <div className="halfDiv">
                  <CommonInput
                    type="text"
                    placeholder="State"
                    label="State"
                    name="state"
                    disabled={true}
                    value={form?.state?.label}
                    focus={
                      !!(
                        typeof formError === "object" &&
                        formError["state.label"]
                      )
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError["state.label"]
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>

                <div className="halfDiv">
                  <CommonInput
                    type="text"
                    placeholder="PIN Code"
                    label="PIN Code"
                    name="pincode"
                    maxLength={6}
                    onKeyDown={handleKeyDown}
                    value={form.pincode}
                    focus={
                      !!(typeof formError === "object" && formError?.pincode)
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.pincode
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>

                <div className="singleDiv">
                  <div className="h4Head">Other Contact</div>
                </div>

                <div className="singleDiv">
                  <CommonInput
                    type="text"
                    placeholder="Contact Number"
                    label="Contact Number"
                    name="contact_no"
                    maxLength={10}
                    onKeyDown={handleKeyDown}
                    value={form.contact_no}
                    focus={
                      !!(typeof formError === "object" && formError?.contact_no)
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.contact_no
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>

                <div className="singleDiv">
                  <CommonInput
                    type="email"
                    placeholder="Email ID"
                    label="Email ID"
                    name="email_id"
                    value={form.email_id}
                    focus={
                      !!(typeof formError === "object" && formError?.email_id)
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.email_id
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>
              </div>
            </div>
          )}

          {activeTab === 2 && (
            <div className="pr_procssBx" id="addPartyContent">
              <div className="pr_procssBx_grid">
                <div className="singleDiv">
                  <CommonInput
                    type="text"
                    label="Code"
                    placeholder="Code"
                    name="code"
                    value={form.code}
                    focus={!!(typeof formError === "object" && formError?.code)}
                    error={
                      !!(typeof formError === "object") ? formError?.code : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                  <p className="_btmInput_para">Leave Blank For Autogenerate</p>
                </div>

                <div className="singleDiv">
                  <CustomSelect
                    options={listData?.zoneList}
                    name="zone_id"
                    flotingLabel="Zone"
                    value={
                      form?.zone_id
                        ? listData?.zoneList?.find(
                            (i) => i?.value == form?.zone_id
                          )
                        : {}
                    }
                    error={formError["zone_id"]}
                    onSelect={(data) => handleOnChange("zone_id", data?.value)}
                    placeholder="Select Zone"
                  />
                </div>
                <div className="singleDiv">
                  <CustomSelect
                    options={listData?.branchList}
                    name="branch_id"
                    flotingLabel="Branch"
                    value={
                      form?.branch_id
                        ? listData?.branchList?.find(
                            (i) => i?.value == form?.branch_id
                          )
                        : {}
                    }
                    error={formError["branch_id"]}
                    onSelect={(data) =>
                      handleOnChange("branch_id", data?.value)
                    }
                    placeholder="Select Branch"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="offcanvas-footer">
        {activeTab == 2 ? (
          <React.Fragment>
            <AddButton
              onClick={(e) => handleClick(e, "add")}
              disabled={loading}
              buttonTitle={
                loading
                  ? " Loading... "
                  : props?.id?.id
                  ? "Update Consignee"
                  : "Add Consignee"
              }
              className={
                loading ? "_addNewComm_btn_disable_" : "_addNewComm_btn"
              }
            />

            {!props?.id?.id && (
              <button
                className={
                  isButtonDisabled
                    ? "_addNewComm_btn_disable_"
                    : "_addNewComm_btn"
                }
                onClick={(e) => handleClick(e, "new")}
                disabled={isButtonDisabled}
              >
                Add & New
              </button>
            )}
            <AddButton
              onClick={onClose}
              buttonTitle="Cancel"
              className={
                loading ? "_addNewComm_btn_disable_" : "_addNewComm_btn"
              }
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <AddButton
              onClick={handleClickContinue}
              buttonTitle="Continue"
              disabled={loading}
              className={
                loading ? "_addNewComm_btn_disable_" : "_addNewComm_btn"
              }
            />
            <AddButton
              onClick={onClose}
              buttonTitle="Cancel"
              className={
                loading ? "_addNewComm_btn_disable_" : "_addNewComm_btn"
              }
            />
          </React.Fragment>
        )}
      </div>
      {showGoogleMapModal && <AddMapLocationModal {...gmapLocate} />}
    </>
  );
};

export default AddConsigneeModal;
