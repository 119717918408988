import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CommonInput, CustomSelect } from 'components'
import { errorSchema, utils } from 'helper';
import { City, State } from "country-state-city";
import Dropdown from 'components/common/Customdropdown/Dropdown';
import StorageService from "utils/StorageServices/StorageServices";
import { UserContext } from "context";
import debounce from 'lodash.debounce';
import toast, { Toaster } from 'react-hot-toast';
import { createConsignor, getAllConsignorgGroup, getConsignorListData } from '_services/accountsServices/consignorServices';
import { AddButton } from 'components/common/CommonButton';
import { constant } from 'constants/constant';
import { createVehicle } from '_services/accountsServices/vehicleServices';
import { getOwnDriverListData } from '_services/accountsServices/ownDriverServices';
import { getSupplierListData } from '_services/accountsServices/supplierServices';
import { getLorryTypeList } from '_services/accountsServices/lrServices';
import { useNavigate } from 'react-router-dom';
import { handleKeyDown } from '_services';
import { getDriverDropdownListData } from '_services/dropdownServices/dropdownService';

const formObj = {
    company_id: StorageService.getCompanyId(),
    vehicle_no: '',
    vehicle_ownership: '1',
    lorry_type_id: '',
    vehicle_type_id_1: {},

    manage_trip_kms: 2,//0-None, 1-Mandatory, 2-Non-Mandatory
    manage_fuel: false,//true-Checked, false-Non-Checked
    vehicle_average: '',
    opening_fuel: '',
    fuel_amount: '',
    fuel_rate: '',
    fuel_out: '',//0-None, 1-Editable, 2-Non-Editable

    driver_id: '',
    supplier_id: '',
    // weight_passing: '',
}

const AddEditVehicleModal = ({
    isOpen,
    onClose,
    callBackVehicle,
    handleAddNewSupplierButtonAction,
    handleAddNewVehicleButtonAction,
    isSupplier,
    isDriver,
}) => {

    const navigate = useNavigate();
    const { masterData, cities, states } = useContext(UserContext)
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [tyrelabel, setTyreLabel] = useState('')
    const [loading, setLoading] = useState(false);
    const [driverList, setDriverList] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const [lorryType, setLorryType] = useState([])

    const [activeTab, setActiveTab] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [isStateClear, setIsStateClear] = useState(0);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createVehicleSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async (shouldCloseModal) => {
        try {
            let object = {
                ...formValue,
                driver_id: (formValue?.vehicle_ownership === '1' || formValue?.vehicle_ownership === '3') ? formValue?.driver_id?.value : '',
                supplier_id: formValue?.vehicle_ownership === '2' ? formValue?.supplier_id?.value : '',
                vehicle_type_id_1: formValue?.vehicle_type_id_1?.value,
                fuel_rate: (formValue?.fuel_amount > 0 && formValue?.opening_fuel > 0) ? (formValue?.fuel_amount / formValue?.opening_fuel).toFixed(2) : ''
            }
            const res = await createVehicle(object);
            if (res?.responseCode === 200) {
                setFormValue({});
                setLoading(false);
                toast.success('Vehicle Created Successfully');
                if (shouldCloseModal) {
                    onClose();
                } else {
                    setActiveTab(1); // Reset tab to the first tab
                }
                callBackVehicle(res?.data?.vehicle)
            } else {
                toast.error(`${res?.responseMessage}`);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            setIsButtonDisabled(false);
        }
    }

    const handleClick = async (e) => {
        e.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createVehicleSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true);
            await apiCallingFunction(true); // Pass true to close the modal
            setFormError("");
        } else {
            setFormError(validationResult);
            setLoading(false);
        }
    };

    const handleResetFormAndSubmit = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true); // Disable the button immediately
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createVehicleSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true);
            await apiCallingFunction(false); // Pass false to keep the modal open and reset the form
            setFormValue((prevData) => ({
                ...prevData,
                company_id: StorageService.getCompanyId(),
                vehicle_no: '',
                vehicle_ownership: '1',
                lorry_type_id: '',
                vehicle_type_id_1: {},
                // vehicle_type_id_2: '',
                driver_id: '',
                supplier_id: '',
                vehicle_average: '',
                // weight_passing: '',
            }))
            setFormError("");
        } else {
            setFormError(validationResult);
            setLoading(false);
            setIsButtonDisabled(false); // Re-enable the button if moving to the next tab
        }
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const onhandleSelect = async (item, name) => {
        const stateObj = { ...formValue, [name]: item };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createVehicleSchema);
            if (validationResult === true) {
                setFormError({});
            } else {
                setFormError(validationResult);
            }
        }
    };

    const changeActiveTab = async (event, value) => {
        event.preventDefault();
        setActiveTab(value);
        setTyreLabel({ label: '', value: '' })
        setFormValue((s) => ({ ...s, vehicle_type_id_1: {} }))
    };

    const getDataDriverList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
                status: 1,
                driver_type: 1,
            }
            const res = await getDriverDropdownListData(object);
            // const res = await getOwnDriverListData(object);
            if (res?.responseCode == 200) {
                setDriverList(res?.data?.driver_list)
            }
        } catch (error) {
            // throw error;
        }
    }

    const getDataSupplierList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
                status: 1
            }
            const res = await getSupplierListData(object);
            if (res?.responseCode == 200) {
                setSupplierList(res?.data?.supplier_list)
            }
        } catch (error) {
            // throw error;
        }
    }

    const getDataLorryTypeList = async () => {
        try {
            const res = await getLorryTypeList();
            if (res?.responseCode == 200) {
                setLorryType(res?.data?.lorry_type)
                setActiveTab(res?.data?.lorry_type[0])
            }
        } catch (error) {
            // throw error;
        }
    }

    useEffect(() => {
        getDataDriverList();
        getDataSupplierList();
        getDataLorryTypeList();
        if (StorageService.getCompanyId()) {
            setFormValue((s) => ({ ...s, company_id: StorageService.getCompanyId() }))
        }
    }, [StorageService.getCompanyId()])

    useEffect(() => {
        if (isSupplier) {
            getDataSupplierList();
        }
    }, [isSupplier])

    useEffect(() => {
        if (isDriver) {
            getDataDriverList();
        }
    }, [isDriver])

    useEffect(() => {
        setFormValue((s) => ({
            ...s,
            driver_id: driverList?.map((el) => {
                return {
                    label: el?.name,
                    value: el?._id
                }
            })[0],
            supplier_id: supplierList?.map((el) => {
                return {
                    label: el?.name,
                    value: el?._id
                }
            })[0],
           
        }));
       
    }, [driverList, supplierList, lorryType]);

    useEffect(() => {
        setFormValue((s) => ({
            ...s,
            lorry_type_id: activeTab?._id,
        }))
    }, [activeTab])

    useEffect(() => {
        setActiveTab(lorryType[0]);
    }, [lorryType])

    useEffect(() => {
        setFormValue((s) => ({
            ...s,
            manage_trip_kms: 0,
            manage_fuel: false,
            vehicle_average: '',
            opening_fuel: '',
            fuel_amount: '',
            fuel_rate: '',
            fuel_out: 0,
        }));
    }, [isStateClear])

    return (
        <>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title"> Add Vehicle </h5>
                <button type="button" className="btn-close" onClick={() => onClose(!isOpen)}></button>
            </div>

            <div className="offcanvas-body">

                <div className='pr_procssBx'>
                    <div className='pr_procssBx_grid'>

                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                // placeholder="Vehicle No"
                                label="Vehicle No *"
                                name='vehicle_no'
                                placeholder="Enter vehicle number (e.g., KA01AB1234)"
                                maxLength={10} // Set maxLength to 10 characters
                                value={formValue.vehicle_no}
                                focus={!!(typeof formError === "object" && formError?.vehicle_no)}
                                error={!!(typeof formError === "object") ? formError?.vehicle_no : ""}
                                onChange={({ target: { name, value } }) =>{
                                    const upperValue = value.toUpperCase();
                                    handleOnChange(name, upperValue)
                                }
                                   
                                }
                            />
                        </div>
                    </div>

                    <div className='checkBx_lv mt-2'>
                        {
                            constant.vehicleOwnership?.map((el, ind) => {
                                return (
                                    <React.Fragment key={ind}>
                                        <input
                                            className="form-check-input _avPointer"
                                            id={+ind + "vehicle_ownership" + el?.value}
                                            type="radio"
                                            name="vehicle_ownership"
                                            checked={formValue?.vehicle_ownership === el?.value}
                                            value={formValue?.vehicle_ownership ? formValue?.vehicle_ownership : ""}
                                            onChange={({ target: { name, value } }) => {
                                                setIsStateClear(el?.value);
                                                handleOnChange(name, el?.value)
                                            }}
                                        />
                                        <label htmlFor={+ind + "vehicle_ownership" + el.value} className="form-check-label" >
                                            {el.label}
                                        </label> &nbsp;
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='addPr_flBx'>
                    <ul className="nav nav-tabs cstNew_tabs _truckSelection" id="addParty" role="tablist">
                        <li className='singleDiv'><h4 className='_trkHead'>Select Fleet Type</h4></li>
                        {lorryType?.length > 0 ? lorryType?.map((item, indx) => (
                            <li
                                className="nav-item"
                                role="presentation"
                                key={indx}
                            >
                                <button id={item?.lorry_type}
                                    className={
                                        "nav-link " + (activeTab?.lorry_type === item?.lorry_type ? "active" : "")
                                    }
                                    type="button"
                                    aria-selected={activeTab?.lorry_type === item?.lorry_type}
                                    onClick={(e, val) => changeActiveTab(e, item)}
                                >
                                    {/* <span> <img src={`${masterData?.s3_url}${item?.icon}`} alt={item?.lorry_type} />  </span> */}
                                    {item?.lorry_type}
                                </button>
                            </li>
                        )) : <p className='p-2 mt-3'> Loading... </p>}
                    </ul>

                    <div className='pr_procssBx' id="addPartyContent">
                        <div className='pr_procssBx_grid'>
                            <div className='singleDiv'>
                                <CustomSelect
                                    options={(activeTab && activeTab?.props?.length > 0 && activeTab?.props[0]?.values?.length > 0) ? activeTab?.props[0]?.values?.map((el) => {
                                        return {
                                            label: el?.name,
                                            value: el?._id
                                        }
                                    }) : []}
                                    name="vehicle_type_id_1"
                                    flotingLabel="Type * "
                                    placeholder={activeTab?.props?.length > 0 && activeTab?.props[0]?.key_label ? activeTab?.props[0]?.key_label : 'Select Type'}
                                    value={tyrelabel}
                                    // focus={!!(typeof formError === "object" && formError?.vehicle_type_id_1)}
                                    error={formError["vehicle_type_id_1.label"]}
                                    onSelect={(data) => {
                                        onhandleSelect(data, 'vehicle_type_id_1')
                                        setTyreLabel(data)
                                    }}
                                />
                            </div>

                            <div className='singleDiv _relatePos'>
                                <CommonInput
                                    type="text"
                                    placeholder="Weight Passing"
                                    label="Weight Passing *"
                                    name='weight_passing'
                                    value={formValue.weight_passing}
                                    focus={!!(typeof formError === "object" && formError?.weight_passing)}
                                    error={!!(typeof formError === "object") ? formError?.weight_passing : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                    onKeyDown={handleKeyDown}
                                />
                                <span className='_weightSpan' > Tons </span>
                            </div>

                            <div>
                                <div className='singleDiv p-1'>
                                    <div className="form-check ps-0 ">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={(formValue?.manage_trip_kms > 0) ? true : false}
                                            id='is_trip_km'
                                            onClick={(e) => setFormValue((s) => ({ ...s, manage_trip_kms: e.target.checked ? 1 : 0 }))}
                                        />
                                        <label className="form-check-label" htmlFor='is_trip_km'> Manage Trip Kms </label>
                                    </div>
                                    {(formValue?.manage_trip_kms > 0) ?
                                        <div className='truck_checkBv mb-2 pt-0'>
                                            {
                                                constant.manageTripKm?.map((el, ind) => {
                                                    return (
                                                        <React.Fragment key={ind}>
                                                            <input
                                                                className="form-check-input _avPointer"
                                                                id={+ind + "manage_trip_kms" + el?.value}
                                                                type="radio"
                                                                name="manage_trip_kms"
                                                                checked={formValue?.manage_trip_kms === el?.value}
                                                                value={formValue?.manage_trip_kms ? formValue?.manage_trip_kms : ""}
                                                                onChange={({ target: { name, value } }) =>
                                                                    handleOnChange(name, el?.value)
                                                                }
                                                            />
                                                            <label htmlFor={+ind + "manage_trip_kms" + el.value} className="form-check-label" >
                                                                {el.label}
                                                            </label> &nbsp;
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </div> : <></>}
                                </div>
                                {
                                    // (activeTab?.lorry_type === 'Truck' || activeTab?.lorry_type === 'LCV' || activeTab?.lorry_type === 'Hyva' || activeTab?.lorry_type === 'Trailer' || activeTab?.lorry_type === 'Tanker') && 
                                    (formValue?.vehicle_ownership !== '2') && (
                                        <React.Fragment>

                                            {(formValue?.manage_trip_kms !== 0) && (<><div className='singleDiv p-1'>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id='is_manage_fuel'
                                                    value={formValue?.manage_fuel}
                                                    checked={formValue.manage_fuel ? true : false}
                                                    onClick={(e) => setFormValue((s) => ({ ...s, manage_fuel: e.target.checked }))}
                                                />
                                                <label className="form-check-label" htmlFor='is_manage_fuel'> Manage Fuel </label>
                                            </div>
                                                {
                                                    formValue?.manage_fuel ? (
                                                        <React.Fragment>
                                                            <div className='pr_procssBx_grid mb-3'>
                                                                <div className='halfDiv'>
                                                                    <CommonInput
                                                                        type="number"
                                                                        placeholder="Fleet Average"
                                                                        label="Fleet Average"
                                                                        name='vehicle_average'
                                                                        value={formValue.vehicle_average}
                                                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                        focus={!!(typeof formError === "object" && formError?.vehicle_average)}
                                                                        error={!!(typeof formError === "object") ? formError?.vehicle_average : ""}
                                                                        onChange={({ target: { name, value } }) =>
                                                                            handleOnChange(name, value)
                                                                        }
                                                                    />
                                                                </div>

                                                                <div className='halfDiv _relatePos'>
                                                                    <CommonInput
                                                                        type="number"
                                                                        placeholder="Opening Fuel"
                                                                        label="Opening Fuel"
                                                                        name='opening_fuel'
                                                                        value={formValue.opening_fuel}
                                                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                        focus={!!(typeof formError === "object" && formError?.opening_fuel)}
                                                                        error={!!(typeof formError === "object") ? formError?.opening_fuel : ""}
                                                                        onChange={({ target: { name, value } }) =>
                                                                            handleOnChange(name, value)
                                                                        }
                                                                    />
                                                                    <span className='_weightSpan' > LTR </span>
                                                                </div>

                                                                <div className='halfDiv'>
                                                                    <CommonInput
                                                                        type="number"
                                                                        placeholder="Fuel Amount"
                                                                        label="Fuel Amount"
                                                                        name='fuel_amount'
                                                                        value={formValue.fuel_amount}
                                                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                        focus={!!(typeof formError === "object" && formError?.fuel_amount)}
                                                                        error={!!(typeof formError === "object") ? formError?.fuel_amount : ""}
                                                                        onChange={({ target: { name, value } }) =>
                                                                            handleOnChange(name, value)
                                                                        }
                                                                    />
                                                                </div>

                                                                <div className='halfDiv'>
                                                                    <CommonInput
                                                                        type="number"
                                                                        placeholder="Fuel Rate"
                                                                        label="Fuel Rate"
                                                                        name='fuel_rate'
                                                                        value={(formValue?.fuel_amount > 0 && formValue?.opening_fuel > 0) ? (formValue?.fuel_amount / formValue?.opening_fuel).toFixed(2) : ''}
                                                                        disabled
                                                                    // focus={!!(typeof formError === "object" && formError?.fuel_rate)}
                                                                    // error={!!(typeof formError === "object") ? formError?.fuel_rate : ""}
                                                                    // onChange={({ target: { name, value } }) =>
                                                                    //     handleOnChange(name, value)
                                                                    // }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ) : <></>
                                                }

                                                <div className='singleDiv p-1'>
                                                    <div className="form-check ps-0 ">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={(formValue?.fuel_out > 0) ? true : false}
                                                            id='is_fuel_average'
                                                            onClick={(e) => setFormValue((s) => ({ ...s, fuel_out: e.target.checked ? 1 : 0 }))}
                                                        />
                                                        <label className="form-check-label" htmlFor='is_fuel_average'> Fuel Out & Average Amount at Trip End </label>
                                                    </div>
                                                    {(formValue?.fuel_out > 0) ? <div className='truck_checkBv mb-2 pt-0'>
                                                        {
                                                            constant.fuelOut_Average?.map((el, ind) => {
                                                                return (
                                                                    <React.Fragment key={ind}>
                                                                        <input
                                                                            className="form-check-input _avPointer"
                                                                            id={+ind + "fuel_out" + el?.value}
                                                                            type="radio"
                                                                            name="fuel_out"
                                                                            checked={formValue?.fuel_out === el?.value}
                                                                            value={formValue?.fuel_out ? formValue?.fuel_out : ""}
                                                                            onChange={({ target: { name, value } }) =>
                                                                                handleOnChange(name, el?.value)
                                                                            }
                                                                        />
                                                                        <label htmlFor={+ind + "fuel_out" + el.value} className="form-check-label" >
                                                                            {el.label}
                                                                        </label> &nbsp;
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div> : <></>}
                                                </div> </>)}

                                        </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {(formValue?.vehicle_ownership === '1' || formValue?.vehicle_ownership === '3') && (
                    <div className='pr_procssBx' id="addPartyContent">
                        <div className='pr_procssBx_grid'>
                            <div className='singleDiv'>
                                <CustomSelect
                                    options={driverList?.map((el) => {
                                        return {
                                            label: el?.name,
                                            value: el?._id
                                        }
                                    })}
                                    name="driver_id"
                                    flotingLabel="Assign Driver"
                                    placeholder="Assign Driver"
                                    value={formValue?.driver_id}
                                    // focus={!!(typeof formError === "object" && formError?.driver_id)}
                                    error={!!(typeof formError === "object") ? formError?.driver_id : ""}
                                    onSelect={(data) => onhandleSelect(data, 'driver_id')}
                                    isAddButton={true}
                                    addButtonAction={handleAddNewVehicleButtonAction}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {formValue?.vehicle_ownership === '2' && (
                    <div className='pr_procssBx' id="addPartyContent">
                        <div className='pr_procssBx_grid'>
                            <div className='singleDiv'>
                                <CustomSelect
                                    options={supplierList?.map((el) => {
                                        return {
                                            label: el?.name,
                                            value: el?._id
                                        }
                                    })}
                                    name="supplier_id"
                                    flotingLabel="Assign Supplier"
                                    placeholder="Assign Supplier"
                                    value={formValue?.supplier_id}
                                    // focus={!!(typeof formError === "object" && formError?.supplier_id)}
                                    error={!!(typeof formError === "object") ? formError?.supplier_id : ""}
                                    onSelect={(data) => onhandleSelect(data, 'supplier_id')}
                                    isAddButton={true}
                                    addButtonAction={handleAddNewSupplierButtonAction}
                                />
                            </div>
                        </div>
                    </div>
                )}

            </div>

            <div className='offcanvas-footer'>
                <AddButton onClick={handleClick} disabled={!formValue?.vehicle_no} name='Add Vehicle' />
                <button
                    className={isButtonDisabled || !formValue?.vehicle_no ? '_addNewComm_btn_disabled' : '_addNewComm_btn'}
                    onClick={handleResetFormAndSubmit}
                    disabled={isButtonDisabled || !formValue?.vehicle_no}

                >
                    Add & New
                </button>
            </div>

        </>
    )
}

export default AddEditVehicleModal
