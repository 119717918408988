import React from 'react';
import './TermsAndConditions.css';

const Policy = () => {
  return (
    <div className='_mainContained'>
      <section className="p-[80px_0]">
        <div className="container_main">
            <div className="p-8 bg-[#f0f8ff]">
                <div>
                    <h1 className="text-[32px] text-center text-black font-[700] mb-[30px] plus_jakarta">
                        Privacy Policy
                    </h1>
                </div>
                <div>
                    <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                        Transport360 ("Website" or Transport360” app or "Transport360.in") hereby termed as “platform”
                        is owned by Delhivery Limited. We at Delhivery Limited (“Delhivery”) consider customer trust as
                        our top priority. We deliver services to millions of customers across the country. Our customers
                        trust us with some of their most sensitive information. This policy informs you of our policies
                        regarding the collection, use, and disclosure of personal data when you use our Services and the
                        choices you have associated with that data.
                    </p>
                    <p className="text-[16px] text-[#000000] mb-[15px]">
                        This Privacy Policy is meant to help you understand the following:
                    </p>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">1. What data do we collect?</h6>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">Personal Data</h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">While using our Services, we may ask you to provide
                        us with certain personally identifiable information(PII) that can be used to contact or identify
                        you. Personally, Identifiable Information may include, but is not limited to:</p>

                    <ul className="list-disc pl-[20px] mb-[10px]">
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Email address</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">First name and Last name</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Phone number </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">IMEI Number</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Advertising ID</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Address, State, Province, ZIP/Postal code,
                                City</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Documents (Including but not limited to KYC
                                Documents, POD, Loading/Unloading etc.)</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Geographical Location</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Client Details (Name, Email Address &amp; Phone
                                Number)</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Supplier Details (Name, Email Address &amp; Phone
                                Number) </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Driver Details (Name &amp; Phone Number)</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Truck Details (Number &amp; ODO reading)</p>
                        </li>



                    </ul>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">Cookies Data</h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">We use cookies and similar tracking technologies to
                        track the activity on our Service and hold certain information.
                        Cookies are files with a small amount of data which may include an anonymous unique identifier.
                        Cookies are sent to your browser from a website and stored on your device. Tracking technologies
                        also used are beacons, tags, and scripts to collect and track information and to improve and
                        analyse our Service.<br/>
                        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                        However, if you do not accept cookies, you may not be able to use some portions of our
                        Service.<br/><br/>
                        Examples of Cookies we use:
                    </p>
                    <ul className="list-disc pl-[20px] mb-[10px]">
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Session Cookies: Session Cookies: We use
                                Session Cookies to operate our Service. </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Preference Cookies: We use Preference Cookies
                                to remember your preferences and various settings. </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Security Cookies: We use Secure Cookies for
                                security purposes.<br/>
                                To view our Cookie policy, please <a className="text-[#0000ee] underline" href="">click
                                    here</a> </p>
                        </li>


                    </ul>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">2. How do we collect your data?</h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">We build a range of services that you would find it
                        on our website, and in order to achieve this we collect personal data from you in a number of
                        ways including:
                    </p>
                    <ul className="list-disc pl-[20px] mb-[10px]">
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Applications used for providing various
                                services</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Web Forms</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">Off-site services</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">API</p>
                        </li>


                    </ul>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">3. Why do we collect your data?</h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">We use the collected data for various purposes
                        including but not limited to the following:
                    </p>
                    <ul className="list-disc pl-[20px] mb-[10px]">
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To provide products and services you request
                                (such as parcel transportation, warehousing, freight, reverse logistics, cross-border
                                and technology services)</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To allow you to participate in interactive
                                features of our Service when you choose to do so
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To do analysis and get valuable information
                                so that we can improve our services which includes but is not limited to Truck Khata app
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To send reminders to the parties for the
                                collection of payments</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To monitor the usage of the Service</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To detect, address and prevent technical
                                issues</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To provide customer support and respond to
                                you about your requests, questions and comments
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To send tracking updates to consignor and
                                consignee</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To give options for convenient delivery for
                                consignee
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To evaluate and improve our business
                                (including developing new products and services; managing our communications;
                                determining the effectiveness of our sales; analysing and enhancing our products,
                                services, websites and apps; ensuring the security of our networks and information
                                systems; performing accounting, auditing, invoicing, reconciliation and collection
                                activities; and improving and maintaining the quality of our customer services)
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To perform data analysis (including market,
                                consumer search and analytics, trend analysis, financial analysis and anonymisation of
                                personal information)</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To protect against, identify and prevent
                                fraud and other prohibited or illegal activity, claims and other liabilities
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To comply with applicable legal requirements
                                and our policies
                            </p>
                        </li>


                    </ul>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">4. Why do we share your data and with
                        whom? </h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">As a rule, we do not share your data with any third
                        party apart from the following mentions:
                    </p>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">Legal Requirements</h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">We may disclose your Personal Data in good faith
                        belief that such action is necessary to: </p>
                    <ul className="list-disc pl-[20px] mb-[10px]">
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To comply with a legal obligation </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To protect and defend the rights or property
                                of Delhivery Limited</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To prevent or investigate possible wrongdoing
                                in connection with the Service
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">To protect the personal safety of users of
                                the Service or the public
                            </p>
                        </li>


                    </ul>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">Google Analytics</h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">Google Analytics is a web analytics service offered
                        by Google that tracks and reports website traffic. Google uses the data collected to track and
                        monitor the use of Delhivery’s Service. This data is shared with other Google services. Google
                        may use the collected data to contextualize and personalize the ads of its own advertising
                        network.<br/>
                        You can opt-out of having made your activity on our Website available to Google Analytics by
                        installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics
                        JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about
                        visits activity.<br/>
                        For more information on the privacy practices of Google, please visit the Google Privacy Terms
                        web page:<br/>
                        <a href="" className="text-[#0000ee] underline">http://www.google.com/privacy/</a>
                    </p>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">Third Party Vendors </h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">We may employ third party vendors and individuals to
                        facilitate our services or to perform Service-related transactions or to assist us in analysing
                        how our Service is used.<br/>
                        Delhivery Ltd will take all steps reasonably necessary to ensure that your data is treated
                        securely and in accordance with this Privacy Policy. Before we engage any third-party vendor, we
                        check their security practices and regularly conduct their security and privacy assessments.<br/>
                        These third parties have access to your Personal Data only to perform these tasks on our behalf
                        and are obligated not to disclose or use it for any other purpose whatsoever. Also, we bind our
                        third-party vendors in the contract to secure your data by putting monetary liabilities and
                        surveillance practices and hence, adhering to this privacy policy.<br/>
                        Your information, including Personal Data, may be transferred to and maintained on computers
                        located outside of your state, province, country or other governmental jurisdiction where the
                        data protection laws may differ than those from your jurisdiction. No transfer of your Personal
                        Data will take place to an organization or a country unless there are adequate controls in place
                        including the security of your data and other personal information.
                    </p>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">5. How long do we keep your personal
                        data? </h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">We will retain your personal information for as long
                        as needed to fulfill the purpose for which we collected it and for a reasonable period
                        thereafter in order to comply with audit, contractual, technical and legal requirements and/or
                        to resolve any future disputes. We may retain aggregated or de-identified data indefinitely or
                        to the extent allowed by applicable law.
                    </p>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">6. How do we secure your personal data?
                    </h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">We maintain administrative, technical and physical
                        safeguards designed to protect the personal information you provide against accidental, unlawful
                        or unauthorised destruction, loss, alteration, access, disclosure or use. Exceptionally if any
                        personal data is present on the package or letter, it may be visible to unauthorized
                        individuals.<br/>
                        Application, data, infrastructure and network security are our key focus areas where data
                        security practices are enforced. We also Adhere to international standard certifications to
                        complement the enforced practices. Considering “Right to Privacy” as a fundamental right of an
                        individual, we have adopted enough measures to protect privacy.
                    </p>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">7. What are your rights as a data
                        subject?
                    </h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">You have the right to enquire on:
                    </p>
                    <ul className="list-disc pl-[20px] mb-[10px]">
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">What personal data we hold about you, </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">The purposes of the processing,
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">The categories of personal data concerned,

                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">The recipients to whom the personal data
                                has/will be disclosed,
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">How long we intend to store your personal
                                data,
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">If we did not collect the data directly from
                                you, information about the source,
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">The right to have incomplete or inaccurate
                                data about you corrected or completed and the process for requesting,
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">The right to request erasure of personal data
                                (where applicable) or to restrict processing in accordance with data protection laws, as
                                well as to object to any direct marketing from us,
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]">The right to report personal data breach at
                                infosec@delhivery.com and compliance@delhivery.com
                            </p>
                        </li>


                    </ul>
                    <h6 className="text-[16px] text-[#000000] font-[600] mb-[10px]">8. Changes to this privacy policy
                    </h6>
                    <p className="text-[16px] text-[#000000] mb-[18px]">We may update Delhivery’s Privacy Policy from time
                        to time. The revised policy will be updated and posted on the website directly. You are advised
                        to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
                        effective when they are posted on this page.
                    </p>
                    <h6 className="text-[24px] text-[#000000] font-[600] mb-[5px]">DEFINITION
                    </h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">In this policy, the following terms shall have the
                        following meanings: </p>
                    <ul className="list-disc pl-[20px] mb-[10px]">
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]"><strong>“Consignee”</strong> shall mean the
                                entity (individual/firm) who is financially responsible (the buyer) for the receipt of a
                                shipment. </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]"><strong>“Consignor”</strong> shall mean a
                                person or firm (usually the seller) who delivers a consignment to a carrier for
                                transporting it to a consignee.</p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]"><strong>“Data Subject”</strong> shall mean
                                any individual person who can be identified, directly or indirectly, via an identifier
                                such as a name, an ID number, location data.
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]"><strong>“Third Party Vendors”</strong> shall
                                mean any individual or organization providing services to Delhivery on a contractual
                                basis.
                            </p>
                        </li>
                        <li>
                            <p className="text-[16px] text-[#000000] mb-[5px]"><strong>“We/Us/Our”</strong> shall mean
                                Delhivery and it’s employees.
                            </p>
                        </li>
                    </ul>
                    <h6 className="text-[24px] text-[#000000] font-[600] mb-[5px]">YOUR CONSENT
                    </h6>
                    <p className="text-[16px] text-[#000000] mb-[10px]">By using our services, you consent to our Privacy
                        Policy and Cookie Policy.</p>
                </div>
            </div>
        </div>
    </section>
    </div>
    
    // <div className="terms-container">
    //   <h1 className="terms-heading">Privacy Policy</h1>
    //   <p className="last-updated">Last updated: Oct 25, 2024</p>

    //   <section className="terms-section">
    //     <h2 className="section-title">AGREEMENT TO TERMS</h2>
    //     <p className="section-content">
    //       These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf
    //       of an entity (“you”) and Delhivery Limited, doing business as Delhivery (hereinafter referred to as “Truck
    //       Khata”, “Delhivery”, “we”, “us”, or “our”), concerning your access to and use of the{' '}
    //       <a href="https://transport360.in" target="_blank" rel="noopener noreferrer">
    //         https://transport360.in
    //       </a>{' '}
    //       website as well as any other media form, media channel, mobile website or mobile application related, linked,
    //       or otherwise connected thereto (collectively, the “Platform”). You agree that by accessing the website and/or
    //       Platform, you have read, understood, and agree to be bound by all of these Terms of Use. IF YOU DO NOT AGREE
    //       WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE website and/or Platform AND
    //       ARE SUGGESTED TO DISCONTINUE USE IMMEDIATELY.
    //     </p>
    //     <p className="section-content">
    //       We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any
    //       time and for any reason. It is your responsibility to periodically review these Terms of Use on the Platform
    //       to stay informed regarding any updates. You will be subject to, and will be deemed to have been made aware of
    //       and to have accepted, the changes in any revised Terms of Use by your continued use of the website/ Platform
    //       after the date such revised Terms of Use are posted.
    //     </p>
    //   </section>

    //   <section className="terms-section">
    //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
    //     <p className="section-content">
    //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
    //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
    //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
    //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
    //       property rights.
    //     </p>
    //     <p className="section-content">
    //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
    //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
    //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
    //       rights not expressly granted to you in and to the Platform and the Marks.
    //     </p>
    //   </section>
    //   <section className="terms-section">
    //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
    //     <p className="section-content">
    //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
    //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
    //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
    //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
    //       property rights.
    //     </p>
    //     <p className="section-content">
    //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
    //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
    //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
    //       rights not expressly granted to you in and to the Platform and the Marks.
    //     </p>
    //   </section>
    //   <section className="terms-section">
    //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
    //     <p className="section-content">
    //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
    //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
    //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
    //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
    //       property rights.
    //     </p>
    //     <p className="section-content">
    //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
    //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
    //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
    //       rights not expressly granted to you in and to the Platform and the Marks.
    //     </p>
    //   </section>
    //   <section className="terms-section">
    //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
    //     <p className="section-content">
    //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
    //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
    //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
    //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
    //       property rights.
    //     </p>
    //     <p className="section-content">
    //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
    //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
    //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
    //       rights not expressly granted to you in and to the Platform and the Marks.
    //     </p>
    //   </section>
    //   <section className="terms-section">
    //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
    //     <p className="section-content">
    //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
    //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
    //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
    //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
    //       property rights.
    //     </p>
    //     <p className="section-content">
    //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
    //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
    //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
    //       rights not expressly granted to you in and to the Platform and the Marks.
    //     </p>
    //   </section>
    //   <section className="terms-section">
    //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
    //     <p className="section-content">
    //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
    //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
    //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
    //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
    //       property rights.
    //     </p>
    //     <p className="section-content">
    //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
    //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
    //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
    //       rights not expressly granted to you in and to the Platform and the Marks.
    //     </p>
    //   </section>
    //   <section className="terms-section">
    //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
    //     <p className="section-content">
    //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
    //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
    //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
    //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
    //       property rights.
    //     </p>
    //     <p className="section-content">
    //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
    //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
    //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
    //       rights not expressly granted to you in and to the Platform and the Marks.
    //     </p>
    //   </section>
    //   <section className="terms-section">
    //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
    //     <p className="section-content">
    //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
    //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
    //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
    //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
    //       property rights.
    //     </p>
    //     <p className="section-content">
    //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
    //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
    //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
    //       rights not expressly granted to you in and to the Platform and the Marks.
    //     </p>
    //   </section>
    //   <footer>
        
    //   </footer>
    // </div>
  );
};

export default Policy;


