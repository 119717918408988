import { City, State } from "country-state-city";
import { API, axiosInstance } from "lib";
import StorageService from "utils/StorageServices/StorageServices";


export const allCities = () => {
    const filteredcity =
      City &&
      City.getCitiesOfCountry("IN")

    const optionsCity = filteredcity?.map((d) => {
      return {
        ...d,
        label: `${d?.name}, ${d?.stateCode}`,
        value:d?.name
      };
    });
    
    StorageService.setCities(JSON.stringify([...optionsCity]))
  };

  export const allStates = ()=>{
  const FilterState = State && State.getStatesOfCountry("IN")
  const optionsState = FilterState?.map((d) => {
    return {
      ...d,
    };
  });
 
  StorageService.setStates(JSON.stringify([...optionsState]))
};

export const getCitiesData = (data={}) => {
    return axiosInstance
      .post(API.GET_CITIES , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err?.response?.data
      });
  };

  export const getStatesData = (data) => {
    return axiosInstance
        .post(
            API.GET_COUNTRY_STATES, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
};

export function handleKeyDown(event) {
  
    function isValidNumber(val) {
      // Allow empty string or check if the value is a valid number (including decimals)
      if (val === "") return true;
      return !!(!isNaN(val) && !isNaN(parseFloat(val)));
    }
    
    function isSpace(str) {
      return str === " ";
    }
    
    function isWhitelistedKey(key) {
      const whitelistedActions = [
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Tab",
        "Delete",
        "." // Allow decimal separator
      ];
      return whitelistedActions.includes(key);
    }

    // Allow whitelisted keys
    if (isWhitelistedKey(event.key)) {
      // let it through
    } else if (isSpace(event.key) || !isValidNumber(event.target.value + event.key)) {
      // Prevent default action for space or invalid numbers
      event.preventDefault();
    }
}



export const getMasterData = (data = {}) => {
    return axiosInstance
        .get(
            API.GET_MASTER_DATA, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
};

export const getCompanyList = (data = {}) => {
    return axiosInstance
        .get(
            API.GET_MASTER_DATA, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
};

export const getDashbordData = (data = {}) => {
    return axiosInstance
        .post(
            API.GET_COMPANY, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
};

export const getMasterRole = (data = {}) => {
  return axiosInstance
      .post(
          API.GET_MASTER_ROLE, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        return error?.response?.data
    });
};

export const getRole = (data = {}) => {
  return axiosInstance
      .post(
          API.ROLE, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        return error?.response?.data
    });
};

export const deleteRole = (data = {}) => {
  return axiosInstance
      .post(
          API.ROLE_DELETE, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        return error?.response?.data
    });
};

export const consignorGroup = (data = {}) => {
  return axiosInstance
      .post(
          API.CONSIGNOR_GROUP, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        return error?.response?.data
    });
};

export const getUser = (data = {}) => {
  return axiosInstance
      .post(
          API.USER, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        return error?.response?.data
    });
};

export const deleteUser = (data = {}) => {
  return axiosInstance
      .post(
          API.DELETE_USER, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        return error?.response?.data
        });
};


export const getallowedmenus = (data = {}) => {
  return axiosInstance
      .post(
          API.MENU, data)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        return error?.response?.data
    });
};

export const getallowedmenus2 = (data = {}) => {
    return axiosInstance
        .post(
            API.MENU_2, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
          return error?.response?.data
      });
  };
  

export const getRoleDetail = (data = {}) => {
    return axiosInstance
        .post(
            API.ROLE_DETAILS, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const updateRoles = (data = {}) => {
    return axiosInstance
        .post(
            API.ROLE_UPDATE, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };


  export const getAllAccount = (data = {}) => {
    return axiosInstance
        .post(
            API.ALL_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getAllRoutes = (data = {}) => {
   
    return axiosInstance
        .post(
            API.ALL_ROUTE, data)
        .then((response) => {
          
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getAllVehicleNumber = (data = {}) => {
   
    return axiosInstance
        .post(
            API.ALL_VEHICLE_NUMBER, data)
        .then((response) => {
          
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getAllDriver = (data = {}) => {
   
    return axiosInstance
        .post(
            API.ALL_DRIVERS, data)
        .then((response) => {
          
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getAllSupplier = (data = {}) => {
   
    return axiosInstance
        .post(
            API.ALL_SUPPLIER, data)
        .then((response) => {
          
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getAllLrApi = (data = {}) => {
   
    return axiosInstance
        .post(
            API.ALL_LR, data)
        .then((response) => {
          
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getTrip = (data = {}) => {
    return axiosInstance
        .post(
            API.TRIP, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getDispatchedTrip = (data = {}) => {
    return axiosInstance
        .post(
            API.DISPATCH_TRIP, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };
  
  export const getTransactionList = (data = {}) => {
    return axiosInstance
        .post(
            API.TRANSACTION, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };
  
  export const getFreightMasterApi = (data = {}) => {
    return axiosInstance
        .post(
            API.FREIGHT_TYPE, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getVenderBankList = (data = {}) => {
    return axiosInstance
        .post(
            API.BANK_LIST, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getAccountFreghtList = (data = {}) => {
    return axiosInstance
        .post(
            API.CHART_OF_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getAccountList = (data = {}) => {
    return axiosInstance
        .post(
            API.ACCOUNT_LIST, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getUserAccount = (data = {}) => {
    return axiosInstance
        .post(
            API.GET_USER_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const lrListofTrip = (data = {}) => {
    return axiosInstance
        .post(
            API.LR_LIST_TRIP, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };
  
  export const getExpense = (data = {}) => {
    return axiosInstance
        .post(
            API.EXPENSE, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getChartExpense = (data = {}) => {
    return axiosInstance
        .post(
            API.CHART_EXPENSE, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getChartRecipt = (data = {}) => {
    return axiosInstance
        .post(
            API.CHART_RECIPT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getCommonExpenseApi = (data = {}) => {
    return axiosInstance
        .post(
            API.COMMON_EXPENSE, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  

  export const getUserAccountList = (data = {}) => {
    return axiosInstance
        .post(
            API.USER_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getAccountingUser= (data = {}) => {
    return axiosInstance
        .post(
            API.ACCOUNTING_GET_USER, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };


  export const getAccountingTransaction= (data = {}) => {
    return axiosInstance
        .post(
            API.ACCOUNTING_TRANSACTION, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };
  
  
  export const getPayment= (data = {}) => {
    return axiosInstance
        .post(
            API.PAYMENT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const paymentCountApi= (data = {}) => {
    return axiosInstance
        .post(
            API.PAYMENT_COUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };
  
  export const getConsignorTransactionList= (data = {}) => {
    return axiosInstance
        .post(
            API.CONSIGNOR_TRAN, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getLastTrip = (data = {}) => {
    return axiosInstance
        .post(
            API.LAST_TRIP, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };
  
  export const getBuiltyApi = (data = {}) => {
    return axiosInstance
        .post(
            API.BUILTY, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };
  

  export const getTripCount = (data = {}) => {
    return axiosInstance
        .post(
            API.TRIP_COUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };


  export const getUserPermissionApi = (data = {}) => {
    return axiosInstance
        .post(
            API.USER_PERMISSION, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getAddUserBranch = (data = {}) => {
    return axiosInstance
        .post(
            API.GET_ADD_USER_BRANCH, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
}



export const vehicleGroup = (data = {}) => {
    return axiosInstance
        .post(
            API.VEHICLE_GROUP, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
      });
  };

  export const getTotalFreightDetais = (data = {}) => {
    return axiosInstance
        .post(
            API.TOTAL_FREIGHT_DETAILS, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };
  

  export const getAddressListApi = (data = {}) => {
    return axiosInstance
        .post(
            API.COMMON_DROPDOWN + `get_addressbook_for_dropdown`, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getMarketVisiblityApi = (data = {}) => {
    return axiosInstance
        .post(
            API.MARKET_VISIBLITY_LIST , data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };
  

  export const createCashAccountApi = (data = {}) => {
    return axiosInstance
        .post(
            API.CASH_ACCOUNT , data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const createBankAccountApi = (data = {}) => {
    return axiosInstance
        .post(
            API.BANK_ACCOUNT , data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const createOtherAccountApi = (data = {}) => {
    return axiosInstance
        .post(
            API.OTHER_ACCOUNT , data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const createFuelAccountApi = (data = {}) => {
    return axiosInstance
        .post(
            API.ADD_FUEL_CARD_ACCOUNT , data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const createFastagAccountApi = (data = {}) => {
    return axiosInstance
        .post(
            API.ADD_FASTAG_ACCOUNT , data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };
  
  export const getAdvanceDriverCategory = (data = {}) => {
    return axiosInstance
        .post(
            API.ADVANCE_DRIVER_CAT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const addExpenseAccount = (data = {}) => {
    return axiosInstance
        .post(
            API.ADD_EXPENSE_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getTripTransactionDispatch = (data = {}) => {
    return axiosInstance
        .post(
            API.TRIP_TRANSACTION, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const getTripRevenue = (data = {}) => {
    return axiosInstance
        .post(
            API.TRIP_REVENUE, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  
  export const getFuelCardMaster = (data = {}) => {
    return axiosInstance
        .post(
            API.FUEL_CARD_MASTER, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const addFuelCardMaster = (data = {}) => {
    return axiosInstance
        .post(
            API.ADD_FUEL_CARD_MASTER, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const fuelCardAccount = (data = {}) => {
    return axiosInstance
        .post(
            API.FUEL_CARD_ACCOUNT_LIST, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const addDriverFromTrip = (data = {}) => {
    return axiosInstance
        .post(
            API.ADD_DRIVER_FROM_TRIP, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
           
            return error?.response?.data
            
        });
  };

  export const createFuelOutApi = (data = {}) => {
    return axiosInstance
        .post(
            API.CREATE_FUEL_OUT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
           
            return error?.response?.data
            
        });
  };

  export const addExpenseApi = (data = {}) => {
    return axiosInstance
        .post(
            API.ADD_EXPENSE_API, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
           
            return error?.response?.data
            
        });
  };
  

  export const getFuelRateApi = (data = {}) =>{
    return axiosInstance
        .post(
            API.GET_FUEL_RATE, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
           
            return error?.response?.data
            
        });
  }

  export const deleteTransactionApi = (data = {}) =>{
    return axiosInstance
        .post(
            API.DELETE_TRANSACTION, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
           
            return error?.response?.data
            
        });
  }
  
  export const venderDropdownApi = (data = {}) =>{
    return axiosInstance
        .post(
            API.VENDER_DROPDOWN, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
           
            return error?.response?.data
            
        });
  }

  export const transferFundApi = (data = {}) =>{
    return axiosInstance
        .post(
            API.TRANSFER_FUND, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
           
            return error?.response?.data
            
        });
  }

  export const createFuelOutown = (data = {}) =>{
    return axiosInstance
        .post(
            API.CREATE_FUEL_OWN, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
           
            return error?.response?.data
            
        });
  }
  
  export const getRoleMasters = (data = {}) => {
    return axiosInstance
      .post(API.GET_ROLE_MASTERS, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
           
        return error?.response?.data
        
    });
  };

  export const getSinglePlanSubscription = (data = {}) => {
    return axiosInstance
      .post(API.GET_SINGLE_PLAN_SUBSCRIPTION, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
           
        return error?.response?.data
        
    });
  };

  export const getFastagMaster = (data = {}) => {
    return axiosInstance
        .post(
            API.GET_FASTAG_MASTER, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const addFastagMaster = (data = {}) => {
    return axiosInstance
        .post(
            API.ADD_FASTAG_MASTER, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const addFastagAccount = (data = {}) => {
    return axiosInstance
        .post(
            API.ADD_FASTAG_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };

  export const fastagAccount = (data = {}) => {
    return axiosInstance
        .post(
            API.GET_FASTAG_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error?.response?.data
        });
  };
  

  