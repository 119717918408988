import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div className='_mainContained'>
            <section className="p-[80px_0]">
                <div className="container_main" >
                    <div className="p-8 bg-[#f0f8ff]" >
                        <div >
                            <h1 className="text-[32px] text-center text-black font-[700] mb-[15px] plus_jakarta">
                                TERMS AND CONDITIONS
                            </h1>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                Last updated : Oct 25, 2024
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-center text-[16px] font-[600] text-[#000000] mb-[10px]">
                                AGREEMENT TO TERMS
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                These Terms of Use constitute a legally binding agreement made
                                between you, whether personally or on behalf of an entity (“you”)
                                and Delhivery Limited, doing business as Delhivery (hereinafter
                                referred to as “Truck Khata”, "Delhivery", “we”, “us”, or “our”),
                                concerning your access to and use of the
                                <a className="text-[#0000EE]" href="https://transport360.urja360.com/">https://transport360.in</a>
                                website as well as any other media form, media channel, mobile
                                website or mobile application related, linked, or otherwise
                                connected thereto (collectively, the “Platform”). You agree that
                                by accessing the website and/ or Platform, you have read,
                                understood, and agree to be bound by all of these Terms of Use. IF
                                YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
                                EXPRESSLY PROHIBITED FROM USING THE website and/ or Platform AND
                                ARE SUGGESTED TO DISCONTINUE USE IMMEDIATELY.<br />
                                We reserve the right, in our sole discretion, to make changes or
                                modifications to these Terms of Use at any time and for any
                                reason. It is your responsibility to periodically review these
                                Terms of Use on the Platform to stay informed regarding any
                                updates. You will be subject to, and will be deemed to have been
                                made aware of and to have accepted, the changes in any revised
                                Terms of Use by your continued use of the website/ Platform after
                                the date such revised Terms of Use are posted.<br />
                                The information provided on the website/ Platform is not intended
                                for distribution to or use by any person or entity in any
                                jurisdiction or country where such distribution or use would be
                                contrary to law or regulation or which would subject us to any
                                registration requirement within such jurisdiction or country
                                without our express consent. Accordingly, those persons who choose
                                to access the website/ Platform from other locations do so on
                                their own initiative and are solely responsible for compliance
                                with local laws, if and to the extent local laws are applicable.
                                We will not take any liability for any such actions on your
                                behalf.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                INTELLECTUAL PROPERTY RIGHTS
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                Unless otherwise indicated, the website/ Platform is our
                                proprietary property and all source code, databases,
                                functionality, software, website designs, audio, video, text,
                                photographs, and graphics on the website/ Platform (collectively,
                                the “Content”) and the trademarks, service marks, and logos
                                contained therein (the “Marks”) are owned or controlled by us or
                                licensed to us, and are protected by applicable intellectual
                                property rights and unfair competition laws, international
                                copyright laws, and international conventions. The Content and the
                                Marks are provided on the Platform “AS IS” for your information
                                and personal use only subject to the terms and conditions of this
                                Agreement only. Except as expressly provided in these Terms of
                                Use, no part of the Platform and no Content or Marks may be
                                copied, reproduced, aggregated, republished, uploaded, posted,
                                publicly displayed, encoded, translated, transmitted, distributed,
                                sold, licensed, or otherwise exploited for any commercial purpose
                                whatsoever, without our express prior written permission.<br />
                                Provided that you are eligible to use the Platform, you are
                                granted a limited license to access and use the Platform and to
                                download or print a copy of any portion of the Content to which
                                you have properly gained access solely for your personal,
                                non-commercial use only as per the Purpose of this engagement. We
                                reserve all rights not expressly granted to you in and to the
                                Platform, the Content and the Marks.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                USER REPRESENTATIONS
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                You will be required to register with the Platform. You agree to
                                keep your password or OTP (One Time Password) confidential and
                                will be responsible for all use of your account and password/OTP.
                                We reserve the right to remove, reclaim, or change a username you
                                select if we determine, in our sole discretion, that such username
                                is inappropriate, obscene, or otherwise objectionable.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                PROHIBITED ACTIVITIES
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                You may not access or use the Platform for any purpose other than
                                that for which we make the Platform available. The Platform may
                                not be used in connection with any commercial endeavors except
                                those that are specifically endorsed or approved by us in writing.
                            </p>
                            <p className="text-[16px] text-[#000000] m-[5px_0] plus_jakarta">
                                As a user of the Platform, you agree not to:
                            </p>
                            <div className="pl-[15px]" >
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    1. Systematically retrieve data or other content from the
                                    Platform to create or compile, directly or indirectly, a
                                    collection, compilation, database, or directory without written
                                    permission from us.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    2. Make any unauthorized use of the Platform, including
                                    collecting usernames and/or email addresses of users by
                                    electronic or other means for the purpose of sending unsolicited
                                    email, or creating user accounts by automated means or under
                                    false pretenses.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    3. Use the Platform to advertise or offer to sell goods and
                                    services.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    4. Circumvent, disable, or otherwise interfere with
                                    security-related features of the Platform, including features
                                    that prevent or restrict the use or copying of any Content or
                                    enforce limitations on the use of the Platform and/or the
                                    Content contained therein.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    5. Engage in unauthorized framing of or linking to the Platform.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    6. Trick, defraud, or mislead us and other users, especially in
                                    any attempt to learn sensitive account information such as user
                                    passwords.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    7. Make improper use of our support services or submit false
                                    reports of abuse or misconduct.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    8. Engage in any automated use of the system, such as using
                                    scripts to send comments or messages, or using any data mining,
                                    robots, or similar data gathering and extraction tools.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    9. Interfere with, disrupt, or create an undue burden on the
                                    Platform or the networks or services connected to the Platform.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    10. Attempt to impersonate another user or person or use the
                                    username of another user.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    11. Sell or otherwise transfer your profile.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    12. Use any information obtained from the Platform in order to
                                    harass, abuse, or harm another person.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    13. Use the Platform as part of any effort to compete with us or
                                    otherwise use the Platform and/or the Content for any
                                    revenue-generating endeavor or commercial enterprise.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    14. Decipher, decompile, disassemble, or reverse engineer any of
                                    the software comprising or in any way making up a part of the
                                    Platform.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    15. Attempt to bypass any measures of the Platform designed to
                                    prevent or restrict access to the Platform, or any portion of
                                    the Platform.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    16. Harass, annoy, intimidate, or threaten any of our employees
                                    or agents engaged in providing any portion of the Platform to
                                    you.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    17. Delete the copyright or other proprietary rights notice from
                                    any Content.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    18. Copy or adapt the Platform’s software, including but not
                                    limited to Flash, PHP, HTML, JavaScript, or other code.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    19. Upload or transmit (or attempt to upload or to transmit)
                                    viruses, Trojan horses, or other material, including excessive
                                    use of capital letters and spamming (continuous posting of
                                    repetitive text), that interferes with any party’s uninterrupted
                                    use and enjoyment of the Platform or modifies, impairs,
                                    disrupts, alters, or interferes with the use, features,
                                    functions, operation, or maintenance of the Platform.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    20. Upload or transmit (or attempt to upload or to transmit) any
                                    material that acts as a passive or active information collection
                                    or transmission mechanism, including without limitation, clear
                                    graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
                                    cookies, or other similar devices (sometimes referred to as
                                    “spyware” or “passive collection mechanisms” or “pcms”).
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    21. Except as may be the result of standard search engine or
                                    Internet browser usage, use, launch, develop, or distribute any
                                    automated system, including without limitation, any spider,
                                    robot, cheat utility, scraper, or offline reader that accesses
                                    the Platform, or using or launching any unauthorized script or
                                    other software.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    22. Disparage, tarnish, or otherwise harm, in our opinion, us
                                    and/or the Platform.
                                </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">
                                    23. Use the Platform in a manner inconsistent with any
                                    applicable laws or regulations.
                                </p>
                            </div>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                USER GENERATED CONTRIBUTIONS
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                The Platform may, if required, invite you to chat, contribute to,
                                or participate in blogs, message boards, online forums, and other
                                functionality, and may provide you with the opportunity to create,
                                submit, post, display, transmit, perform, publish, distribute, or
                                broadcast content and materials to us or on the Platform,
                                including but not limited to text, writings, video, audio,
                                photographs, graphics, comments, suggestions, or personal
                                information or other material (collectively, "Contributions").
                                Contributions may be viewable by other users of the Platform and
                                through third-party websites. As such, any Contributions you
                                transmit may be treated as non-confidential and non-proprietary.
                                When you create or make available any Contributions, you thereby
                                represent and warrant that:
                            </p>
                            <div className="pl-[15px]" >
                                <p className="text-[16px] text-[#000000] mb-[5px]">1. The creation, distribution, transmission,
                                    public display, or performance, and the accessing, downloading, or copying of your
                                    Contributions do not and will not infringe the proprietary rights, including but not limited
                                    to the copyright, patent, trademark, trade secret, or moral rights of any third party.</p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">2. You are the creator and owner of or have the
                                    necessary licenses, rights, consents, releases, and permissions to use and to authorize us,
                                    the Platform, and other users of the Platform to use your Contributions in any manner
                                    contemplated by the Platform and these Terms of Use.</p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">3. You have the written consent, release, and/or
                                    permission of each and every identifiable individual person in your Contributions to use the
                                    name or likeness of each and every such identifiable individual person to enable inclusion
                                    and use of your Contributions in any manner contemplated by the Platform and these Terms of
                                    Use.</p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">4. Your Contributions are not false, inaccurate,
                                    or misleading.</p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">5. Your Contributions are not unsolicited or
                                    unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass
                                    mailings, or other forms of solicitation. </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">6. Your Contributions are not obscene, lewd,
                                    lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as
                                    determined by us). </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">7. Your Contributions do not ridicule, mock,
                                    disparage, intimidate, or abuse anyone. </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">8. Your Contributions are not used to harass or
                                    threaten (in the legal sense of those terms) any other person and to promote violence
                                    against a specific person or className of people. </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">9. Your Contributions do not violate any
                                    applicable law, regulation, or rule.</p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">10. Your Contributions do not violate the privacy
                                    or publicity rights of any third party. </p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">11. Your Contributions do not violate any
                                    applicable law concerning child pornography, or otherwise intended to protect the health or
                                    well-being of minors.</p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">12. Your Contributions do not include any
                                    offensive comments that are connected to race, national origin, gender, sexual preference,
                                    or physical handicap.</p>
                                <p className="text-[16px] text-[#000000] mb-[5px]">13. Contributions do not otherwise violate, or
                                    link to material that violates, any provision of these Terms of Use, or any applicable law
                                    or regulation. Any use of the Platform in violation of the foregoing violates these Terms of
                                    Use and may result in, among other things, termination or suspension of your rights to use
                                    the Platform and subsequent indemnification as provided herein under.</p>
                            </div>
                        </div>

                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                CONTRIBUTION LICENSE
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                By posting your Contributions to any part of the Platform, you automatically grant, and you
                                represent and warrant that you have the right to grant, to us an unrestricted, unlimited,
                                irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right,
                                and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle,
                                archive, store, cache, publicly perform, publicly display, reformat, translate, transmit,
                                excerpt (in whole or in part), and distribute such Contributions (including, without limitation,
                                your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare
                                derivative works of, or incorporate into other works, such Contributions, and grant and
                                authorize sublicenses of the foregoing. The use and distribution may occur in any media formats
                                and through any media channels.<br />
                                This license will apply to any form, media, or technology now known or hereafter developed, and
                                includes our use of your name, company name, and franchise name, as applicable, and any of the
                                trademarks, service marks, trade names, logos, and personal and commercial images you provide.
                                You waive all moral rights in your Contributions, and you warrant that moral rights have not
                                otherwise been asserted in your Contributions.<br />
                                We do not assert any ownership over your Contributions. You retain full ownership of all of your
                                Contributions and any intellectual property rights or other proprietary rights associated with
                                your Contributions. We are not liable for any statements or representations in your
                                Contributions provided by you in any area on the Platform. You are solely responsible for your
                                Contributions to the Platform and you expressly agree to exonerate and indemnify us from any and
                                all responsibility and to refrain from any legal action against us regarding your Contributions.
                                We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change
                                any Contributions; (2) to re-categorize any Contributions to place them in more appropriate
                                locations on the Platform; and (3) to pre-screen or delete any Contributions at any time and for
                                any reason, without notice. We have no obligation to monitor your Contributions.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                GUIDELINES FOR REVIEWS
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                We may provide you areas on the Platform to leave reviews or ratings. When posting a review, you
                                must comply with the following criteria: (1) you should have firsthand experience with the
                                person/entity being reviewed; (2) your reviews should not contain offensive profanity, or
                                abusive, racist, offensive, or hate language; (3) your reviews should not contain discriminatory
                                references based on religion, race, gender, national origin, age, marital status, sexual
                                orientation, or disability; (4) your reviews should not contain references to illegal activity;
                                (5) you should not be affiliated with competitors if posting negative reviews; (6) you should
                                not make any conclusions as to the legality of conduct; (7) you may not post any false or
                                misleading statements; and (8) you may not organize a campaign encouraging others to post
                                reviews, whether positive or negative.<br />
                                We may accept, reject, or remove reviews in our sole discretion if we feel they are not suitable
                                to be placed in the public domain. We have absolutely no obligation to screen reviews or to
                                delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not
                                endorsed by us, and do not necessarily represent our opinions or the views of any of our
                                affiliates or partners. We do not assume liability for any review or for any claims,
                                liabilities, or losses resulting from any review. By posting a review, you hereby grant to us a
                                perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable
                                right and license to reproduce, modify, translate, transmit by any means, display, perform,
                                and/or distribute all content relating to reviews.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                MOBILE APPLICATION LICENSE
                            </h6>
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">Use License</h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                If you access the Platform via a mobile application, then we grant you a revocable,
                                non-exclusive, non-transferable, limited right to install and use the mobile application on
                                wireless electronic devices owned or controlled by you, and to access and use the mobile
                                application on such devices strictly in accordance with the terms and conditions of this mobile
                                application license contained in these Terms of Use. You shall not: (1) decompile, reverse
                                engineer, disassemble, attempt to derive the source code of, or decrypt the application; (2)
                                make any modification, adaptation, improvement, enhancement, translation, or derivative work
                                from the application; (3) violate any applicable laws, rules, or regulations in connection with
                                your access or use of the application; (4) remove, alter, or obscure any proprietary notice
                                (including any notice of copyright or trademark) posted by us or the licensors of the
                                application; (5) use the application for any revenue generating endeavor, commercial enterprise,
                                or other purpose for which it is not designed or intended; (6) make the application available
                                over a network or other environmental permitting access or use by multiple devices or users at
                                the same time; (7) use the application for creating a product, service, or software that is,
                                directly or indirectly, competitive with or in any way a substitute for the application; (8) use
                                the application to send automated queries to any website or to send any unsolicited commercial
                                e-mail; or (9) use any proprietary information or any of our interfaces or our other
                                intellectual property in the design, development, manufacture, licensing, or distribution of any
                                applications, accessories, or devices for use with the application.
                            </p>
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">Apple and Android Devices</h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                The following terms apply when you use a mobile application obtained from any source including
                                but not limited to the Apple Store or Google Play (each an “App Distributor”) to access the
                                Platform: (1) the license granted to you for our mobile application is limited to a
                                non-transferable license to use the application on a device that utilizes the Apple iOS or
                                Android operating systems, as applicable, and in accordance with the usage rules set forth in
                                the applicable App Distributor’s terms of service; (2) in the event of any failure of the mobile
                                application to conform to any applicable warranty, you may notify the applicable App
                                Distributor, and the App Distributor, in accordance with its terms and policies, may refund the
                                purchase price, if any, paid for the mobile application, and to the maximum extent permitted by
                                applicable law, the App Distributor will have no other warranty obligation whatsoever with
                                respect to the mobile application; (3) you must comply with applicable third-party terms of
                                agreement when using the mobile application, e.g., if you have a VoIP application, then you must
                                not be in violation of their wireless data service agreement when using the mobile application;
                                and (4) you acknowledge and agree that the App Distributors are third-party beneficiaries of the
                                terms and conditions in this mobile application license contained in these Terms of Use, and
                                that each App Distributor will have the right (and will be deemed to have accepted the right) to
                                enforce the terms and conditions in this mobile application license contained in these Terms of
                                Use against you as a third-party beneficiary thereof.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                SUBMISSIONS
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other
                                information regarding the Platform ("Submissions") provided by you to us are non-confidential
                                and shall become our sole property. We shall own exclusive rights, including all intellectual
                                property rights, and shall be entitled to the unrestricted use and dissemination of these
                                Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or
                                compensation to you. You hereby waive all moral rights to any such Submissions, and you hereby
                                warrant that any such Submissions are original with you or that you have the right to submit
                                such Submissions. You agree there shall be no recourse against us for any alleged or actual
                                infringement or misappropriation of any proprietary right in your Submissions.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                PLATFORM MANAGEMENT
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                We reserve the right, but not the obligation, to: (1) monitor the Platform for violations of
                                these Terms of Use; (2) take appropriate legal action against anyone who, in our sole
                                discretion, violates the law or these Terms of Use, including without limitation, reporting such
                                user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse,
                                restrict access to, limit the availability of, or disable (to the extent technologically
                                feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and
                                without limitation, notice, or liability, to remove from the Platform or otherwise disable all
                                files and content that are excessive in size or are in any way burdensome to our systems; and
                                (5) otherwise manage the Platform in a manner designed to protect our rights and property and to
                                facilitate the proper functioning of the Platform.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                PRIVACY POLICY
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                We care about data privacy and security. By using the Platform, you agree to be bound by our
                                Privacy Policy posted on the Platform, which is incorporated into these Terms of Use. Please be
                                advised the Platform is hosted in India. If you access the Platform from any other region of the
                                world with laws or other requirements governing personal data collection, use, or disclosure
                                that differ from applicable laws in India, then through your continued use of the Platform, you
                                are transferring your data to India, and you agree to have your data transferred to and
                                processed in India.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                TERM AND TERMINATION
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                These Terms of Use shall remain in full force and effect while you use the Platform. WITHOUT
                                LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE
                                DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE PLATFORM (INCLUDING
                                BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT
                                LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF
                                USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE
                                PLATFORM OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
                                WITHOUT WARNING, IN OUR SOLE DISCRETION.<br />
                                If we terminate or suspend your account for any reason, you are prohibited from registering and
                                creating a new account under your name, a fake or borrowed name, or the name of any third party,
                                even if you may be acting on behalf of the third party. In addition to terminating or suspending
                                your account, we reserve the right to take appropriate legal action, including without
                                limitation pursuing civil, criminal, and injunctive redress.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                MODIFICATIONS AND INTERRUPTIONS
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                We reserve the right to change, modify, or remove the contents of the Platform at any time or
                                for any reason at our sole discretion without notice. However, we have no obligation to update
                                any information on our Platform. We also reserve the right to modify or discontinue all or part
                                of the Platform without notice at any time. We will not be liable to you or any third party for
                                any modification, price change, suspension, or discontinuance of the Platform.<br />
                                We cannot guarantee the Platform will be available at all times. We may experience hardware,
                                software, or other problems or need to perform maintenance related to the Platform, resulting in
                                interruptions, delays, or errors. We reserve the right to change, revise, update, suspend,
                                discontinue, or otherwise modify the Platform at any time or for any reason without notice to
                                you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience
                                caused by your inability to access or use the Platform during any downtime or discontinuance of
                                the Platform. Nothing in these Terms of Use will be construed to obligate us to maintain and
                                support the Platform or to supply any corrections, updates, or releases in connection therewith.
                                However, we will ensure
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                GOVERNING LAW &amp; DISPUTE RESOLUTION
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                These Terms shall be governed by and defined following the laws of India. Delhivery Limited and
                                yourself irrevocably consent that the courts of New Delhi, India shall have exclusive
                                jurisdiction to resolve any dispute which may arise in connection with these terms.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                CORRECTIONS
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                There may be information on the Platform that contains typographical errors, inaccuracies, or
                                omissions, including descriptions, pricing, availability, and various other information. We
                                reserve the right to correct any errors, inaccuracies, or omissions and to change or update the
                                information on the Platform at any time, without prior notice.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                DISCLAIMER
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                THE PLATFORM IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE
                                PLATFORM AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
                                DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE PLATFORM AND YOUR USE
                                THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                                PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
                                ACCURACY OR COMPLETENESS OF THE PLATFORM’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE
                                PLATFORM AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
                                INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                                WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE PLATFORM, (3) ANY UNAUTHORIZED ACCESS
                                TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
                                INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                                PLATFORM, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
                                THROUGH THE PLATFORM BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
                                MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
                                POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE PLATFORM. WE DO NOT WARRANT, ENDORSE,
                                GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD
                                PARTY THROUGH THE PLATFORM, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
                                FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
                                RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS
                                OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
                                ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                INDEMNIFICATION
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates,
                                and all of our respective officers, agents, partners, and employees, from and against any loss,
                                damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by
                                any third party due to or arising out of: (1) your Contributions; (2) use of the Platform; (3)
                                breach of these Terms of Use; (4) any breach of your representations and warranties set forth in
                                these Terms of Use; (5) your violation of the rights of a third party, including but not limited
                                to intellectual property rights; (6) any overt harmful act toward any other user of the Platform
                                with whom you connected via the Platform; or (7) breach of applicable laws. Notwithstanding the
                                foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of
                                any matter for which you are required to indemnify us, and you agree to cooperate, at your
                                expense, with our defense of such claims. We will use reasonable efforts to notify you of any
                                such claim, action, or proceeding which is subject to this indemnification upon becoming aware
                                of it.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                USER DATA
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                We will maintain certain data that you transmit to the Platform for the purpose of managing the
                                performance of the Platform, as well as data relating to your use of the Platform. Although we
                                perform regular routine backups of data, you are solely responsible for all data that you
                                transmit or that relates to any activity you have undertaken using the Platform. You agree that
                                we shall have no liability to you for any loss or corruption of any such data, and you hereby
                                waive any right of action against us arising from any such loss or corruption of such data.
                            </p>
                        </div>
                        <div className="mb-[25px]" >
                            <h6 className="text-[16px] font-[600] text-[#000000] mb-[10px]">
                                MISCELLANEOUS
                            </h6>
                            <p className="text-[16px] text-[#000000] mb-[15px] plus_jakarta">
                                These Terms of Use and any policies or operating rules posted by us on the Platform or in respect to the Platform constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the Platform. You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </div>

        // <div className="terms-container">
        //   <h1 className="terms-heading">TERMS AND CONDITIONS</h1>
        //   <p className="last-updated">Last updated: Oct 25, 2024</p>

        //   <section className="terms-section">
        //     <h2 className="section-title">AGREEMENT TO TERMS</h2>
        //     <p className="section-content">
        //       These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf
        //       of an entity (“you”) and Delhivery Limited, doing business as Delhivery (hereinafter referred to as “Truck
        //       Khata”, “Delhivery”, “we”, “us”, or “our”), concerning your access to and use of the{' '}
        //       <a href="https://transport360.in" target="_blank" rel="noopener noreferrer">
        //         https://transport360.in
        //       </a>{' '}
        //       website as well as any other media form, media channel, mobile website or mobile application related, linked,
        //       or otherwise connected thereto (collectively, the “Platform”). You agree that by accessing the website and/or
        //       Platform, you have read, understood, and agree to be bound by all of these Terms of Use. IF YOU DO NOT AGREE
        //       WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE website and/or Platform AND
        //       ARE SUGGESTED TO DISCONTINUE USE IMMEDIATELY.
        //     </p>
        //     <p className="section-content">
        //       We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any
        //       time and for any reason. It is your responsibility to periodically review these Terms of Use on the Platform
        //       to stay informed regarding any updates. You will be subject to, and will be deemed to have been made aware of
        //       and to have accepted, the changes in any revised Terms of Use by your continued use of the website/ Platform
        //       after the date such revised Terms of Use are posted.
        //     </p>
        //   </section>

        //   <section className="terms-section">
        //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
        //     <p className="section-content">
        //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
        //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
        //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
        //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
        //       property rights.
        //     </p>
        //     <p className="section-content">
        //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
        //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
        //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
        //       rights not expressly granted to you in and to the Platform and the Marks.
        //     </p>
        //   </section>
        //   <section className="terms-section">
        //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
        //     <p className="section-content">
        //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
        //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
        //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
        //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
        //       property rights.
        //     </p>
        //     <p className="section-content">
        //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
        //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
        //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
        //       rights not expressly granted to you in and to the Platform and the Marks.
        //     </p>
        //   </section>
        //   <section className="terms-section">
        //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
        //     <p className="section-content">
        //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
        //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
        //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
        //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
        //       property rights.
        //     </p>
        //     <p className="section-content">
        //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
        //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
        //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
        //       rights not expressly granted to you in and to the Platform and the Marks.
        //     </p>
        //   </section>
        //   <section className="terms-section">
        //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
        //     <p className="section-content">
        //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
        //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
        //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
        //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
        //       property rights.
        //     </p>
        //     <p className="section-content">
        //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
        //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
        //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
        //       rights not expressly granted to you in and to the Platform and the Marks.
        //     </p>
        //   </section>
        //   <section className="terms-section">
        //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
        //     <p className="section-content">
        //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
        //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
        //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
        //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
        //       property rights.
        //     </p>
        //     <p className="section-content">
        //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
        //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
        //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
        //       rights not expressly granted to you in and to the Platform and the Marks.
        //     </p>
        //   </section>
        //   <section className="terms-section">
        //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
        //     <p className="section-content">
        //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
        //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
        //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
        //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
        //       property rights.
        //     </p>
        //     <p className="section-content">
        //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
        //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
        //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
        //       rights not expressly granted to you in and to the Platform and the Marks.
        //     </p>
        //   </section>
        //   <section className="terms-section">
        //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
        //     <p className="section-content">
        //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
        //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
        //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
        //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
        //       property rights.
        //     </p>
        //     <p className="section-content">
        //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
        //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
        //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
        //       rights not expressly granted to you in and to the Platform and the Marks.
        //     </p>
        //   </section>
        //   <section className="terms-section">
        //     <h2 className="section-title">INTELLECTUAL PROPERTY RIGHTS</h2>
        //     <p className="section-content">
        //       Unless otherwise indicated, the website/ Platform is our proprietary property and all source code, databases,
        //       functionality, software, website designs, audio, video, text, photographs, and graphics on the website/
        //       Platform (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the
        //       “Marks”) are owned or controlled by us or licensed to us, and are protected by applicable intellectual
        //       property rights.
        //     </p>
        //     <p className="section-content">
        //       Provided that you are eligible to use the Platform, you are granted a limited license to access and use the
        //       Platform and to download or print a copy of any portion of the Content to which you have properly gained
        //       access solely for your personal, non-commercial use as per the Purpose of this engagement. We reserve all
        //       rights not expressly granted to you in and to the Platform and the Marks.
        //     </p>
        //   </section>
        //   <footer>

        //   </footer>
        // </div>
    );
};

export default TermsAndConditions;


