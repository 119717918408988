import React, { useEffect, useState } from 'react'
import { CommonInput, CommonModal } from 'components'
import toast, { Toaster } from 'react-hot-toast';
import { errorSchema, utils } from 'helper'
import { Checkbox } from 'pages/User/AddUser'
import { addNewLrSeries, updateLrSeriesData } from '_services/accountsServices/lrSeriesServices';
import StorageService from 'utils/StorageServices/StorageServices';

const AddNewSeriesModal = (props) => {

    let { previosModal, closeModal, formData, fetchLrSeriesDataList, isEditLR } = props;

    const formObj = {
        company_id: StorageService.getCompanyId(),
        branch_id: formData?.branch_id?.value,
        prefix: '',
        next_number: '',
        set_as_default: '',
    }

    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [responseError, setResponseError] = useState('')
    const [loading, setLoading] = useState(false);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addNewLrSeriesModalSchema);
            if (validationResult === true) {
                setFormError("");
                setResponseError("")
            } else {
                setFormError(validationResult);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.addNewLrSeriesModalSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true);
            try {
                if (isEditLR?._id) {
                    const res = await updateLrSeriesData(formValue);
                    if (!!res) {
                        setFormValue({});
                        setLoading(false);
                        toast.success('Series Update Successfully');
                        previosModal();
                        closeModal();
                    } else {
                        setLoading(false);
                    }
                } else {
                    const res = await addNewLrSeries(formValue);
                    if (!!res) {
                        setFormValue({});
                        setLoading(false);
                        toast.success('New Series Create Successfully');
                        previosModal();
                        closeModal();
                    } else {
                        setLoading(false);
                    }
                }
            } catch (error) {
                setResponseError(error?.response?.data?.responseMessage)
                setLoading(false);
            }
            setFormError("");
        } else {
            setFormError(validationResult);
            setLoading(false);
        }
    };

    let handleProps = {
        ...props,
        onSubmit: handleSubmit,
    }

    useEffect(() => {
        if (isEditLR?._id) {
            setFormValue((s) => ({
                ...s,
                series_id: isEditLR?._id,
                company_id: isEditLR?.company_id,
                branch_id: isEditLR?.branch_id,
                prefix: isEditLR?.prefix,
                next_number: isEditLR?.next_number,
                set_as_default: isEditLR?.set_as_default,
            }))
        }

    }, [isEditLR?._id])

    return (
        <div>
            <CommonModal {...handleProps}>
                <div className='addNewlrSeries_body'>
                    <div className='_inrPrt_flx2 '>
                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                label="Prefix"
                                placeholder="Prefix * "
                                name='prefix'
                                value={formValue.prefix}
                                focus={!!(typeof formError === "object" && formError?.prefix)}
                                error={!!(typeof formError === "object") ? formError?.prefix : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='singleDiv'>
                            <CommonInput
                                type="number"
                                label="Next Number"
                                placeholder="Next Number * "
                                name='next_number'
                                value={formValue.next_number}
                                focus={!!(typeof formError === "object" && formError?.next_number)}
                                error={!!(typeof formError === "object") ? formError?.next_number : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='ps-1  mt-n1'>
                            <Checkbox
                                type="checkbox"
                                name="Set as Default"
                                id="set_as_default"
                                isChecked={formValue.set_as_default === 1}
                                handleClick={(e) => {
                                    let value = e?.target?.checked ? 1 : 0;
                                    setFormValue((s) => ({ ...s, set_as_default: value }))
                                }}
                            />
                        </div>
                    </div>
                    {responseError && (
                        <span className="input_drop_error">
                            {responseError}
                        </span>
                    )}
                </div>
            </CommonModal>
        </div>
    )
}

export default AddNewSeriesModal
