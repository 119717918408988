import {
  addFuelCardMaster,
  createFuelAccountApi,
  getAllVehicleNumber,
  getFuelCardMaster,
  handleKeyDown,
} from "_services";
import { CommonInput, CommonModal, CustomSelect } from "components";
import { errorSchema, utils } from "helper";
import AddEditModal from "modals/AddEditModal";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { StorageServices } from "utils";
import StorageService from "utils/StorageServices";

const AddFuelCardModal = (props) => {
  const initialFrom = {
    company_id: StorageServices.getCompanyId(),
    fuel_card_number: "",
    associate_vehicle_id: "",
    fuel_opening_balance: "",
    fuel_credit_or_debit: 1,
    fuel_card_master_id: "",
  };

  const [form, setForm] = useState(initialFrom);
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [isOpenFuelModal, setIsOpenFuelModal] = useState(false);
  const [masterFcList, setMasterFcList] = useState([]);

  useEffect(() => {
    fetchInitialData();
    getMasterFc();
  }, []);

  const fetchInitialData = async () => {
    try {
      const [vehicleRes] = await Promise.all([getVehicleList()]);

      if (vehicleRes?.responseCode === 200) {
        const list = vehicleRes.data?.vehicle_list.map((i) => ({
          ...i,
          label: i.vehicle_no,
          value: i._id,
        }));
        setVehicleList(list);

        handleListResponse(list, "associate_vehicle_id");
        handleListResponse(list, "fastag_associate_vehicle_id");
      } else {
        toast.error(vehicleRes?.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
      toast.error("Error fetching initial data.");
    }
  };

  const handleListResponse = (list, labelKey) => {
    if (list.length > 0) {
      setForm((prev) => ({ ...prev, [labelKey]: list[0].value }));
    }
  };

  const getVehicleList = () => {
    return getAllVehicleNumber({
      company_id: StorageService.getCompanyId(),
    });
  };

  const getMasterFc = async () => {
    try {
      const res = await getFuelCardMaster({
        company_id: StorageService.getCompanyId(),
      });
      if (res?.responseCode === 200) {
        setMasterFcList(
          res?.data?.fuelcard_master?.map((i) => ({
            ...i,
            label: i?.name,
            value: i?._id,
          }))
        );
      }
    } catch (error) {}
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...form, [name]: value };
    setForm(stateObj);

    if (!!formError) {
      const validationResult = await utils.checkFormError(
        stateObj,
        errorSchema.fuelSchema
      );

      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleOnSubmit = async () => {
    const validationResult = await utils.checkFormError(
      { ...form },
      errorSchema.fuelSchema
    );

    const payloads = {
      company_id: form?.company_id,
      fuel_card_number: form?.fuel_card_number,
      associate_vehicle_id: form?.associate_vehicle_id,
      opening_balance: form?.fuel_opening_balance,
      credit_or_debit: form?.fuel_credit_or_debit,
      fuel_card_master_id: form?.fuel_card_master_id,
    };

    if (validationResult == true) {
      setLoading(true);
      try {
        const apiCall = createFuelAccountApi;

        if (!payloads || !apiCall) {
          toast?.error("Invalid tab or missing payload");
          return;
        }

        const res = await apiCall(payloads);

        if (res?.responseCode === 200) {
          setLoading(false);
          props?.closeModal();
          props?.getAccountListHandler();
          toast?.success(res?.responseMessage);
        } else {
          setLoading(false);
          toast?.error(res?.responseMessage);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
        toast?.error(error?.message || "An unexpected error occurred");
      }
    } else {
      setLoading(false);
      setFormError(validationResult);
    }
  };

  const addFuelCardHandle = (data) => {
    setIsOpenFuelModal(true);
  };

  const callBackApi = (data) => {
    getMasterFc();
    handleOnChange("fuel_card_master_id", data?.fuelcard_master?._id);
  };

  const propsTransAccount = {
    ...props,
    onSubmit: handleOnSubmit,
    isLoadding: loading,
    disabled: loading,
    show: isOpenFuelModal ? "" : "show",
  };

  let openZoneModal = {
    isOpen: isOpenFuelModal,
    closeModal: (e) => setIsOpenFuelModal(false),
    // nextModal: (e) => submitUpdateData(e),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add Fuel Card Master",
    submitTitle: "Add FC Master",
    footerPart: true,
    label: "Fuel Card Master *",
    placeholder: "Enter Fuel Card Master ",
    ApiPoint: addFuelCardMaster,
    callBackApi: callBackApi,
  };

  return (
    <>
      <CommonModal {...propsTransAccount}>
        <div className="addLr_body">
          <div className="addPr_flBx _addTrns_flx">
            <div className="_allDflx ">
              <div>
                <CommonInput
                  type="text"
                  placeholder="Fuel Card Number"
                  label="Fuel Card Number"
                  name="fuel_card_number"
                  focus={!!formError?.fuel_card_number}
                  error={formError.fuel_card_number}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  value={form?.fuel_card_number}
                />
              </div>

              <div>
                <CustomSelect
                  options={vehicleList}
                  label="Associate Vehicle"
                  placeholder="Associate Vehicle"
                  name="associate_vehicle_id"
                  focus={!!formError?.associate_vehicle_id}
                  error={formError.associate_vehicle_id}
                  onSelect={(data) =>
                    handleOnChange("associate_vehicle_id", data?.value)
                  }
                  value={vehicleList?.find(
                    (i) => i?.value == form?.associate_vehicle_id
                  )}
                />
              </div>

              <div>
                <CommonInput
                  type="text"
                  placeholder="Opening Balance (Rs)"
                  label="Opening Balance (Rs)"
                  name="fuel_opening_balance"
                  focus={!!formError?.fuel_opening_balance}
                  error={formError.fuel_opening_balance}
                  onChange={({ target: { name, value } }) => {
                    const validValue = value.replace(/[^0-9.]/g, "");
                    const formattedValue = validValue.includes(".")
                      ? validValue.split(".")[0] +
                        "." +
                        validValue.split(".")[1].slice(0, 2)
                      : validValue;

                    handleOnChange(name, formattedValue);
                  }}
                  value={form?.fuel_opening_balance}
                  onKeyDown={handleKeyDown}
                />
              </div>

              <div className="d-flex gap-4">
                {[
                  { label: "(+) Balance", value: 1 },
                  { label: "(-) Balance", value: 2 },
                ].map((i) => (
                  <div className="_dualCheckr_bx">
                    <input
                      className="form-check-input"
                      id={i?.label}
                      type="radio"
                      name="fuel_credit_or_debit"
                      checked={i.value == form.fuel_credit_or_debit}
                      value={i.value}
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, Number(value))
                      }
                    />
                    <label htmlFor={i?.label} className="form-check-label">
                      {i?.label}
                    </label>
                  </div>
                ))}
              </div>

              <div>
                <CustomSelect
                  options={masterFcList}
                  label="Fuel Card Master"
                  placeholder="Fuel Card Master"
                  name="fuel_card_master_id"
                  focus={!!formError?.fuel_card_master_id}
                  error={formError.fuel_card_master_id}
                  onSelect={(data) =>
                    handleOnChange("fuel_card_master_id", data?.value)
                  }
                  isAddButton={true}
                  addButtonAction={addFuelCardHandle}
                  value={
                    form?.fuel_card_master_id
                      ? masterFcList?.find(
                          (i) => i?.value == form?.fuel_card_master_id
                        )
                      : { label: "", value: "" }
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </CommonModal>
    {isOpenFuelModal && <AddEditModal {...openZoneModal} />}
    </>
  );
};

export default AddFuelCardModal;
