import React, { useContext, useEffect, useState } from 'react'
import LrDetailsPage from './LrDetailsPage';
import { getDataLrDetails } from '_services/accountsServices/lrServices';
import StorageService from 'utils/StorageServices/StorageServices';
import { fetchConsignorDetailsData } from '_services/accountsServices/consignorServices';
import { useNavigate } from 'react-router-dom';
import { utils } from 'helper';
import AtPartyPointModal from 'modals/AtPartyPointModal';
import PodRecievedModal from 'modals/PodRecievedModal';
import { UserContext } from 'context';
import { RiAddLargeFill } from 'react-icons/ri';
import { podReceivedData, podStatusUpdateData, uploadFiles } from '_services';
import toast from 'react-hot-toast';
import ContinuePodRecievedModal from 'modals/ContinuePodRecievedModal';
import EditPodStatus from 'modals/EditPodStatus';
import { getMaterialCategoryListData } from '_services/materialServices/materialCategoryServices';
import PermissionModal from 'modals/PermissionModal';

const LrDetailModal = (props) => {
    const navigate = useNavigate();
    const { masterData, setLrDetialsFrom, lrPermissions, permissionModal, setPermissionModal } = useContext(UserContext)
    let { isOpen, onClose, nextModal } = props;

    const [loading, setLoading] = useState(false);
    const [lrDetailsData, setLrDetailsData] = useState({});
    const [consignorDetailsData, setConsignorDetailsData] = useState({});
    const [isOpenPartyPoint, setIsOpenPartyPoint] = useState(false);
    const [isOpenPodReceived, setIsOpenPodReceived] = useState(false);
    const [continePodReceived, setContinePodReceived] = useState(false);
    const [isPodStatusEdit, setIsPodStatusEdit] = useState(false);
    const [callDetails, setCallDetails] = useState(false);

    const [materialCategoryList, setMaterialCategoryList] = useState([]);

    const getTableMaterialCategoryListData = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getMaterialCategoryListData(object);
            if (res?.responseCode == 200) {
                setMaterialCategoryList(res?.data?.material_categorys)
            }
        } catch (error) {
            // throw error;
        }
    }

    const getDetailsOfLrData = async (data) => {
        try {
            let object = {
                company_id: data?.company_id ? data?.company_id : StorageService?.getCompanyId(),
                lr_id: data?._id,
            }
            const res = await getDataLrDetails(object);
            if (res?.responseCode === 200) {
                setLoading(false);
                setLrDetailsData(res?.data?.lr)
            }
        } catch (error) {
            setLoading(false);
            // throw error;
        }
    }

    const getDetailsOfConsignorData = async (data) => {
        try {
            let object = {
                consignor_id: data?.consignor_id?._id ? data?.consignor_id?._id : data?.consignor_id,
                company_id: data?.company_id ? data?.company_id : StorageService?.getCompanyId(),
            }
            const res = await fetchConsignorDetailsData(object);
            if (!!res) {
                setLoading(false);
                setConsignorDetailsData(res?.data?.consignor);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (isOpen) {
            setLoading(true);
            getDetailsOfLrData(isOpen);
            getTableMaterialCategoryListData();
        }
    }, [isOpen])

    useEffect(() => {
        if (isOpen?.consignor_id) {
            setLoading(true);
            getDetailsOfConsignorData(isOpen);
        }
    }, [isOpen])

    let partyPointModalProps = {
        isOpen: isOpenPartyPoint,
        closeModal: (e) => setIsOpenPartyPoint(false),
        lrId: lrDetailsData?._id,
        companyId: lrDetailsData?.company_id,
        modalMainClass: "_dashCommon_Modal _smModal",
        modalTitle: isOpenPartyPoint?.name === 'party_point' ? 'At Party Point' : 'Delivered',
        submitTitle: isOpenPartyPoint?.name === 'party_point' ? 'At Party Point' : 'Delivered',
    }

    let podRecievedProps = {
        isOpen: isOpenPodReceived,
        closeModal: (e) => setIsOpenPodReceived(false),
        nextModal: (e) => setContinePodReceived(e),
        modalMainClass: "_dashCommon_Modal _smModal",
        modalTitle: 'POD Received',
        submitTitle: 'POD Received',
        detailsLrData: lrDetailsData
    }

    const apiCallingFunction = async (data) => {
        try {
            const res = await podReceivedData(data);
            if (!!res) {
                setIsOpenPodReceived(false);
                setContinePodReceived(false);
                setLoading(false);
                toast.success(`${res?.responseMessage}`);
            } else {
                setLoading(false);
                setIsOpenPodReceived(false);
                setContinePodReceived(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const closeModalFunction = () => {
        setIsOpenPodReceived(false);
        setContinePodReceived(false);
    }

    const continueOnClickHandler = () => {
        let obj = {
            ...continePodReceived,
            pod_flag: 1
        }
        apiCallingFunction(obj);
    }

    const saveOnClickHandler = () => {
        let obj = {
            ...continePodReceived,
            pod_flag: 0
        }
        apiCallingFunction(obj);
    }

    let continuePodRecievedModalProps = {
        isOpen: continePodReceived,
        closeModal: closeModalFunction,
        modalMainClass: "_dashCommon_Modal _smModal",
        cancelButtonTitle: 'Save',
        modalTitle: 'POD Received',
        submitTitle: 'POD Received',
        footerPart: true,
        submitTitle: loading ? 'Loading...' : 'Continue',
        disabled: loading,
        continueOnClick: continueOnClickHandler,
        saveOnClick: saveOnClickHandler,
    }

    useEffect(() => {
        if (isOpen) {
            getDetailsOfLrData(isOpen);
        }
    }, [isOpenPartyPoint, isOpenPodReceived, continePodReceived, isOpen, isPodStatusEdit])

    const handleLrEditAction = () => {
        nextModal({ ...consignorDetailsData, isEditLr: true, lr_id: lrDetailsData?._id });
    }

    const submitUpdateData = async (data) => {
        try {
            const res = await podStatusUpdateData(data);
            if (!!res) {
                getDetailsOfLrData(isOpen);
                toast.success(`Update successfully`);
                setLoading(false);
                setIsPodStatusEdit(false);
            } else {
                setLoading(false);
                setIsPodStatusEdit(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    let podStatusEditProps = {
        isOpen: isPodStatusEdit,
        closeModal: (e) => setIsPodStatusEdit(false),
        nextModal: (e) => submitUpdateData(e),
        modalMainClass: "_dashCommon_Modal _smModal",
        modalTitle: 'Update POD Status',
        submitTitle: 'Update POD Status',
    }

    let permissionModalProps = {
        isOpen: permissionModal,
        closeModal: (e) => setPermissionModal(!permissionModal),
        modalMainClass: "_dashCommon_Modal _smModal",
        modalTitle: "Permission",
        submitTitle: "Close",
        onSubmit: (e) => setPermissionModal(!permissionModal),
    }

    useEffect(() => {
        if (callDetails && isOpen?.company_id) {
            getDetailsOfLrData(isOpen);
        }
    }, [callDetails])

    const getTodayDate2 = (date) => {
        const today = new Date(date);
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    return (
        <div>

            <div className="offcanvas-header lrd-header">
                <h5 className="offcanvas-title"> {loading ? 'Loading...' : lrDetailsData?.lr_number} <span className={masterData?.lr_status[lrDetailsData?.lr_status] === 'Pending' ? '_lrPending_status' : (masterData?.lr_status[lrDetailsData?.lr_status] === 'In Transit' ? '_lrInTransit_status' : (masterData?.lr_status[lrDetailsData?.lr_status] === 'At Party Point' ? '_lrPartyPoint_status' : ((masterData?.lr_status[lrDetailsData?.lr_status] === 'Delivered' ? '_lrDelivered_status' : (masterData?.lr_status[lrDetailsData?.lr_status] === 'Bill Raised' ? '_lrBillRaised_status' : '')))))}>{masterData?.lr_status[lrDetailsData?.lr_status]} </span> </h5>
                <button type="button" className="btn-close" onClick={() => onClose(!isOpen)}></button>
            </div>


            <nav className='lrNavigate'>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-lrDetail-tab" data-bs-toggle="tab" data-bs-target="#nav-lrDetail" type="button" role="tab" aria-controls="nav-lrDetail" aria-selected="true"> <span>LR Detail</span> </button>

                    <button className="nav-link" id="nav-lrStatus-tab" data-bs-toggle="tab" data-bs-target="#nav-lrStatus" type="button" role="tab" aria-controls="nav-lrStatus" aria-selected="false"> <span>LR Status</span> </button>

                </div>
            </nav>

            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-lrDetail" role="tabpanel" aria-labelledby="nav-lrDetail-tab" tabIndex="0">
                    <div className='offcanvas-body'>
                        {loading ? <div className='p-3'> Loading ... </div> : (lrDetailsData && consignorDetailsData) && <LrDetailsPage lrData={lrDetailsData} permissions={consignorDetailsData} materialCategoryList={materialCategoryList} />}
                    </div>

                    {lrDetailsData?.lr_status <= 5 && <div className='offcanvas-footer'>
                        {
                            (lrPermissions?.permissions?.edit === 1) ?
                                <button className='_addComm_btn _sameWid' disabled={loading} onClick={(e) => handleLrEditAction(e)}>Edit</button>
                                : <button className='_addComm_btn _sameWid' disabled={loading} onClick={(e) => setPermissionModal(!permissionModal)}>Edit</button>
                        }
                        <button className='_cancel_btn _avPointer' onClick={(e) => onClose(false)} >Cancel</button>
                    </div>}
                </div>

                <div className="tab-pane fade" id="nav-lrStatus" role="tabpanel" aria-labelledby="nav-lrStatus-tab" tabIndex="0">
                    <div className='offcanvas-body'>
                        <div className='lrFlow_rls'>
                            <div className='_inrLr_flow'>
                                <ul>
                                    {
                                        lrDetailsData?.lr_process?.length > 0 && lrDetailsData?.lr_process?.map((el, ind) => {
                                            return (
                                                <li className={`lrsCommon ${el?.status === 1 ? 'lrs_active' : ''}`} key={ind}>
                                                    <div className='stepsBx'>
                                                        <div className='stepIcon'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                <circle cx="8.1076" cy="8.1076" r="8.1076" fill="#1450EC" />
                                                                <path d="M3.47461 7.722L6.5637 10.8111L12.7404 4.6329" stroke="white" strokeWidth="1.45609" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>

                                                        <div className='stepInfos'>
                                                            <h4> {el?.name} <span className='_stpDateTime'> | {utils.localDateWithTime(el?.date)} </span> 
                                                            {/* <button className='_editStep'>Edit</button>  */}
                                                            </h4>
                                                            {el?.type === 'intransit' && <div className='_transitDetail'>
                                                                <span className='_stLr_truckNo'> {el?.vehicle_no} </span> <span className='_stmarket'> {el?.vehicle_ownership} Driver</span> <span className='_stLr_truckType'> {el?.lorry_type} </span>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                            <div className='_stpsBtn_bx'>
                                {
                                    lrDetailsData?.lr_status === 1 && (
                                        <button className='_lrdSteps_btn' onClick={() => {
                                            setLrDetialsFrom(lrDetailsData)
                                            navigate('/trip/addtrip/createtrip')
                                        }}> <span>Create Trip</span> </button>
                                    )
                                }
                                {
                                    lrDetailsData?.lr_status === 3 && (
                                        <button className='_lrdSteps_btn' onClick={() => setIsOpenPartyPoint({ name: 'party_point' })}> <span> Mark At Party Point </span> </button>
                                    )
                                }
                                {
                                    lrDetailsData?.lr_status === 4 && (
                                        <button className='_lrdSteps_btn' onClick={() => setIsOpenPartyPoint({ name: 'delivered' })}> <span> Mark Delivered </span> </button>
                                    )
                                }
                                {
                                    lrDetailsData?.lr_status === 5 && (
                                        <button className='_lrdSteps_btn'> <span> Raise Bill </span> </button>
                                    )
                                }
                            </div>
                        </div>

                        <div className='podFlow_rls'>
                            <div className='_inrPod_flow'>
                                <div className='_podHead'><p>POD Status</p> <span className={lrDetailsData?.pod_status === 1 ? '_pendingStat' : '_receivedStat'}>{masterData?.pod_status[lrDetailsData?.pod_status]} </span></div>
                                {/* <div className='_podHead mt-2'><p> {getTodayDate2(lrDetailsData?.pod_date)} </p> {lrDetailsData?.pod_status > 1 && <span onClick={(e) => setIsPodStatusEdit({ ...lrDetailsData })}> Edit </span>} </div> */}
                                {/* <div className='_podHead mt-2'><p> {utils.firstLetterCapital(lrDetailsData?.pod_remark)} </p> </div> */}
                                {/* <div className='d-flex'>
                                    {
                                        lrDetailsData?.pod_file?.length > 0 && lrDetailsData?.pod_file?.map((el, ind) => {
                                            return (
                                                <div key={ind} className='hgt_wdt_avt mt-2'>
                                                    <img src={`${masterData?.s3_url}${el?.path}`} alt={ind} />
                                                </div>
                                            )
                                        })
                                    }
                                    {loading ? <div className='flex_div mt-2'> Loading... </div> : <div className='flex_div'>
                                        <div className='singleDiv mt-2'>
                                            <input
                                                type="file"
                                                name='file'
                                                id='_file'
                                                hidden
                                            // onChange={(e) => handleFileChange(e)}
                                            />
                                            <label htmlFor='_file'> &nbsp; <RiAddLargeFill className='_avPointer' /> </label>
                                        </div>
                                    </div>}

                                </div> */}
                            </div>

                            {(lrDetailsData?.pod_status === 1 && lrDetailsData?.lr_status >= 3) &&
                                <div className='_stpsBtn_bx'>
                                    <button className='_podSteps_btn' onClick={(e) => setIsOpenPodReceived({ ...lrDetailsData })}> <span> POD Received </span> </button>
                                </div>
                            }
                            {
                                lrDetailsData?.pod_status > 1 &&
                                <div className='_stpsBtn_bx'>
                                    <button className='_podSteps_btn' onClick={(e) => setIsPodStatusEdit({ ...lrDetailsData })}> <span> Edit POD Received </span> </button>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>

            {
                isOpenPartyPoint?.name
                && (
                    <AtPartyPointModal {...partyPointModalProps} />
                )
            }

            {
                isOpenPodReceived?._id
                && (
                    <PodRecievedModal {...podRecievedProps} />
                )
            }

            {
                continePodReceived?.date
                && (
                    <ContinuePodRecievedModal {...continuePodRecievedModalProps} />
                )
            }

            {
                isPodStatusEdit?._id
                && (
                    <EditPodStatus {...podStatusEditProps} />
                )
            }
            {
                permissionModal
                && (
                    <PermissionModal {...permissionModalProps} />
                )
            }

        </div >
    )
}

export default React.memo(LrDetailModal)