import { profitLossReportList } from '_services/reportService/reportService'
import { CommonDateRange, Heading } from 'components'
import React, { useEffect, useState } from 'react'
import moment from "moment";
import StorageService from 'utils/StorageServices';
import Loading from 'pages/Loading';

const ProfitLossReport = () => {

  const [profitLossData, setProfitLossData] = useState([])
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  const [selectedRange, setSelectedRange] = useState({
    startDate: moment(firstDay).subtract(31, 'days'),
    endDate: moment(lastDay),
  });


  const [filter, setFilter] = useState({
    limit: 100,
    offset: 1,
  });

  useEffect(() => {
    getProfitLossReportList()
  }, [filter])

  const getProfitLossReportList = async () => {
    setLoading(true)
    const payload = {
      ...filter,
      company_id: StorageService.getCompanyId(),
      start_date: moment(selectedRange.startDate).format("YYYY-MM-DD"),
      end_date: moment(selectedRange.endDate).format("YYYY-MM-DD"),
    }
    try {
      const res = await profitLossReportList(payload);
      if (res?.responseCode == 200) {
        setProfitLossData(res?.data)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const handleApply = (newRange) => {
    setSelectedRange(newRange);
    setFilter((s) => ({
      ...s,
      offset: 1,
    }));
  };

  const resetFunction = () => {
    const newStartDate = moment(firstDay).subtract(31, 'days');
    const newEndDate = moment(lastDay);

    setSelectedRange({
      startDate: newStartDate,
      endDate: newEndDate,
    });

    setFilter((s) => ({
      ...s,
      offset: 1,
    }));
  };

  return (
    <>
      <Heading title="Profit and Loss Report" />
      <div className='_mrktLoad_mainBx __main_p_n_l'>
        <div className='_mrktFilt_mainBx'>
          <div className='_mrktInfilts_bx' style={{ minHeight: "100%" }}>
            <div class="head_load" ><span class="search_loads"> Filters </span></div>

            <div className='_optFilts'>
              <div style={{ marginBottom: '12px' }}>
                <CommonDateRange
                key={`${selectedRange.startDate}-${selectedRange.endDate}`}
                  initialStartDate={selectedRange.startDate}
                  initialEndDate={selectedRange.endDate}
                  onApply={handleApply}
                />
              </div>

              <button className='reSet_btn_lg' onClick={(e) => resetFunction(e)}>Reset</button>
            </div>
          </div>
        </div>
        {loading ? <div style={{position: 'relative', width: '100%'}}><Loading/></div> : 
        
        <div className='_pNl_listBx'>
          <div className='_pNl_list_otrBx'>
            <h4>Profit and Loss Report</h4>
            <p>{`From ${moment(selectedRange.startDate).format("DD-MMM-YYYY")} - ${moment(selectedRange.endDate).format("DD-MMM-YYYY")}`}</p>

            <div className='_pNl_list_inrBx'>
              <div className='__pNlFlx' style={{ marginBottom: '16px' }}>
                <span>Own/ Leased Vehicle Trips</span> <span>{profitLossData?.own_leased_revenue?.total_trips}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Revenue</span> <span>₹ {profitLossData?.own_leased_revenue?.total_party_freight}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Trip Charges</span> <span>₹ {profitLossData?.own_leased_revenue?.total_truck_freight}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Claims</span> <span>₹ {profitLossData?.own_leased_revenue?.claims}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Other Charges</span> <span>₹ {profitLossData?.own_leased_revenue?.other_charges}</span>
              </div>

              <div className='__pNlFlx __plTotalBl' style={{ marginBottom: '12px' }}>
                <span>Trip Profit</span> <span>₹ {profitLossData?.own_leased_revenue?.total_profit_loss}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Servicing</span> <span>₹ {profitLossData?.own_leased_revenue?.servicing}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Documents</span> <span>₹ {profitLossData?.own_leased_revenue?.documents}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Salary</span> <span>₹ {profitLossData?.own_leased_revenue?.salary}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Fastag (Not Associated)</span> <span>₹ {profitLossData?.own_leased_revenue?.fastag}</span>
              </div>

              <div className='__pNlFlx __plTotalBl' style={{ marginBottom: '12px' }}>
                <span>Profit from Own/ Leased (A)</span> <span>₹ {profitLossData?.own_leased_revenue?.profit_A}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '16px' }}>
                <span>Market Vehicle Trips</span> <span>{profitLossData?.market_revenue?.total_trips}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Revenue</span> <span>₹ {profitLossData?.market_revenue?.total_party_freight}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Trip Freight</span> <span>₹ {profitLossData?.market_revenue?.total_truck_freight}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Claims</span> <span>₹ {profitLossData?.market_revenue?.claims}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Other Charges</span> <span>₹ {profitLossData?.market_revenue?.other_charges}</span>
              </div>

              <div className='__pNlFlx __plTotalBl' style={{ marginBottom: '12px' }}>
                <span>Trip Profit Market (B)</span> <span>₹ {profitLossData?.market_revenue?.profit_B}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Expenses</span> <span></span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Office Expenses</span> <span>₹ {profitLossData?.expenses?.office_expenses}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Salary</span> <span>₹ {profitLossData?.expenses?.salary}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Claims</span> <span>₹ {profitLossData?.expenses?.claims}</span>
              </div>

              <div className='__pNlFlx' style={{ marginBottom: '10px' }}>
                <span>Other Charges</span> <span>₹ {profitLossData?.expenses?.other_charges}</span>
              </div>

              <div className='__pNlFlx __plTotalBl' >
                <span>Total Expenses (C)</span> <span>₹ {profitLossData?.expenses?.total_expenses}</span>
              </div>

              <div className='__pNlFlx __plTotalYl' >
                <span>Profit & Loss (A+B-C)</span> <span>₹ {profitLossData?.profit_and_loss}</span>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </>
  )
}

export default ProfitLossReport