const API = {
  // Auth
  SEND_OTP: `/user/send_otp`,
  LOGOUT:`user/logout`,
  VERIFY_OTP: `user/verify_otp`,
  CREATE_ADMIN: "user/create_admin",
  CREATE_COMAPNY: "company/create_company",
  GET_MASTER_DATA: `common/get_master_data`,
  GET_LORRY_TYPE: `/settings/get_lorry_type`,
  GET_COMPANY: `user/get_dashboard_data`,
  CONSIGNOR_DATA: `account/`,
  CONSIGNOR_GROUP_DATA: `consignor_group/get_consignor_group`,
  GET_MASTER_ROLE: "menu/add_role_master_data",
  ADD_ROLE: "role/create_role",
  ROLE: `role/get_role`,
  ROLE_DELETE: "role/delete_role",
  CONSIGNOR_GROUP: "consignor_group/get_consignor_group",
  ADD_USER: "user/add_user_to_company",
  USER: `user/get_company_users`,
  DELETE_USER: "user/delete_user",
  SETTINGS_DATA: `settings/`,
  MENU: "menu/get_allowed_menus",
  LORRY_RECEIPT: `lr/`,
  LR_DEAULT_COLUMN: "common/reset_user_lr_columns",
  GET_BRANCH: "branch/",
  ROLE_DETAILS: `role/get_role_detail`,
  ROLE_UPDATE: "role/update_role",
  COMMON_DROPDOWN: `common/`,
  ALL_ACCOUNT: `chart_account/get_chart_account`,
  ALL_ROUTE: `route/get_route`,
  ALL_VEHICLE_NUMBER: "common/get_vehicle_for_dropdown",
  ALL_DRIVERS: "common/get_driver_for_dropdown",
  ALL_SUPPLIER: "common/get_supplier_for_dropdown",
  ADD_ROUTE: `route/add_route`,
  ALL_LR: `lr/apk_lr_list`,
  ALL_LR: `lr/lr_list`,
  ADD_TRIP: `trip/add_trip`,
  TRIP: `trip/trip_list`,
  MATERIAL_DATA: `material/`,
  DISPATCH_TRIP: `trip/get_trip`,
  TOTAL_FREIGHT: `dispatch/add_total_freight`,
  TRANSACTION: `dispatch/trip_transaction_list`,
  UPLOAD_PROFILE_IMAGE: `user/upload_user_avatar`,
  TRIP_SERVIES: `trip/`,
  FREIGHT_TYPE: `common/get_master_category`,
  BANK_LIST: `account/get_bank_account`,
  CHART_OF_ACCOUNT: `chart_account/get_account`,
  ACCOUNT_LIST: `account/get_bank_account`,
  ADD_BANK_ACCOUNT: `account/add_bank_account`,
  UPDATE_USER_LR_COLUMNS: `common/update_user_lr_columns`,
  ADD_ADV_FREIGHT: `dispatch/create_trip_transaction`,
  GET_USER_ACCOUNT: `chart_account/get_account_for_user`,
  TOTAL_FREIGHT_DETAILS: `dispatch/get_total_freight`,
  EDIT_TOTAL_FREIGHT: `dispatch/update_total_freight`,
  ALL_CITIES_API:`csc/get_citys`,
  ALL_STATE_API:`csc/get_country_states`,
  CREATE_ROUTE:`company/update_company_route`,
  TRIP_TRANSACTION:`dispatch/trip_transactions`,
  TRIP_REVENUE:`dispatch/trip_revenue`,
  //tesing api
 
  LR_LIST_TRIP: `trip/apk_get_trip_lr`,
  EXPENSE: `dispatch/get_expense_category`,
  USER_ACCOUNT: `user/get_account_from_user`,
  LORRY_RECEIPT_COUNT: `lr/get_lr_count`,
  ACCOUNTING_GET_USER: `user/get_accounting_from_user`,
  CHART_EXPENSE:`chart_account/get_expense_category`,
  COMMON_EXPENSE:`chart_account/get_expense_category`,
  CHART_RECIPT:`chart_account/get_receipt_account`,
  ADVANCE_DRIVER_CAT:`chart_account/get_driver_advance_category`,
  // supplier
  CREATE_SUPPLIER: `account/apk_add_supplier`,
  EDIT_SUPPLIER: ``,
  DELETE_SUPPLIER: `account/delete_supplier`,
  GET_SUPPLIER_LIST: `account/get_supplier_list`,
  GET_SINGLE_SUPPLIER: `account/get_supplier`,
  CHANGE_SUPPLIER_STATUS: `account/change_supplier_status`,
  // unit
  CREATE_UNIT: `account/apk_add_unit`,
  EDIT_UNIT: ``,
  DELETE_UNIT: `account/delete_unit`,
  GET_UNIT_LIST: `account/get_unit_list`,
  GET_SINGLE_UNIT: `account/get_unit`,
  CHANGE_STATUS_OF_UNIT: `account/change_unit_status`,
  // consignee
  CREATE_CONSIGNEE: `account/apk_add_consignee`,
  EDIT_CONSIGNEE: ``,
  DELETE_CONSIGNEE: `account/delete_consignee`,
  GET_CONSIGNEE_LIST: `account/get_consignee_list`,
  GET_SINGLE_CONSIGNEE: `account/get_consignee`,
  CHANGE_CONSIGNEE_STATUS: `account/change_consignee_status`,
  // vendor
  CREATE_VENDOR: `account/add_vendor`,
  EDIT_VENDOR: ``,
  GET_VENDOR_LIST: `account/get_vendor_list`,
  GET_SINGLE_VENDOR: `account/get_vendor`,
  DELETE_VENDOR: `account/delete_vendor`,
  CHANGE_VENDOR_STATUS: `account/change_vendor_status`,
  // branch
  CREATE_BRANCH: `branch/apk_add_branch`,
  UPDATE_BRANCH: `branch/update_branch`,
  EDIT_BRANCH: ``,
  GET_BRANCH_LIST: `branch/get_branch_list`,
  GET_SINGLE_BRANCH: `branch/get_branch`,
  DELETE_BRANCH: `branch/delete_branch`,
  CHANGE_BRANCH_STATUS: `branch/change_branch_status`,

  // fuel station
  CREATE_FUEL_STATION: `account/add_fuelstation`,
  EDIT_FUEL_STATION: ``,
  GET_LIST_FUEL_STATION: `account/get_fuelstation_list`,
  SINGLE_FUEL_STATION: `account/get_fuelstation`,
  DELETE_FUEL_STATION: `account/delete_fuelstation`,
  CHANGE_FUEL_STATION_STATUS: `account/change_fuelstation_status`,
  USER_ACCOUNT: `user/get_account_from_user`,
  LORRY_RECEIPT_COUNT: `lr/get_lr_count`,
  ACCOUNTING_GET_USER: `user/get_accounting_from_user`,
  GET_FUEL_STATEMENT: `account/get_fuel_statement`,
  // contract
  CREATE_CONTRACT: `account/add_contract`,
  EDIT_CONTRACT: `account/update_contract`,
  DELETE_CONTRACT: `account/delete_contract`,
  GET_CONTRACT_LIST: `account/get_contract_list`,
  GET_SINGLE_CONTRACT: `account/get_contract`,
  CONTRACT_STATUS_CHANGES: `account/change_contract_status`,
  ACCOUNTING_TRANSACTION: `dispatch/accounting_transaction_list`,
  // own driver
  CREATE_OWN_DRIVER: `account/apk_add_own_driver`,
  EDIT_OWN_DRIVER: ``,
  GET_OWN_DRIVER_LIST: `account/get_driver_list`,
  GET_DRIVER_DETAILS: `account/get_driver`,
  CHANGE_DRIVER_STATUS: `account/change_driver_status`,
  DELETE_OWN_DRIVER: `account/delete_driver`,
  PAYMENT: `dispatch/payment_approval_list`,
  PAYMENT_COUNT:`dispatch/get_payment_approval_count`,
  ADD_CONSIGNOR_CLAIM: `dispatch/create_consignor_transaction`,
  // consignor Contract Rates
  CREATE_RATE: `account/add_contract_rates`,
  EDIT_RATE: ``,
  DELETE_RATE: `account/delete_contract_rates`,
  GET_RATE_LIST: `account/get_contract_rate_list`,
  GET_SINGLE_RATE: `account/get_contract_rates`,
  RATE_STATUS_CHANGES: `account/change_contract_rates_status`,
  // accounting chart of account
  CREATE_CHART_ACCOUNT: `chart_account/create_chart_of_account`,
  EDIT_CHART_ACCOUNT: `chart_account/update_chart_of_account`,
  DELETE_CHART_ACCOUNT: `chart_account/delete_chart_of_account`,
  GET_CHART_ACCOUNT_LIST: `chart_account/get_chart_account`,
  GET_SINGLE_CHART_ACCOUNT: `chart_account/get_chart_account`,
  GET_DROPDOWN_CHART_ACCOUNT: `chart_account/get_chart_of_account_type`,
  CONSIGNOR_TRAN: `dispatch/consignor_transactions`,
  // zone
  CREATE_ZONE: `settings/add_zone`,
  EDIT_ZONE: ``,
  GET_ZONE_LIST: `settings/get_zone_list`,
  GET_SINGLE_ZONE: `settings/get_zone`,
  DELETE_ZONE: `settings/delete_zone`,
  UPDATE_APPROVE: `dispatch/update_payment_approval`,
  // consignor Group
  CREATE_CONSIGNOR_GROUP: `consignor_group/add_consignor_group`,
  EDIT_CONSIGNOR_GROUP: ``,
  GET_CONSIGNOR_GROUP_LIST: `consignor_group/get_consignor_group_list`,
  GET_SINGLE_CONSIGNOR_GROUP: `consignor_group/get_single_consignor_group`,
  DELETE_CONSIGNOR_GROUP: `consignor_group/delete_consignor_group`,
  // vehicle Group
  CREATE_VEHICLE_GROUP: `settings/add_vehicle_group`,
  EDIT_VEHICLE_GROUP: ``,
  GET_VEHICLE_GROUP_LIST: `settings/get_vehicle_group_list`,
  GET_SINGLE_VEHICLE_GROUP: `settings/get_vehicle_group`,
  DELETE_VEHICLE_GROUP: `settings/delete_vehicle_group`,
  // ledget statement
  GET_LEDGER_STATEMENT: `account/get_account_statement`,
  // Invoice Bill raise
  CREATE_INVOICE_BILL_RAISE: `billraise/create_billraise`,
  GET_INVOICE_BILL_RAISE_LIST: `billraise/billraised_list`,
  GET_DETAILS_INVOICE_BILL_RAISE: `billraise/get_billraised_lr`,
  GET_SUMMARY_DETAILS: `billraise/get_billraised`,
  GET_INVOICE_BILL_RAISE_SUMMARY: `billraise/get_summary_for_billraise`,
  GET_LR_FOR_INVOICE_BILL_RAISE: `billraise/get_lr_for_billraise`,
  CREATE_INVOICE_BILL_RAISE_TEMPLATE: `common/create_LRtemplate`,
  UPDATE_LR_INVOICE_BILL_RAISE: `common/update_template_lr_columns`,
  RESET_LR_INVOICE_BILL_RAISE: `common/reset_template_lr_columns`,
  //
  ADD_FUEL_TRAN: `dispatch/create_fuel_transaction`,
  END_TRIP: `trip/apk_end_trip`,
  LAST_TRIP: `trip/get_last_trip`,
  BUILTY: `lr/get_bilty`,
  TRIP_COUNT: `trip/get_trip_count`,

  // Add Driver Transaction
  ADD_DRIVER_TRANSACTION: `dispatch/create_own_trip_transaction`,
  ADD_EWAY_WILL: `lr/add_ewaybill_to_lr`,
  USER_PERMISSION: `user/get_setting_from_user`,
  USER_STATUS: `user/change_user_status_to_company`,
  DELETE_USER: `user/delete_user_to_company`,
  GET_SINGLE_USER: `user/get_setting_from_user`,
  USER_DETAILS: `user/get_company_user_detail`,
  GET_ADD_USER_BRANCH: `branch/get_branch_for_user`,
  EDIT_USER: `user/update_user_to_company`,
  //Vehicle Group

  VEHICLE_GROUP: `common/get_vehicle_group_for_dropdown`,

  // Main Dasboard
  GET_DASHBOARD_COUNT: `dashboard/get_dashboard_count`,
  VEHICLE_GROUP: `common/get_vehicle_group_for_dropdown`,
  // lr
  LR_LIST: `lr/lr_list`,
  PLAN: `plan/`,
  ACCOUNT: `account/`,
  FUEL_CARD:`fuelcard/`,
  FASTAG:`fastag/`,

  //address_book
  GET_ADDRESS_BOOK: `account/addressbook_list`,
  CREATE_ADDRESS_BOOK: `account/add_addressbook`,
  DELETE_ADDRESS_BOOK: `account/delete_addressbook`,
  GET_ADDRESS_ASSOCIATE: `common/get_associate_party`,
  GET_FUEL_STATION_DROP: `common/get_fuelstation_for_dropdown`,
  CONTRACT_RATE: `dispatch/get_contract_rates`,
  GET_ADDTESS_BOOK_DETAILS: `account/get_addressbook`,
  GET_ADDRESS_BOOK: `account/addressbook_list`,
  CREATE_ADDRESS_BOOK: `account/add_addressbook`,
  GET_ADDRESS_ASSOCIATE: `common/get_associate_party`,
  GET_FUEL_STATION_DROP: `common/get_fuelstation_for_dropdown`,
  CONTRACT_RATE: `dispatch/get_contract_rates`,
  UPDATE_ADDRESS: `account/update_addressbook`,

  //connections services
  GET_CONNECTION_COUNT: `connection/count`,
  GET_SUGGESTION_LIST: `connection/suggestion_list`,
  GET_CONNECTION_LIST: `connection/connection_list`,
  SEND_REQUEST_CONNECTION: `connection/sent_connection_request`,
  WITHDRAWAL_CONNECTION_REQUEST: `connection/withdrawal_request`,
  REJECT_CONNECTION_REQUEST: `connection/reject_request`,
  REMOVE_CONNECTION_REQUEST: `connection/remove_request`,
  ACCEPT_CONNECTION_REQUEST: `connection/accept_request`,
  MARKET_VISIBLITY_LIST: `connection/get_lists`,

  GET_CONNECTION_LISTS: `connection/get_lists`,
  ADD_CONNECTION_LISTS: `connection/add_list`,
  GET_RATINGS_REVIEW_LISTS: `connection/rating_list`,
  ADD_RATINGS_REVIEW: `connection/add_rating`,

  //Inden Service

  CREATE_INDENT: `plan/add_plan`,
  MATERIAL_LIST: `material_type/get_material_type`,
  INDENT_LIST: `plan/plan_list`,
  INDENT_COUNT: `plan/get_plan_count`,
  INDENT_DETAILS_API: `plan/get_plan`,
  INDENT_UPDATE: `plan/update_plan`,
  CLOSE_INDENT: `plan/plan_closed`,
  DISPATCH_INDENT: `plan/plan_dispatch`,
  DELETE_INTENT:`plan/delete_plan`,
  // Market Loads Service

  GET_MARKET_LOAD_LIST: `plan/market_load`,
  CREATE_BID_MARKET_LOAD: `bid/create_bid`,
  UPDATE_BID_MARKET_LOAD: `bid/update_bid`,
  GET_BID_LIST: `bid/bid_list`,
  GET_BID_COUNT: `bid/count`,
  CHANGES_BID_STATUS: `bid/change_bid_status`,
  UPDATE_MARKET_VISIBLITY:`plan/update_market_visibility`,
  // market load mark favorite unfavorite filters
  GET_FAVORITE_LOCATION: `plan/get_favorite_location`,
  SAVE_FAVORITE_LOCATION: `plan/save_favorite_location`,
  RECENT_SEARCH_DATA: `plan/recent_search_list`,

  //account

  CASH_ACCOUNT:`chart_account/add_cash_account`,
  BANK_ACCOUNT:`chart_account/add_bank_account`,
  OTHER_ACCOUNT:`chart_account/add_other_account`,
  FUEL_ACCOUNT:`chart_account/add_fuelcard_account`,
  FASTAG_ACCOUNT:`chart_account/add_fastag_account`,
  ADD_EXPENSE_ACCOUNT:`dispatch/create_accounting_transaction`,
  FUEL_CARD_MASTER:`fuelcard/fc_master_list`,
  ADD_FUEL_CARD_MASTER:`fuelcard/add_fc_master`,
  FUEL_CARD_ACCOUNT_LIST:`fuelcard/get_fc_account`,
  ADD_DRIVER_FROM_TRIP:`trip/add_driver_to_trip`,
  CREATE_FUEL_OUT:`dispatch/create_fuelout_transaction`,
  ADD_EXPENSE_API:`chart_account/add_expense_category`,
  GET_FUEL_RATE:`account/get_fuel_average_rate`,
  DELETE_TRANSACTION:`dispatch/delete_transaction`,
  VENDER_DROPDOWN:`common/get_vendor_for_dropdown`,



  //Report
  PROFIT_AND_LOSS:`reports/profit_and_loss`,
  OWN_VEHICLE_PROFITABILITY:`reports/own_vehicle_profitability`,
  TRIP_DETAILS_REPORT: `reports/trip_report/in_excel`,
  TRIP_REVENUE_REPORT: `reports/get/revenue_report/excel_sheet`,

  TRANSFER_FUND:`accounting/fund_transfer`,
  CREATE_FUEL_OWN:`dispatch/add_fuel_to_truck`,

  // staff
  CREATE_STAFF: `account/add_staff`,
  EDIT_STAFF: `account/edit_staff`,
  DELETE_STAFF: `account/delete_staff`,
  GET_STAFF_LIST: `account/get_staff_list`,
  GET_SINGLE_STAFF: `account/get_staff`,
  CHANGE_STAFF_STATUS: `account/change_staff_status`,
  GET_ROLE_MASTERS:`menu/get_permission_from_subscription`,
  GET_SINGLE_PLAN_SUBSCRIPTION:``,
  MENU_2:`menu/get_allowed_menus2`,
  GET_CITIES: `csc/get_state_cities`,
  GET_COUNTRY_STATES: `csc/get_country_states`,

  //fasttag
  ADD_FASTAG_MASTER:`fastag/add_ft_master`,
  GET_FASTAG_MASTER:`fastag/ft_master_list`,
  ADD_FASTAG_ACCOUNT:`fastag/add_ft_account`,
  ADD_FUEL_CARD_ACCOUNT:`fuelcard/add_fc_account`,

  GET_FUEL_COMPANY_DROPDOWN:`fuelcard/get_fc_company_for_dropdown`,
  ADD_FUEL_COMPANY : `fuelcard/add_fc_company`,
  FUEL_TXN_LIST:`fuelcard/get_fc_txn`,
  GET_FASTAG_COMPANY_DROPDOWN:`fastag/get_ft_company_for_dropdown`,
  GET_FASTAG_MASTER_DROPDOWN:`fastag/ft_master_list`,
  FUEL_CARD_ACCOUNT_DROPDOWN:`fuelcard/get_fc_account_for_dropdown`,
  GET_FASTAG_ACCOUNT:`fastag/get_ft_account`,
  FASTAG_TXN_LIST:`fastag/fetch_ft_txn`,
};

export default {
  ...API,
};
