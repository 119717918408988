import React, { useState, useRef } from 'react'
import { ButtonHeading, ConsigneeDetailsTab, VehicleDetailsTab } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import LoadingBar from 'react-top-loading-bar';
import FuelStatement from '../FuelStatement';
import { useParams } from 'react-router-dom';
import VehicleDocuments from '../VehicleDocuments';
import { AddFuelModal, AddFuelOwn } from 'modals';

const VehicleDetails = () => {
    let { id } = useParams();
    const [activeTab, setActiveTab] = useState(1);
    const loadingBarRef = useRef(null)
    const [getData, setGetData] = useState(null)
    const [isOpenFuel, setIsOpenFuel] = useState(false);
    const [addFuel,setAddFuel] = useState(0)
    const tabProps = {
        tabList: constant.vehicle_DetailsTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab,
       
    }

    const handleDataFetch = (data) => {
        setGetData(data)
        setAddFuel("")
    }

    const toggleOffcanvas = () => {
        setIsOpenFuel(!isOpenFuel);
       
    };

    const callbackTotal = ()=>{
        setAddFuel((prev) => Number(prev) + 1);
      
    }

    console.log("dfdf",addFuel)
    let fuelProps = {
        isOpen: isOpenFuel,
        closeModal: (e) => setIsOpenFuel(false),
        headerPart: true,
        footerPart: true,
        modalMainClass: "_dashCommon_Modal _smModal",
        modalTitle: "Add Fuel",
        submitTitle: "Send for Approval",
        getData:getData,
        callBackFreight: callbackTotal,
        
      };

    return (
        <div className=''>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <ButtonHeading title={getData?.vehicle_no ? getData?.vehicle_no : 'Loading...'} buttonTitle={getData?.vehicle_ownership!=2?"Add Fuel":""}  onClick={toggleOffcanvas}/>

            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && <VehicleDocuments onDataFetch={handleDataFetch} loadingBarRef={loadingBarRef}/>}
                
                {activeTab === 2 && (
                    <VehicleDetailsTab onDataFetch={handleDataFetch} loadingBarRef={loadingBarRef} addFuel={addFuel} />
                )}

                {activeTab === 3 && <FuelStatement onDataFetch={handleDataFetch} addFuel={addFuel}/>}

                {isOpenFuel && <AddFuelOwn {...fuelProps} />}

            </div>

        </div>
    )
}

export default VehicleDetails;