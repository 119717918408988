import { getStatesData } from '_services';
import { CommonInput, CommonModal, CustomSelect, MapInput } from 'components'
import { Images } from 'constants';
import { UserContext } from 'context';
import { State } from 'country-state-city';
import { addRoute } from 'features';
import { errorSchema, utils } from 'helper';
import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import StorageService from 'utils/StorageServices/StorageServices';

const AddRouteModal = (props) => {
    const {
        masterData,
        cities,
        setCommonError,
        commonError,
        setShowCreateCompanyModal,
        setUserData,
        setCities,setMasterData,
        userDetails,
        showCreateCompanyModal,
        setToken,
        userData
    
      } = useContext(UserContext);

    const initiFrom = {
        from_city: "",
        from_state: "",
        lat: "",
        long: "",
      };
    
      const initiTo = {
        to_city: "",
        to_state: "",
        lat: "",
        long: "",
       
      };

  const dispatch = useDispatch();
  const [fromLoction, setFromLoction] = useState([initiFrom]);
  const [toLoction, setToLoction] = useState([initiTo]);
  const [fromlocationValue, setFromLocatonValue] = useState([""]);
  const [tolocationValue, setToLocatonValue] = useState([""]);
  const [formError, setFormError] = useState("");
  const [loading,setLoading] = useState(false)
  const [common, setCommon] = useState({
    company_id:StorageService.getCompanyId(),
    route_name:"",
    route_code:"",
    from_location:[],
    to_location:[]
})


useEffect(()=>{
  if(fromLoction[0].from_city && toLoction[0].to_city){
    const fromCity = fromLoction.map((i)=>i.from_city).join(" + ")
    const toCity = toLoction.map((i)=>i.to_city).join(" + ")
    setCommon({...common,route_name:`${fromCity} - ${toCity}`})
  }

},[fromLoction,toLoction])

const handleSave = async() => {
  const validationResult = await utils.checkFormError(
    {from_location:fromLoction,to_location:toLoction,route_name:common?.route_name,route_code:common?.route_code}, errorSchema.addRouteValidation

  );
    common.from_location = fromLoction;
    common.to_location = toLoction;
    setCommon({ ...common });

    let payload = common;

    try {
      if (validationResult === true) {
        setLoading(true)
        const res = await dispatch(addRoute(payload));
        if (res?.payload?.responseCode === 200) {
          props?.callbackSelectRoute(res?.payload?.data?.route)
          toast.success(res?.payload?.responseMessage);
         props?.closeModal()
         setLoading(false)
        } else {
          toast.error(res?.payload?.responseMessage);
          setLoading(false)
        }
      }else{
        setFormError(validationResult);
      }
      } catch (error) {
        console.log(error);
      }

 
}

const handleOnChange = async (name, value) => {
    const stateObj = { ...common, [name]: value };

    setCommon(stateObj);
    if (!!formError) {
      const validationResult = await utils.checkFormError({route_name:common?.route_name,route_code:common?.route_code}, errorSchema.addRouteValidation);
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
};

const handleInputChange = async(
    value,
    index,
    label,
    tableIndex
  ) => {
    if (label == "Pickup Location") {

      let stateDetails;

      let data = {
        countryCode : value?.countryCode,
        stateCode : value?.stateCode
      }
      let response = await getStatesData(data);
      if (response?.responseCode === 200) {
        stateDetails = response?.data[0]
      }

        // const stateDetails = State.getStateByCodeAndCountry(
        //     value?.stateCode,
        //     value?.countryCode
        //   );
      const NewFromLocation = [...fromLoction];
      const NewInputLocation = [...fromlocationValue];
      NewFromLocation[index].from_city = value.name;
      NewFromLocation[index].from_state = stateDetails.name;
      NewFromLocation[index].lat = value?.latitude;
      NewFromLocation[index].long = value?.longitude;
      setFromLocatonValue(NewInputLocation);
      setFromLoction(NewFromLocation);

      if (!!formError) {
        const validationResult = await utils.checkFormError({from_location:fromLoction}, errorSchema.addRouteValidation);
        if (validationResult === true) {
          setFormError("");
        } else {
          setFormError(validationResult);
        }
      }
    }

    if (label === "Drop Location") {
      let stateDetails;

      let data = {
        countryCode : value?.countryCode,
        stateCode : value?.stateCode
      }
      let response = await getStatesData(data);
      if (response?.responseCode === 200) {
        stateDetails = response?.data[0]
      }
      
      // const stateDetails = State.getStateByCodeAndCountry(
      //     value?.stateCode,
      //     value?.countryCode
      //   );

      const NewToLocation = [...toLoction];
      const NewInputLocation = [...tolocationValue];
      NewToLocation[index].to_city = value.name;
      NewToLocation[index].to_state = stateDetails.name;
      NewToLocation[index].lat = value?.latitude;
      NewToLocation[index].long = value?.longitude;
      setToLocatonValue(NewInputLocation);
      setToLoction(NewToLocation);

      if (!!formError) {
        const validationResult = await utils.checkFormError({to_location:toLoction}, errorSchema.addRouteValidation);
        if (validationResult === true) {
          setFormError("");
        } else {
          setFormError(validationResult);
        }
      }
    }
  };

  const handleInputChange2 = (e, index, label) => {
    if (label === "From City") {
      if (!e.target.value) {
        const NewFromLocation = [...fromLoction];
        NewFromLocation[index] = initiFrom;
        setFromLoction(NewFromLocation);
      }

      const NewInputLocation = [...fromlocationValue];
      NewInputLocation[index] = e.target.value;
      setFromLocatonValue(NewInputLocation);
    }

    if (label === "To City") {
      if (!e.target.value) {
        const NewFromLocation = [...toLoction];
        NewFromLocation[index] = initiTo;
        setToLoction(NewFromLocation);
      }

      const NewInputLocation = [...tolocationValue];
      NewInputLocation[index] = e.target.value;
      setToLocatonValue(NewInputLocation);
    }
    
  };

  const addFromInput = (e) => {
    e.preventDefault();
    
    setFromLoction((preState) => {
      return [...preState, initiFrom];
    });
    
    setFromLocatonValue((preState) => {
      return [...preState, ""];
    });
  };

  const addToInput = (e) => {
    e.preventDefault();
    
    setToLoction((preState) => {
      return [...preState, initiTo];
    });
    
    setToLocatonValue((preState) => {
      return [...preState, ""];
    });
  };

  const removeFromInput = (index) => {
    const newFromIput = [...fromLoction];
    const NewInputLocation = [...fromlocationValue];

    newFromIput.splice(index, 1);
    NewInputLocation.splice(index, 1);

    setFromLoction(newFromIput);
    setFromLocatonValue(NewInputLocation);
  };

  const removeToInput = (index) => {
    const newFromIput = [...toLoction];

    const NewInputLocation = [...tolocationValue];

    newFromIput.splice(index, 1);
    NewInputLocation.splice(index, 1);

    setToLoction(newFromIput);
    setToLocatonValue(NewInputLocation);
  };

  const handleRouteInhance = {
    ...props,
    onSubmit: handleSave,
    isLoadding:loading,
    disabled:loading?true:false
  }
  

    return (
        <div>
            <CommonModal {...handleRouteInhance}>
                <div className='_crtRoute_body'>
                    <div className='pdlLx_bx'>
                    {fromLoction?.map((data, index1) => {
                        return (
                        <>
                        <div className='_addmores_bx' key={`route_${index1}`}>
                            <div className='singleDiv _fsFlex'>
                                <CustomSelect
                                label="Pickup Location"
                                 component_class="vehicle_number_trip"
                                options={cities}
                                dynamicIndex={index1}
                                onSelect={handleInputChange}
                                value={{
                                  label: data?.from_city
                                    ? `${data?.from_city}, ${data?.from_state}`
                                    : "",
                                  value: `${data?.from_city}`,
                                }}
                                error={`${formError[`from_location[${index1}].from_city`]}` !== "undefined" ? `${formError[`from_location[${index1}].from_city`]}` : ""}
                                />
                         {fromLoction?.length > 1 && (<button className='_del_pl' onClick={()=>removeFromInput(index1)}><img src={Images.DELETEICON.default} alt="" /></button>)}
                            </div>
                        </div>
                        </>
                        
                        )
                        
                        })}
                        <button className='_addMore_pl' onClick={addFromInput}>+ Add Pickup Location</button>
                       
                    </div>

                    <div className='pdlLx_bx'>
                    {toLoction?.map((data, index1) => {
                          return (<>
                          <div className='_addmores_bx' key={`route_${index1}`}>
                            <div className='singleDiv _fsFlex'>
                                <CustomSelect
                                label="Drop Location"
                                 component_class="vehicle_number_trip"
                                options={cities}
                                dynamicIndex={index1}
                                onSelect={handleInputChange}
                                value={{
                                  label: data?.to_city
                                    ? `${data?.to_city}, ${data?.to_state}`
                                    : "",
                                  value: `${data?.to_city}`,
                                }}
                                error={`${formError[`to_location[${index1}].to_city`]}` !== "undefined" ? `${formError[`to_location[${index1}].to_city`]}` : ""}

                                />
                        {toLoction?.length > 1 && (<button className='_del_pl' onClick={()=>removeToInput(index1)}><img src={Images.DELETEICON.default} alt="" /></button>)}
                            </div>
                        </div>
                          </>
                          
                        )})}
                        
                        <button className='_addMore_pl'  onClick={addToInput}>+ Add Drop Location</button>
                    </div>

                    <div className='singleDiv'>
                        <CommonInput
                        type="text"
                        label="Route Name"
                        name="route_name"
                        value={common?.route_name}
                        onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      error={formError?.route_name}
                        />
                    </div>

                    <div className='singleDiv'>
                        <CommonInput
                        type="text"
                        label="Route Code"
                        value={common?.route_code}
                        name="route_code"
                        onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      maxLength={6}
                      error={formError?.route_code}
                        />
                        <p className='_btmLine'>Leave blank to autogenerate</p>
                    </div>
                </div>
            </CommonModal>
        </div>
    )
}

export default AddRouteModal
