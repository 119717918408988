import CustomPagination from "components/CustomPagination";
import { Images } from "constants";
import { UserContext } from "context";
import { utils } from "helper";
import { LrDetailModal } from "modals";
import Loading from "pages/Loading";
import React, { useContext, useState } from "react";
import { IoChevronBack, IoChevronDownCircleOutline, IoChevronForward } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

const AllDispatchDetailTab = ({
  list,
  loading,
  handlePageChange,
  filter,
  totalItems,
  itemsPerPage,
  callBackLrId,
}) => {
  const { masterData, allMenu } = useContext(UserContext);
  const nevigate = useNavigate();
  const [showLrDetail, setShowLrDetail] = useState(false);
  const [showAddLR, setShowAddLR] = useState(false);
  const childsUser = allMenu?.find((i) => i?.label === "Trip");
  const handleClick = (event) => {
    event.stopPropagation();
  };

  const handleDetailsAction = (e, data) => {
    e.stopPropagation();
    setShowLrDetail(data);
  };

  const handleNextModal = (data) => {
    setShowLrDetail(!showLrDetail);
    setShowAddLR(data);
  };

  const planFromLoc = (loc) => {
    return loc.map((item) => item.from_city).join(" + ");
  };

  const planToLoc = (loc) => {
    return loc.map((item) => item.to_city).join(" + ");
  };

  return (
    <>
      <div className="_indentTable _planLTable _disPatchView __trpDetail">
        <div class="accordion" id="indentTableAccordian">
          <table>
            <thead>
              <tr>
                {/* <th>Dispatch Date</th>
                <th>Party</th>
                <th>From Station</th>
                <th>To Station</th>
                <th>Branch Name</th>
                <th>LR Date</th>
                <th>LR Number</th>
                <th>Invoice Number</th>
                <th>LR Status</th>
                <th>Eway Bill Date</th>
                <th>Eway Bill No.</th> */}

                <th>Trip Number</th>
                <th>Start Date</th>
                <th>From Station</th>
                <th>To Station</th>
                <th>End Date</th>
                <th>Branch Name</th>
                <th>Vehicle Number</th>
                <th>Driver/ Supplier</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>

            {loading ? (
              <div>
                <Loading />
              </div>
            ) : list?.trip_list?.length > 0 ? (
              list?.trip_list?.map((trip, index) => {
                const isFirstItem = index === 0;
                return (
                  <tbody className="accordion-item"
                    onClick={() => childsUser?.view == 1 ? nevigate(`dispatched/${trip?._id}`) : ""}
                    key={trip?._id}
                  >
                    <tr>
                      <td >
                        <div className="_separateBxs">
                          <Link
                            className="_trip_ind me-2"
                            to={`dispatched/${trip?._id}`}
                            onClick={handleClick}
                          >
                            {" "}
                            <span>Trip</span>
                            <div className="trpCode">
                              {trip?.trip_number?trip?.trip_number:""}
                            </div>{" "}
                          </Link>


                          {/* <div className="_trckDetail ms-3">
                            <img src={Images.TRUCKICON.default} alt="" />

                            <p>
                              {" "}
                              {trip?.vehicle_id?.vehicle_no.slice(0, 4) +
                                " " +
                                trip?.vehicle_id?.vehicle_no.slice(4, 6) +
                                " " +
                                trip?.vehicle_id?.vehicle_no.slice(6)}{" "}
                            </p>
                          </div> */}

                          {/* <div
                            className={
                              trip?.vehicle_id?.vehicle_ownership == 1
                                ? "_trkMark _lrDelivered_status"
                                : trip?.vehicle_id?.vehicle_ownership == 2
                                  ? "_trkMark _lrPartyPoint_status"
                                  : "_trkMark _lrInTransit_status"
                            }
                          >
                            {
                              masterData?.vehicle_ownership[
                              trip?.vehicle_id?.vehicle_ownership
                              ]
                            }
                          </div> */}

                          {/* <div className="_trkTyre">
                            <span>{trip?.vehicle_type_id?.name}</span>
                          </div> */}

                          {/* <p className="_rsp_suplier ms-4">
                            {trip?.supplier_id
                              ? `Supplier- ${trip?.supplier_id?.name}`
                              : `Driver- ${trip?.driver_id?.name}`}
                          </p>

                          <p className="_rsp_suplier ms-4">{trip?.route_name}</p> */}


                        </div>

                      </td>

                      <td>
                        <div className="_dateNtime _pDate">
                          <p className="_tblText_inDent">{trip?.dispatch_date?utils.formatDateMonth(trip?.dispatch_date):""}</p>
                          <p className="_lgtText_inDent">{trip?.dispatch_date?utils.formatTime(trip?.dispatch_date):""}</p>
                        </div>
                      </td>

                      <td>
                        <div className="__locationWid_bx">
                          <p className="_tblText_inDent">{planFromLoc(trip?.from_location)}</p>
                        </div>
                      </td>

                      <td>
                        <div className="__locationWid_bx">
                          <p className="_tblText_inDent">{planToLoc(trip?.to_location)}</p>
                        </div>
                      </td>

                      <td>
                        <div className="_dateNtime _pDate">
                          <p className="_tblText_inDent">{trip?.end_date?utils.formatDateMonth(trip?.end_date):"-"}</p>
                          <p className="_lgtText_inDent">{trip?.end_date?utils.formatTime(trip?.end_date):""}</p>
                        </div>
                      </td>

                      <td>
                        {trip?.branch_id?.branch}
                      </td>

                      <td>
                        <div className="_trckDetail">
                          {/* <img src={Images.TRUCKICON.default} alt="" /> */}

                          <p>
                            {" "}
                            {trip?.vehicle_id?.vehicle_no.slice(0, 4) +
                              " " +
                              trip?.vehicle_id?.vehicle_no.slice(4, 6) +
                              " " +
                              trip?.vehicle_id?.vehicle_no.slice(6)}{" "}
                          </p>
                        </div>
                        <div className="__vrhDetailBx">
                          <div
                            className={
                              trip?.vehicle_id?.vehicle_ownership == 1
                                ? "_trkMark _lrDelivered_status"
                                : trip?.vehicle_id?.vehicle_ownership == 2
                                  ? "_trkMark _lrPartyPoint_status"
                                  : "_trkMark _lrInTransit_status"
                            }
                          >
                            {
                              masterData?.vehicle_ownership[
                              trip?.vehicle_id?.vehicle_ownership
                              ]
                            }
                          </div>
                          <div className="__containrType">{trip?.lorry_type_id?.lorry_type}</div>
                        </div>
                      </td>

                      <td>{trip?.vehicle_id?.vehicle_ownership == 1?`Driver - ${trip?.driver_id?.name}`:`Supplier - ${trip?.supplier_id?.name}`}</td>

                      <td>
                        <span
                          className={
                            masterData?.trip_status[trip?.trip_status] ===
                              "Pending"
                              ? "_lrPending_status"
                              : masterData?.trip_status[trip?.trip_status] ===
                                "In Transit"
                                ? "_lrInTransit_status"
                                : masterData?.trip_status[trip?.trip_status] ===
                                  "At Party Point"
                                  ? "_lrPartyPoint_status"
                                  : masterData?.trip_status[trip?.trip_status] ===
                                    "Delivered"
                                    ? "_lrDelivered_status"
                                    : masterData?.trip_status[trip?.trip_status] ===
                                      "Bill Raised"
                                      ? "_lrBillRaised_status"
                                      : ""
                          }
                        >
                          {masterData?.trip_status[trip?.trip_status]}
                        </span>
                      </td>

                      <td>
                        <div className="">
                          <button
                            class={`accordion-button _plnChev_btn ${isFirstItem ? "" : "collapsed"
                              }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${trip?._id}`}
                            aria-expanded={isFirstItem ? "true" : "false"}
                            aria-controls={`${trip?._id}`}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <IoChevronDownCircleOutline />
                          </button>
                        </div>
                      </td>
                    </tr>


                    <tr >
                      <td colSpan={10} className="__accTd_tab">
                        <div
                          id={`${trip?._id}`}
                          className={`accordion-collapse collapse ${isFirstItem ? "show" : ""
                            }`}
                          data-bs-parent="#indentTableAccordian"
                        >
                          <div class="accordion-body">
                            {trip?.lr_list?.map((lr) => {
                              return (
                                <div key={lr?._id} className="__tMax_bx">
                                  <div className="_poDetails">
                                    <span>
                                      LR Date
                                    </span>
                                    <p>
                                      {lr?.dispatch_date?utils.formatDateMonth(lr?.dispatch_date):""}
                                    </p>
                                  </div>

                                  <div className="_poDetails">
                                    <span>
                                      LR Number
                                    </span>
                                    <p>
                                      {lr?.lr_number}
                                    </p>
                                  </div>

                                  <div className="_poDetails">
                                    <span>
                                      Invoice Number
                                    </span>
                                    <p>
                                      {lr?.invoice_number ? lr?.invoice_number : '-'}
                                    </p>
                                  </div>

                                  <div className="_poDetails">
                                    <span>
                                      Party
                                    </span>
                                    <p>
                                      {lr?.consignor_id?.name}
                                    </p>
                                  </div>

                                  <div className="_poDetails">
                                    <span>
                                      From Unit
                                    </span>
                                    {lr?.unit_id && <><p>
                                    
                                     {lr?.unit_id?.code && lr?.unit_id?.name
                                    ? `${lr?.unit_id?.code} | ${lr?.unit_id?.name}`
                                    : lr?.unit_id?.code || lr?.unit_id?.name}
                                    </p>
                                    <p style={{ fontSize: '12px' }}>{lr?.unit_id?.address}</p></>}
                                  </div>

                                  <div className="_poDetails">
                                    <span>
                                      Consignee
                                    </span>
                                    {lr?.consignee_id && <><p>
                                      {lr?.consignee_id?.code && lr?.consignee_id?.name
                                    ? `${lr?.consignee_id?.code} | ${lr?.consignee_id?.name}`
                                    : lr?.consignee_id?.code || lr?.consignee_id?.name}
                                    </p>
                                    <p style={{ fontSize: '12px' }}>{lr?.consignee_id?.address}</p></>}
                                  </div>

                                  <div className="_poDetails">
                                    <span>
                                      Eway Valid Till
                                    </span>
                                    <p>
                                    {lr?.eway_expiry_date?utils.formatDateMonth(lr?.eway_expiry_date):""}
                                    </p>
                                  </div>

                                  <div className="_poDetails">
                                    <span>
                                      Eway Bill Number
                                    </span>
                                    <p>
                                      {lr?.eway_bill_number}
                                    </p>
                                    <p style={{ fontSize: '12px' }}>{lr?.eway_bill_date?utils.formatDateMonth(lr?.eway_bill_date):""}</p>
                                  </div>

                                  <div className="__plStatus">
                                    <div className="_poDetails">
                                      <span>
                                        LR Status
                                      </span>
                                    </div>
                                    <span
                                      className={
                                        masterData?.lr_status[lr?.lr_status] ===
                                          "Pending"
                                          ? "_lrPending_status"
                                          : masterData?.lr_status[lr?.lr_status] ===
                                            "In Transit"
                                            ? "_lrInTransit_status"
                                            : masterData?.lr_status[lr?.lr_status] ===
                                              "At Party Point"
                                              ? "_lrPartyPoint_status"
                                              : masterData?.lr_status[lr?.lr_status] ===
                                                "Delivered"
                                                ? "_lrDelivered_status"
                                                : masterData?.lr_status[lr?.lr_status] ===
                                                  "Bill Raised"
                                                  ? "_lrBillRaised_status"
                                                  : ""
                                      }
                                    >
                                      {" "}
                                      {masterData?.lr_status[lr?.lr_status]
                                        ? masterData?.lr_status[lr?.lr_status]
                                        : ""}{" "}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </td>

                    </tr>



                    {/* <tr>
                      <td colSpan={10} style={{ background: "transparent" }}></td>
                    </tr> */}
                  </tbody>
                );
              })
            ) : (
              <tr>
                <td> No data available </td>
              </tr>
            )}
          </table>
        </div>
      </div>

      {list?.trip_list?.length > 0 && <div className="paginationFt_avt ">
        <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter?.offset}
          onPageChange={handlePageChange}
        />
      </div>}

      <div
        className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${showLrDetail ? "show" : ""
          }`}
        tabIndex={-1}
        style={{ visibility: showLrDetail ? "visible" : "hidden" }}
      >
        {showLrDetail && (
          <LrDetailModal
            isOpen={showLrDetail}
            onClose={(e) => setShowLrDetail(false)}
            nextModal={(e) => handleNextModal(e)}
          />
        )}
      </div>
      <div className={showLrDetail ? "offBack_drop" : ""}></div>


      {/* Old Code Commented by Anvit */}

      {/* <div className="_indentTable _disPatchView">
        <table>
          <thead>
            <tr>
              <th>Dispatch Date</th>
              <th>Party</th>
              <th>From Station</th>
              <th>To Station</th>
              <th>Branch Name</th>
              <th>LR Date</th>
              <th>LR Number</th>
              <th>Invoice Number</th>
              <th>LR Status</th>
              <th>Eway Bill Date</th>
              <th>Eway Bill No.</th>
            </tr>
          </thead>

          {loading ? (
            <div>
              <Loading />
            </div>
          ) : list?.trip_list?.length > 0 ? (
            list?.trip_list?.map((trip) => {
              return (
                <tbody
                  onClick={() => childsUser?.view==1?nevigate(`dispatched/${trip?._id}`):""}
                  key={trip?._id}
                >
                  {trip?.lr_list?.map((lr) => {
                    return (
                      <tr key={lr?._id}>
                        <td>
                          <div className="_dateNtime _pDate">
                            <p className="_tblText_inDent">
                              {utils.formatDateMonth(lr?.dispatch_date)}
                            </p>
                            <p className="_lgtText_inDent">
                              {utils.formatTime(lr?.dispatch_date)}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {lr?.consignor_id?.name}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {lr?.from_location}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">{lr?.to_location}</p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {lr?.branch_id?.branch}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="_dateNtime">
                            <div className="__locationWid_bx">
                              <p className="_tblText_inDent">
                                {utils.formatDateMonth(lr?.invoice_date)}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="_dateNtime">
                            <div className="__locationWid_bx">
                              <p className="_tblText_inDent">
                                <Link
                                  className=""
                                  onClick={(e) => handleDetailsAction(e, lr)}
                                >
                                  {lr?.lr_number}
                                </Link>
                              </p>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              <Link
                                className=""
                                onClick={(e) => handleDetailsAction(e, lr)}
                              >
                                {lr?.invoice_number}
                              </Link>
                            </p>
                          </div>
                        </td>

                        <td>
                          <div>
                            <span
                              className={
                                masterData?.lr_status[lr?.lr_status] ===
                                "Pending"
                                  ? "_lrPending_status"
                                  : masterData?.lr_status[lr?.lr_status] ===
                                    "In Transit"
                                  ? "_lrInTransit_status"
                                  : masterData?.lr_status[lr?.lr_status] ===
                                    "At Party Point"
                                  ? "_lrPartyPoint_status"
                                  : masterData?.lr_status[lr?.lr_status] ===
                                    "Delivered"
                                  ? "_lrDelivered_status"
                                  : masterData?.lr_status[lr?.lr_status] ===
                                    "Bill Raised"
                                  ? "_lrBillRaised_status"
                                  : ""
                              }
                            >
                              {" "}
                              {masterData?.lr_status[lr?.lr_status]
                                ? masterData?.lr_status[lr?.lr_status]
                                : ""}{" "}
                            </span>
                          </div>
                        </td>

                        <td>
                          <div className="_dateNtime _pDate">
                            {lr?.eway_bill_date ? (
                              <>
                                <p className="_tblText_inDent">
                                  {utils?.formatDateMonth(lr?.eway_bill_date)}
                                </p>
                                <p className="_tblText_inDent">
                                  {utils?.formatDateMonth(lr?.eway_expiry_date)}
                                </p>
                              </>
                            ) : (
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  callBackLrId(lr);
                                }}
                              >
                                + Add Eway Bill
                              </span>
                            )}
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {lr?.eway_bill_number ? (
                                <>{lr?.eway_bill_number}</>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    callBackLrId(lr);
                                  }}
                                >
                                  + Add Eway Bill
                                </span>
                              )}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td colSpan={11}>
                      <div className="_separateBxs">
                        <Link
                          className="_trip_ind me-2"
                          to={`dispatched/${trip?._id}`}
                          onClick={handleClick}
                        >
                          {" "}
                          <span>Trip</span>
                          <div className="trpCode">
                            {trip?.trip_number}
                          </div>{" "}
                        </Link>

                        <span
                          className={
                            masterData?.trip_status[trip?.trip_status] ===
                            "Pending"
                              ? "_lrPending_status"
                              : masterData?.trip_status[trip?.trip_status] ===
                                "In Transit"
                              ? "_lrInTransit_status"
                              : masterData?.trip_status[trip?.trip_status] ===
                                "At Party Point"
                              ? "_lrPartyPoint_status"
                              : masterData?.trip_status[trip?.trip_status] ===
                                "Delivered"
                              ? "_lrDelivered_status"
                              : masterData?.trip_status[trip?.trip_status] ===
                                "Bill Raised"
                              ? "_lrBillRaised_status"
                              : ""
                          }
                        >
                          {masterData?.trip_status[trip?.trip_status]}
                        </span>

                        <div className="_trckDetail ms-3">
                          <img src={Images.TRUCKICON.default} alt="" />

                          <p>
                            {" "}
                            {trip?.vehicle_id?.vehicle_no.slice(0, 4) +
                              " " +
                              trip?.vehicle_id?.vehicle_no.slice(4, 6) +
                              " " +
                              trip?.vehicle_id?.vehicle_no.slice(6)}{" "}
                          </p>
                        </div>

                        <div
                          className={
                            trip?.vehicle_id?.vehicle_ownership == 1
                              ? "_trkMark _lrDelivered_status"
                              : trip?.vehicle_id?.vehicle_ownership == 2
                              ? "_trkMark _lrPartyPoint_status"
                              : "_trkMark _lrInTransit_status"
                          }
                        >
                          {
                            masterData?.vehicle_ownership[
                              trip?.vehicle_id?.vehicle_ownership
                            ]
                          }
                        </div>

                        <div className="_trkTyre">
                          <span>{trip?.vehicle_type_id?.name}</span>
                        </div>

                        <p className="_rsp_suplier ms-4">
                          {trip?.supplier_id
                            ? `Supplier- ${trip?.supplier_id?.name}`
                            : `Driver- ${trip?.driver_id?.name}`}
                        </p>

                        <p className="_rsp_suplier ms-4">{trip?.route_name}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={11} style={{ background: "transparent" }}></td>
                  </tr>
                </tbody>
              );
            })
          ) : (
            <tr>
              <td> No data available </td>
            </tr>
          )}
        </table>
      </div>
     
      {list?.trip_list?.length > 0  && <div className="paginationFt_avt ">
        <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter?.offset}
          onPageChange={handlePageChange}
        />
      </div>}

      <div
        className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${
          showLrDetail ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showLrDetail ? "visible" : "hidden" }}
      >
        {showLrDetail && (
          <LrDetailModal
            isOpen={showLrDetail}
            onClose={(e) => setShowLrDetail(false)}
            nextModal={(e) => handleNextModal(e)}
          />
        )}
      </div>
      <div className={showLrDetail ? "offBack_drop" : ""}></div> */}
    </>
  );
};

export default AllDispatchDetailTab;
