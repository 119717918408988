import React, { useState, useEffect } from 'react'
import { CommonInput, CommonModal, CustomSelect } from 'components'
import { fetchConsignorDetailsData } from '_services/accountsServices/consignorServices';
import StorageService from 'utils/StorageServices/StorageServices';
import { useParams } from 'react-router-dom'
import { errorSchema, utils } from 'helper';
import { UserContext } from 'context';
import { useContext } from 'react'
import { createContract, updateContract } from '_services/accountsServices/contractServices';

const formObj = {
    company_id: StorageService.getCompanyId(),
    consignor_id: '',
    name: '',
    start_date: '',
    end_date: '',
    invoice_format: {},
    chargeable_on: 1,
    matrix_allowed: 1,
    rate_type: [],
    variable_rates: [],
    other_settings: []
}

const AddContractModal = (props) => {

    let { isOpen } = props
    const { masterData } = useContext(UserContext)
    const [formValue, setFormValue] = useState(formObj);

    useEffect(() => {
        if (isOpen?._id) {
            let data = masterData?.consignor_template_list?.length > 0 ? masterData?.consignor_template_list?.map((el) => {
                return {
                    label: el?.name,
                    value: el?.id
                }
            })?.find((el) => (el?.value === isOpen?.invoice_format)) : []

            let startDate1 = new Date(isOpen?.start_date);
            let startDate2 = startDate1?.toISOString()?.split('T')[0];

            let endDate1 = new Date(isOpen?.end_date);
            let endDate2 = endDate1?.toISOString()?.split('T')[0];

            setFormValue((s) => ({
                ...s,
                company_id: isOpen?.company_id,
                consignor_id: isOpen?.consignor_id,
                name: isOpen?.name,
                start_date: startDate2,
                end_date: endDate2,
                invoice_format: data,
                chargeable_on: isOpen?.chargeable_on,
                matrix_allowed: isOpen?.matrix_allowed,
                rate_type: isOpen?.rate_type,
                variable_rates: isOpen?.variable_rates,
                other_settings: isOpen?.other_settings,
                contract_id: isOpen?._id,
            }))
        }
    }, [isOpen, masterData?.consignor_template_list])

    return (
        <div>
            <CommonModal {...props}>
                <div className='addCont_body'>

                    <div className='_acFlx_'>
                        <div className='_fxs_cstBx'>
                            <CommonInput
                                type="text"
                                placeholder="Contract Name"
                                label="Contract Name *"
                                name='name'
                                value={formValue.name}
                                disabled
                            />
                        </div>

                        <div className='_fxs_cstBx'>
                            <CommonInput
                                type="date"
                                placeholder="Start Date"
                                label="Start Date *"
                                name='start_date'
                                onKeyDown={(e) => e.preventDefault()}
                                value={formValue.start_date}
                                disabled
                            />
                        </div>

                        <div className='_fxs_cstBx'>
                            <CommonInput
                                type="date"
                                placeholder="End Date"
                                label="End Date *"
                                name='end_date'
                                onKeyDown={(e) => e.preventDefault()}
                                value={formValue.end_date}
                                disabled
                            />
                        </div>
                        <div className='_fxs_cstBx'>
                            <CommonInput
                                type="text"
                                placeholder="Select Invoice Format"
                                label="Select Invoice Format *"
                                value={formValue?.invoice_format?.label}
                                disabled
                            />

                        </div>
                    </div>

                    <div className='_acFlx_'>
                        <div className='_fxs_cstBx'>
                            <CommonInput
                                type="text"
                                placeholder="Consignor Name"
                                label="Consignor Name *"
                                name='name'
                                disabled
                                value={formValue?.consignor_id?.name}
                            />
                        </div>
                    </div>

                    <div className='con_mHead'>
                        <h6>Chargeable On</h6>
                        <div className='_conM_flx'>
                            {
                                masterData?.contract_chargeable_on_list?.map((el, ind) => {
                                    return (
                                        <div className="form-check" key={ind + el?.id}>
                                            <input
                                                className="form-check-input _avPointer"
                                                type="radio"
                                                name="chargeable_on"
                                                id={ind + el?.name}
                                                checked={formValue?.chargeable_on === el?.id}
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor={ind + el?.name}>
                                                {el?.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='con_mHead'>
                        <h6>Matrix Allowed</h6>
                        <div className='_conM_flx'>
                            {
                                masterData?.contract_matrix_allowed_list?.map((el, ind) => {
                                    return (
                                        <div className="form-check" key={ind + el?.id}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="matrix_allowed"
                                                id={ind + el?.name}
                                                checked={formValue?.matrix_allowed === el?.id}
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor={ind + el?.name}>
                                                {el?.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='con_mHead'>
                        <h6>Rate Type</h6>
                        <div className='_conM_flx'>
                            {
                                masterData?.contract_rate_type_list?.map((el, ind) => {
                                    return (
                                        <div className="_cstForm_check" key={ind + el?.id}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="rate_type"
                                                id={ind + el?.name}
                                                checked={formValue?.rate_type?.some(item => item.id === el?.id)}
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor={ind + el?.name}>
                                                {el?.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='con_mHead'>
                        <h6>Variable Rate on Fields</h6>
                        <div className='_conM_flx'>
                            {
                                masterData?.contract_variable_rates_list?.map((el, ind) => {
                                    return (
                                        <div className="_cstForm_check" key={ind + el?.id}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="variable_rates"
                                                id={ind + el?.name}
                                                checked={formValue?.variable_rates?.some(item => item.id === el?.id)}
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor={ind + el?.name}>
                                                {el?.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='con_mHead'>
                        <h6>Other Settings</h6>
                        <div className='_conM_flx'>
                            {
                                masterData?.contract_other_settings_list?.map((el, ind) => {
                                    return (
                                        <div className="_cstForm_check" key={ind + el?.id}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="other_settings"
                                                id={ind + el?.name}
                                                checked={formValue?.other_settings?.some(item => item.id === el?.id)}
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor={ind + el?.name}>
                                                {el?.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
            </CommonModal>
        </div>
    )
}

export default AddContractModal
