import { API, axiosInstance } from "lib";

export const getSupplierListData = (data={}) => {
    return axiosInstance
      .post(API.GET_SUPPLIER_LIST , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
  //      throw err;
      });
  };

export const createSupplier = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_SUPPLIER , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const deleteSupplierData = (data={}) => {
  return axiosInstance
    .post(API.DELETE_SUPPLIER , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getSupplierDetailsData = (data={}) => {
  return axiosInstance
    .post(API.GET_SINGLE_SUPPLIER , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};
export const changeSupplierStatusData = (data={}) => {
  return axiosInstance
    .post(API.CHANGE_SUPPLIER_STATUS , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};