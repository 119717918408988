import { addressImport, planImport } from "_services";
import { getPartyAssociateApi } from "_services/accountsServices/addressBookService";
import {
  getConsignorDropdownListData,
  getContractDropdownListData,
} from "_services/dropdownServices/dropdownService";
import { CommonInput, CustomSelect, ExcelDownload, Heading } from "components";
import { UserContext } from "context";
import { errorSchema, utils } from "helper";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import StorageService from "utils/StorageServices";

const PartyAddress = () => {
  

  const loadingArray = [
    { label: "Loading point / Unit", value: 1 },
    { label: "Drop point / Consignee", value: 2 },
  ];

  const initi = {
    company_id: StorageService.getCompanyId(),
    category: "",
    associate_party_id: "",
    location_point: "",
    file:""
  };
  const { masterData} = useContext(UserContext);

  const navigate = useNavigate();
  const [form, setForm] = useState(initi);
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
  const [partyAssociateList, setPartyAssociateList] = useState([]);
  const [listData, setListData] = useState({
    categoryList: [],
  });
const [addressInportList,setAddressInportList] = useState([])
const [hasMoreVehicle, setHasMoreVehicle] = useState(true);
const [page, setPage] = useState(1);
const [tableData,setTableData] = useState([])
const [statusCounts, setStatusCounts] = useState({
  Rejected: 0,
  Updated: 0,
  Added: 0,
  All: 0,
});
  useEffect(() => {
    // if (masterData) {
      fetchData();
    // }
  }, []);

  useEffect(() => {
    const validCategories = [1, 2, 3];
    const updatedForm = { ...form };
  
    if (form?.category) {
      updatedForm.associate_party_id = "";
      updatedForm.location_point = form?.category === 1 ? 0 : 0;
      setForm(updatedForm);
      setPage(1)
    }
   
  
    if (validCategories.includes(form?.category)) {
      fetchPartAssociateList();
    } else {
      setPartyAssociateList([]);
    }
  }, [form?.category]);

  //file

  const handleOnChange = async (name, value) => {
  
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.addAddressImport
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (validTypes.includes(file.type)) {
        handleOnChange("file", file);
        setFormError({
          ...formError,
          file: "",
        });
      } else {
        setFormError({
          ...formError,
          file: "Please upload only CSV or XLSX files.",
        });
      }
    }
  };

  //common Api function
  const fetchData = async () => {
    try {
     
      const categoryList = formatList(
        masterData?.addressbook_category_list,
        "name",
        "id"
      );

      setListData({
        categoryList,
      });
      setInitialFormValues(categoryList);
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data", error);
    }
  };

  const formatList = (list, labelKey, valueKey, defaultOption = null) => {
    const formattedList = list?.map((item) => ({
      ...item,
      label: item[labelKey],
      value: item[valueKey],
    }));
    return defaultOption
      ? [{ label: defaultOption, value: "" }, ...formattedList]
      : formattedList;
  };

  const setInitialFormValues = (categoryList) => {
 
    handleOnChange("category", categoryList[0]?.value || "");
  };

  const commonFetchFuction = async (
    apiMethod,
    payload = {},
    transformer = (res) => res
  ) => {
    try {
      const res = await apiMethod(payload);
      if (res?.responseCode === 200) {
        return transformer(res);
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  };


  const getpartyAssociate = (page = 1, limit = 10) => {
    return getPartyAssociateApi({
      company_id: StorageService.getCompanyId(),
      category:form?.category,
      offset: page,
      limit,
    });
  };

  const fetchPartAssociateList = async (nextPage = 1) => {
    try {
      const res = await getpartyAssociate(nextPage, 10);

      if (res?.responseCode === 200) {
        const newVehicles = res?.data?.list.map((i) => ({
          ...i,
          label: i.name,
          value: i._id,
        }));

        if (nextPage==1) {
          setPartyAssociateList(newVehicles);
        } else {
          setPartyAssociateList((prevList) => [...prevList, ...newVehicles]);
        }

        if (newVehicles.length < 10) {
          setHasMoreVehicle(false);
        } else {
          setHasMoreVehicle(true);
          setPage(nextPage + 1);
        }
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching vehicle list:", error);
      toast.error("Error fetching vehicle list.");
    }
  };

  const handleImportFile = async () => {
   
    const formData = new FormData();
    Object.keys(form).forEach((key) => {
      if (key === "file" && form[key]) {
        formData.append(key, form[key]);
      } else {
        formData.append(key, form[key]);
      }
    });

    const validationResult = await utils.checkFormError(
      { ...form },
      errorSchema.addAddressImport
    );

 

    if (validationResult == true) {
      setLoading(true);
      try {
        const res = await addressImport(formData);
        if (res?.responseCode == 200) {
          setLoading(false);
          setAddressInportList(res?.data?.results)
          toast?.success(res?.responseMessage);
          // toast(
          //   `
          //         New plan count:${res?.data?.new_plan_count}
    
          //         New po count:${res?.data?.new_po_count}
    
          //          Re-Direct to planList ...
          //         `,
          //   {
          //     duration: 3000,
          //   }
          // );
          const execleDat = res?.data?.results?.map((i)=>{
            return {
              "Name":i?.value?.name,
              "status":i?.status,
              "remark":i?.remarks

            }
          })

          const counts = res?.data?.results.reduce(
            (acc, item) => {
              if (item.status === 'Not Updated') acc.Rejected++;
              if (item.status === 'Updated') acc.Updated++;
              if (item.status === 'Added') acc.Added++;
              acc.All++;
              return acc;
            },
            { Rejected: 0, Updated: 0, Added: 0,All: 0  }
          );

          setStatusCounts(counts);
          setTableData([...execleDat])
        } else {
          setLoading(false);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      setFormError(validationResult);
    }
  };

  return (
    <>
      <Heading title="Party Address Upload" />

      <div className="_Qwk_inr">
        <div className="_tierPart">
          <CustomSelect
             labelRedStar={true}
             label="Category"
             placeholder="Select Category"
             options={listData?.categoryList}
             onSelect={(data) => {
               handleOnChange("category", data.value);
             }}
             value={
               form?.category
                 ? listData?.categoryList?.find(
                     (i) => i.value == form?.category
                   )
                 : { label: "", value: "" }
             }
             focus={!!(typeof formError === "object" && formError?.category)}
             error={
               !!(typeof formError === "object") ? formError?.category : ""
             }
          />
        </div>
        {[1, 2, 3].includes(form?.category) && (
        <div className="_tierPart">
          <CustomSelect
             placeholder={`Select Associate ${
                form?.category == 1
                  ? "Party"
                  : form?.category == 2
                  ? "Supplier"
                  : "Vender"
              }`}
              label={`Associate ${
                form?.category == 1
                  ? "Party"
                  : form?.category == 2
                  ? "Supplier"
                  : "Vender"
              }`}
              options={partyAssociateList}
              onSelect={(data) => {
                handleOnChange("associate_party_id", data.value);
              }}
              value={
                form?.associate_party_id
                  ? partyAssociateList?.find(
                      (i) => i.value == form?.associate_party_id
                    )
                  : { label: "", value: "" }
              }
              focus={
                !!(
                  typeof formError === "object" &&
                  formError?.associate_party_id
                )
              }
              error={
                !!(typeof formError === "object")
                  ? formError?.associate_party_id
                  : ""
              }

              enableInfiniteScroll={partyAssociateList.length>4?true:false}
              fetchMoreData={() => fetchPartAssociateList(page)}
              hasMore={hasMoreVehicle}
          />
        </div>   )}
        {form?.category ==1 && <div className="_tierPart">
          <CustomSelect
             
             label="Type {Unit/ Consignee}"
             placeholder="Select Type {Unit/ Consignee}"
             options={loadingArray}
             onSelect={(data) => {
               handleOnChange("location_point", data.value);
             }}
             value={
               form?.location_point
                 ? loadingArray?.find(
                     (i) => i.value == form?.location_point
                   )
                 : { label: "", value: "" }
             }
             focus={!!(typeof formError === "object" && formError?.location_point)}
             error={
               !!(typeof formError === "object") ? formError?.location_point : ""
             }
          />
        </div>}
        <div className="_exceluploadBx">
          <CommonInput
            type="file"
            onChange={handleFileChange}
            accept=".csv, .xlsx"
            error={formError?.file}
          />
        </div>

        <button className="_flUpload_btn" disabled={loading} onClick={()=>handleImportFile()}>{loading?"Uploading..":"Upload File"}</button>
      </div>

      {addressInportList?.length >0 &&<div className="tbdFilts_bx">
        <div className="_hedFilts">
          <h4>Status</h4> <ExcelDownload tableData={tableData} />
        </div>

         <div className="_tbdOx_bx">
          <div className="_fourTbx">
            <h4>{statusCounts?.All}</h4>
            <p className="__successTxt">All</p>
          </div>

          <div className="_fourTbx">
            <h4>{statusCounts?.Added}</h4>
            <p className="__successTxt">Added</p>
          </div>

          <div className="_fourTbx">
            <h4>{statusCounts?.Updated}</h4>
            <p className="__successTxt">Updated</p>
          </div>

          <div className="_fourTbx">
            <h4>{statusCounts?.Rejected}</h4>
            <p className="__rejectTxe">Not Updated</p>
          </div>
        </div>
      </div>}

      {addressInportList?.length>0 && <div className="_qwkTable_dix">
        <div className="_indentTable _disPatchView _fuelCrd__table">
          <table>
            <thead>
              <tr>
                <th>Address Name</th>
                <th>Status</th>
                <th>Remarks</th>
              </tr>
            </thead>

            <tbody>
              {addressInportList?.length && addressInportList?.map((i)=>{
          
               return  <tr>
                <td>
                  <div className="_dateNtime _pDate">
                    <p className="_tblText_inDent">{i?.value?.name}</p>
                  </div>
                </td>

                <td>
                  <div className="_dateNtime _pDate">
                    <p className={`_tblText_inDent${i?.status=="Not Updated"?" __rejectTxe":" __successTxt"}`}>{i?.status}</p>
                  </div>
                </td>

                <td>
                  <div className="_dateNtime _pDate">
                    <p className="_tblText_inDent">{i?.remarks}</p>
                  </div>
                </td>
              </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>}
    </>
  );
};

export default PartyAddress;
