import React, { useEffect, useState } from 'react'
import { errorSchema, utils } from 'helper'
import { CommonModal, CustomSelect } from 'components'
import StorageService from 'utils/StorageServices/StorageServices'
import { getConsignorDropdownListData } from '_services/dropdownServices/dropdownService'
import { fetchConsignorDetailsData } from '_services/accountsServices/consignorServices'

const formObj = {
    party: {},
}

const AddLrModal = (props) => {
    let { nextModal, closeModal, addButtonAction, showPrefield } = props;

    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [consignorList, setConsignorList] = useState([]);

    const getDropdownDataConsignorList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getConsignorDropdownListData(object);
            setLoading(false);
            if (res?.responseCode == 200) {
                setConsignorList(res?.data?.consignor_list)
            }
        } catch (error) {
            // throw error;
        }
    }

    useEffect(() => {
        setLoading(true);
        getDropdownDataConsignorList();
    }, [])

    const onhandleSelect = async (item, name) => {
        const stateObj = { ...formValue, [name]: item };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createLrModalSchema);
            if (validationResult === true) {
                setFormError({});
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async () => {
        try {
            let object = {
                consignor_id: formValue?.party?.value,
                company_id: StorageService.getCompanyId(),
            }
            const res = await fetchConsignorDetailsData(object);
            if (!!res) {
                nextModal(res?.data?.consignor);
                closeModal(false);
                setFormValue({});
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createLrModalSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true);
            apiCallingFunction()
            setFormError("");
        } else {
            setFormError(validationResult);
            setLoading(false);
        }
    }

    const propsLrModalInhance = {
        ...props,
        isLoadding: loading,
        disabled: loading,
        onSubmit: (e) => handleSubmit(e),
    }

    useEffect(() => {
        if (consignorList?.length > 0 && showPrefield) {
            setFormValue((s) => ({
                ...s,
                party: consignorList?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?._id,
                    }
                })[0],
            }))
        }
    }, [consignorList, showPrefield])

    const buttonActionHandler = () => {
        addButtonAction(true);
        closeModal(false)
    }

    return (
        <div>
            <CommonModal {...propsLrModalInhance}>
                <div className='addLr_body'>
                    <CustomSelect
                        options={consignorList?.map((el) => {
                            return {
                                label: el?.name,
                                value: el?._id,
                                consignor_code: el?.consignor_code,
                                template_no: el?.template_no,
                            }
                        })}
                        name="party"
                        label="Select Party"
                        placeholder="Select Party"
                        isAddButton={true}
                        addButtonAction={(e) => buttonActionHandler(e)}
                        value={formValue?.party?.value ? formValue?.party : {}}
                        error={formError["party.label"]}
                        // error={!!(typeof formError === "object") ? formError?.party : ""}
                        onSelect={(data) => onhandleSelect(data, 'party')}
                    />
                </div>
            </CommonModal>
        </div>
    )
}

export default AddLrModal
