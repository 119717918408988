import { API, axiosInstance } from "lib";

export const createMarketDriver = (data = {}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'apk_add_market_driver', data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getMarketDriverListData = (data={}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'get_driver_list' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const deleteMarketDriverData = (data={}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'delete_driver' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const fetchMarketDriverDetailsData = (data={}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'get_market_driver' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};