import { API, axiosInstance } from "lib";

export const createRate = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_RATE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getRateListData = (data = {}) => {
  return axiosInstance
    .post(API.GET_RATE_LIST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const deleteRateData = (data = {}) => {
  return axiosInstance
    .post(API.DELETE_RATE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const singleRateDetailsData = (data = {}) => {
  return axiosInstance
    .post(API.GET_SINGLE_RATE, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const activeInactiveRateStatus = (data = {}) => {
  return axiosInstance
    .post(API.RATE_STATUS_CHANGES, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};