import React, { useState, useEffect } from 'react'
import { CommonInput, CommonModal, CustomSelect } from 'components'
import { fetchConsignorDetailsData } from '_services/accountsServices/consignorServices';
import StorageService from 'utils/StorageServices/StorageServices';
import { useParams } from 'react-router-dom'
import { errorSchema, utils } from 'helper';
import { UserContext } from 'context';
import { useContext } from 'react'
import { createContract, updateContract } from '_services/accountsServices/contractServices';

const formObj = {
    company_id: StorageService.getCompanyId(),
    consignor_id: '',
    name: '',
    start_date: '',
    end_date: '',
    invoice_format: {},
    chargeable_on: 1,
    matrix_allowed: 1,
    rate_type: [],
    variable_rates: [],
    other_settings: []
}

const AddContractModal = (props) => {

    let { isOpen, closeModal } = props

    let { id } = useParams();
    let company_id = StorageService.getCompanyId();
    const { masterData } = useContext(UserContext)
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [endDisabled, setEndDisabled] = useState(new Date());
    const [detailsData, setDetailsData] = useState({});

    const getDataConsignorDetails = async (id_1, id_2) => {
        try {
            let object = {
                consignor_id: id_1,
                company_id: id_2,
            }
            const res = await fetchConsignorDetailsData(object);
            if (res?.responseCode == 200) {
                setDetailsData(res?.data?.consignor)
            }
        } catch (error) {
            // throw error;
        }
    }

    useEffect(() => {
        if (id && company_id) {
            getDataConsignorDetails(id, company_id);
        }
    }, [id])

    useEffect(() => {
        if (!isOpen?._id) {
            if (detailsData?.name && detailsData?._id) {
                setFormValue((s) => ({
                    ...s,
                    start_date: utils.getTodayDate(),
                    invoice_format: masterData?.consignor_template_list?.length > 0 ? masterData?.consignor_template_list?.map((el) => {
                        return {
                            label: el?.name,
                            value: el?.id
                        }
                    })?.find((el) => (el?.label === 'Default')) : [],
                    consignor_id: {
                        _id: detailsData?._id,
                        name: detailsData?.name
                    },
                }))
            }
        }
    }, [detailsData])

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createContractSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const onhandleSelect = async (item, name) => {
        const stateObj = { ...formValue, [name]: item };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createContractSchema);
            if (validationResult === true) {
                setFormError({});
            } else {
                setFormError(validationResult);
            }
        }
    };

    const handleArrayOnChange = (element, field_name) => {
        let array = [...formValue[field_name]];
        if (array.some(item => item.id === element.id)) {
            array = array.filter(item => item.id !== element.id);
        } else {
            array.push(element);
        }
        setFormValue({ ...formValue, [field_name]: array });
    };

    console.log('formValue', formValue)

    const submitContractHandler = async () => {
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createContractSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true)
            try {
                if (!!isOpen?._id) {
                    const object = {
                        ...formValue,
                        start_date: formValue?.start_date !== '' ? formValue?.start_date : new Date(),
                        end_date: formValue?.end_date !== '' ? formValue?.end_date : new Date(),
                        consignor_id: formValue?.consignor_id?._id,
                        company_id: StorageService.getCompanyId(),
                        invoice_format: formValue?.invoice_format?.value,
                    }
                    const res = await updateContract(object);
                    if (res?.responseCode == 200) {
                        closeModal(false);
                        setLoading(true)
                    } else {
                        setLoading(false)
                    }
                } else {
                    const object = {
                        ...formValue,
                        consignor_id: formValue?.consignor_id?._id,
                        company_id: StorageService.getCompanyId(),
                        invoice_format: formValue?.invoice_format?.value,
                        start_date: formValue?.start_date !== '' ? formValue?.start_date : new Date(),
                        end_date: formValue?.end_date !== '' ? formValue?.end_date : new Date(),
                    }
                    const res = await createContract(object);
                    if (res?.responseCode == 200) {
                        closeModal(false);
                        setLoading(true)
                    } else {
                        setLoading(false)
                    }
                }
            } catch (error) {
                setLoading(false)
                // throw error;
            }
        } else {
            setFormError(validationResult);
            setLoading(false);
        }
    }

    const modifyProps = {
        ...props,
        isLoadding: loading,
        onSubmit: submitContractHandler,
        disabled: loading,
    }

    useEffect(() => {
        if (!isOpen?._id) {
            if (formValue?.start_date) {
                const currentDate = new Date(formValue?.start_date);
                const formattedCurrentDate = currentDate?.toISOString()?.split('T')[0];
                setEndDisabled(formattedCurrentDate);
            }
        }
    }, [formValue?.start_date])

    useEffect(() => {
        if (isOpen?._id) {
            let data = masterData?.consignor_template_list?.length > 0 ? masterData?.consignor_template_list?.map((el) => {
                return {
                    label: el?.name,
                    value: el?.id
                }
            })?.find((el) => (el?.value === isOpen?.invoice_format)) : []

            let startDate1 = new Date(isOpen?.start_date);
            let startDate2 = startDate1?.toISOString()?.split('T')[0];

            let endDate1 = new Date(isOpen?.end_date);
            let endDate2 = endDate1?.toISOString()?.split('T')[0];

            setFormValue((s) => ({
                ...s,
                company_id: isOpen?.company_id,
                consignor_id: isOpen?.consignor_id,
                name: isOpen?.name,
                start_date: startDate2,
                end_date: endDate2,
                invoice_format: data,
                chargeable_on: isOpen?.chargeable_on,
                matrix_allowed: isOpen?.matrix_allowed,
                rate_type: isOpen?.rate_type,
                variable_rates: isOpen?.variable_rates,
                other_settings: isOpen?.other_settings,
                contract_id: isOpen?._id,
            }))
        }
    }, [isOpen, masterData?.consignor_template_list])

    return (
        <div>
            <CommonModal {...modifyProps}>
                <div className='addCont_body'>

                    <div className='_acFlx_'>
                        <div className='_fxs_cstBx'>
                            <CommonInput
                                type="text"
                                placeholder="Contract Name"
                                label="Contract Name *"
                                name='name'
                                value={formValue.name}
                                focus={!!(typeof formError === "object" && formError?.name)}
                                error={!!(typeof formError === "object") ? formError?.name : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='_fxs_cstBx'>
                            <CommonInput
                                type="date"
                                placeholder="Start Date"
                                label="Start Date"
                                name='start_date'
                                onKeyDown={(e) => e.preventDefault()}
                                value={formValue.start_date}
                                focus={!!(typeof formError === "object" && formError?.start_date)}
                                error={!!(typeof formError === "object") ? formError?.start_date : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='_fxs_cstBx'>
                            <CommonInput
                                type="date"
                                placeholder="End Date"
                                label="End Date"
                                name='end_date'
                                min={endDisabled}
                                onKeyDown={(e) => e.preventDefault()}
                                value={formValue.end_date}
                                focus={!!(typeof formError === "object" && formError?.end_date)}
                                error={!!(typeof formError === "object") ? formError?.end_date : ""}
                                onChange={({ target: { name, value } }) => {
                                    if (value >= formValue?.start_date) {
                                        handleOnChange(name, value)
                                    }
                                }
                                }
                            />
                        </div>
                        <div className='_fxs_cstBx'>
                            <CustomSelect
                                options={masterData?.consignor_template_list?.map((el) => {
                                    return {
                                        label: el?.name,
                                        value: el?.id
                                    }
                                })}
                                onSelect={(data) => onhandleSelect(data, 'invoice_format')}
                                value={formValue?.invoice_format}
                                placeholder="Select Invoice Format"
                                error={formError["invoice_format.label"]}
                                label="Invoice Format"
                            />
                        </div>
                    </div>

                    <div className='_acFlx_'>
                        <div className='_fxs_cstBx'>
                            <CommonInput
                                type="text"
                                placeholder="Consignor Name"
                                label="Consignor Name *"
                                name='name'
                                disabled
                                value={formValue?.consignor_id?.name}
                            />
                        </div>
                    </div>

                    <div className='con_mHead'>
                        <h6>Chargeable On</h6>
                        <div className='_conM_flx'>
                            {
                                masterData?.contract_chargeable_on_list?.map((el, ind) => {
                                    return (
                                        <div className="form-check" key={ind + el?.id}>
                                            <input
                                                className="form-check-input _avPointer"
                                                type="radio"
                                                name="chargeable_on"
                                                id={ind + el?.name}
                                                checked={formValue?.chargeable_on === el?.id}
                                                onChange={({ target: { name, value } }) =>
                                                    handleOnChange(name, el?.id)
                                                }
                                            />
                                            <label className="form-check-label" htmlFor={ind + el?.name}>
                                                {el?.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='con_mHead'>
                        <h6>Matrix Allowed</h6>
                        <div className='_conM_flx'>
                            {
                                masterData?.contract_matrix_allowed_list?.map((el, ind) => {
                                    return (
                                        <div className="form-check" key={ind + el?.id}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="matrix_allowed"
                                                id={ind + el?.name}
                                                checked={formValue?.matrix_allowed === el?.id}
                                                onChange={({ target: { name, value } }) => {
                                                    handleOnChange(name, el?.id)
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor={ind + el?.name}>
                                                {el?.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='con_mHead'>
                        <h6>Rate Type</h6>
                        <div className='_conM_flx'>
                            {
                                masterData?.contract_rate_type_list?.map((el, ind) => {
                                    return (
                                        <div className="_cstForm_check" key={ind + el?.id}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="rate_type"
                                                id={ind + el?.name}
                                                checked={formValue?.rate_type?.some(item => item.id === el?.id)}
                                                onChange={(e) => handleArrayOnChange(el, 'rate_type')}
                                            />
                                            <label className="form-check-label" htmlFor={ind + el?.name}>
                                                {el?.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='con_mHead'>
                        <h6>Variable Rate on Fields</h6>
                        <div className='_conM_flx'>
                            {
                                masterData?.contract_variable_rates_list?.map((el, ind) => {
                                    return (
                                        <div className="_cstForm_check" key={ind + el?.id}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="variable_rates"
                                                id={ind + el?.name}
                                                checked={formValue?.variable_rates?.some(item => item.id === el?.id)}
                                                onChange={(e) => handleArrayOnChange(el, 'variable_rates')}
                                            />
                                            <label className="form-check-label" htmlFor={ind + el?.name}>
                                                {el?.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='con_mHead'>
                        <h6>Other Settings</h6>
                        <div className='_conM_flx'>
                            {
                                masterData?.contract_other_settings_list?.map((el, ind) => {
                                    return (
                                        <div className="_cstForm_check" key={ind + el?.id}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="other_settings"
                                                id={ind + el?.name}
                                                checked={formValue?.other_settings?.some(item => item.id === el?.id)}
                                                onChange={(e) => handleArrayOnChange(el, 'other_settings')}
                                            />
                                            <label className="form-check-label" htmlFor={ind + el?.name}>
                                                {el?.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
            </CommonModal>
        </div>
    )
}

export default AddContractModal
