import ErrorBoundary from "ErrorBoundary";
import "./App.css";
import "./assets/css/global.css";
import "./assets/css/responsive.css";
import { UserContextProvider } from "context";
import Router from "router/Router";
import { useNetworkStatus } from "hooks";
import toast, { Toaster } from 'react-hot-toast';

import React from "react";
import { GoogleMapsProvider } from "components/GoogleMapsProvider/GoogleMapsProvider";
export const ToggleSidebar = React.createContext(null);

function App() {
  const isOnline = useNetworkStatus();
  return (
    <ErrorBoundary>
      <UserContextProvider>
      <GoogleMapsProvider>
        <Router />
        </GoogleMapsProvider>
      </UserContextProvider>
      {!isOnline && (
        <div
          style={{
            position: "fixed",
            zIndex: 9999,
            bottom: 0,
            background: "#cb1515",
            color: "#fff",
            width: "100%",
            textAlign: "center",
            padding: "6px 0",
          }}
        >
          <p style={{ margin: 0 }}>
            You are offline. Please check your internet connection.
          </p>
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </ErrorBoundary>
  );
}

export default App;
