import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const NewRole = ({
  roleModules,
  setRoleModules,
  loading = false,
  handleSubmit,
}) => {
  const navigate = useNavigate();

  const handleChange = (moduleId, childId, key, value) => {
    setRoleModules((prevModules) => {
      let data = prevModules.map((module) => {
        if (module._id === moduleId) {
          return {
            ...module,
            children: module.children.map((child) => {
              if (child._id === childId) {
                const updatedChild = { ...child };

                if (key === "full_access") {
                  updatedChild.full_access = value;
                  updatedChild.view = updatedChild?.view != 0 ? value : 0;
                  updatedChild.create = updatedChild?.create != 0 ? value : 0;
                  updatedChild.edit = updatedChild?.edit != 0 ? value : 0;
                  updatedChild.delete = updatedChild?.delete != 0 ? value : 0;
                } else {
                  updatedChild[key] = value;
                  if (key === "view") {
                    updatedChild.view = value;
                  } else {
                    
                    const keysToCheck = ["edit", "create", "delete"];
                    const hasAnyPermission = keysToCheck.some(
                      (perm) => updatedChild[perm] === 1
                    );
                    updatedChild.view = hasAnyPermission ? 1 : 2;
                  }
  
                }

                const keysToCheck = ["view", "create", "edit", "delete"];

                const filteredKeys = keysToCheck.filter(
                  (key) => updatedChild[key] !== 0
                );

                const allPermissionsChecked = filteredKeys.every(
                  (key) => updatedChild[key] === 1
                );

                updatedChild.full_access = allPermissionsChecked ? 1 : 2;
                return updatedChild;
              }
              return child;
            }),
          };
        }
        return module;
      });
      return data;
    });
  };

  // const handleParentCheckbox = (moduleId, isChecked, moduleLabel) => {
  //   setRoleModules((prevModules) =>
  //     prevModules.map((module) => {
  //       if (module._id === moduleId) {
  //         return {
  //           ...module,
  //           children: module.children.map((child) => {
  //             return {
  //               ...child,
  //               available: isChecked ? 1 : 0,
  //               not_available: 0,
  //               premium: 0,
  //               coming_soon: 0,
  //               web: child?.web !== 0 ? (isChecked ? 1 : 2) : 0,
  //               mobile: child?.mobile !== 0 ? (isChecked ? 1 : 2) : 0,
  //               full_access: isChecked ? 1 : 2,
  //               view: child?.view !== 0 ? (isChecked ? 1 : 2) : 0,
  //               create: child?.create !== 0 ? (isChecked ? 1 : 2) : 0,
  //               edit: child?.edit !== 0 ? (isChecked ? 1 : 2) : 0,
  //               delete: child?.delete !== 0 ? (isChecked ? 1 : 2) : 0,
  //               excel: child?.excel !== 0 ? (isChecked ? 1 : 2) : 0,
  //               pdf: child?.pdf !== 0 ? (isChecked ? 1 : 2) : 0,
  //               template_no: "",
  //             };
  //           }),
  //         };
  //       }
  //       return module;
  //     })
  //   );
  // };

  const handleParentCheckbox = (moduleId, isChecked, moduleLabel) => {
    setRoleModules((prevModules) =>
      prevModules.map((module) => {
        if (module._id === moduleId) {
          return {
            ...module,
            children: module.children.map((child) => {
              // Check if the moduleLabel is "dashboard", "quick upload", "reports"
              // or the child.label is "payment approval", "bids", or "directory"
             
              if (
                ["Dashboard", "Quick Uploads", "Reports"].includes(moduleLabel) ||
                ["Payment Approval", "Bids", "Directory"].includes(child.label)
              ) {
                // Do not change edit, delete, view, and create for these modules/children
                return {
                  ...child,
                  available: isChecked ? 1 : 0,
                  not_available: 0,
                  premium: 0,
                  coming_soon: 0,
                  web: child?.web !== 0 ? (isChecked ? 1 : 2) : 0,
                  mobile: child?.mobile !== 0 ? (isChecked ? 1 : 2) : 0,
                  full_access: isChecked ? 1 : 2,
                  // Keep view, create, edit, delete unchanged
                  view: child?.view,
                  create: child?.create,
                  edit: child?.edit,
                  delete: child?.delete,
                  excel: child?.excel !== 0 ? (isChecked ? 1 : 2) : 0,
                  pdf: child?.pdf !== 0 ? (isChecked ? 1 : 2) : 0,
                  template_no: "",
                };
              } else {
                // Default behavior for other modules/children
                return {
                  ...child,
                  available: isChecked ? 1 : 0,
                  not_available: 0,
                  premium: 0,
                  coming_soon: 0,
                  web: child?.web !== 0 ? (isChecked ? 1 : 2) : 0,
                  mobile: child?.mobile !== 0 ? (isChecked ? 1 : 2) : 0,
                  full_access: isChecked ? 1 : 2,
                  view: child?.view !== 0 ? (isChecked ? 1 : 2) : 0,
                  create: child?.create !== 0 ? (isChecked ? 1 : 2) : 0,
                  edit: child?.edit !== 0 ? (isChecked ? 1 : 2) : 0,
                  delete: child?.delete !== 0 ? (isChecked ? 1 : 2) : 0,
                  excel: child?.excel !== 0 ? (isChecked ? 1 : 2) : 0,
                  pdf: child?.pdf !== 0 ? (isChecked ? 1 : 2) : 0,
                  template_no: "",
                };
              }
            }),
          };
        }
        return module;
      })
    );
  };
  


  const handleInputChange = (moduleId, childId, value) => {
    setRoleModules((prevModules) =>
      prevModules.map((module) => {
        if (module._id === moduleId) {
          return {
            ...module,
            children: module.children.map((child) => {
              if (child._id === childId) {
                return { ...child, template_no: value };
              }
              return child;
            }),
          };
        }
        return module;
      })
    );
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  return (
    <>
      <div className="__madCont_bx">
        <div className="__inrFxl_cnt">
          <div className="_otrRl_table">
            {roleModules?.map((module, ind) => {
              const isFullAccessForAllChildren = module?.children?.some((child) => {
               
                const hasAnyPermission =
                 
                  child.view == 1 ||
                  child.create == 1 ||
                  child.edit == 1 ||
                  child.delete == 1 ||
                  child.web == 1 ||
                  child.mobile == 1;
              
                const isExcel = module?.label === "Reports" ? child.excel === 1 : false;
                const isPdf = module?.label === "Reports" ? child.pdf === 1 : false;
  
                return hasAnyPermission || isExcel || isPdf;
              });



              return (
                <>
                  <div className="_mainRl_table" key={ind}>
                    <div className="_addRl_headCheck">
                      <input
                        type="checkbox"
                        label="Dashboard"
                        id={`parent_checkbox_${module._id}`}
                        checked={isFullAccessForAllChildren}
                        onChange={(e) =>
                          handleParentCheckbox(
                            module._id,
                            e.target.checked,
                            module.label
                          )
                        }
                      />
                      <label
                        htmlFor={`parent_checkbox_${module._id}`}
                        className="anvLabel"
                      >
                        {module?.label}
                      </label>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Description</th>

                          {/* <th>
                                                        <div className='_flRx_dl'>
                                                            <div className='_frTie'>Available</div>
                                                            <div className='_frTie'>Not Available</div>
                                                            <div className='_frTie'>Premium</div>
                                                            <div className='_frTie'>Coming Soon</div>
                                                        </div>
                                                    </th> */}

                          <th>
                            <div className="_flRx_dl">
                              <div className="_TwTie">Web</div>
                              <div className="_TwTie">Mobile</div>
                            </div>
                          </th>
                          {module?.label === "Reports" ? (
                            <th>
                              <div className="_flRx_dl justify-content-start">
                                <div className="_TwTie inxEl">Excel</div>
                                <div className="_TwTie inxEl">PDF</div>
                              </div>
                            </th>
                          ) : module?.label === "Dashboard" ||
                            module?.label === "Quick Uploads" ? (
                            <th></th>
                          ) : (
                            <th>
                              <div className="_flRx_dl">
                                <div className="_fvTie">Full Access</div>
                                <div className="_fvTie">View</div>
                                <div className="_fvTie">Create</div>
                                <div className="_fvTie">Edit</div>
                                <div className="_fvTie">Delete</div>
                              </div>
                            </th>
                          )}

                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        {module?.children?.map((child, index) => {
                          const isAvailable = child.available === 1;
                          const isCheckboxDisabled =
                            !isAvailable ||
                            child.not_available === 1 ||
                            child.premium === 1 ||
                            child.coming_soon === 1;
                          return (
                            <tr key={`childIndex_${index}`}>
                              <td>{child?.label} </td>

                              {/* <td>
                                                                <div className="_flRx_dl">
                                                                    {['available', 'not_available', 'premium', 'coming_soon'].map((key, indd) => (
                                                                        <div key={indd} className="_frTie">
                                                                            <input
                                                                                type="radio"
                                                                                name={`chlRadio_${child._id}`}
                                                                                id={`${child._id}_${key}`}
                                                                                checked={child[key] === 1}
                                                                                onChange={() => handleChildRadioChange(module._id, child._id, key)}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </td> */}

                              <td>
                                <div className="_flRx_dl">
                                  <div className="_TwTie">
                                    <input
                                      type="checkbox"
                                      name="web"
                                      id={`web_${child._id}`}
                                      disabled={child.web == 0}
                                      checked={child.web === 1}
                                      onChange={(e) =>
                                        handleChange(
                                          module._id,
                                          child._id,
                                          "web",
                                          e.target.checked ? 1 : 2
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`web_${child._id}`}
                                      className="anvLabel"
                                    ></label>
                                  </div>

                                  <div className="_TwTie">
                                    <input
                                      type="checkbox"
                                      name="mobile"
                                      id={`mobile_${child._id}`}
                                      disabled={child.mobile == 0}
                                      checked={child.mobile === 1}
                                      onChange={(e) =>
                                        handleChange(
                                          module._id,
                                          child._id,
                                          "mobile",
                                          e.target.checked ? 1 : 2
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`mobile_${child._id}`}
                                      className="anvLabel"
                                    ></label>
                                  </div>
                                </div>
                              </td>

                              {module?.label === "Dashboard" ||
                              module?.label === "Quick Uploads" ||
                              child?.label === "Payment Approval" ||
                              child?.label === "Directory" ||
                              child?.label === "Bids" ? (
                                <td></td>
                              ) : module?.label === "Reports" ? (
                                <td>
                                  <div className="_flRx_dl justify-content-start">
                                    {["excel", "pdf"].map((key) => (
                                      <div key={key} className="_TwTie inxEl">
                                        <input
                                          type="checkbox"
                                          name={key}
                                          id={`${key}_${child._id}`}
                                          disabled={child[key] === 0}
                                          checked={child[key] === 1}
                                          onChange={(e) =>
                                            handleChange(
                                              module._id,
                                              child._id,
                                              key,
                                              e.target.checked ? 1 : 2
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor={`${key}_${child._id}`}
                                          className="anvLabel"
                                        ></label>
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  <div className="_flRx_dl">
                                    {[
                                      "full_access",
                                      "view",
                                      "create",
                                      "edit",
                                      "delete",
                                    ].map((key) => {
                                      const isFullAccessDisabled =
                                        key === "full_access" &&
                                        [
                                          "view",
                                          "create",
                                          "edit",
                                          "delete",
                                        ].every((perm) => child[perm] === 0);

                                      return (
                                        <div key={key} className="_fvTie">
                                          <input
                                            type="checkbox"
                                            name={key}
                                            id={`${key}_${child._id}`}
                                            disabled={
                                              key === "full_access"
                                                ? isFullAccessDisabled:key === "view"?["edit","delete","create"].some((key)=>child[key]==1) 
                                                : child[key] === 0
                                            }
                                            checked={ key === "full_access"?[
                                              "view",
                                              "create",
                                              "edit",
                                              "delete",
                                            ].every((perm) => child[perm] === 1):child[key] === 1}
                                            onChange={(e) =>
                                              handleChange(
                                                module._id,
                                                child._id,
                                                key,
                                                e.target.checked ? 1 : 2
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={`${key}_${child._id}`}
                                            className="anvLabel"
                                          ></label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </td>
                              )}

                              <td>
                                {child.premium === 1 && (
                                  <input
                                    type="number"
                                    className="form-control"
                                    maxLength="8"
                                    placeholder="Template No."
                                    onInput={maxLengthCheck}
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                    value={child.template_no}
                                    onChange={(e) =>
                                      handleInputChange(
                                        module._id,
                                        child._id,
                                        e.target.value
                                      )
                                    }
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      {/* <div className='__mdxFooter'>
                <button className='_continueBn' onClick={(e) => handleSubmit(e)} disabled={loading}>{loading ? 'Loading...' : 'Save & Submit'}</button>
                <button className='_cancleBn' onClick={(e) => navigate('/plans')}>Cancel</button>
            </div> */}
    </>
  );
};

export default NewRole;
