import { ButtonHeading, CustomPagination } from "components";
import { AddFastTagModal, AddFuelCardModal } from "modals";
import React, { useContext, useEffect, useState } from "react";
import Tab from "components/common/Tab";
import { constant } from "constants/constant";
import OwnDriver from "../OwnDriver";
import MarketDriver from "../MarketDriver";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { UserContext } from "context";
import toast from "react-hot-toast";
import { fastagAccount } from "_services";
import { StorageServices } from "utils";
import { getFastagTxnList } from "_services/accountsServices/fuelStationServices";

const FastTag = () => {
  const { mastersChild } = useContext(UserContext);
  const masterChildFasttag = mastersChild?.find((i) => i?.label === "Fasttag");
  const [showExpModal, setShowExpModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [fuelCardAccountList, setFuelCardAccountList] = useState([]);
  const [fuelTransactionList, setFuelTransactionList] = useState([]);
  const [filter, setFilter] = useState({
    associate_status: 0,
    limit: 10,
    offset: 1,
  });
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  const [tabs, setTabs] = useState([
    { label: "All", value: 0 },
    { label: "Assigned", value: 1 },
    { label: "Unassigned", value: 2 },
    { label: "Omitted", value: 3 },
  ]);

  // const toggleOffcanvas = () => {
  //     setShowOffcanvas(!showOffcanvas);
  //     setEditFuelStationData(null)
  //   };

  useEffect(() => {
    getfastagAccount();
    getFastagTax();
  }, [filter]);

  const getFastagTax = async () => {
    const payload = {
      company_id: StorageServices?.getCompanyId(),
      ...filter,
    };

    try {
      const res = await getFastagTxnList(payload);

      if (res?.responseCode == 200) {
        setFuelTransactionList(res?.data?.getTxnData);
        setTotalItems(res?.data?.totalRecords);
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {}
  };

  const getfastagAccount = async () => {
    try {
      const res = await fastagAccount({
        company_id: StorageServices.getCompanyId(),
      });

      if (res?.responseCode == 200) {
        const list = res?.data?.fastag?.map((i) => ({
          ...i,
          master_name: i?.fastag_company_id?.name,
        }));
        setFuelCardAccountList(list);
      }
    } catch (error) {}
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };

  let AddFastTagModals = {
    isOpen: showExpModal,
    closeModal: (e) => setShowExpModal(false),
    modalMainClass: "_dashCommon_Modal ",
    modalTitle: "Add Fast Tag",
    // headerPart: true,
    footerPart: true,
    submitTitle: "Add Fast Tag",
    // getAccountListHandler: getAccountListHandler
  };

  const tabProps = {
    tabList: constant.fuel_cardTabs,
    callBackTab: (e) => setActiveTab(e),
    activeTab: activeTab,
  };

  return (
    <div>
      <ButtonHeading
        title={"Fastag"}
        onClick={() => setShowExpModal(!showExpModal)}
        buttonTitle={
          masterChildFasttag?.permissions?.create === 1 && "Add Fast Tag"
        }
        showSearchFilter={{
          search: true,
          filterOption: true,
          filterCount: true,
        }}
        // expenseOnClick={() => setShowExpModal(!showExpModal)}
        // Expenses={true}
        // onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        // filter={filter}
        // setFilter={setFilter}
      />

      <div className="addPr_flBx">
        <Tab {...tabProps} />
        {activeTab === 1 && (
          <div className="_mainFc_hv">
            <div className="accordion _fuelCard_acc" id="quickUploadAccordion">
              {fuelCardAccountList.map((account, index) => {
                const firstReturn = (vehicleNumber) => {
                  const firstPart = vehicleNumber.slice(0, 5);
                  return firstPart;
                };

                const secondReturn = (vehicleNumber) => {
                  const secondPart = vehicleNumber.slice(5);
                  return secondPart;
                };

                return (
                  <div className="accordion-item" key={account.id}>
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          index === 0 ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded={index === 0 ? "true" : "false"}
                        aria-controls={`collapse${index}`}
                      >
                        <div className="_msxFlx">
                          <p>
                            {account.master_name} | {account.customer_id}
                          </p>
                          <span>{account?.fastag_accounts?.length}</span>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${
                        index === 0 ? "show" : ""
                      }`}
                      data-bs-parent="#quickUploadAccordion"
                    >
                      <div className="accordion-body">
                        <table className="_trpTable_xd">
                          {account?.fastag_accounts.map((item) => {
                            return (
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="signDropdown">
                                      <button className="dropbtn11">
                                        <BsThreeDotsVertical />
                                      </button>
                                      <div className="dropdownContent">
                                        {masterChildFasttag?.view === 1 && (
                                          <Link
                                            to="#"
                                            className="_avPointer"
                                            style={{ textDecoration: "none" }}
                                          >
                                            <span className="_samAnchor">
                                              <span className="viewIcon_avt">
                                                <AiOutlineEye />
                                              </span>
                                              View
                                            </span>
                                          </Link>
                                        )}
                                        {masterChildFasttag?.edit === 1 && (
                                          <div className="_avPointer">
                                            <span className="_samAnchor">
                                              <span className="editIcon_avt">
                                                <FiEdit />
                                              </span>
                                              Edit
                                            </span>
                                          </div>
                                        )}
                                        {masterChildFasttag?.delete === 1 && (
                                          <div className="_avPointer">
                                            <span className="_samAnchor">
                                              <span className="deleteIcon_avt">
                                                <FaRegTrashCan color="red" />
                                              </span>
                                              Delete
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <b>{`${
                                        item?.account_number
                                          ? item?.account_number
                                          : ""
                                      }`}</b>
                                      <span className="textBottom">
                                        {firstReturn(
                                          item?.associate_vehicle_id?.vehicle_no
                                            ? item?.associate_vehicle_id
                                                ?.vehicle_no
                                            : ""
                                        )}{" "}
                                        <span className="blue">
                                          {secondReturn(
                                            item?.associate_vehicle_id
                                              ?.vehicle_no
                                              ? item?.associate_vehicle_id
                                                  ?.vehicle_no
                                              : ""
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                  </td>
                                  {/* <td>
                                                          <div>
                                                            <b>
                                                              Example Petroleum, Bharat Petroleum
                                                              Dealers, Mungeli
                                                            </b>
                                                            <span className="textBottom">
                                                              {new Date().toLocaleDateString()}{" "}
                                                              {new Date().toLocaleTimeString()}
                                                            </span>
                                                          </div>
                                                        </td> */}
                                  <td>
                                    <div>
                                      <span className="textBottom">
                                        Balance
                                      </span>
                                      <span>{`Rs. ${item?.balance.toFixed(
                                        2
                                      )}`}</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {activeTab === 2 && (
          <div>
            <ul className="_tapUpper_tab">
              {tabs.map((tab, index) => (
                <li
                  key={index}
                  className={
                    tab.value === filter?.associate_status ? "active" : ""
                  }
                  onClick={() =>
                    setFilter({ ...filter, associate_status: tab?.value })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <span> {tab.label} </span>
                </li>
              ))}
            </ul>

            <div className="_indentTable _disPatchView _fuelCrd__table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>VEHICLE NUMBER</th>
                    <th>TOLL PLAZA</th>
                    <th>AMOUNT</th>
                    <th>TRIP RELATION</th>
                  </tr>
                </thead>
                {fuelTransactionList &&
                  fuelTransactionList?.map((item) => {
                    return (
                      <tbody>
                        <tr>
                          <td>
                            <div className="signDropdown">
                              <button className="dropbtn11">
                                <BsThreeDotsVertical />
                              </button>
                              <div className="dropdownContent">
                                {masterChildFasttag?.permissions?.view ===
                                  1 && (
                                  <Link
                                    to="/"
                                    className="_avPointer"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <span className="_samAnchor">
                                      <span className="viewIcon_avt">
                                        <AiOutlineEye />
                                      </span>
                                      View
                                    </span>
                                  </Link>
                                )}
                                {masterChildFasttag?.permissions?.edit ===
                                  1 && (
                                  <div
                                    // onClick={(e) => editHandler(item)}
                                    className="_avPointer"
                                  >
                                    <span className="_samAnchor">
                                      <span className="editIcon_avt">
                                        <FiEdit />
                                      </span>
                                      Edit
                                    </span>
                                  </div>
                                )}
                                {masterChildFasttag?.permissions?.delete ===
                                  1 && (
                                  <div
                                    // onClick={(e) => deleteHandler(item)}
                                    className="_avPointer"
                                  >
                                    <span className="_samAnchor">
                                      <span className="deleteIcon_avt">
                                        <FaRegTrashCan color="red" />
                                      </span>
                                      Delete
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className="_dateNtime _pDate">
                              <p className="_tblText_inDent">
                                {item?.vehicle?.vehicle_no}
                              </p>
                              <p className="_lgtText_inDent">
                                {item?.transaction_no}
                              </p>
                            </div>
                          </td>

                          <td>
                            <div className="_dateNtime _pDate">
                              <p className="_tblText_inDent">
                                {item?.plaza_code}
                              </p>
                            </div>
                          </td>

                          <td>
                            <div className="__locationWid_bx">
                              <p className="_lgtText_inDent">Charges</p>
                              <p className="_tblText_inDent">
                                Rs.{" "}
                                {item?.credit_or_debit === 2
                                  ? item?.debit_amount?.toFixed(2)
                                  : item?.credit_amount?.toFixed(2)}
                              </p>
                            </div>
                          </td>

                          <td>
                            {!item?.trip ? (
                              <div>
                                <button className="_addComm_btn">
                                  Add Relation
                                </button>
                                <button className="_addComm_btn ms-1">
                                  OMIT 
                                </button>
                              </div>
                            ) : (
                              <div>
                                <div className="remove_div">
                                  <p className="_tblText_inDent">
                                    Trip - {item?.trip?.trip_number} 
                                  </p>
                                  <button>
                                Remove
                                </button>
                                </div>
                                <div className="remove_div">
                                  <p className="_tblText_inDent">
                                    Route - {item?.trip?.route_name} 
                                  </p>
                                 
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            </div>
            {fuelTransactionList?.length > 0 && (
              <div className="paginationFt_avt ">
                <CustomPagination
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  currentPage={filter?.offset}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {showExpModal && <AddFastTagModal {...AddFastTagModals} />}
    </div>
  );
};

export default FastTag;
