import { API, axiosInstance } from "lib";

export const getZoneListData = (data={}) => {
  return axiosInstance
    .post(API.GET_ZONE_LIST , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const createZone = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_ZONE , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getSingleVenderData = (data={}) => {
  return axiosInstance
    .post(API.GET_SINGLE_ZONE , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const deleteZoneData = (data={}) => {
  return axiosInstance
    .post(API.DELETE_ZONE , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};