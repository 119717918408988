import { API, axiosInstance } from "lib";


export const deleteUserData = (data={}) => {
  return axiosInstance
    .post(API.DELETE_USER , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getUserDetailsData = (data={}) => {
  return axiosInstance
    .post(API.GET_SINGLE_USER , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};
export const changeUserStatusData = (data={}) => {
  return axiosInstance
    .post(API.USER_STATUS , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};