import React, { useContext, useEffect, useRef, useState } from 'react'
import { ButtonHeading, CommonInput, CommonModal, CustomPagination } from 'components'
import { changeStatusOfConsignorData, deleteConsignorData, getstaffListData } from '_services/accountsServices/consignorServices';
import StorageService from "utils/StorageServices/StorageServices";
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import MastersFilterModal from 'modals/MastersFilterModal';
import RupeeIcon from '../Supplier/RupeeIcon';
import LoadingBar from 'react-top-loading-bar';
import { UserContext } from 'context';
import { AddEditStaff } from 'modals';
import { deleteStaffData, getStaffChangeStatusData, getStaffListData } from '_services/accountsServices/staffServices';

const Staff = (props) => {

  const { mastersChild } = useContext(UserContext);
  const masterChildParty = mastersChild?.find((i) => i?.label === "Party");
  const loadingBarRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [staffList, setStaffList] = useState([])
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [checkboxStates, setCheckboxStates] = useState({});

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: '',
    status: ''
  });

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const getDataStaffList = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        ...filter,
        company_id: StorageService.getCompanyId(),
      }
      const res = await getStaffListData(object);
      if (res?.responseCode == 200) {
        setStaffList(res?.data?.list);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {
      toast.error(`Error`);
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  useEffect(() => {
    getDataStaffList();
  }, [filter, showOffcanvas])

  const deleteHandler = (item) => {
    setIsDelete(item)
  }

  const submitDeleteConsignor = async () => {
    setLoading(true);
    try {
      let object = {
        company_id: isDelete?.company_id,
        staff_id: isDelete?._id,
      }
      const res = await deleteStaffData(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getDataStaffList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success('Error !! ');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  }

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteConsignor,
    isLoadding: loading,
  }

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Staff Name", accessor: "party_name" },
    { header: "Contact No.", accessor: "contact_nunber" },
    { header: "City", accessor: "city" },
    { header: "Status", accessor: "status" },
  ];

  useEffect(() => {
    if (staffList && staffList.length > 0) {
      const initialStates = staffList.reduce((acc, item) => {
        acc[item._id] = Boolean(item?.active);
        return acc;
      }, {});
      setCheckboxStates(initialStates);
    }
  }, [staffList]);

  const handleCheckboxChange = (id) => {
    const newStatus = !checkboxStates[id];
    setCheckboxStates(prevStates => ({ ...prevStates, [id]: newStatus }));

    const object = {
      company_id: StorageService.getCompanyId(),
      staff_id: id,
      active: Number(newStatus)
    };
    statusChangeofConsigner(object);
  };

  const statusChangeofConsigner = async (obj) => {
    try {
      if (obj) {
        const res = await getStaffChangeStatusData(obj);
        if (res?.responseCode === 200) {
          toast.success(`${res?.responseMessage}`);
        } else {
          toast.error('Error !!');
          await getDataStaffList();
        }
      }
    } catch (error) {
      toast.error(error.message);
      await getDataStaffList();
    }
  };

  const RowListData = (data) => {

    return data?.map((item, i) => ({
      ...item,
      serial: <>
        <div className="signDropdown">
          <button className="dropbtn11"> <BsThreeDotsVertical /> </button>
          <div className="dropdownContent">
          {masterChildParty?.permissions?.view === 1 &&
            <Link to={`details/${item?._id}`}
              className='_avPointer'
              style={{ textDecoration: 'none' }}
            >
              <span className='_samAnchor'>
                <span className='viewIcon_avt'>
                  <AiOutlineEye />
                </span>
                &nbsp; View
              </span>
            </Link>
            }
            {masterChildParty?.permissions?.edit === 1 &&
            <div
              className='_avPointer'
            >
              <span className='_samAnchor'>
                <span className='editIcon_avt'>
                  <FiEdit />
                </span>
                Edit
              </span>
            </div>}
            
            {masterChildParty?.permissions?.delete === 1 &&
              <div
                onClick={(e) => deleteHandler(item)}
                className='_avPointer'
              >
                <span className='_samAnchor'>
                  <span className='deleteIcon_avt'>
                    <FaRegTrashCan color='red' />
                  </span>
                  Delete
                </span>
              </div>
             }
          </div>
        </div>
      </>,
      party_name:
        <Link to={`details/${item?._id}`} style={{ textDecoration: 'none' }}>
          {item?.name ? item?.name : '-'}
        </Link>,

      contact_nunber:
        <React.Fragment>
          {item?.contact_no ? item?.contact_no : '-'}
        </React.Fragment>,

      city:
        <React.Fragment>
          {item?.city?.label ? item?.city?.label : '-'}
        </React.Fragment>,

      status: (
        <div className="_anvSwitcherNew form-check form-switch" style={{ width: "125px" }}>
          <input
            className="form-check-input"
            type="checkbox"
            name="actStatus"
            id={`actStatus-${item._id}`}
            autoComplete="off"
            checked={checkboxStates[item._id]}
            onChange={() => handleCheckboxChange(item._id)}
          />
          <span className={checkboxStates[item._id] ? "active_label_masters_status" : "disabled_label_masters_status"}>
            {checkboxStates[item._id] ? "Active" : "Disabled"}
          </span>
        </div>
      ),
      action:
        <React.Fragment>
          -
        </React.Fragment>
      ,
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }))
  };


  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      
      <ButtonHeading
        title={"Staff"}
        onClick={toggleOffcanvas}
        buttonTitle={masterChildParty?.permissions?.create === 1 && "Add Staff"}
        showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        filter={filter}
        setFilter={setFilter}
      />

      <div className='_tabWith_outTab'>
        <BootstrapTable
          columns={columns}
          data={RowListData(staffList?.length > 0 ? staffList : [])}
          isLoading={loading}
        />

        {staffList?.length && <div className='paginationFt_avt'>
        <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter.offset}
          onPageChange={handlePageChange}
        />
        </div>}
      </div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvas ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showOffcanvas ? 'visible' : 'hidden' }}
      >
        {showOffcanvas &&
          <AddEditStaff isOpen={showOffcanvas} onClose={(e) => setShowOffcanvas(false)} />
        }
      </div>
      <div className={showOffcanvas ? 'offBack_drop' : ''}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
      >
        {showFilterModal &&
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        }
      </div>
      <div className={showFilterModal ? 'offBack_drop' : ''}></div>

      {
        isDelete?.company_id && (
          <CommonModal {...propsObject}>
            <p className='p-3'> Are you sure you want to delete {isDelete?.name} ?</p>
          </CommonModal>
        )
      }
    </div>
  )
}

export default Staff