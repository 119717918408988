import { API, axiosInstance } from "lib";

export const getAllConsignorgGroup = (data={}) => {
  return axiosInstance
    .post(API.CONSIGNOR_GROUP_DATA , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const createConsignor = (data = {}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'apk_add_consignor', data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const getConsignorListData = (data={}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'get_consignor_list' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const deleteConsignorData = (data={}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'delete_consignor' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const fetchConsignorDetailsData = (data={}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'get_consignor' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const updateConsignorSetting = (data = {}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'update_setting_for_consignor', data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const changeStatusOfConsignorData = (data = {}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'change_consignor_status', data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const updateConsignorOtherSetting = (data = {}) => {
  return axiosInstance
    .post(API.CONSIGNOR_DATA + 'update_other_setting_for_consignor', data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};

export const updateBranchOtherSetting = (data = {}) => {
  return axiosInstance
    .post(API.GET_BRANCH + 'update_setting_for_branch', data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
//      throw err;
    });
};