import { CustomSelect } from 'components';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from 'context';
import { State } from "country-state-city";
import { constant } from 'constants/constant';
import { VehicleTypeModal } from 'modals';
import { getStatesData } from '_services';

const InvitationFilters = ({ filter, setFilter, lorryType }) => {
    const { masterData, cities } = useContext(UserContext);
    const [vehicleTypeshow, setVehicleTypeShow] = useState(false);
    const [vehicleTypeLabel, setVehicleTypeLabel] = useState({ label: "", value: "" });
    const [companyType, setCompanyType] = useState([]);
    const [entityType, setEntityType] = useState([]);
    const [search, setSearch] = useState('');

    const onhandleSelect = async (item, name) => {
        if (name === "location") {
            let data = {
                countryCode : item?.countryCode,
                stateCode : item?.stateCode
              }
              let response = await getStatesData(data);
              if (response?.responseCode === 200) {
                setFilter((filter) => ({
                    ...filter,
                    offset: 1,
                    filters: {
                        ...filter.filters,
                        [name]: { ...item, city: item.value, state: response?.data?.name }
                    }
                }));
              }
            // let stateDetails = State.getStateByCodeAndCountry(item?.stateCode, item?.countryCode);
            // setFilter((filter) => ({
            //     ...filter,
            //     offset: 1,
            //     filters: {
            //         ...filter.filters,
            //         [name]: { ...item, city: item.value, state: stateDetails?.name }
            //     }
            // }));
        } else {
            setFilter((filter) => ({
                ...filter,
                offset: 1,
                filters: {
                    ...filter.filters,
                    [name]: item,
                }
            }));
        }
    };

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (search.trim() !== "") {
                onhandleSelect(search, 'keyword');
            }
        }, 1000);

        return () => clearTimeout(debounceTimer);
    }, [search]);

    useEffect(() => {
        if (masterData?.company_type?.length > 0) {
            let data = [{ label: 'Any', value: '' }, ...masterData?.company_type?.map((el) => ({
                // label: el?.name === 'I am a Truck Owner' ? 'Truck Owner' :
                //     el?.name === 'I am a Transporter' ? 'Transporter' :
                //         el?.name === 'I am a Transport Agent' ? 'Transport Agent' : 'Other',
                label:el?.name,
                value: el?._id,
            }))];
            setCompanyType(data);
        }

        if (masterData?.entity_type_list?.length > 0) {
            let data2 = [{ label: 'Any', value: '' }, ...masterData?.entity_type_list?.map((el) => ({
                label: el?.name,
                value: el?.id,
            }))];
            setEntityType(data2);
        }
    }, [masterData?.company_type, masterData?.entity_type_list]);

    const resetFunction = () => {
        setSearch('');
        setVehicleTypeLabel({ label: '', value: '' });
        setFilter((filter) => ({
            ...filter,
            offset: 1,
            filters: {
                company_type: { label: '', value: '' },
                entity_type: { label: '', value: '' },
                lorry_type_id: { label: '', value: '' },
                rating: { label: '', value: '' },
                location: { label: '', value: '' },
            }
        }));
    };

    const callBackVehicleType = (value) => {
        onhandleSelect({ label: value?.activeTab?.lorry_type, value: value?.activeTab?._id }, 'lorry_type_id');
        setVehicleTypeLabel({ label: `${value?.activeTab?.lorry_type}`, value: "" });
        setVehicleTypeShow(false);
    };

    let vehicleTypeProps = {
        isOpen: vehicleTypeshow,
        closeModal: () => setVehicleTypeShow(false),
        headerPart: true,
        footerPart: true,
        modalMainClass: "_dashCommon_Modal _smModal",
        modalTitle: "Vehicle Type",
        submitTitle: "Save",
        isVehicleTyre: false,
        lorryType,
        callBackVehicleType,
    };


    return (
        <>
            <div className='_mrktFilt_mainBx'>
                <div className='_mrktInfilts_bx'>
                    <div className='head_load'>
                        <span className="search_loads"> Filters </span>
                        <button className='reSet_btn' onClick={(e) => resetFunction(e)}>Reset</button>
                    </div>
                    <div className='_optFilts'>
                        <div style={{ marginBottom: '5px' }}>
                            <span className='invitation_fonts'> Type </span>
                            <CustomSelect
                                options={companyType}
                                value={filter?.filters?.company_type}
                                onSelect={(data) => onhandleSelect(data, 'company_type')}
                                placeholder="Any"
                                isClearOption={filter?.filters?.company_type?.value ? true : false}
                                isClearHandleChange={(event) => {
                                    event.stopPropagation();
                                    setFilter((filter) => ({
                                        ...filter,
                                        filters: {
                                            ...filter.filters,
                                            company_type: { label: '', value: '' }
                                        }
                                    }))
                                }}
                            />
                        </div>

                        <div style={{ marginBottom: '5px' }}>
                            <span className='invitation_fonts'>Entity</span>
                            <CustomSelect
                                options={entityType}
                                value={filter?.filters?.entity_type}
                                onSelect={(data) => onhandleSelect(data, 'entity_type')}
                                placeholder="Any"
                                isClearOption={filter?.filters?.entity_type?.value ? true : false}
                                isClearHandleChange={(event) => {
                                    event.stopPropagation();
                                    setFilter((filter) => ({
                                        ...filter,
                                        filters: {
                                            ...filter.filters,
                                            entity_type: { label: '', value: '' }
                                        }
                                    }))
                                }}
                            />
                        </div>

                        <div style={{ marginBottom: '5px' }}>
                            <span className='invitation_fonts'>Location</span>
                            <CustomSelect
                                options={[{ label: 'All Location', value: '' }, ...cities?.map((el) => {
                                    return {
                                        ...el,
                                        label: el?.label,
                                        value: el?.value,
                                    }
                                })]}
                                value={filter?.filters?.location}
                                onSelect={(data) => onhandleSelect(data, 'location')}
                                placeholder="All Location"
                                isClearOption={filter?.filters?.location?.value ? true : false}
                                isClearHandleChange={(event) => {
                                    event.stopPropagation();
                                    setFilter((filter) => ({
                                        ...filter,
                                        filters: {
                                            ...filter.filters,
                                            location: { label: '', value: '' }
                                        }
                                    }))
                                }}
                            />
                        </div>

                        <div
                            className="_divForth"
                            onClick={() => setVehicleTypeShow(true)}
                            style={{ marginBottom: '5px' }}
                        >
                            <span className='invitation_fonts'> Vehicle Type </span>
                            <CustomSelect
                                placeholder="Any"
                                options={[
                                    { label: "Vehicle Number", value: "123" },
                                ]}
                                value={vehicleTypeLabel}
                                openList={false}
                                isClearOption={filter?.filters?.lorry_type_id?.label ? true : false}
                                isClearHandleChange={(event) => {
                                    event.stopPropagation();
                                    setFilter((filter) => ({
                                        ...filter,
                                        filters: {
                                            ...filter.filters,
                                            lorry_type_id: { label: '', value: '' }
                                        }
                                    }))
                                    setVehicleTypeLabel({
                                        label: '',
                                        value: '',
                                    });
                                }}
                            />
                        </div>

                        <div style={{ marginBottom: '5px' }}>
                            <span className='invitation_fonts'> Rating </span>
                            <CustomSelect
                                options={constant.ratingArray}
                                value={filter?.filters?.rating}
                                onSelect={(data) => onhandleSelect(data, 'rating')}
                                placeholder="Any"
                                isClearOption={filter?.filters?.rating?.value ? true : false}
                                isClearHandleChange={(event) => {
                                    event.stopPropagation();
                                    setFilter((filter) => ({
                                        ...filter,
                                        filters: {
                                            ...filter.filters,
                                            rating: { label: '', value: '' }
                                        }
                                    }))
                                }}
                            />
                        </div>

                        <div className="_inpW_search _lrl_inpSrch mt-3" style={{ marginBottom: '10px' }}>
                            <svg className='srcIcon' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path opacity="0.2" d="M17.347 16.3507L12.7293 11.733C13.8389 10.4008 14.3923 8.69214 14.2742 6.96238C14.1562 5.23263 13.3757 3.61498 12.0954 2.44596C10.815 1.27695 9.13319 0.64656 7.39985 0.685944C5.66652 0.725329 4.0151 1.43145 2.78913 2.65742C1.56317 3.88339 0.857043 5.53481 0.817658 7.26814C0.778274 9.00147 1.40866 10.6833 2.57768 11.9636C3.7467 13.244 5.36434 14.0244 7.0941 14.1425C8.82385 14.2606 10.5325 13.7072 11.8647 12.5976L16.4824 17.2153L17.347 16.3507ZM2.06069 7.43204C2.06069 6.34363 2.38344 5.27967 2.98812 4.3747C3.5928 3.46972 4.45227 2.76438 5.45782 2.34787C6.46337 1.93135 7.56985 1.82238 8.63734 2.03471C9.70483 2.24705 10.6854 2.77117 11.455 3.54078C12.2246 4.3104 12.7487 5.29095 12.9611 6.35844C13.1734 7.42593 13.0644 8.53241 12.6479 9.53797C12.2314 10.5435 11.5261 11.403 10.6211 12.0077C9.71611 12.6124 8.65215 12.9351 7.56375 12.9351C6.10474 12.9335 4.70596 12.3532 3.67428 11.3215C2.64261 10.2898 2.0623 8.89104 2.06069 7.43204Z" fill="black"></path>
                            </svg>
                            <input
                                type="text"
                                placeholder="Search"
                                className="simpleFilter_input"
                                autoComplete="off"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {vehicleTypeshow && <VehicleTypeModal {...vehicleTypeProps} />}
        </>
    )
}

export default React.memo(InvitationFilters)