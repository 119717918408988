import React, { useEffect, useState, useRef, useContext } from 'react';
import { CommonModal, CustomPagination } from 'components';
import StorageService from "utils/StorageServices/StorageServices";
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { AddEditOwnDriver } from 'modals';
import { changeDriverStatusData, deleteOwnDriverData, getOwnDriverListData } from '_services/accountsServices/ownDriverServices';
import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import RupeeIcon from '../Supplier/RupeeIcon';
import LoadingBar from 'react-top-loading-bar';
import { UserContext } from 'context';
import { getDriverDropdownListData } from '_services/dropdownServices/dropdownService';

const OwnDriver = ({ showOffcanvas, toggleOffcanvas }) => {

  const loadingBarRef = useRef(null);
  const { mastersChild } = useContext(UserContext);
  const masterChildDriver = mastersChild?.find((i) => i?.label === "Driver");
  const [ownDriverList, setOwnDriverList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: '',
    status: 1,
    driver_type:1
  });

  const [editOwnDriverData, setEditOwnDriverData] = useState(null)
  const [checkboxStates, setCheckboxStates] = useState({});

  const getDataOwnDriverList = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        ...filter,
        company_id: StorageService.getCompanyId(),
      }
      // const res = await getDriverDropdownListData(object);
      const res = await getOwnDriverListData(object);
      if (res?.responseCode == 200) {
        setOwnDriverList(res?.data?.driver_list);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {
      // throw error;
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  useEffect(() => {
    getDataOwnDriverList();
  }, [filter, showOffcanvas])

  const deleteHandler = (item) => {
    setIsDelete(item);
  }

  const submitDeleteOwnDriver = async (e) => {
    e.preventDefault()
    setLoading(true);
    try {
      let object = {
        company_id: isDelete?.company_id,
        driver_id: isDelete?._id,
      }
      const res = await deleteOwnDriverData(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getDataOwnDriverList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success('Error !! ');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  }

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteOwnDriver,
    isLoadding: loading,
  }

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Driver Name", accessor: "driver_name" },
    { header: "Contact No.", accessor: "contact_nunber" },
    { header: "Licence No.", accessor: "licence_number" },
    { header: "Truck Associated", accessor: "truck_associated" },
    { header: "Balance", accessor: "balance" },
    { header: "Status", accessor: "status" },
    
  ];

  useEffect(() => {
    if (ownDriverList && ownDriverList.length > 0) {
      const initialStates = ownDriverList.reduce((acc, item) => {
        acc[item._id] = Boolean(item?.active);
        return acc;
      }, {});
      setCheckboxStates(initialStates);
    }
  }, [ownDriverList]);

  const handleCheckboxChange = (id) => {
    const newStatus = !checkboxStates[id];

    setCheckboxStates(prevStates => ({ ...prevStates, [id]: newStatus }));

    const object = {
      company_id: StorageService.getCompanyId(),
      driver_id: id,
      active: Number(newStatus)
    };
    statusChangeofOwnDriver(object);
  };

  const statusChangeofOwnDriver = async (obj) => {
    try {
      if (obj) {
        const res = await changeDriverStatusData(obj);
        if (res?.responseCode === 200) {
          toast.success(`${res?.responseMessage}`);
        } else {
          toast.error('Error !!');
          await getDataOwnDriverList()
        }
      }
    } catch (error) {
      toast.error(error.message);
      await getDataOwnDriverList()
    }
  };



  const editHandler = (supplier) => {
    toggleOffcanvas(true);
    setEditOwnDriverData(supplier);
  };

  const RowListData = (data) => {

    return data?.map((item, i) => ({
      ...item,
      serial:
        <React.Fragment>
          <div className="signDropdown">
            <button className="dropbtn11">
              <BsThreeDotsVertical />
            </button>
            <div className="dropdownContent">
              {masterChildDriver?.view === 1 && <Link to={`/account/driver/details/${item?._id}`} className='_avPointer'
                style={{ textDecoration: 'none' }}
              >
                <span className='_samAnchor'>
                  <span className='viewIcon_avt'>
                    <AiOutlineEye />
                  </span>View
                </span>
              </Link>}

              {/* {masterChildDriver?.edit === 1 && <div
                onClick={(e) => editHandler(item)}
                className='_avPointer'
              >
                <span className='_samAnchor'>
                  <span className='editIcon_avt'>
                    <FiEdit />
                  </span>
                  Edit
                </span>
              </div>}

              {masterChildDriver?.delete === 1 && <div
                onClick={(e) => deleteHandler(item)}
                className='_avPointer'
              >
                <span className='_samAnchor'>
                  <span className='deleteIcon_avt'>
                    <FaRegTrashCan color='red' />
                  </span>
                  Delete
                </span>
              </div>} */}
            </div>
          </div>

        </React.Fragment>,
      driver_name:
        <Link to={`/account/driver/details/${item?._id}`} style={{ textDecoration: 'none' }} state={{ data: { driver:"own"} } }>
          {item?.name ? item?.name : '-'}
        </Link>,
      contact_nunber:
        <React.Fragment>
          {item?.contact_no ? item?.contact_no : '-'}
        </React.Fragment>,
      licence_number:
        <React.Fragment>
          {item?.licence_number ? item?.licence_number : '-'}
        </React.Fragment>,
      truck_associated:
        <React.Fragment>
          -
        </React.Fragment>,
      balance: item?.balance ? (
        <div style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          color: Number(item.balance) < 0 ? '#dc3545' : Number(item.balance) > 0 ? '#28a745' : '#1450ec'
        }}>
          <span>
            <RupeeIcon
              color={item.balance < 0 ? '#dc3545' : item.balance > 0 ? '#28a745' : '#1450ec'}
              size={20}
            />
          </span>
          <span>
            {item?.balance}
          </span>
        </div>

      ) : (
        <div style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          color: '#1450ec'
        }}>
          <span>
            <RupeeIcon
              color='#1450ec'
              size={20}
            />
          </span>
          <span>
            0
          </span>
        </div>
      ),
      status: (
        <div className="_anvSwitcherNew form-check form-switch" style={{ width: "125px" }}>
          <input
            className="form-check-input"
            type="checkbox"
            name="actStatus"
            id={`actStatus-${item._id}`}
            autoComplete="off"
            checked={checkboxStates[item._id]}
            onChange={() => handleCheckboxChange(item._id)}
          />
          <span className={checkboxStates[item._id] ? "active_label_masters_status" : "disabled_label_masters_status"}>
            {checkboxStates[item._id] ? "Active" : "Disabled"}
          </span>
        </div>
      ),
      action: (
        <React.Fragment>
          -
        </React.Fragment>
      ),
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };

  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <div className='_tabWith_topTab'>
        <BootstrapTable
          columns={columns}
          data={RowListData(ownDriverList?.length > 0 ? ownDriverList : [])}
          isLoading={loading}
        />
        {ownDriverList?.length && <div className='paginationFt_avt'>
        <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter.offset}
          onPageChange={handlePageChange}
        />
        </div>}
      </div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvas ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showOffcanvas ? 'visible' : 'hidden' }}
      >
        {showOffcanvas &&
          <AddEditOwnDriver isOpen={showOffcanvas} onClose={toggleOffcanvas} />
        }
      </div>

      <div className={showOffcanvas ? 'offBack_drop' : ''}></div>

      {isDelete?._id && (
        <CommonModal {...propsObject}>
          <p className='p-3'> Are you sure you want to delete {isDelete?.name} ? </p>
        </CommonModal>
      )}
    </div>
  );
}

export default OwnDriver;
