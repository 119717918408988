import React, { useContext, useEffect, useState, useRef } from 'react'
import StorageService from 'utils/StorageServices/StorageServices';
import { useNavigate } from 'react-router-dom'
import { getLrCountData, getMainLrListData, updateUserLrColumns } from '_services/accountsServices/lrServices'
import { CustomTableNew } from 'components/CustomTable'
import moment from 'moment';
import { UserContext } from 'context'
import { getInvoiceBillRaise, getInvoiceBillRaiseDataOfLr, resetColumnInvoiceBillRaise, updateInvoiceBillRaiseDataOfLr } from '_services/invoiceBillRaiseServices';
import { CommonInput, CustomSelect } from 'components';
import { getLrTemplateDropdownListData } from '_services/dropdownServices/dropdownService';
import { AddEditLrModal, AddInvoiceTemplateModal, ConfirmModalFor, LrDetailModal } from 'modals';

const BillRaiseSelectLR = () => {

    const navigate = useNavigate()
    const { masterData, states, setIsMaterialIndex, invoiceBillRaiseData, setInvoiceBillRaiseData } = useContext(UserContext)
    const [dataLrCount, setDataLrCount] = useState('');
    const [showAddLR, setShowAddLR] = useState(false);
    const [checkedBillRaiseLr, setCheckedBillRaiseLr] = useState(invoiceBillRaiseData?.select_lr_ids)
    const [isOpenInvoiceTemplate, setIsOpenInvoiceTemplate] = useState(false);
    const [showLrDetail, setShowLrDetail] = useState(false)
    const [leaveShowConfirm, setLeaveShowConfirm] = useState(false);
    const shouldReloadRef = useRef(false);
    // Lr table start 

    const [columns, setColumns] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [filterableColumns, setFilterableColumns] = useState([]);
    const [isMasterFilter, setIsMasterFilter] = useState(true);
    const [error, setError] = useState(true);
    const [loading, setLoading] = useState(true);
    const [pageLimit, setPageLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [lrTemplateList, setLrTemplateList] = useState([])
    const [templateStatus, setTemplateStatus] = useState({});
    const [stateStatus, setStateStatus] = useState({});

    const handleDetailsAction = (data) => {
        setShowLrDetail(data);
    }

    const transformData = (data) => {
        return data.map(item => ({
            _id: item?._id,
            lr_number: item?.lr_number ? <div className='_tblText _lrNums' onClick={(e) => handleDetailsAction(item)}> <a className='_avPointer'> {item?.lr_number} </a> </div> : '-',
            trip_id: item?.trip_id?.trip_number ? <div className='_tblText'>{item?.trip_id?.trip_number} </div> : '-',
            plan_id: item?.plan_id?.indent_number ? <div className='_tblText'>{item?.plan_id?.indent_number} </div> : '-',
            invoice_date: item?.invoice_date ? <div className='_dateNtime'>
                <span className='_tblText'>{moment(item?.invoice_date).format('DD MMM YYYY')}</span>
                <span className='_lgtText'>{moment(item?.invoice_date).format('hh:mm A')}</span>
            </div> : '-',
            dispatch_date: item?.trip_id?.dispatch_date ? <div className='_dateNtime'>
                <span className='_tblText'>{moment(item?.trip_id?.dispatch_date).format('DD MMM YYYY')}</span>
                <span className='_lgtText'>{moment(item?.trip_id?.dispatch_date).format('hh:mm A')}</span>
            </div> : '-',
            consignor_name: item?.consignor_id?.name ? <div className='_tblText _oneLine_tx'>{item?.consignor_id?.name}</div> : '-',
            branch_name: item?.branch_id?.branch ? <div className='_tblText _oneLine_tx'>{item?.branch_id.branch}</div> : '-',
            // from_location: item?.from_location ? item?.from_location : '-',
            from_location: item?.from_location ? (
                <div className='_locationWid_bx' onClick={(e) => handleDetailsAction(item)}>
                    <p className="_tblText">{item.from_location}</p>
                    {((item.consignee_id && item.consignee_id.consignee_code) || (item.consignee_id && item.consignee_id.name)) && <p className="_lgtText">{item.consignee_id && item.consignee_id.consignee_code && item.consignee_id.consignee_code} |{item.consignee_id && item.consignee_id.name && item.consignee_id.name} </p>}
                </div>
            ) : '-',
            // to_location: item?.to_location ? item?.to_location : '-',
            to_location: item?.to_location ? (
                <div className='_locationWid_bx'>
                    <p className="_tblText">{item.to_location}</p>
                    {((item.unit_id && item.unit_id.unit_code) || (item.unit_id && item.unit_id.name)) && <p className="_lgtText">{item.unit_id && item.unit_id.unit_code && item.unit_id.unit_code} |{item.unit_id && item.unit_id.name && item.unit_id.name} </p>}
                </div>
            ) : '-',
            invoice_number: item?.invoice_number ? <div className='_tblText'>{item?.invoice_number}</div> : '-',
            sap_invoice_number: item?.sap_invoice_number ? <div className='_tblText'>{item?.sap_invoice_number}</div> : '-',
            gst_number: item?.gst_number ? <div className='_tblText'>{item?.gst_number}</div> : '-',
            po_number: item?.po_number ? <div className='_tblText'>{item?.po_number}</div> : '-',
            mlr_number: item?.mlr_number ? <div className='_tblText'>{item?.mlr_number}</div> : '-',
            // lr_status: item?.lr_status ? console.log("item", item?.lr_freight) : '-',
            lr_status: item?.lr_status ? (
                <span className={masterData?.lr_status[item?.lr_status] === 'Pending' ? '_lrPending_status' : (masterData?.lr_status[item?.lr_status] === 'In Transit' ? '_lrInTransit_status' : (masterData?.lr_status[item?.lr_status] === 'At Party Point' ? '_lrPartyPoint_status' : ((masterData?.lr_status[item?.lr_status] === 'Delivered' ? '_lrDelivered_status' : (masterData?.lr_status[item?.lr_status] === 'Bill Raised' ? '_lrBillRaised_status' : '')))))}> {masterData?.lr_status[item?.lr_status] ? masterData?.lr_status[item?.lr_status] : ''} </span>
            ) : '-',
            pod_status: item?.pod_status ? <span className={masterData?.pod_status[item?.pod_status] === 'Pending' ? '_lrPending_status' : (masterData?.pod_status[item?.pod_status] === 'In Transit' ? '_lrInTransit_status' : (masterData?.pod_status[item?.pod_status] === 'At Party Point' ? '_lrPartyPoint_status' : ((masterData?.pod_status[item?.pod_status] === 'Received' ? '_lrDelivered_status' : (masterData?.pod_status[item?.pod_status] === 'Bill Raised' ? '_lrBillRaised_status' : '')))))}> {masterData?.pod_status[item?.pod_status] ? masterData?.pod_status[item?.pod_status] : ''} </span>
                : '-',
            created_by: item?.created_by ? <div className='_tblText'>{item?.created_by}</div> : '-',
            eway_bill_number: item?.eway_bill_number ? <div className='_tblText'>{item?.eway_bill_number}</div> : '-',
            eway_bill_date: item?.eway_bill_date ? <div className='_dateNtime'>
                <span className='_tblText'>{moment(item?.eway_bill_date).format('DD MMM YYYY')}</span>
                <span className='_lgtText'>{moment(item?.eway_bill_date).format('hh:mm A')}</span>
            </div> : '-',
            eway_expiry_date: item?.eway_expiry_date ? <div className='_dateNtime'>
                <span className='_tblText'>{moment(item?.eway_expiry_date).format('DD MMM YYYY')}</span>
                <span className='_lgtText'>{moment(item?.eway_expiry_date).format('hh:mm A')}</span>
            </div> : '-',
            eway_bill_status: item?.eway_bill_status ? <div className='_tblText'>{item?.eway_bill_status}</div> : '-',
            total_qty: item?.total_qty ? <div className='_tblText'>{item?.total_qty}</div> : '-',
            total_kot: item?.total_kot ? <div className='_tblText'>{item?.total_kot}</div> : '-',
            total_freight: item?.total_freight ? <div className='_tblText'>{item?.total_freight}</div> : '-',
            dispatch_date: item?.dispatch_date ? <div className='_tblText'>{moment(item?.dispatch_date).format('DD MMM YYYY')}</div> : '-',
            eway_bill_status: item?.eway_bill_status === 0 ? <span className='_lrPending_status'>  Pending </span> : '-',
            vehicle_no: item?.vehicle_no ? <div className='_tblText'>{item?.vehicle_no}</div> : '-',
            vehicle_ownership: item?.vehicle_ownership ? <div className='_tblText'>{masterData?.vehicle_ownership[item?.vehicle_ownership]}</div> : '-',
            lorry_type: item?.lorry_type ? <div className='_tblText'>{item?.lorry_type}</div> : '-',
            supplier_name: item?.supplier_name ? <div className='_tblText'>{item?.supplier_name}</div> : '-',
            vehicle_type: item?.vehicle_type ? <div className='_tblText'>{item?.vehicle_type}</div> : '-',
            indent_number: item?.indent_number ? <div className='_tblText'>{item?.indent_number}</div> : '-',
            pod_date: item?.pod_date ? <div className='_tblText'>{moment(item?.pod_date).format('DD MMM YYYY')}</div> : '-',
            pod_file: item?.pod_file ? <div className='_tblText'>{item?.pod_file?.length ? item?.pod_file?.length : 0}</div> : '-',
            trip_number: item?.trip_number ? <div className='_tblText'>{item?.trip_number} </div> : '-',
            driver_name: item?.driver_name ? <div className='_tblText'>{item?.driver_name}</div> : '-',
            total_weight: item?.total_weight ?
                <div className='_tblText'>{item?.total_weight}</div>
                : '-'
        }));
    };

    const getData = async (page, templateObj) => {
        try {
            let postData = {
                company_id: StorageService.getCompanyId(),
                consignor_id: invoiceBillRaiseData?.consignor_id?.value ? invoiceBillRaiseData?.consignor_id?.value : '',
                template_id: templateObj?.value,
                state: '',
                limit: pageLimit,
                offset: currentPage,
                search: '',
                select_lr_ids: [],
            }
            const response = await getInvoiceBillRaiseDataOfLr(postData);
            setColumns(response?.data?.lr_columns);
            setApiData(transformData(response?.data?.lr_list));
            setFilterableColumns(response?.data?.lr_filter_columns);
            setIsMasterFilter(true);
            setTotalPages(Math.ceil(response?.data?.total_record / pageLimit));
            setLoading(false);
            // setFilterableColumns(response.filterableColumns);
            // setIsMasterFilter(response.isMasterFilter);
        } catch (error) {
            setLoading(false);
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    const updateLrColumns = async (data) => {
        let postData = {
            company_id: StorageService.getCompanyId(),
            template_id: templateStatus?.value,
            lr_columns: data
        }
        try {
            const response = await updateInvoiceBillRaiseDataOfLr(postData);
            setColumns(response?.data?.lr_columns);
            setApiData(transformData(response?.data?.lr_list));
            setFilterableColumns(response?.data?.lr_filter_columns);
            setIsMasterFilter(true);
            setTotalPages(Math.ceil(response?.data?.total_record / pageLimit));
            setLoading(false);
            // setFilterableColumns(response.filterableColumns);
            // setIsMasterFilter(response.isMasterFilter);
        } catch (error) {
            setLoading(false);
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    // const getLrTopStatusCount = async () => {
    //     try {
    //         let object = {
    //             company_id: StorageService.getCompanyId(),
    //             branch_id: '',
    //             consignor_id: '',
    //             search: '',
    //             start_date: '',
    //             end_date: '',
    //         }
    //         const res = await getLrCountData(object);
    //         if (res?.responseCode == 200) {
    //             setDataLrCount(res?.data?.lr_status)
    //         }
    //     } catch (error) {
    //         // throw error;
    //     }
    // }

    useEffect(() => {
        if (templateStatus?.value) {
            getData(currentPage, templateStatus);
        }
        setIsMaterialIndex('');
        // getLrTopStatusCount();
        setLoading(true);
    }, [masterData, showAddLR, currentPage, templateStatus, StorageService.getCompanyId(), stateStatus])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    // Lr table end

    const renderUiStepers = () => {
        return <>
            <div className='contentH_new'>
                <h4>Create Bill Raise</h4>
                <div className='_stepedBxs'>
                    <div className='_seperateCheckes activated'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <circle cx="10.4999" cy="10.5" r="10.5" fill="#20A154" />
                            <path d="M5.99988 10.1672L9.16704 13.3343L15.4999 7" stroke="white" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <span>Bill Details</span>
                    </div>

                    <div className='_seperateCheckes activated'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
                            <path d="M6 10.1672L9.16716 13.3343L15.5 7" stroke="#828282" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <span>Select LR</span>
                    </div>

                    <div className='_seperateCheckes'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
                            <path d="M6 10.1672L9.16716 13.3343L15.5 7" stroke="#828282" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <span>Selected LR</span>
                    </div>

                    <div className='_seperateCheckes'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
                            <path d="M6 10.1672L9.16716 13.3343L15.5 7" stroke="#828282" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <span>Summary</span>
                    </div>
                </div>
            </div>
        </>
    }

    useEffect(() => {
        if (lrTemplateList?.length > 0) {
            setTemplateStatus(lrTemplateList[0]);
            setInvoiceBillRaiseData((s) => ({ ...s, template_id: lrTemplateList[0] }))
        }else{
            setLoading(false);
        }
    }, [lrTemplateList])

    const getTemplateLrList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                search: "",
            }
            const res = await getLrTemplateDropdownListData(object);
            if (res?.data?.list?.length > 0) {
                let responseData = res?.data?.list?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?._id,
                    }
                })
                setLrTemplateList(responseData?.reverse());
            }
        } catch (error) {
            // throw error;
        }
    }

    useEffect(() => {
        getTemplateLrList();
    }, [isOpenInvoiceTemplate, StorageService.getCompanyId()])

    const showingDropdownFilters = () => {
        return <>
            <div className="_fleX_inds _blRaise_fits">
                <div className="_divForth">
                    <CommonInput
                        type="text"
                        placeholder="Party Name"
                        label="Party Name"
                        name='name'
                        value={invoiceBillRaiseData?.consignor_id?.label ? invoiceBillRaiseData?.consignor_id?.label : ''}
                        disabled={true}
                    />
                </div>

                {
                    invoiceBillRaiseData?.receiving_state?.label ? <div className="_divForth">
                        <CommonInput
                            type="text"
                            placeholder="Receiving State"
                            label="Receiving State *"
                            name='state'
                            value={invoiceBillRaiseData?.receiving_state?.label ? invoiceBillRaiseData?.receiving_state?.label : ''}
                            disabled={true}
                        />
                    </div> :
                        <div className="_divForth">
                            <CustomSelect
                                label="All States"
                                placeholder="All States"
                                options={states?.length > 0 ? states?.map((el) => {
                                    return {
                                        ...el,
                                        label: el?.name,
                                        value: el?.name,
                                    }
                                }) : []}
                                name="state"
                                value={stateStatus}
                                onSelect={(data) => setStateStatus(data)}
                            />
                        </div>
                }

                <div className="_divForth">
                    <CustomSelect
                        label="Template Format"
                        placeholder="Template Format"
                        options={lrTemplateList}
                        name="template_id"
                        value={templateStatus}
                        isAddButton={true}
                        addButtonAction={(e) => setIsOpenInvoiceTemplate(true)}
                        onSelect={(data) => { setTemplateStatus(data); setInvoiceBillRaiseData((s) => ({ ...s, template_id: data })); }}
                    />
                </div>
            </div>
        </>
    }

    const handleSelectLrSteper = async (e) => {
        e.preventDefault();
        if (checkedBillRaiseLr?.length > 0) {
            setInvoiceBillRaiseData((s) => ({ ...s, select_lr_ids: checkedBillRaiseLr, template_id: templateStatus, state: stateStatus }));
            navigate('/invoicing/selectedlr');
        }
    }

    const listCheckboxHandle = async (id) => {
        let data = [...checkedBillRaiseLr];
        let values = data?.includes(id) ? data?.filter((item) => item !== id) : [...data, id]
        setCheckedBillRaiseLr(values);

        // setCheckedBillRaiseLr((checkedBillRaiseLr) =>
        //     checkedBillRaiseLr?.includes(id)
        //         ? checkedBillRaiseLr?.filter((item) => item !== id)
        //         : [...checkedBillRaiseLr, id]
        // );
    };

    let AddtemplateInvoiceModal = {
        isOpen: isOpenInvoiceTemplate,
        closeModal: (e) => setIsOpenInvoiceTemplate(!isOpenInvoiceTemplate),
        modalMainClass: "_dashCommon_Modal _lgModal blurModal",
        modalTitle: "Create Template",
        submitTitle: "Create Template",
        data: masterData?.billraise_lr_list_columns,
        isDivForth: true,
        columns: masterData.billraise_lr_list_columns,
        filterableColumns: filterableColumns,
        data: apiData,
        setColumns: (data) => updateLrColumns(data)
    }

    const handleNextModal = (data) => {
        setShowLrDetail(!showLrDetail);
        setShowAddLR(data);
    }

    const discardHandlerFunction = (e) => {
        e.preventDefault();
        setLeaveShowConfirm(true);
    }

    const submitLeaveModalProps = () => {
        navigate('/invoicing')
    }

    let leaveModalProps = {
        show: leaveShowConfirm,
        closeModal: (e) => setLeaveShowConfirm(!leaveShowConfirm),
        title: "Leave This Page",
        submitTitle: "Confirm",
        onSubmit: submitLeaveModalProps,
        confirmModal: "confirmModal",
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (!shouldReloadRef.current) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const resetLRTableColumn = async () => {
        try {
            let postData = {
                company_id: StorageService.getCompanyId(),
                template_id: templateStatus?.value ? templateStatus?.value : '',
            }
            const response = await resetColumnInvoiceBillRaise(postData);
            if (response?.responseCode == 200) {
                setColumns(response?.data?.lr_columns)
            }

        } catch (error) {
            console.error('Error fetching deault LR table column', error);
        } finally {
        }
    }

    return (
        <>
            <div className='_billRaise_dataTable'>
                <CustomTableNew
                    columns={columns}
                    data={apiData}
                    filterableColumns={filterableColumns}
                    isMasterFilter={isMasterFilter}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    itemsPerPage={pageLimit}
                    setColumns={(data) => updateLrColumns(data)}
                    showingStatus={renderUiStepers()}
                    isShowingCheckbox={true}
                    checkedBillRaiseLr={checkedBillRaiseLr}
                    listCheckboxHandle={(data) => listCheckboxHandle(data)}
                    showDropdownFilters={showingDropdownFilters()}
                    showDropdownFilterclassName={true}
                    loading={loading}
                    resetLRTableColumn={() => resetLRTableColumn()}
                    isTemplateName={true}
                // setCheckedBillRaiseLr={setCheckedBillRaiseLr}
                />
            </div>

            <div className='_billRaiseFooter'>
                <div className='prevDiscard_bx'>
                    <button className='_previousBtn' onClick={(e) => navigate('/invoicing/createbillraise')}><span>Previous</span></button>
                    <button className='_discardBtn' onClick={(e) => discardHandlerFunction(e)}><span>Discard</span></button>
                </div>

                <div>
                    <button
                        className='_stepSuccessBtn'
                        disabled={checkedBillRaiseLr?.length === 0}
                        onClick={(e) => handleSelectLrSteper(e)}
                    >
                        Next : Select  LR
                    </button>
                </div>
            </div>

            {
                isOpenInvoiceTemplate &&
                (
                    <AddInvoiceTemplateModal {...AddtemplateInvoiceModal} />
                )
            }

            <div
                className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${showAddLR ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: showAddLR ? 'visible' : 'hidden' }}
            >
                {showAddLR
                    && (
                        <AddEditLrModal isOpen={showAddLR} onClose={() => setShowAddLR(false)} />
                    )
                }
            </div>
            <div className={showAddLR ? 'offBack_drop' : ''}></div>

            <div
                className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${showLrDetail ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: showLrDetail ? 'visible' : 'hidden' }}
            >
                {showLrDetail
                    && (
                        <LrDetailModal
                            isOpen={showLrDetail}
                            onClose={(e) => setShowLrDetail(false)}
                            nextModal={(e) => handleNextModal(e)}
                        />
                    )
                }
            </div>
            <div className={showLrDetail ? 'offBack_drop' : ''}></div>

            {leaveShowConfirm && (
                <ConfirmModalFor {...leaveModalProps}>
                    <>
                        <p className="_confirmPrx"> Do you really want leave this Page ?</p>
                    </>
                </ConfirmModalFor>
            )}
        </>
    )
}

export default BillRaiseSelectLR